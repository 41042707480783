import Tooltip from "../Tooltip";
import { ITooltipProps } from "../interfaces/ITooltipProps";

interface Props extends ITooltipProps {
  classNameSVG?: string;
  tooltipContent?: string;
}

const DeleteButton = ({ onClick, width = 40, height = 40, className, classNameSVG, tooltipContent }: Props) => {
  return (
    <Tooltip content={tooltipContent}>
      <button onClick={onClick} className={className}>
        <svg
          className={`stroke-1 ${classNameSVG ? classNameSVG : "stroke-[#86878b] hover:stroke-[#4495ff]"}`}
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 40 40"
          fill="none"
          stroke="currentColor"
        >
          <path fill="none" stroke="none" d="M0 0h40v40H0z" />
          <g data-name="btn_delete">
            <path data-name="\uC120 3" transform="translate(12.999 13)" strokeLinecap="round" strokeWidth={2} fill="none" d="m0 0 14 14" />
            <path data-name="\uC120 3" transform="rotate(90 6.779 19.779)" strokeLinecap="round" strokeWidth={2} fill="none" d="m0 0 13.56 13.559" />
          </g>
        </svg>
      </button>
    </Tooltip>
  );
};

export default DeleteButton;
