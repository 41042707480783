import { ButtonProps } from "../interfaces/ButtonProps";

const DefaultAvatar = ({ width = 44, height = 44, ...props }: ButtonProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 44 44" {...props}>
    <path d="M22 0A22 22 0 1 1 0 22 22 22 0 0 1 22 0" fill="#e1e1e1" />
    <path d="M22 7.213a7.574 7.574 0 1 1-7.574 7.574A7.574 7.574 0 0 1 22 7.213" fill="#fff" />
    <path d="M22 25.246A12.623 12.623 0 0 0 9.377 37.869v2.144a21.976 21.976 0 0 0 25.246 0v-2.144A12.623 12.623 0 0 0 22 25.246" fill="#fff" />
  </svg>
);

export default DefaultAvatar;
