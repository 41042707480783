import YesNoDialog from "../YesNoDialog";
import { useDispatch } from "react-redux";
import { setAssignHostPopup } from "../../store/windowControlSlice";
import RoomClient from "../../RoomClient";
import { toast } from "react-toastify";
import { setHostBid } from "../../store/roomInfoSlice";
import { changeHostBid } from "store/peerSlice";
import { useTranslation } from "react-i18next";

interface IProps {
  roomClient: RoomClient;
  isOpen: boolean;
  username: string;
  bid: string;
}

const AssignRoomHost = (props: IProps) => {
  const { isOpen, roomClient, username, bid } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlerHostSet = (res: boolean) => {
    if (!res) return;

    roomClient.closeScreenShare();

    roomClient.sendChangeHost(bid, (result: boolean) => {
      if (result) {
        dispatch(changeHostBid(bid));
        dispatch(setHostBid(bid));
      } else {
        toast.warn(t("msg.사회자 양도 실패"), {
          autoClose: 3000,
          progress: undefined,
        });
      }
    });
  };

  const setIsOpen = (open: boolean) => {
    dispatch(
      setAssignHostPopup({
        isShow: open,
        username,
        bid,
      }),
    );
  };

  return (
    <YesNoDialog
      msg={`${username} ${t("msg.사회자 양도확인")}`}
      isOpen={isOpen}
      callback={(res: boolean) => handlerHostSet(res)}
      setIsOpen={(open: boolean) => setIsOpen(open)}
      leftButtonText={t("취소")}
      rightButtonText={t("확인")}
    />
  );
};

export default AssignRoomHost;
