import { SimpleUserInfo } from "types/commonTypes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectPeers } from "store/peerSlice";

function useFindUser(bid: string) {
  const peers = useSelector(selectPeers);
  const [data, setData] = useState<SimpleUserInfo>();

  useEffect(() => {
    const findPeer = peers.find(peer => peer.peerId === bid);
    const simpleUserInfo: SimpleUserInfo = {
      isInsider: false,
      bidOrEmail: bid,
      username: findPeer?.userInfo.username,
      picurl: findPeer?.userInfo.picurl,
    };

    setData(simpleUserInfo);
  }, [bid, peers]);

  return data;
}

export default useFindUser;
