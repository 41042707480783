import React, { useState } from "react";

interface IMobileMenuButton {
  SvgIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  onClick: any;
  isSubMenu?: boolean;
}

const MobileMenuButton = ({ SvgIcon, label, onClick, isSubMenu }: IMobileMenuButton) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className={`px-6 h-[2.75rem] flex items-center cursor-pointer ${isSubMenu && "ml-6"}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-6 h-6">
        <SvgIcon fill={isHovered ? "#4495ff" : "#fff"} />
      </div>
      <div className={`ml-3 ${isHovered ? "text-blue-10" : "text-white"} overflow-x-hidden whitespace-nowrap text-ellipsis`}>{label}</div>
    </div>
  );
};

export default MobileMenuButton;
