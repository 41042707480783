import React, { useState } from "react";
import Tooltip from "components/common/Tooltip";

interface IProps {
  SvgIconNormal: React.FC<React.SVGProps<SVGSVGElement>>;
  SvgIconHover: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick: any;
  isCheck?: boolean;
  tooltipContent?: string;
}

const IconButton = React.forwardRef((props: IProps, ref) => {
  const { SvgIconNormal, SvgIconHover, onClick, isCheck, tooltipContent } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip content={tooltipContent || ""}>
      <div
        data-tooltip-content={tooltipContent || ""}
        data-tooltip-id="tooltip"
        className="cursor-pointer"
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered || isCheck ? <SvgIconHover /> : <SvgIconNormal />}
      </div>
    </Tooltip>
  );
});

export default IconButton;
