import React from "react";
import RoomClient from "RoomClient";
import UserInfoRow from "./UserInfoRow";
import UserCommand from "./UserCommand";
import { withRoomContext } from "RoomContext";
import { PeerInfo } from "types/clientTypes";

interface IProps {
  roomClient: RoomClient;
  members: PeerInfo[];
  setIsOpenSubWindow: (isOpen: boolean) => void;
  searchUserName: string;
}

export const JoinMemberList = ({ roomClient, members, setIsOpenSubWindow, searchUserName }: IProps) => {
  return (
    <div className="w-full border-0 border-red-20">
      {members.map(m => {
        if (searchUserName && !m.userInfo.username.includes(searchUserName)) {
          return null;
        }

        return (
          <div key={m.socketId} className="flex w-full h-12 justify-between items-center py-[5px] px-[10px] bg-[#333]">
            <div className="overflow-hidden">
              <UserInfoRow m={m.userInfo} isHost={m.isHost} />
            </div>

            <div className="flex h-full items-center shrink-0 grow-0">
              <UserCommand roomClient={roomClient} m={m} setIsOpenSubWindow={setIsOpenSubWindow} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(withRoomContext(JoinMemberList));
