import { IInnerAttendee } from "api/manager/invite";
import ProfileImg from "components/ProfileImg";
import CloseButton from "components/common/buttons/CloseButton";
// import { TreeDataNode } from "components/common/interfaces/OrgData";

interface IProps {
  data: IInnerAttendee;
  onDelete: (key: string) => void;
}
const InternalAttendeeBox = (props: IProps) => {
  const { data, onDelete } = props;

  return (
    <span className="h-[2.25rem] border border-[#86878b] text-sm rounded p-2 mx-1 mb-2 bg-[#333] flex shrink-0 items-center select-none">
      <div className="w-7 h-7 shrink-0">
        <ProfileImg borderWidth={1} picurl={data.picurl} borderColor={"#fff"} />
      </div>
      <div className="ml-1">{data.name}</div>
      <div className="ml-1">{data.position}</div>
      {data.team && <span>({data.team})</span>}
      {!data.readonly && <CloseButton width={13} height={13} className="ml-1" classNameSVG="stroke-C-c8cace" onClick={() => onDelete(data.bid)} />}
    </span>
  );
};

export default InternalAttendeeBox;
