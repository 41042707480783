import Axios from "axios";
import { store } from "store/store";
import { getConfig } from "../config";
import { Attachment } from "components/docSharing/filelist/AttachmentTypes";

export const getAttachments = async (roomId: number): Promise<Attachment[]> => {
  try {
    const url = `${store.getState().env.value.MGR_SERVER}/api/video/meetings/attachments/list`;

    let data = {
      meetingId: roomId,
    };

    const config = getConfig(store.getState().auth.token);
    const axiosInstance = Axios.create({ timeout: 10000 });

    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (e) {
    console.log("error from manager attachments list api");
    console.log(e);
    return [];
  }
};
