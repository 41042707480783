import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectAllSttData, selectTempStt } from "store/noteSlice";
import { selectIsSttTranslation } from "store/roomInfoSlice";
import { selectIsShowCaption } from "store/windowControlSlice";
import { selectSttToLanguage } from "store/settingSlice1";
import { SpeechToTextData } from "types/commonTypes";

interface SpeechToTextProps {
  isSmall: boolean;
  bidOrEmail: string;
  isRemote: boolean;
}

const SubtitleView = (props: SpeechToTextProps) => {
  const { isSmall, bidOrEmail, isRemote } = props;
  const textRef = useRef<HTMLDivElement>(null);

  const [recogText, setRecogText] = useState(
    "",
    // '긴 문장을 인식했을때 표시되는 형태를 확인해 봅니다. 어떻게 나올지 궁금하네요. 자동으로 줄넘김이 되면 좋겠는데 그게 될지 모르겠습니다.',
    // '짧은 문장',
  );
  const [transText, setTransText] = useState("");

  const allNoteData = useSelector(selectAllSttData);
  const isShowCaption = useSelector(selectIsShowCaption);
  const tempStt = useSelector(selectTempStt);
  const translationEnabled = useSelector(selectIsSttTranslation);
  const transLanguage = useSelector(selectSttToLanguage)?.slice(0, 2);

  const showTransText = useCallback(
    (noteToShow: SpeechToTextData) => {
      const translations = noteToShow.translations;
      if (translations) {
        const tr = translations?.filter(item => item.to === transLanguage)[0];
        if (tr && translationEnabled) {
          setTransText(tr.text);
        }
      } else setTransText("");
    },
    [transLanguage, translationEnabled],
  );

  useEffect(() => {
    const myNote = allNoteData.filter(note => note.userInfo.bidOrEmail === bidOrEmail);
    if (myNote.length > 0) {
      const noteToShow = myNote[myNote.length - 1];
      setRecogText(noteToShow.text);
      showTransText(noteToShow);
    }
  }, [allNoteData, bidOrEmail, isRemote, showTransText, transLanguage, translationEnabled]);

  useEffect(() => {
    if (tempStt && tempStt?.userInfo.bidOrEmail === bidOrEmail) {
      setRecogText(tempStt.text);
      showTransText(tempStt);
    }
  }, [tempStt, bidOrEmail, showTransText]);

  useEffect(() => {
    if (recogText.length > 0 && textRef.current) {
      textRef.current.hidden = false;
      const timer = setTimeout(() => {
        if (textRef.current) textRef.current.hidden = true;
      }, 20000);

      return () => clearTimeout(timer);
    }
  }, [recogText]);

  return (
    <div>
      {isShowCaption && recogText.length > 0 && (
        <div ref={textRef} className="w-full py-1 px-2 bg-black/75 text-white">
          <div>{recogText}</div>
          {translationEnabled && <div className={`text-center ${isSmall ? "text-[1rem]" : "text-[2.5rem]"} `}>{transText}</div>}
        </div>
      )}
    </div>
  );
};

SubtitleView.defaultProps = {
  isSmall: false,
};

export default SubtitleView;
