import Axios from "axios";
import { store } from "store/store";
import { getConfig } from "../config";
import { Attachment } from "components/docSharing/filelist/AttachmentTypes";

export interface ICreateReq {
  meetingId: number;
  attachments: Attachment[];
}

export const deleteAttachment = async (fileId: string) => {
  try {
    const url = `${store.getState().env.value.MGR_SERVER}/api/video/meetings/attachments`;
    let data = { attachmentId: fileId };
    // console.log("attachments deleteFile 실행:", data);

    const config = getConfig(store.getState().auth.token);
    const axiosInstance = Axios.create({ timeout: 10000 });
    // console.log("body에 넣을값", { data, ...config });

    const response = await axiosInstance.delete(url, { data, ...config });
    // console.log("mgr api deleteAttachment response", response);
    return { result: response.status === 200 };
  } catch (e) {
    console.log("error from manager deleteAttachment api", e);
  }
};
