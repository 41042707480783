import { Value } from "components/common/interfaces/TypeSelectListbox";
import { useTranslation } from "react-i18next";
import { isSafari } from "react-device-detect";
interface SuccessProp {
  speakerDevice: Value;
  micDevice: Value;
}
const TestSuccess = (props: SuccessProp) => {
  const { speakerDevice, micDevice } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col p-[1.5rem]">
      <div className="text-base font-bold text-center mt-[0.15rem]">{t("setting.스피커 및 마이크 상태가 양호합니다.")}</div>

      <div className="mt-[6.18rem]">
        <table className="table w-full">
          <tbody className="text-sm">
            <tr className="h-8 border-b-4 border-b-[#0000]">
              <td className="w-1 h-full pr-12 whitespace-nowrap align-middle">
                <span>{t("스피커")}</span>
              </td>
              <td className="text-left align-middle">
                {!isSafari ? <span className="">{speakerDevice.name}</span> : <span className="">{t("시스템 기본")}</span>}
              </td>
            </tr>
            <tr className="h-8 border-b-1 border-b-[#0000]">
              <td className="w-1 h-full pr-12 whitespace-nowrap align-middle">
                <span className="">{t("마이크")}</span>
              </td>
              <td className="text-left align-middle">
                <span className="">{micDevice.name}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TestSuccess;
