import Axios from "axios";
import { store } from "store/store";
import { getConfig } from "../config";
import { Attachment } from "components/docSharing/filelist/AttachmentTypes";

export interface ICreateReq {
  meetingId: number;
  attachments: Attachment[];
}

export const updateAttachment = async (fileId: string, convertJobId: string) => {
  try {
    const url = `${store.getState().env.value.MGR_SERVER}/api/video/meetings/attachments`;
    let data = { fileid: fileId, convertJobId };
    // console.log("attachments updateAttachment 실행:", data);

    const config = getConfig(store.getState().auth.token);
    const axiosInstance = Axios.create({ timeout: 30000 });

    const response = await axiosInstance.patch(url, data, config);
    // console.log("mgr api updateAttachment response", response);
    return { result: response.status === 200 };
  } catch (e) {
    console.log("error from manager updateAttachment api", e);
  }
};
