import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectConsumers } from "store/consumerSlice";
import { setCanClose } from "store/chatSlice";
import { MyConsumer } from "types/clientTypes";
import { AuthUserInfo, SimpleUserInfo } from "types/commonTypes";
import ProfileImg from "../../ProfileImg";
import { useTranslation } from "react-i18next";

interface PickerProps {
  onSelect: (name: string) => void;
}

const MentionPicker = (props: PickerProps) => {
  const { onSelect } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const consumers = useSelector(selectConsumers);

  useEffect(() => {
    dispatch(setCanClose(false));

    return () => {
      dispatch(setCanClose(true));
    };
  }, [dispatch]);

  const handleAll = () => {
    onSelect(t("chat.@모두"));
  };

  const handlePick = (user: AuthUserInfo) => {
    onSelect(`@${user.username}`);
  };

  return (
    <div className="bg-[#555] h-full text-white text-sm shadow-md px-[0rem] py-1">
      <div className="h-[2.75rem] flex justify-start items-center cursor-pointer px-4 hover:bg-[#777]" onClick={handleAll}>
        <span className="font-bold">{t("chat.@모두")}</span>
        <span className="text-[#777] ml-4">{t("chat.전체멘션")}</span>
      </div>
      <div>
        {consumers.map((item: MyConsumer) => {
          if (item.consumer.kind === "video") return null;

          return (
            <div
              key={item.consumer.producerId}
              className="h-[2.75rem] px-4 flex justify-start items-center cursor-pointer hover:bg-[#777]"
              onClick={() => handlePick(item.consumer.appData.userInfo as AuthUserInfo)}
            >
              <div className="ml-1 w-8 h-8">
                <ProfileImg picurl={(item.consumer.appData.userInfo as SimpleUserInfo).picurl} borderWidth={1} />
              </div>

              <div className="ml-4">{(item.consumer.appData.userInfo as SimpleUserInfo).username}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MentionPicker;
