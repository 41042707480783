import { useState } from "react";
import { useGetOrgTree } from "../api/common";
import { TreeDataNode, ResponseOrgData, User } from "../interfaces/OrgData";
import { AxiosResponse } from "axios";
import _ from "lodash";

interface RequestOrgParent {
  cmd: string;
  sid: string;
  what: { gid: string }[];
  kind: string;
  include: string;
}

const generateTreeDataDept = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  if (!orgData.result.dept) return [];

  const {
    result: {
      dept: { data: dataDept },
    },
  } = orgData;

  return dataDept.map(
    (item: {
      // gid: string;
      deptcode: string;
      // multiname: Record<string, string>[];
      deptname: string;
      parentdeptcode: string;
    }) => {
      // const language = _.find(item.multiname, ["language", "ko"]);

      return {
        // key: item.gid,
        key: item.deptcode,
        title: item.deptname,
        isLeaf: false,
        parentdeptcode: item.parentdeptcode,
        children: null,
      };
    },
  );
};

const generateTreeDataUser = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  if (!orgData.result.user) return [];

  const {
    result: {
      user: { data: dataUser },
    },
  } = orgData;

  return dataUser.map((item: User) => {
    // const language = _.find(item.multiname, ["language", "ko"]);

    return {
      key: `${item.deptcode}|${item.bid}`,
      bid: item.bid,
      title: item.username,
      isLeaf: true,
      parentdeptcode: item.deptcode,
      position: item.position,
      team: item.deptname,
      url: item.picurl,
      children: null,
    };
  });
};

const generateTreeData = (orgData: AxiosResponse<ResponseOrgData>["data"]) => {
  const dataDept = generateTreeDataDept(orgData);
  const dataUser = generateTreeDataUser(orgData);
  return dataDept.concat(dataUser);
};

const useFinalOrgFlatData = (requestOrgParent: RequestOrgParent) => {
  const [finalOrgFlatData, setFinalOrgFlatData] = useState<TreeDataNode[]>([]);
  const orgFlatParent = useGetOrgTree(requestOrgParent, {
    refetchOnWindowFocus: false,
    onSuccess: (newData: AxiosResponse<ResponseOrgData>) => {
      const newFlatData = generateTreeData(newData.data);
      // console.log("🚀 ~ file: useFinalOrgFlatData.ts:88 ~ useFinalOrgFlatData ~ newData:", newFlatData);

      setFinalOrgFlatData(oldData => {
        // console.log("useFinalOrgFlatData oldData", oldData);
        const oldAndNew = [...oldData, ...newFlatData];

        return _.uniqBy(oldAndNew, "key");
      });
    },
  });

  return { orgFlatParent, finalOrgFlatData };
};

export default useFinalOrgFlatData;
