import React, { useState } from "react";
import { selectIsShowBottomVideoList, setIsShowBottomVideoList } from "store/windowControlSlice";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as IconPipOn } from "assets/btn-pip-on.svg";
import { ReactComponent as IconPipOff } from "assets/btn-pip-off.svg";

interface IProps {
  divStyle: React.CSSProperties;
}

const PipOnOffButton = ({ divStyle }: IProps) => {
  const [isHoveredPip, setIsHoveredPip] = useState(false);
  const isShowBottomVideoList = useSelector(selectIsShowBottomVideoList);
  const dispatch = useDispatch();

  return (
    <div
      className="absolute bottom-4 right-4 w-[2.25rem] h-[2.25rem] cursor-pointer z-30"
      onMouseEnter={() => setIsHoveredPip(true)}
      onMouseLeave={() => setIsHoveredPip(false)}
      style={divStyle}
    >
      {isShowBottomVideoList ? (
        <IconPipOn
          fill={isHoveredPip ? "#4495ff" : "#fff"}
          onClick={() => {
            dispatch(setIsShowBottomVideoList(false));
            setIsHoveredPip(false);
          }}
        />
      ) : (
        <IconPipOff
          fill={isHoveredPip ? "#4495ff" : "#fff"}
          onClick={() => {
            dispatch(setIsShowBottomVideoList(true));
            setIsHoveredPip(false);
          }}
        />
      )}
    </div>
  );
};

export default PipOnOffButton;
