import { forwardRef, Ref } from "react";
import Avatar from "./Avatar";
import CloseButton from "../buttons/CloseButton";

interface Props {
  url: string;
  showDelete?: boolean;
  name: string;
  position?: string;
  team: string;
  presence?: string;
  onClickDelete?: () => void;
}

const Member = forwardRef(function FnMember(
  { url, showDelete = false, name, position, team, presence, onClickDelete, ...props }: Props,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <div className="flex flex-row gap-x-3 items-center w-full" ref={ref} {...props}>
      <Avatar url={url} className="flex-none" presence={presence} />
      <div className="flex-auto text-sm text-C-555555">
        <div>
          <b>{name}</b> <span className="text-C-676767">{position}</span>
        </div>
        <div>{team}</div>
      </div>
      {showDelete && <CloseButton width={13} height={13} className="flex-none" classNameSVG="stroke-C-b4b5b8" onClick={onClickDelete} />}
    </div>
  );
});

export default Member;
