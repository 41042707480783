import React, { useEffect, useRef, useState, useCallback } from "react";
import RoomClient from "RoomClient";
import SwitchComp from "../Switch";
import { withRoomContext } from "RoomContext";
import { ReactComponent as IconInfo } from "assets/icon-info.svg";
import { selectRoomInfo, selectIsPrivate, selectPassword, setPassword, selectRoomSetting, setRoomSetting } from "store/roomInfoSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeRoomSetting } from "api/manager/room";
import { toast } from "react-toastify";

interface PropsDefault {
  onCloseView: () => void;
  roomClient: RoomClient;
  buttonRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}

const LockSetting = (props: PropsDefault) => {
  const { onCloseView, roomClient, buttonRef, isMobile } = props;
  const { t } = useTranslation();
  const rootDivRef = useRef<HTMLDivElement>(null);
  const isPrivate = useSelector(selectIsPrivate);
  const roomInfo = useSelector(selectRoomInfo);
  const roomSetting = useSelector(selectRoomSetting);
  const savedPassword = useSelector(selectPassword);
  const [enableInputPassword, setEnableInputPassword] = useState(savedPassword.length > 0);
  const [tipShow, setTipShow] = useState(false);
  const pwdInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const [inputPassword, setInputPassword] = useState(savedPassword);

  const notiSetPrivate = (checked: boolean) => {
    let msg = "";
    if (checked) msg = t("lock.비공개회의로 설정되었습니다.");
    else msg = t("lock.공개회의로 설정되었습니다.");
    toast.info(msg);
    roomClient.sendNoti(msg, false);
  };

  const notiSetPassword = useCallback(() => {
    let msg = "";
    if (inputPassword.length === 0) msg = t("lock.비밀번호가 해제되었습니다.");
    else msg = t("lock.비밀번호가 설정되었습니다.");

    toast.info(msg);
    roomClient.sendNoti(msg, false);
  }, [inputPassword.length, roomClient, t]);

  const changePassword = useCallback(() => {
    const newSetting = { ...roomSetting, password: inputPassword };
    roomClient.sendChangeRoomSetting(newSetting, (result: boolean) => {
      if (result) {
        changeRoomSetting(roomInfo.id, newSetting);
        dispatch(setPassword(inputPassword));
        notiSetPassword();
      }
    });
  }, [dispatch, inputPassword, notiSetPassword, roomClient, roomInfo.id, roomSetting]);

  const checkPasswordChange = useCallback(() => {
    if (enableInputPassword && inputPassword.length !== 4) {
      toast.warn(t("lock.비밀번호길이오류"));
    } else if (inputPassword !== savedPassword) {
      changePassword();
    }
  }, [changePassword, enableInputPassword, inputPassword, savedPassword, t]);

  const handleClickOutside = useCallback(
    (ev: Event) => {
      if (buttonRef && buttonRef.current?.contains(ev.target as Node)) {
        checkPasswordChange();
        return;
      }

      if (rootDivRef.current && !rootDivRef.current.contains(ev.target as Node)) {
        checkPasswordChange();
        onCloseView();
      }
    },
    [buttonRef, checkPasswordChange, onCloseView],
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (pwdInputRef.current) {
      pwdInputRef.current.disabled = savedPassword.length === 0;
    }

    setInputPassword(savedPassword);
    setEnableInputPassword(savedPassword.length > 0);
  }, [savedPassword.length, pwdInputRef, savedPassword]);

  const onChangedPrivate = (checked: boolean) => {
    const password = checked ? roomSetting.password : "";

    const newRoomInfo = { ...roomSetting, isPrivate: checked, password };
    roomClient.sendChangeRoomSetting(newRoomInfo, async (result: boolean) => {
      if (result) {
        dispatch(setRoomSetting(newRoomInfo));
        changeRoomSetting(roomInfo.id, newRoomInfo);
        notiSetPrivate(checked);
      }
    });
    if (!checked && enableInputPassword) onChangedEnablePassword(false);
  };

  const onChangedEnablePassword = (checked: boolean) => {
    if (checked && !isPrivate) return;

    setEnableInputPassword(checked);
    if (!checked) {
      setInputPassword("");
    }

    if (pwdInputRef.current) {
      pwdInputRef.current.disabled = !checked;
    }
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 4) return;
    setInputPassword(e.target.value);
  };

  return (
    <div
      ref={rootDivRef}
      className={`${
        !isMobile && "md:absolute md:bottom-1"
      } flex flex-col items-center w-[18.75rem] z-40 rounded shadow-lg bg-[#333] select-none max-h-[70vh] text-white`}
    >
      <div className="flex h-9 justify-start items-center w-full pl-4">
        <div className="text-sm font-bold">{t("lock.회의실 잠금")}</div>
        <div className="relative">
          <IconInfo className="relative ml-[0.125rem]" onMouseEnter={() => setTipShow(true)} onMouseLeave={() => setTipShow(false)} />

          {tipShow && (
            <div className="absolute bottom-[100%] mb-1 md:ml-2 left-1/2 -translate-x-1/2 w-[15.75rem] rounded bg-[#555] px-2 py-px text-sm shadow-lg z-50 whitespace-pre-wrap">
              <div className="m-2 block text-center text-xs text-white whitespace-pre-line">
                {t("lock.툴팁")
                  .split("\n")
                  .map((line, idx) => (
                    <span key={idx}>
                      {line}
                      <br />
                    </span>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="bg-[#555] h-[1px] w-full"></div>
      <div className="flex px-6 w-full h-9 justify-between items-center">
        <div className="text-sm">{t("lock.회의 비공개")}</div>
        <SwitchComp enabled={isPrivate} onChange={onChangedPrivate} />
      </div>

      <div className="flex px-6 w-full h-9 justify-between items-center">
        <div className={`text-sm ${!isPrivate && "text-[#86878b]"}`}>{t("lock.비밀번호 설정")}</div>
        <SwitchComp enabled={enableInputPassword} onChange={onChangedEnablePassword} readonly={!isPrivate} />
      </div>

      <div className="flex px-6 w-full h-9 justify-between items-center mb-5">
        <input
          ref={pwdInputRef}
          className="w-full h-8 rounded px-3 text-sm bg-[#555] input-number-password read-only:placeholder-black border border-[#86878b] focus:outline-none focus:border-[#4495ff]"
          type="number"
          inputMode="numeric"
          id="lock-pwd"
          placeholder={t("lock.비밀번호 설정도움말")}
          value={inputPassword}
          onChange={onChangePassword}
        />
      </div>
    </div>
  );
};

export default withRoomContext(LockSetting);
