import YesNoDialog from "../YesNoDialog";
import { useDispatch } from "react-redux";
import { setIsShowHostModeRequest } from "../../store/windowControlSlice";
import RoomClient from "../../RoomClient";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface IProps {
  roomClient: RoomClient;
  isOpen: boolean;
}

const RequestRoomHost = (props: IProps) => {
  const { isOpen, roomClient } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlerHostSet = (res: boolean) => {
    if (!res) return;

    roomClient.sendRequestHost(roomClient.userInfo.bid, roomClient.userInfo.username, (result: boolean) => {
      if (result) {
        toast.info(t("msg.사회자 양도요청 성공"), {
          autoClose: 3000,
          progress: undefined,
        });
      }
    });
  };

  const setIsOpen = (open: boolean) => {
    dispatch(setIsShowHostModeRequest(open));
  };

  return (
    <div className="bg-yellow-400">
      <YesNoDialog
        msg={t("msg.사회자 양도요청")}
        isOpen={isOpen}
        callback={(res: boolean) => handlerHostSet(res)}
        setIsOpen={(open: boolean) => setIsOpen(open)}
        leftButtonText={t("취소")}
        rightButtonText={t("host.양도 요청")}
      />
    </div>
  );
};

export default RequestRoomHost;
