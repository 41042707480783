import { useEffect, useState } from "react";
import ProfileImg from "../ProfileImg";
import useFindUser from "hooks/useFindUser";
import { MemoData } from "../../types/commonTypes";
import { parseISO, format } from "date-fns";

interface NoteRowProps {
  memoData: MemoData;
  localName: string;
  locale?: Locale;
}

const MemoRow = (props: NoteRowProps) => {
  const { userInfo, time, text, isLocal } = props.memoData;
  const { locale } = props;
  const [memoTime, setMemoTime] = useState<string>("");
  const user = useFindUser(userInfo.bidOrEmail);

  useEffect(() => {
    if (time) {
      setMemoTime(format(parseISO(time), "a h:mm", { locale: locale }));
    }
  }, [locale, time]);

  const TextBox = () => {
    let boxStyle = "flex flex-col max-w-[17.5rem] rounded border p-[0.5rem] text-[#555] mt-1 shadow-md select-text";
    if (isLocal) {
      boxStyle += " bg-[#e6edf8] ml-0 mr-6";
    } else {
      boxStyle += " bg-[#f1f3f6] ml-0 mr-0";
    }

    return (
      <div className={boxStyle}>
        <div>
          <span className="text-sm whitespace-pre-wrap text-start break-all">{text}</span>
        </div>
      </div>
    );
  };

  if (!isLocal) {
    return (
      <div className="flex justify-start items-start w-full my-4 pl-6 py-1">
        {/* 프로필 사진 */}
        <div className="w-11 h-11 shrink-0">
          <ProfileImg picurl={user?.picurl} borderWidth={1} />
        </div>

        <div className="flex flex-col ml-3">
          <div className="text-xs font-bold text-white">{user ? user.username : "-"}</div>
          <div className="flex items-end">
            <TextBox />
            {memoTime && <div className="text-[#86878b] ml-1 text-xs">{memoTime}</div>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-end items-start w-full py-1">
      <div className="flex items-end">
        {memoTime && <div className="text-[#86878b] mr-1 text-xs">{memoTime}</div>}
        <TextBox />
      </div>
    </div>
  );
};

export default MemoRow;
