import React from "react";
import ClientVideoView from "components/videoView/ClientVideoView";
import { useSelector } from "react-redux";
import { selectConsumers } from "store/consumerSlice";
import { selectPeers } from "store/peerSlice";
import { MyConsumer, PeerInfo } from "types/clientTypes";
import { useHostPeer } from "hooks/useHostPeer";
import { AppData } from "types/commonTypes";
import { selectCurrentHostSocketId } from "store/docSharingSlice";

interface IProps {
  localAppData?: AppData;
  includeHost?: boolean;
  includeDocHost?: boolean;
}

const PeerVideoList = (props: IProps) => {
  const { localAppData, includeHost, includeDocHost } = props;
  const consumers = useSelector(selectConsumers);
  const peers = useSelector(selectPeers);
  const hostPeer = useHostPeer();
  const docSharingHostSocketId = useSelector(selectCurrentHostSocketId);

  // useEffect(() => {
  // console.log("***** PeerVideoList peers *****", peers);
  // console.log("***** PeerVideoList consumers *****", consumers);
  // }, [consumers, peers]);

  const findConsumer = (socketId: string) => {
    const findConsumer = consumers.filter(
      (myconsumer: MyConsumer) => myconsumer.consumer.appData.socketId === socketId && myconsumer.consumer.kind === "video",
    );

    if (findConsumer.length > 0) return findConsumer[0];
    else return undefined;
  };

  if (peers.length === 0) return <></>;

  return (
    <>
      {peers.map((peer: PeerInfo) => {
        if (!includeHost && peer.socketId === hostPeer?.socketId) {
          console.log("PeerVideoList - 사회자 제외");
          return null; // 사회자 제외
        } else if (peer.socketId === localAppData?.socketId) {
          console.log("PeerVideoList - 로컬 제외");
          return null; // 로컬 제외
        } else if (!includeDocHost && peer.socketId === docSharingHostSocketId) {
          console.log("PeerVideoList - 문서공유 호스트는 제외");
          return null; // 문서공유 호스트 제외
        }

        const myConsumer: MyConsumer = findConsumer(peer.socketId) as unknown as MyConsumer;

        return (
          <ClientVideoView
            key={peer.socketId}
            isRemote
            isHost={peer.socketId === hostPeer?.socketId}
            socketId={peer.socketId}
            stream={myConsumer && peer.isCamEnable ? myConsumer.stream : undefined}
            consumer={myConsumer && peer.isCamEnable ? myConsumer.consumer : undefined}
            userInfo={peer.userInfo}
          />
        );
      })}
    </>
  );
};

PeerVideoList.defaultProps = {
  incluseHost: false,
  includeDocHost: false,
};

export default React.memo(PeerVideoList);
