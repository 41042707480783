import { ButtonProps } from "../interfaces/ButtonProps";

interface IButtonProps extends ButtonProps {
  kind: "primary" | "cancel" | "cancel-dark" | "secondary" | "withIcon" | "attend" | "absent" | "undefined";
  label: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

export const Button = ({ onClick, label, kind, disabled, className, type = "button", ...props }: IButtonProps) => {
  const styleDefault = `min-w-[6.25rem] h-8 rounded text-sm px-3 ${className ?? ""}`;
  let styleKind;

  switch (kind) {
    case "primary":
      styleKind =
        "bg-C-1f62b9 hover:bg-C-599aee active:bg-C-32418e disabled:bg-C-ebedf1 text-white disabled:text-C-b4b5b8 disabled:border disabled:border-C-b4b5b8 disabled:border-solid";
      break;

    case "cancel":
      styleKind = "bg-white hover:bg-C-f1f3f6 active:bg-C-f1f3f6 text-C-555555 border border-C-555555 border-shadow";
      break;

    case "cancel-dark":
      styleKind = "bg-C-222222 hover:bg-C-444444 active:bg-C-222222 text-C-ffffff border border-C-555555 border-shadow";
      break;

    case "secondary":
      styleKind = "bg-white hover:bg-C-d9e5f9 active:bg-C-b8d3fe text-C-1f62b9 border border-C-1f62b9 border-solid";
      break;

    case "withIcon":
      styleKind =
        "bg-white hover:bg-C-d9e5f9 active:bg-C-b8d3fe text-C-1f62b9 border border-C-1f62b9 border-solid  flex  items-center justify-center ";
      break;

    case "attend":
      styleKind =
        "bg-white hover:bg-C-f1f3f6  active:bg-C-eef8e7  active:border-C-21bf60 active:text-C-21bf60 text-C-555555 border border-C-c8cace border-solid  flex  items-center justify-center ";
      break;

    case "absent":
      styleKind =
        "bg-white hover:bg-C-f1f3f6  active:bg-C-f8e7e7  active:border-C-d21111 active:text-C-d21111 text-C-555555 border border-C-c8cace border-solid  flex  items-center justify-center ";
      break;

    case "undefined":
      styleKind =
        "bg-white hover:bg-C-f1f3f6  active:bg-C-ebedf1  active:border-C-555555  text-C-555555 border border-C-c8cace border-solid  flex  items-center justify-center ";
      break;

    default:
      break;
  }

  return (
    <button type={type} className={[styleDefault, styleKind].join(" ")} disabled={disabled} onClick={onClick} {...props}>
      {kind === "withIcon" && (
        <svg className="pr-1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path fill="none" d="M0 0h18v18H0z" />
          <path
            d="M6 11c-3.308 0-6 2.236-6 4.984a1 1 0 0 0 .442.829C.7 16.987.718 17 4.963 17h1.042c5.351 0 5.351 0 5.65-.259a1 1 0 0 0 .345-.757C12 13.236 9.309 11 6 11m-3.774 3.995A4.105 4.105 0 0 1 6 13a4.1 4.1 0 0 1 3.774 1.995c-1.911.006-5.642.006-7.548 0"
            fill="#1f62b9"
          />
          <path d="M6 10a5 5 0 1 0-5-5 5.006 5.006 0 0 0 5 5m0-8a3 3 0 1 1-3 3 3 3 0 0 1 3-3" fill="#1f62b9" />
          <path d="M17 9h-2V7a1 1 0 0 0-2 0v2h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2" fill="#1f62b9" />
        </svg>
      )}
      {label}
    </button>
  );
};
export default Button;
