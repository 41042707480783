import React from "react";
import { Modal } from "./Modal";
import { useTranslation } from "react-i18next";

export interface YesNoDialogProps {
  isOpen: boolean;
  callback: (res: boolean) => void;
  setIsOpen: (open: boolean) => void;
  msg: string;
  leftButtonText?: string;
  rightButtonText?: string;
}

const YesNoDialog = (props: YesNoDialogProps) => {
  const { isOpen, callback, setIsOpen, msg } = props;
  const { t } = useTranslation();
  let { leftButtonText, rightButtonText } = props;

  if (!leftButtonText) leftButtonText = t("아니오");
  if (!rightButtonText) rightButtonText = t("예");

  const handleClick = (res: boolean) => {
    callback(res);
    setIsOpen(false);
  };

  const handleIgnoreClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="text-sm text-white select-none" onClick={handleIgnoreClick}>
        <div className="fixed inset-0 bg-black/75 z-50" />
        <div className="fixed min-w-[21.875rem] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 p-7 rounded shadow bg-[#333]">
          <div className="my-1 text-center">
            {msg.split("\n").map((line, idx) => (
              <span key={idx}>
                {line}
                <br />
              </span>
            ))}

            <div className="flex justify-center items-center mt-8 cursor-pointer">
              <button
                className="rounded border border-[#86878b] flex justify-center items-center w-24 h-8 mr-1 hover:bg-[#444]"
                onClick={() => handleClick(false)}
              >
                {leftButtonText}
              </button>
              <button
                className="rounded flex justify-center items-center min-w-[6rem] h-8 ml-6 px-4 bg-blue-20 hover:bg-blue-30 whitespace-nowrap"
                onClick={() => handleClick(true)}
              >
                {rightButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default YesNoDialog;
