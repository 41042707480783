import { useCallback, useEffect, useState } from "react";
import Step1Speaker from "./Step1Speaker";
import Step2Mic from "./Step2Mic";
import TestSuccess from "./TestSuccess";
import TestFail from "./TestFail";
import { ReactComponent as IconClose } from "assets/btn-close.svg";
import { Value } from "components/common/interfaces/TypeSelectListbox";

interface SpeakerMicTestProps {
  setIsOpen: any;
  onSpeakerDeviceId: (id: string) => void;
  onMicDeviceId: (id: string) => void;
}

export enum Step {
  SpeakerTest,
  MicTest,
  TestSuccess,
  TestFail,
}

const TestRoot = (props: SpeakerMicTestProps) => {
  const { setIsOpen, onSpeakerDeviceId, onMicDeviceId } = props;
  const [curStep, setCurStep] = useState<Step>(Step.SpeakerTest);
  const [successSpeakerDevice, setSuccessSpeakerDevice] = useState<Value>({ id: "", name: "" });
  const [successMicDevice, setSuccessMicDevice] = useState<Value>({ id: "", name: "" });

  useEffect(() => {
    console.log("TestRoot 시작");
    return () => {
      console.log("TestRoot 종료.");
    };
  }, []);

  const onSuccessStep1 = useCallback(
    (val: Value) => {
      onSpeakerDeviceId(val.id);
      setSuccessSpeakerDevice(val);
      setCurStep(Step.MicTest);
    },
    [onSpeakerDeviceId],
  );

  const onSuccessMic = useCallback(
    (val: Value) => {
      onMicDeviceId(val.id);
      setSuccessMicDevice(val);
      setCurStep(Step.TestSuccess);
    },
    [onMicDeviceId],
  );

  return (
    <div className="flex flex-col justify-center bg-[#333] rounded w-[24.5rem]">
      <div className="h-16 flex justify-end items-center pr-[1.438rem] cursor-pointer" onClick={() => setIsOpen(false)}>
        <IconClose />
      </div>
      <div className="p-0">
        {curStep === Step.SpeakerTest && <Step1Speaker onSuccess={onSuccessStep1} onFail={() => setCurStep(Step.TestFail)} />}
        {curStep === Step.MicTest && (
          <Step2Mic onSuccess={onSuccessMic} onFail={() => setCurStep(Step.TestFail)} speakerDevice={successSpeakerDevice} />
        )}
        {curStep === Step.TestSuccess && <TestSuccess speakerDevice={successSpeakerDevice} micDevice={successMicDevice} />}
        {curStep === Step.TestFail && <TestFail />}
      </div>
    </div>
  );
};

export default TestRoot;
