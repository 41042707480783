import ProfileImg from "../ProfileImg";
import useFindUser from "hooks/useFindUser";
import { SpeechToTextData } from "../../types/commonTypes";
import { selectSttToLanguage } from "store/settingSlice1";
import { selectIsSttTranslation } from "store/roomInfoSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ReactComponent as IconTranslation } from "assets/icon-translation.svg";
import { parseISO, format } from "date-fns";

interface SpeechToTextRowProps {
  sttData: SpeechToTextData;
  localName: string;
  onTransText: () => void;
  locale?: Locale;
}

const SpeechToTextRow = (props: SpeechToTextRowProps) => {
  const { userInfo, time, text, isLocal, translations } = props.sttData;
  const { onTransText, locale } = props;
  const [displayTime, setDisplayTime] = useState<string>("");
  const transLanguage = useSelector(selectSttToLanguage)?.slice(0, 2);
  const translationEnabled = useSelector(selectIsSttTranslation);
  const [transText, setTransText] = useState("");
  const user = useFindUser(userInfo.bidOrEmail);

  useEffect(() => {
    if (time) {
      setDisplayTime(format(parseISO(time), "a h:mm", { locale: locale }));
    }
  }, [locale, time]);

  useEffect(() => {
    const tr = translations?.filter(item => item.to === transLanguage)[0];
    if (tr && translationEnabled) {
      setTransText(tr.text);
    }
  }, [transLanguage, translationEnabled, translations]);

  useEffect(() => {
    onTransText();
  }, [onTransText, transText]);

  const TextBox = () => {
    let boxStyle = "flex flex-col max-w-[17.5rem] rounded border p-[0.5rem] text-[#555] mt-1 shadow-md text-sm";
    if (isLocal) {
      boxStyle += " bg-[#e6edf8] ml-0 mr-6";
    } else {
      boxStyle += " bg-[#f1f3f6] ml-0 mr-0";
    }

    return (
      <div className={boxStyle}>
        <div className="whitespace-pre-wrap text-start">{text}</div>

        {transText && (
          <div>
            <div className="w-full h-[1px] bg-[#c8cace]"></div>
            <div>
              <IconTranslation className="inline mb-px" />
              <span className="text-[#4495ff] ml-1">{transText}</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  if (!isLocal) {
    return (
      <div className="flex justify-start items-start w-full my-4 pl-6 py-1">
        {/* 프로필 사진 */}
        <div className="w-11 h-11 shrink-0">
          <ProfileImg picurl={user?.picurl} borderWidth={1} />
        </div>

        <div className="flex flex-col ml-3">
          <div className="text-xs font-bold text-white">{user ? user.username : "-"}</div>
          <div className="flex items-end">
            <TextBox />
            {displayTime && <div className="text-[#86878b] ml-1 text-xs">{displayTime}</div>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-end items-start w-full py-1">
      <div className="flex items-end">
        {displayTime && <div className="text-[#86878b] mr-1 text-xs">{displayTime}</div>}
        <TextBox />
      </div>
    </div>
  );
};

export default SpeechToTextRow;
