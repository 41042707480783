import Axios from "axios";
import { store } from "store/store";
import { getConfig } from "./config";

export interface IInnerAttendee {
  bid: string;
  name: string;
  picurl?: string;
  position?: string;
  team?: string;
  readonly?: boolean;
}

export interface IOuterAttendee {
  name: string;
  email: string;
  readonly?: boolean;
}

export interface IInvite {
  meetingId: number;
  innerAttendees: IInnerAttendee[];
  outerAttendees: IOuterAttendee[];
}

export const invite = async (roomId: number, innerAttendees: IInnerAttendee[], outerAttendees: IOuterAttendee[]) => {
  try {
    const url = `${store.getState().env.value.MGR_SERVER}/api/video/meetings/invite`;

    let data: IInvite = {
      meetingId: roomId,
      innerAttendees,
      outerAttendees,
    };

    console.log("invite 실행:", data);

    const config = getConfig(store.getState().auth.token);
    const axiosInstance = Axios.create({ timeout: 10000 });

    const response = await axiosInstance.patch(url, data, config);
    return response.data;
  } catch (e) {
    console.log("error from manager invite api");
    console.log(e);
    return { message: "error", err: e };
  }
};
