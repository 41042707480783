import { useTranslation } from "react-i18next";

const AccessDenied = () => {
  const { t } = useTranslation();
  return (
    <div className="absolute top-0 left-0 w-screen h-full bg-[#333] flex justify-center items-center">
      <div className="text-base text-white font-bold select-none">{t("화상회의 종료")}</div>
    </div>
  );
};

export default AccessDenied;
