import React from "react";
import { Modal } from "components/Modal";
import { useTranslation } from "react-i18next";

export interface IProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const NotSupportDocFileFormat = (props: IProps) => {
  const { isOpen, setIsOpen } = props;
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <div>
        <div className="fixed inset-0 bg-black/75 z-50"></div>
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 py-[1.875rem] px-4 md:min-w-[21.875rem] rounded shadow bg-zinc-700 text-white">
          <div className="flex flex-col justify-center items-center mt-7 cursor-pointer text-sm">
            <div className="">{t("msg.변환할 수 없는 파일 형식입니다.")}</div>

            <button
              className="rounded flex justify-center items-center w-24 h-9 mt-[3.438rem] bg-[#1f62b9] hover:bg-[#599aee] disabled:bg-[#444] disabled:border disabled:border-[#555]"
              onClick={handleClick}
            >
              {t("확인")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NotSupportDocFileFormat;
