import { Suspense, lazy } from "react";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import App from "./App";
import LoadingSimple from "components/LoadingSimple";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setFullScreenCssProperty } from "lib/utils";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const AccessDenied = lazy(() => import("pages/AccessDenied"));
const EndPage = lazy(() => import("pages/EndPage"));
const KickOutPage = lazy(() => import("pages/KickOutPage"));
const WebcamMicHelp = lazy(() => import("pages/help/WebcamMicHelp"));
const TesterSelectV5 = lazy(() => import("pages/TesterSelectV5"));
const TesterSelectV6 = lazy(() => import("pages/TesterSelectV6"));
const TestPage = lazy(() => import("pages/TestPage"));
const TestRender = lazy(() => import("pages/TestRender"));
const TestGrid = lazy(() => import("pages/TestGrid"));
const WebRtcPerfTest = lazy(() => import("pages/WebRtcPerfTest"));

const container = document.getElementById("root")!;
const root = createRoot(container);
const queryClient = new QueryClient();

if (process.env.NODE_ENV !== "development") {
  // console.log = () => {};
}

console.log("process.env.NODE_ENV", process.env.NODE_ENV);

setFullScreenCssProperty();

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <div className="full-screen">
        <BrowserRouter>
          <Suspense fallback={<LoadingSimple />}>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/denied" element={<AccessDenied />} />
              <Route path="/end" element={<EndPage />} />
              <Route path="/kickout" element={<KickOutPage />} />
              <Route path="/help/webcam" element={<WebcamMicHelp />} />
              <Route path="/test" element={<TesterSelectV5 />} />
              <Route path="/test6" element={<TesterSelectV6 />} />
              <Route path="/testPage" element={<TestPage />} />
              <Route path="/testRender" element={<TestRender />} />
              <Route path="/testGrid" element={<TestGrid />} />
              <Route path="/perf" element={<WebRtcPerfTest />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
