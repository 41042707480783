import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { store } from "store/store";
import { IPresignedFileUploadRequest } from "types/fileUpload";
import { postReqPresignedUrl } from "./postReqPresignedUrl";
import { v4 as uuidv4 } from "uuid";

export const uploadFile = async (
  file: File,
  fileid: string,
  abortController: AbortController,
  onProgress: (percent: number) => void,
  strid: string = "UCWORKS.user.default.kr.v2",
) => {
  const fileParam: IPresignedFileUploadRequest = {
    sid: store.getState().auth.sid,
    relsvcname: "colla",
    relkey: `vc-${store.getState().room.info.id}`,
    operation: "PUT",
    country: "KR",
    usertenantid: store.getState().room.tenantId,
    what: [
      {
        filedir: "",
        filetenantid: store.getState().room.tenantId,
        filename: file.name,
        filesize: file.size,
        fileid: fileid,
        strid,
      },
    ],
  };

  try {
    const presignedRes = await postReqPresignedUrl(fileParam);

    const formData = new FormData();
    formData.append("file", file, uuidv4().toString());

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      signal: abortController.signal,
      timeout: 120000,
      onUploadProgress: progressEvent => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percent);
      },
    };

    const res = await axios.put(presignedRes.result[0].presignedurl, formData, config);
    console.log("common fileserver upload res", res);
    return res.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error("fileupload error", error, file.name);
    }
  }
};
