import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SelectListbox2 from "components/SelectListbox2";
import RoomClient from "RoomClient";
import LoadingSimple from "components/LoadingSimple";
import { useSelector } from "react-redux";
import { selectMics, selectSelectedMic } from "store/settingSlice1";
import { Value } from "components/common/interfaces/TypeSelectListbox";
import { withRoomContext } from "RoomContext";
import { useTranslation } from "react-i18next";

interface Step2Props {
  roomClient: RoomClient;
  speakerDevice: Value;
  onSuccess: (val: Value) => void;
  onFail: () => void;
}

enum State {
  Rec,
  Play,
  Stop,
}

const Step2Mic = (props: Step2Props) => {
  const { onSuccess, speakerDevice } = props;
  const { t } = useTranslation();
  const [selectedOptionMic, setSelectedOptionMic] = useState<Value>({ id: "", name: "" });
  const [options_mic, setOptionMic] = useState<Value[]>([]);
  const [state, setState] = useState<State>(State.Stop);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [audioArray] = useState<Blob[]>([]);
  const [isEnd, setIsEnd] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [isForceRecStop, setIsForceRecStop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [micStream, setMicStream] = useState<MediaStream>();
  const mics = useSelector(selectMics);
  const selectedMic = useSelector(selectSelectedMic);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  let bufferLength = 2048;

  const dataArray = useMemo(() => {
    return new Uint8Array(bufferLength);
  }, [bufferLength]);

  const audioContext = useMemo(() => {
    return new AudioContext();
  }, []);

  const analyser = useMemo(() => {
    return audioContext.createAnalyser();
  }, [audioContext]);

  const animationLooper = useCallback(() => {
    if (canvasRef.current) {
      const canvasCtx = canvasRef.current?.getContext("2d");
      if (!canvasCtx) return;

      const WIDTH = canvasRef.current.width;
      const HEIGHT = canvasRef.current.height;

      canvasCtx.fillStyle = "#555";
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      canvasCtx.fillStyle = "#4495ff";
      let w = 0;
      for (var i = 0; i < bufferLength; i++) {
        w = (dataArray[i] / 256) * WIDTH;
        canvasCtx.fillRect(0, 0, w, HEIGHT);
      }
    }
  }, [bufferLength, dataArray]);

  const draw = useCallback(() => {
    if (!isEnd) {
      animationLooper();
      analyser.getByteTimeDomainData(dataArray);
      requestAnimationFrame(draw);
    }
  }, [analyser, animationLooper, dataArray, isEnd]);

  const handleClick = async (isYes: boolean) => {
    if (isYes) {
      onSuccess(selectedOptionMic);
    } else {
      await forceRecStop();

      const values = Array.from(mics.values());
      let curIdx = values.findIndex(val => val.deviceId === selectedOptionMic.id);
      if (++curIdx >= values.length) curIdx = 0;
      changeOption(options_mic[curIdx]);
    }
  };

  const changeAudioInputDevice = useCallback(
    async (deviceId: string) => {
      console.log("changeAudioInputDevice", deviceId);

      try {
        // 마이크 mediaStream 생성: Promise를 반환하므로 async/await 사용
        const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { ideal: deviceId } } });
        setMicStream(stream);
        let recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        const s = audioContext.createMediaStreamSource(stream);
        // setSource(s);
        s.connect(analyser);
        analyser.fftSize = bufferLength;
        analyser.getByteFrequencyData(dataArray);
        draw();

        recorder.ondataavailable = event => {
          if (isEnd && recorder.state !== "inactive") {
            recorder.stop();
          }
          console.log("record ondataavailable. size:", event.data.size, isEnd);
          audioArray.push(event.data);
        };

        recorder.onstop = event => {
          console.log("record onstop.", event);
          if (audioArray.length === 0) {
            console.log("Step2MicDebug. 녹음된 데이터가 없습니다.");
            return;
          }

          if (isForceRecStop) {
            return;
          }

          const blob = new Blob(audioArray, { type: "audio/mp3" });
          audioArray.splice(0);

          const audioURL = window.URL.createObjectURL(blob);

          if (audioRef.current) {
            audioRef.current.src = audioURL;
            const audio: any = audioRef.current;
            if (speakerDevice && typeof audio.setSinkId === "function") {
              audio.setSinkId(speakerDevice.id).then(() => {
                audioRef.current?.play();
              });
            } else {
              audioRef.current?.play();
            }
          }
        };

        console.log("### 녹음 시작~~~~");
        setIsForceRecStop(false);
        recorder.start(500);
        setState(State.Rec);

        if (timeoutId) global.clearTimeout(timeoutId);
        const id = setTimeout(() => {
          if (recorder.state === "recording") recorder.stop();
        }, 5000);

        setTimeoutId(id);
      } catch (e) {
        console.log("changeAudioInputDevice error", e);
      }
    },
    [analyser, audioArray, audioContext, bufferLength, dataArray, draw, isEnd, isForceRecStop, speakerDevice, timeoutId],
  );

  const changeOption = useCallback(
    (v: Value) => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
      }

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.srcObject = null;
      }

      if (micStream) {
        const tracks = micStream.getTracks();
        tracks.forEach(track => track.stop());
      }

      setSelectedOptionMic(v);
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        changeAudioInputDevice(v.id);
      }, 1000);
    },
    [changeAudioInputDevice, mediaRecorder, micStream],
  );

  useEffect(() => {
    console.log("Step2 실행");
    return () => {
      console.log("Step2 종료");
      setIsEnd(true);
    };
  }, []);

  useEffect(() => {
    let audioRefCurrent: HTMLAudioElement;
    if (audioRef.current) {
      audioRefCurrent = audioRef.current;

      audioRef.current.addEventListener("ended", () => {
        console.log("### audio ended");
        if (mediaRecorder?.state !== "recording") setState(State.Stop);
        else setState(State.Rec);
      });

      audioRef.current.addEventListener("playing", event => {
        console.log("### audio playing");
        setState(State.Play);
      });
    }

    return () => {
      if (audioRefCurrent) {
        audioRefCurrent.removeEventListener("ended", () => {});
        audioRefCurrent.removeEventListener("playing", () => {});
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef]);

  useEffect(() => {
    const newMics: Value[] = [];

    mics.forEach((values, key) => {
      const val = { id: key, name: values.label };
      newMics.push(val);
      if (key === selectedMic) {
        changeOption(val);
      }
    });

    setOptionMic(newMics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mics, selectedMic, t]);

  const onClickStartRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") return;
    if (state === State.Play) return;

    changeAudioInputDevice(selectedOptionMic.id);
  };

  const forceRecStop = async () => {
    if (timeoutId) global.clearTimeout(timeoutId);
    setIsForceRecStop(true);

    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      audioArray.splice(0);
      setState(State.Stop);
    }
  };

  return (
    <div className="relative">
      <audio ref={audioRef} />
      <div className="flex justify-center items-center">
        <div className="text-sm text-center text-[#c8cace]">{t("setting.마이크 테스트 중...")}</div>
        <div className="text-xs ml-2 cursor-pointer h-6" onClick={onClickStartRecording}>
          {state === State.Rec && (
            <div className="rounded-full px-2 h-full flex place-items-center animate-pulse bg-red-400">{t("setting.녹음중")}</div>
          )}
          {state === State.Play && (
            <div className="rounded-full px-2 h-full flex place-items-center animate-pulse bg-green-400">{t("setting.재생중")}</div>
          )}
          {state === State.Stop && <div className="rounded-full px-2 h-full flex place-items-center bg-gray-400">{t("중지")}</div>}
        </div>
      </div>
      <div className="text-base text-center font-bold mt-2 px-6">{t("setting.마이크테스트 도움말")}</div>

      <div className="mt-8 flex justify-center items-center">
        <button className="rounded flex justify-center items-center w-24 h-8 bg-[#1f62b9] hover:bg-[#3f82d9]" onClick={() => handleClick(true)}>
          {t("예")}
        </button>
        <button
          className="rounded border border-zinc-400 flex justify-center items-center w-24 h-8 mr-1 hover:bg-zinc-400 ml-6"
          onClick={() => handleClick(false)}
        >
          {t("아니오")}
        </button>
      </div>

      <div className="px-6 w-full mt-[2.25rem] mb-4">
        <table className="table w-full">
          <tbody className="text-sm">
            <tr className="h-8 border-b-4 border-b-[#0000]">
              <td className="w-1 h-full pr-4 whitespace-nowrap leading-8">
                <span>{t("마이크")}</span>
              </td>
              <td className="relative z-20">
                <div className="absolute top-pt left-0 w-full">
                  <SelectListbox2 options={options_mic} value={selectedOptionMic} onChange={v => changeOption(v)} optionsHeight={40} />
                </div>
              </td>
            </tr>
            <tr className="h-8 border-b-1 border-b-[#0000]">
              <td className="w-1 h-full pr-4 whitespace-nowrap leading-8">
                <span>{t("setting.입력레벨")}</span>
              </td>
              <td className="leading-8 flex items-center h-8">
                <div className="grow relative ml-[0rem] h-2 bg-[#555]">
                  <canvas ref={canvasRef} className="w-full h-full absolute top-0 left-0" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {isLoading && (
        <div className="absolute inset-0 flex justify-center items-center">
          <LoadingSimple />
        </div>
      )}
    </div>
  );
};

export default withRoomContext(Step2Mic);
