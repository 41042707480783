export interface IProps {
  width?: number;
  height?: number;
  className?: string;
  stroke?: string;
}

const ButtonMicOffLarge = (props: IProps) => {
  const { width = 36, height = 36, className, stroke = "#fff" } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 36" className={className}>
      <g transform="translate(-2.26 -5.192)">
        <circle
          cx={18}
          cy={18}
          r={18}
          transform="translate(2.26 5.192)"
          style={{
            fill: "rgba(0,0,0,.75)",
          }}
        />
        <g transform="translate(538.575 -224.925)">
          <rect
            data-name="\uC0AC\uAC01\uD615 249"
            width={10}
            height={15.754}
            rx={5}
            transform="translate(-523.315 235.116)"
            style={{
              stroke,
              fill: "none",
              strokeWidth: 2,
            }}
          />
          <path
            data-name="\uC120 89"
            transform="translate(-518.315 254.379)"
            style={{
              stroke,
              fill: "none",
              strokeWidth: 2,
            }}
            d="M0 0v6.337"
          />
          <path
            data-name="\uC120 90"
            transform="translate(-524.315 260.716)"
            style={{
              strokeLinecap: "round",
              stroke,
              fill: "none",
              strokeWidth: 2,
            }}
            d="M0 0h11.939"
          />
          <path
            data-name="\uD328\uC2A4 550"
            d="M-510.815 240.648c0 3.807-3.583 6.892-8 6.892s-8-3.085-8-6.892"
            transform="translate(.5 7.268)"
            style={{
              strokeLinecap: "round",
              stroke,
              fill: "none",
              strokeWidth: 2,
            }}
          />
          <path
            data-name="\uC120 296"
            transform="translate(-527.315 239.117)"
            style={{
              stroke: "#ff3e72",
              strokeLinecap: "round",
              fill: "none",
              strokeWidth: 2,
            }}
            d="M18 0 0 17"
          />
        </g>
      </g>
    </svg>
  );
};

export default ButtonMicOffLarge;
