import { useCallback, useEffect, useRef } from "react";
import RoomClient from "RoomClient";
import MobileMenuButton from "./MobileMenuButton";
import MobileMenuButtonEx from "./MobileMenuButtonEx";
import HoverAssignMobileMenuButton from "./HoverAssignMobileMenuButton";
import screenfull from "screenfull";
import { store } from "store/store";
import { selectIsLocalHost, selectIsHostMode, selectRoomInfo, selectIsPrivate } from "store/roomInfoSlice";
import {
  setIsShowHostModeEnable,
  setIsShowHostModeRequest,
  setIsShowHostSetting,
  setIsShowDocList,
  setIsShowMobileMenu,
  setIsShowLockSetting,
  setIsShowChangeView,
  setIsShowCaption,
  selectIsShowCaption,
  setIsShowUsers,
  setIsShowStopDocSharingPopup,
  setIsShowVirtualBgSetting,
  selectIsShowDocSharingHost,
  selectIsShowDocSharingClient,
  selectIsShowHostModeEnable,
  selectIsShowVirtualBgSetting,
  setIsShowRecordingStoreList,
} from "store/windowControlSlice";
import { toast } from "react-toastify";
import { withRoomContext } from "RoomContext";
import { setIsShowSettingWindow, selectIsLocalCamOn, selectIsLocalMicOn } from "store/settingSlice1";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePeer } from "hooks/usePeer";
import { useCanVirtualBg } from "hooks/useCanVirtualBg";
import { isMobile, isIOS, isSafari, browserVersion } from "react-device-detect";
import { ReactComponent as IconCloseSizeBar } from "assets/icon-close-side-bar.svg";
import { ReactComponent as IconLock } from "assets/btn-lock-xs.svg";
import { ReactComponent as IconUnLock } from "assets/btn-unlock-xs.svg";
import { ReactComponent as IconPerson } from "assets/btn-attendant-xs.svg";
import { ReactComponent as IconShareScreen } from "assets/btn-share-screen-xs.svg";
import { ReactComponent as IconShareDoc } from "assets/btn-share-doc-xs.svg";
import { ReactComponent as IconScreenShot } from "assets/btn-screenshot-xs.svg";
import { ReactComponent as IconRecording } from "assets/btn-record-xs.svg";
import { ReactComponent as IconHost } from "assets/icon-host.svg";
import { ReactComponent as IconCc } from "assets/icon-cc-xs.svg";
import { ReactComponent as IconCcX } from "assets/icon-cc-xs-x.svg";
import { ReactComponent as IconCamera } from "assets/btn-camera-xs.svg";
import { ReactComponent as IconCameraOff } from "assets/btn-camera-off-xs.svg";
import { ReactComponent as IconCameraSetting } from "assets/btn-camera-setting.svg";
import { ReactComponent as IconCameraSettingBlue } from "assets/btn-camera-setting-blue.svg";
import { ReactComponent as IconMic } from "assets/btn-mic-xs.svg";
import { ReactComponent as IconMicOff } from "assets/btn-mic-off-xs.svg";
import { ReactComponent as IconView } from "assets/btn-view-xs.svg";
import { ReactComponent as IconFullScreen } from "assets/btn-full-screen-xs.svg";
import { ReactComponent as IconSetting } from "assets/btn-setting-xs.svg";
import { ReactComponent as IconSettingBlue } from "assets/btn-setting-xs-blue.svg";
import { ReactComponent as IconQuit } from "assets/btn-quit-xs.svg";
import { executeScreenshotFunc } from "store/utilSlice";

interface MobileMenuProps {
  roomClient: RoomClient;
  buttonRef?: React.RefObject<HTMLDivElement>;
}

const MobileMenu = (props: MobileMenuProps) => {
  const { roomClient, buttonRef } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPrivate = useSelector(selectIsPrivate);
  const isShowCaption = useSelector(selectIsShowCaption);
  const isShowDocSharingHost = useSelector(selectIsShowDocSharingHost);
  const isShowDocSharingClient = useSelector(selectIsShowDocSharingClient);
  const isLocalHost = useSelector(selectIsLocalHost);
  const isHostMode = useSelector(selectIsHostMode);
  const isHostModeEnableView = useSelector(selectIsShowHostModeEnable);
  const isLocalCamOn = useSelector(selectIsLocalCamOn);
  const isLocalMicOn = useSelector(selectIsLocalMicOn);
  const isShowVirtualBgSetting = useSelector(selectIsShowVirtualBgSetting);
  const roomInfo = useSelector(selectRoomInfo);
  const rootDivRef = useRef<HTMLDivElement>(null);
  const peer = usePeer(roomClient.socket.id);
  const canVirtualBg = useCanVirtualBg();

  const handleClose = useCallback(() => {
    dispatch(setIsShowMobileMenu(false));
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (ev: Event) => {
      if (buttonRef && buttonRef.current?.contains(ev.target as Node)) {
        handleClose();
        return;
      }

      if (rootDivRef.current && !rootDivRef.current.contains(ev.target as Node)) {
        handleClose();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [handleClose, isHostModeEnableView, buttonRef]);

  useEffect(() => {
    if (!isShowCaption) {
      roomClient.stt?.stop();
    } else {
      roomClient.stt?.start();
    }
  }, [isShowCaption, roomClient.stt]);

  const handleLockSetting = () => {
    dispatch(setIsShowLockSetting(true));
    handleClose();
  };

  const handleShowUsers = () => {
    dispatch(setIsShowUsers(true));
    handleClose();
  };

  const handleShareScreen = () => {
    if (isLocalHost) {
      if (isShowDocSharingHost || isShowDocSharingClient) {
        dispatch(setIsShowStopDocSharingPopup(true));
      } else {
        roomClient.closeScreenShare();
        roomClient.screenShare();
      }
    } else {
      toast.warn(t("msg.화면공유 권한없음"));
    }

    handleClose();
  };

  const handleShareDoc = () => {
    dispatch(setIsShowDocList(true));
    handleClose();
  };

  const handleCaptureScreen = () => {
    handleClose();

    setTimeout(() => {
      dispatch(executeScreenshotFunc());
    }, 1000);
  };

  const handleRecording = () => {
    dispatch(setIsShowRecordingStoreList(true));
    handleClose();
  };

  const handleSetHostMode = () => {
    if (isLocalHost) {
      dispatch(setIsShowHostSetting(true));
    } else if (isHostMode) {
      dispatch(setIsShowHostModeRequest(true));
    } else {
      dispatch(setIsShowHostModeEnable(true));
    }
    handleClose();
  };

  const handleCaption = () => {
    dispatch(setIsShowCaption(!isShowCaption));
  };

  const handleCameraOnOff = (enable: boolean) => {
    roomClient.pauseWebcam(!enable);
  };

  const handleVirtualBgSettingShow = (enable: boolean) => {
    dispatch(setIsShowVirtualBgSetting(enable));
    handleClose();
  };

  const handleMicOnOff = async (enable: boolean) => {
    const isLocalHost = store.getState().room.isLocalHost;
    const isSelfMicOn = store.getState().room.setting.isSelfMicOn;

    if (!isLocalHost && !isSelfMicOn && enable) toast.warn(t("msg.마이크 ON/OFF 권한없음"));
    else roomClient.muteAudio(!enable);
  };

  const handleChangeView = () => {
    dispatch(setIsShowChangeView(true));
    handleClose();
  };

  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }

    handleClose();
  };

  const handleSetting = () => {
    dispatch(setIsShowSettingWindow(true));
    handleClose();
  };

  const handleQuit = () => {
    roomClient.close();
  };

  return (
    <div ref={rootDivRef} className="bg-[#333] h-full w-[17rem] select-none">
      <div className="h-[2.75rem] bg-[#333] flex items-center pl-4 cursor-pointer" onClick={handleClose}>
        <IconCloseSizeBar />
      </div>
      <div className="text-white text-base bg-[#333]">
        <div className="">
          <MobileMenuButtonEx
            EnabledIcon={IconLock}
            DisabledIcon={IconUnLock}
            label={`${roomInfo.accessCode} ${roomInfo.title}`}
            onClick={handleLockSetting}
            isEnabled={isPrivate}
          />
        </div>

        <MobileMenuButton SvgIcon={IconPerson} label={t("menu.참여자목록")} onClick={handleShowUsers} />
        <div className="border-b border-[#555]" />

        {!isMobile && <MobileMenuButton SvgIcon={IconShareScreen} label={t("mobile-menu.화면공유")} onClick={handleShareScreen} />}
        <MobileMenuButton SvgIcon={IconShareDoc} label={t("menu.문서공유")} onClick={handleShareDoc} />

        <MobileMenuButton SvgIcon={IconScreenShot} label={t("menu.스크린샷")} onClick={handleCaptureScreen} />

        {!isMobile && (!isSafari || (isSafari && Number(browserVersion) >= 15)) && (
          <MobileMenuButton SvgIcon={IconRecording} label={t("menu.녹화")} onClick={handleRecording} />
        )}

        <div className="border-b border-[#555]" />

        <MobileMenuButton SvgIcon={IconHost} label={t("mobile-menu.사회자모드")} onClick={handleSetHostMode} />
        <div className="border-b border-[#555]" />

        <MobileMenuButtonEx EnabledIcon={IconCc} DisabledIcon={IconCcX} label={t("menu.자막")} onClick={handleCaption} isEnabled={isShowCaption} />
        <MobileMenuButton SvgIcon={IconView} label={t("menu.화면보기")} onClick={handleChangeView} />

        <MobileMenuButtonEx
          EnabledIcon={IconCamera}
          DisabledIcon={IconCameraOff}
          label={t("menu.카메라")}
          onClick={() => handleCameraOnOff(!peer?.isCamEnable)}
          isEnabled={(peer?.isCamEnable && isLocalCamOn) ?? false}
        />

        {(!isSafari || canVirtualBg) && (
          <HoverAssignMobileMenuButton
            NormalIcon={IconCameraSetting}
            HoverIcon={IconCameraSettingBlue}
            label={t("menu.가상 배경화면 설정")}
            onClick={() => handleVirtualBgSettingShow(!isShowVirtualBgSetting)}
            isSubMenu={true}
          />
        )}

        <MobileMenuButtonEx
          EnabledIcon={IconMic}
          DisabledIcon={IconMicOff}
          label={t("menu.마이크")}
          onClick={() => handleMicOnOff(!peer?.isMicEnable)}
          isEnabled={(peer?.isMicEnable && isLocalMicOn) ?? false}
        />

        {!(isIOS && isSafari) && <MobileMenuButton SvgIcon={IconFullScreen} label={t("menu.전체화면")} onClick={handleFullScreen} />}

        <HoverAssignMobileMenuButton NormalIcon={IconSetting} HoverIcon={IconSettingBlue} label={t("menu.환경설정")} onClick={handleSetting} />

        <MobileMenuButton SvgIcon={IconQuit} label={t("menu.나가기")} onClick={handleQuit} />
      </div>
    </div>
  );
};

export default withRoomContext(MobileMenu);
