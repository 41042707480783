import React, { useRef, useState } from "react";
import NoteMain from "components/note/NoteMain";
import ChatMain from "components/chat/ChatMain";
import Setting from "components/setting/Setting";
import MobileMenu from "components/mobileMenu/MobileMenu";
import SelectViewMode from "components/menubar/SelectViewMode";
import HostModeEnable from "components/home/ActiveHostMode";
import RequestRoomHost from "components/home/RequestRoomHost";
import InviteAttendeeRoot from "components/invite/InviteAttendeeRoot";
import AzureStt from "components/speechToText/AzureStt";
import MenuBar from "components/menubar/MenuBar";
import RoomClient from "RoomClient";
import Users from "components/menubar/users/Users";
import LockSetting from "components/menubar/LockSetting";
import HostModeSetting from "components/menubar/HostModeSetting";
import DocSharingFileList from "components/docSharing/filelist/DocSharingFileList";
import DocSharingTool from "components/docSharing/tool/DocSharingTool";
import BackgroundSelector from "components/virtualBackground/BackgroundSelector";
import RecordingFileList from "components/recording/RecordingFileList";
import DimDiv from "components/DimDiv";
import StopDocSharingPopup from "components/home/StopDocSharingPopup";
import AssignRoomHost from "components/home/AssignRoomHost";
import ServerDisconnected from "components/home/ServerDisconnected";
import DuplicateJoin from "components/home/DuplicateJoin";
import { ReactComponent as IconMobileMenu } from "assets/toggle-lnb-handler.svg";
import { ReactComponent as IconNote } from "assets/icon-note.svg";
import { ReactComponent as IconChat } from "assets/icon-timeline.svg";
import { withRoomContext } from "RoomContext";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useMeasure } from "react-use";
import { selectUnreadCnt } from "store/chatSlice";
import { setIsShowSettingWindow, selectIsShowSettingWindow } from "store/settingSlice1";
import { selectRoomSetting, selectRoomState } from "store/roomInfoSlice";
import {
  selectIsShowHostModeEnable,
  selectIsShowHostModeRequest,
  selectIsShowInvitePopup,
  selectIsShowHostSetting,
  selectIsShowDocList,
  selectIsShowRecordingStoreList,
  selectIsShowNote,
  selectIsShowChatting,
  selectIsShowDocSharingHost,
  selectIsShowDocSharingClient,
  selectIsShowMenuBar,
  selectIsShowUsers,
  selectIsFullScreen,
  selectIsShowMobileMenu,
  selectIsShowChangeView,
  selectIsShowLockSetting,
  selectIsShowVirtualBgSetting,
  selectIsShowStopDocSharingPopup,
  selectAssignHostPopup,
  setIsShowChangeView,
  setIsShowMobileMenu,
  setIsShowLockSetting,
  setIsShowInvitePopup,
  setIsShowHostSetting,
  setIsShowDocList,
  setIsShowRecordingStoreList,
  setIsShowNote,
  setIsShowChatting,
  setIsShowUsers,
  setIsShowVirtualBgSetting,
} from "store/windowControlSlice";
import { selectIsShowToolbar, setIsShowToolbar } from "store/docSharingSlice";
import { isMobile } from "react-device-detect";

interface IProps {
  roomClient: RoomClient;
}

const HomeCommon = (props: IProps) => {
  const { roomClient } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chatBtnRef = useRef<HTMLDivElement>(null);
  const noteBtnRef = useRef<HTMLDivElement>(null);
  const [isBtnNoteHover, setIsBtnNoteHover] = useState(false);
  const [isBtnChatHover, setIsBtnChatHover] = useState(false);
  const roomSetting = useSelector(selectRoomSetting);
  const unreadChatCnt = useSelector(selectUnreadCnt);
  const isShowLockSetting = useSelector(selectIsShowLockSetting);
  const isShowHostSetting = useSelector(selectIsShowHostSetting);
  const isShowDocList = useSelector(selectIsShowDocList);
  const isShowRecordingStoreList = useSelector(selectIsShowRecordingStoreList);
  const isShowSettingWindow = useSelector(selectIsShowSettingWindow);
  const isShowMobileMenu = useSelector(selectIsShowMobileMenu);
  const isShowChangeView = useSelector(selectIsShowChangeView);
  const isShowHostModeEnable = useSelector(selectIsShowHostModeEnable);
  const isShowHostModeRequest = useSelector(selectIsShowHostModeRequest);
  const isShowInvitePopup = useSelector(selectIsShowInvitePopup);
  const isShowNote = useSelector(selectIsShowNote);
  const isShowChatting = useSelector(selectIsShowChatting);
  const isShowUsers = useSelector(selectIsShowUsers);
  const isFullScreen = useSelector(selectIsFullScreen);
  const isShowDocSharingHost = useSelector(selectIsShowDocSharingHost);
  const isShowDocSharingClient = useSelector(selectIsShowDocSharingClient);
  const isShowDocSharingToolbar = useSelector(selectIsShowToolbar);
  const isShowMenuBar = useSelector(selectIsShowMenuBar);
  const isShowVirtualBgSetting = useSelector(selectIsShowVirtualBgSetting);
  const isShowStopDocSharingPopup = useSelector(selectIsShowStopDocSharingPopup);
  const assignHostPopup = useSelector(selectAssignHostPopup);
  const roomState = useSelector(selectRoomState);
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const [refDocSharingToolbar, { height: docSharingToolbarHeight }] = useMeasure<HTMLDivElement>();

  const handleShowChatDiv = () => {
    dispatch(setIsShowChatting(!isShowChatting));
  };

  const handleShowMobileMenu = () => {
    dispatch(setIsShowMobileMenu(true));
    dispatch(setIsShowToolbar(false));
  };

  return (
    <>
      {/* 우측상단 회의록, 대화 버튼 */}
      <div className="absolute top-4 right-4 z-40 flex flex-col items-end">
        <div
          ref={noteBtnRef}
          className="flex flex-col w-[4.75rem] h-[4.75rem] justify-center items-center rounded-full bg-black/75 cursor-pointer"
          onClick={() => dispatch(setIsShowNote(!isShowNote))}
          onMouseEnter={() => setIsBtnNoteHover(true)}
          onMouseLeave={() => setIsBtnNoteHover(false)}
        >
          {isBtnNoteHover ? <IconNote className="w-9 h-9" fill="#4495ff" /> : <IconNote className="w-9 h-9" fill="#fff" />}
          <div
            className={`text-[0.8rem] font-bold text-center text-white mt-[0.125rem] select-none ${
              isBtnNoteHover ? "text-[#4495ff]" : "text-white"
            } `}
          >
            {t("home.회의록")}
          </div>
          {roomSetting.isAutoSaveNote && <div className="absolute top-0 right-0 w-[0.938rem] h-[0.938rem] rounded-full bg-[#fa114f]"></div>}
        </div>

        <div
          ref={chatBtnRef}
          className="relative flex flex-col w-[4.75rem] h-[4.75rem] justify-center items-center rounded-full bg-black/75 mt-2 cursor-pointer"
          onClick={handleShowChatDiv}
          onMouseEnter={() => setIsBtnChatHover(true)}
          onMouseLeave={() => setIsBtnChatHover(false)}
        >
          <IconChat className="w-9 h-9" fill={isBtnChatHover ? "#4495ff" : "#fff"} />
          <div
            className={`text-[0.8rem] font-bold text-center text-white mt-[0.125rem] select-none ${isBtnChatHover ? "text-[#4495ff]" : "text-white"}`}
          >
            {t("home.대화")}
          </div>
          {unreadChatCnt > 0 && (
            <div className="absolute top-0 right-0 rounded-full bg-[#fa114f] min-w-[0.938rem] h-[0.938rem] text-center">
              <div className=" text-white text-[0.625rem] font-bold">{unreadChatCnt < 100 ? unreadChatCnt : "99+"}</div>
            </div>
          )}
        </div>
      </div>

      {/* 회의록 창 */}
      {isShowNote && (
        <div className="absolute top-0 right-0 mb-px z-[60] h-full w-full flex justify-end items-start">
          <NoteMain onCloseView={() => dispatch(setIsShowNote(false))} buttonRef={noteBtnRef} />
        </div>
      )}

      {/* 대화창 */}
      {isShowChatting && (
        <div className="absolute top-0 sm:bottom-0 right-0 mb-px z-[60] h-full w-full flex justify-end items-start sm:items-end">
          <ChatMain onCloseView={() => dispatch(setIsShowChatting(false))} buttonRef={chatBtnRef} />
        </div>
      )}

      {/* 환경설정창 */}
      {isShowSettingWindow && (
        <DimDiv>
          <Setting onCloseView={() => dispatch(setIsShowSettingWindow(false))} />
        </DimDiv>
      )}

      {/* 모바일 메뉴 */}
      {isShowMobileMenu && (isMobile || !isMd) && (
        <div className="bg-black/75 w-full h-full absolute inse-0 z-50 overflow-y-auto flex justify-end">
          <MobileMenu />
        </div>
      )}

      {/* 잠금설정 */}
      {isShowLockSetting && (!isMd || isMobile) && (
        <DimDiv>
          <div className="absolute left-[50%] -translate-x-2/4 bottom-0 h-full flex items-center text-white z-20">
            <LockSetting
              onCloseView={() => {
                dispatch(setIsShowLockSetting(false));
              }}
              buttonRef={undefined}
              isMobile={isMobile}
            />
          </div>
        </DimDiv>
      )}

      {/* 참여자목록 */}
      {isShowUsers && (!isMd || isMobile) && (
        <DimDiv>
          <div className="absolute left-[50%] -translate-x-2/4 bottom-0 h-full flex items-center text-white z-20">
            <Users onCloseView={() => dispatch(setIsShowUsers(false))} buttonRef={undefined} isMobile={isMobile} />
          </div>
          <div className="hidden md:block absolute top-0 w-[4.75rem] h-[0.2rem] bg-blue-10" />
        </DimDiv>
      )}

      {/* 사회자설정 */}
      {isShowHostSetting && (!isMd || isMobile) && (
        <DimDiv>
          <div className="absolute left-[50%] -translate-x-2/4 bottom-0 h-full flex items-center text-white z-20">
            <HostModeSetting onCloseView={() => dispatch(setIsShowHostSetting(false))} buttonRef={undefined} isMobile={isMobile} />
          </div>
        </DimDiv>
      )}

      {/* 화면보기 설정창(강조/분할 선택) */}
      {isShowChangeView && (!isMd || isMobile) && (
        <DimDiv>
          <div className="absolute top-[50%] -translate-y-2/4 left-[50%] -translate-x-2/4 z-50">
            <SelectViewMode onCloseView={() => dispatch(setIsShowChangeView(false))} />
          </div>
        </DimDiv>
      )}

      {/* 문서공유 파일리스트 */}
      {isShowDocList && (!isMd || isMobile) && (
        <DimDiv>
          <div className="absolute left-[50%] -translate-x-2/4 bottom-0 h-full flex items-center text-white z-20">
            <DocSharingFileList onCloseView={() => dispatch(setIsShowDocList(false))} buttonRef={undefined} isMobile={isMobile} />
          </div>
        </DimDiv>
      )}

      {/* 녹화파일 목록 */}
      {isShowRecordingStoreList && (!isMd || isMobile) && (
        <DimDiv>
          <div className="absolute left-[50%] -translate-x-2/4 bottom-0 h-full flex items-center text-white z-20">
            <RecordingFileList onCloseView={() => dispatch(setIsShowRecordingStoreList(false))} buttonRef={undefined} isMobile={isMobile} />
          </div>
        </DimDiv>
      )}

      {/* 가상배경 선택창 */}
      {isShowVirtualBgSetting && (!isMd || isMobile) && (
        <DimDiv>
          <div className="absolute top-[50%] -translate-y-2/4 left-[50%] -translate-x-2/4 z-50">
            <BackgroundSelector onCloseView={() => dispatch(setIsShowVirtualBgSetting(false))} buttonRef={undefined} />
          </div>
        </DimDiv>
      )}

      {isShowHostModeEnable && <HostModeEnable isOpen={isShowHostModeEnable} roomClient={roomClient} />}
      {isShowHostModeRequest && <RequestRoomHost isOpen={isShowHostModeRequest} roomClient={roomClient} />}
      {isShowInvitePopup && <InviteAttendeeRoot onClose={() => dispatch(setIsShowInvitePopup(false))} />}
      {isShowStopDocSharingPopup && <StopDocSharingPopup roomClient={roomClient} isOpen={isShowStopDocSharingPopup} />}

      {isMobile && (isShowDocSharingHost || isShowDocSharingClient) && !isShowNote && !isShowChatting && !isShowSettingWindow && (
        <div
          ref={refDocSharingToolbar}
          className={`absolute bottom-0 w-full z-50 transition-all ${isShowDocSharingToolbar ? "mb-[0rem]" : "translate-y-full"}`}
        >
          <DocSharingTool isHost={isShowDocSharingHost} />
        </div>
      )}

      {assignHostPopup.isShow && (
        <AssignRoomHost roomClient={roomClient} isOpen={assignHostPopup.isShow} username={assignHostPopup.username} bid={assignHostPopup.bid} />
      )}

      {roomState === "disconnected" && <ServerDisconnected />}
      {roomState === "duplicate" && <DuplicateJoin peerInfo={roomClient.duplicateJoinInfo} />}

      <AzureStt />

      {/* 하단 메뉴바 */}
      {!isMobile ? (
        <div className={`transition-all fixed flex w-full bg-[#333] z-40 ${isShowMenuBar || !isFullScreen ? "bottom-0" : "-bottom-[4.75rem]"}`}>
          <MenuBar />
        </div>
      ) : (
        <>
          {!isShowSettingWindow && !isShowMobileMenu && (
            <div
              className="fixed bottom-4 right-4 h-9 w-9 rounded-full text-white bg-black/75 z-50 flex justify-center items-center cursor-pointer"
              onClick={handleShowMobileMenu}
              style={{ marginBottom: isShowDocSharingToolbar ? `${docSharingToolbarHeight}px` : "0px" }}
            >
              <IconMobileMenu />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(withRoomContext(HomeCommon));
