import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import IconButton from "components/common/buttons/IconButton";
import Moment from "react-moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RecordingState, selectLocalRecordingState, setLocalRecordingState } from "store/recordingSlice";
import { setIsShowRecordingControl } from "store/windowControlSlice";
import { recordingDb } from "lib/recording/RecordingDb";
import { selectProducers } from "store/producerSlice";
import { selectConsumers } from "store/consumerSlice";
import { selectRecordingMicId, selectLatestRecordingStartTime } from "store/recordingSlice";
import { recorder } from "lib/recording/ScreenRecorder";
import { useMeasure } from "react-use";
import { ReactComponent as IconRecordingActive } from "assets/icon-recording-active.svg";
import { ReactComponent as IconRecord } from "assets/btn-record-record.svg";
import { ReactComponent as IconRecordHover } from "assets/btn-record-record-hover.svg";
import { ReactComponent as IconPause } from "assets/btn-record-pause.svg";
import { ReactComponent as IconPauseHover } from "assets/btn-record-pause-hover.svg";
import { ReactComponent as IconStop } from "assets/btn-record-stop.svg";
import { ReactComponent as IconStopHover } from "assets/btn-record-stop-hover.svg";
import { ReactComponent as IconDownload } from "assets/btn-record-download.svg";
import { ReactComponent as IconDownloadHover } from "assets/btn-record-download-hover.svg";
import { ReactComponent as IconQuit } from "assets/btn-quit.svg";
import { ReactComponent as IconQuitHover } from "assets/btn-quit-hover.svg";
import { store } from "store/store";

const RecordingControl = () => {
  const { t } = useTranslation();
  const [stateText, setStateText] = useState("");
  const [recButtonColor, setRecButtonColor] = useState("#fa114f");
  const rootDivRef = useRef<HTMLDivElement>(null);
  const recordingState = useSelector(selectLocalRecordingState);
  const producers = useSelector(selectProducers);
  const consumers = useSelector(selectConsumers);
  const selectMicId = useSelector(selectRecordingMicId);
  const startTime = useSelector(selectLatestRecordingStartTime);
  const [ref, { right, bottom }] = useMeasure<HTMLDivElement>();
  const parentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const checkRecordingDb = useCallback(async () => {
    const list = await recordingDb.getDataList();
    if (list.length > 0) {
      setStateText(t("recording.완료"));
      setRecButtonColor("#4495ff");
    } else {
      setStateText(t("recording.정지"));
      setRecButtonColor("#ffffff");
    }
  }, [t]);

  const collectAudioTracksToRecord = useCallback(() => {
    const additionalAudioTracks: MediaStreamTrack[] = [];
    const micProducer = producers.find(p => p.appData.source === "mic");
    if (micProducer && micProducer.track) additionalAudioTracks.push(micProducer.track);
    return additionalAudioTracks;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startRecording = useCallback(async () => {
    if (store.getState().recording.localRecordingState !== RecordingState.Stop) return;

    await recorder.startRecording(collectAudioTracksToRecord());
    recorder.checkAudioConsumer(consumers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    startRecording();
  }, [startRecording]);

  useEffect(() => {
    recorder.checkMicProducer(producers);
  }, [selectMicId, producers]);

  useEffect(() => {
    recorder.checkAudioConsumer(consumers);
  }, [consumers]);

  useEffect(() => {
    switch (recordingState) {
      case RecordingState.Prepare:
        setStateText(t("recording.준비중"));
        setRecButtonColor("#fff");
        break;
      case RecordingState.Stop:
      case RecordingState.Complete:
        console.log("Stop 또는 Complete", recordingState.toString());
        checkRecordingDb();
        break;
      case RecordingState.Recording:
        setStateText(t("recording.기록중"));
        setRecButtonColor("#fa114f");
        break;
      case RecordingState.Pause:
        setStateText(t("recording.기록 일시중지 됨"));
        setRecButtonColor("#fff");
        break;
    }
  }, [checkRecordingDb, recordingState, t]);

  const handleStart = () => {
    recordingState === RecordingState.Pause ? recorder.resumeLocalRecording() : startRecording();
  };

  const handleStop = () => {
    recorder.stopRecording();
  };

  const handlePause = () => {
    recorder.pauseLocalRecording();
  };

  const handleDownload = () => {
    console.log("RecordingControl download");
    recorder.downloadLastFile();
  };

  const handleClose = () => {
    dispatch(setIsShowRecordingControl(false));
    dispatch(setLocalRecordingState(RecordingState.Stop));
  };

  const onDragStop = (event: DraggableEvent, data: DraggableData) => {
    // console.log("drag onStop", data.x, data.y);
  };

  const onDrag = (event: DraggableEvent, data: DraggableData) => {
    // console.log("drag onDrag", data.x, data.y);
  };

  return (
    <div ref={ref} className="absolute top-0 left-0 w-full h-full">
      <div ref={parentRef} className="absolute top-0 left-0 z-50 border-0 border-[#f00]">
        <Draggable
          defaultPosition={{ x: 0, y: 0 }}
          grid={[1, 1]}
          bounds={{
            left: 0,
            top: 0,
            right: right - (parentRef.current ? parentRef.current.offsetWidth : 0),
            bottom: bottom - (parentRef.current ? parentRef.current.offsetHeight : 0),
          }}
          onStop={onDragStop}
          onDrag={onDrag}
        >
          <div
            ref={rootDivRef}
            className="w-[20rem] h-[3.875rem] bg-black/80 ml-auto mr-auto rounded shadow flex items-center justify-between px-4 cursor-move"
          >
            <div className="flex items-center">
              <IconRecordingActive className="" fill={recButtonColor} />
              <span className="flex justify-center text-white ml-2">{stateText}</span>
              {RecordingState.Recording === recordingState && (
                <div className="ml-2 text-white tabular-nums">
                  <Moment date={startTime.getTime() - recorder.prevRecTime} interval={1000} format={"HH:mm:ss"} durationFromNow />
                </div>
              )}
            </div>

            {recordingState === RecordingState.Pause && (
              <div className="flex">
                <div className="">
                  <IconButton
                    onClick={handleStart}
                    SvgIconNormal={IconRecord}
                    SvgIconHover={IconRecordHover}
                    tooltipContent={t("recording.녹화시작")}
                  />
                </div>
                <div className="ml-2">
                  <IconButton onClick={handleStop} SvgIconNormal={IconStop} SvgIconHover={IconStopHover} tooltipContent={t("recording.정지")} />
                </div>
              </div>
            )}

            {recordingState === RecordingState.Recording && (
              <div className="flex">
                <div className="mr-2">
                  <IconButton
                    onClick={handlePause}
                    SvgIconNormal={IconPause}
                    SvgIconHover={IconPauseHover}
                    tooltipContent={t("recording.일시정지")}
                  />
                </div>
                <IconButton onClick={handleStop} SvgIconNormal={IconStop} SvgIconHover={IconStopHover} tooltipContent={t("recording.정지")} />
              </div>
            )}

            {recordingState === RecordingState.Complete && (
              <div className="flex">
                <div className="mr-2">
                  <IconButton
                    onClick={handleDownload}
                    SvgIconNormal={IconDownload}
                    SvgIconHover={IconDownloadHover}
                    tooltipContent={t("recording.다운로드")}
                  />
                </div>
                <IconButton onClick={handleClose} SvgIconNormal={IconQuit} SvgIconHover={IconQuitHover} tooltipContent={t("닫기")} />
              </div>
            )}
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default RecordingControl;
