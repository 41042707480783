const config = {
  requestRetries: 3,
  requestTimeout: 20000,
  videoParams: {
    // mediasoup params
    encodings: [
      {
        rid: "r0",
        maxBitrate: 100000,
        scalabilityMode: "S1T3",
      },
      {
        rid: "r1",
        maxBitrate: 300000,
        scalabilityMode: "S1T3",
      },
      {
        rid: "r2",
        maxBitrate: 900000,
        scalabilityMode: "S1T3",
      },
    ],
    // https://mediasoup.org/documentation/v3/mediasoup-client/api/#ProducerCodecOptions
    codecOptions: {
      videoGoogleStartBitrate: 1000,
    },
  },
  aspectRatio: 1.6,
  userMedia: {
    audio: true,
    video: {
      width: {
        min: 320,
        max: 1920,
      },
      height: {
        min: 200,
        max: 1080,
      },
    },
  },
  displayMedia: {
    chrome: {
      videoBitsPerSecond: 8000000,
      video: {
        displaySurface: "monitor",
        width: { ideal: 1920 },
      },
      audio: true,
      advanced: [
        { width: 1920, height: 1080 },
        { width: 1280, height: 720 },
      ],
    },
    safari: {
      video: true,
      audio: false,
    },
  },
};

export default config;
