import React, { useCallback, useEffect, useState } from "react";
import SmallOverlayVideoView from "components/videoView/SmallOverlayVideoView";
import { useSelector } from "react-redux";
import { selectConsumers } from "store/consumerSlice";
import { selectCurrentHostSocketId } from "store/docSharingSlice";
import { selectPeers } from "store/peerSlice";
import { MyConsumer, PeerInfo } from "types/clientTypes";

const DocSharingHostVideoView = () => {
  const [stream, setStream] = useState<MediaStream>();
  const [hostPeer, setHostPeer] = useState<PeerInfo>();
  const hostSocketId = useSelector(selectCurrentHostSocketId);
  const consumers = useSelector(selectConsumers);
  const peers = useSelector(selectPeers);

  const findConsumer = useCallback(
    (socketId: string): MyConsumer | undefined => {
      const findConsumer = consumers.filter(
        (myconsumer: MyConsumer) => myconsumer.consumer.appData.socketId === socketId && myconsumer.consumer.kind === "video",
      );

      if (findConsumer.length > 0) return findConsumer[0];
      else return undefined;
    },
    [consumers],
  );

  useEffect(() => {
    const hostConsumer = findConsumer(hostSocketId);
    if (hostConsumer) setStream(hostConsumer.stream);
    const peer = peers.find(p => p.socketId === hostSocketId);
    if (peer) setHostPeer(peer);
  }, [findConsumer, hostSocketId, peers]);

  return <SmallOverlayVideoView stream={stream} picurl={hostPeer?.userInfo.picurl} socketId={hostSocketId} peer={hostPeer} />;
};

export default DocSharingHostVideoView;
