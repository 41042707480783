import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";

interface IProps {
  value: number;
  text?: string;
}

const ProgressCircle = ({ value, text }: IProps) => {
  return (
    <CircularProgressbar
      value={value}
      text={text}
      strokeWidth={10}
      styles={buildStyles({
        // Rotation of path and trail, in number of turns (0-1)
        rotation: 0.0,

        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: "butt",

        // Text size
        textSize: "16px",

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,

        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: "none",

        // Colors
        pathColor: "#4495ff",
        textColor: "#fff",
        trailColor: "#0000",
        backgroundColor: "#0000",
      })}
    />
  );
};

export default ProgressCircle;
