import Axios from "axios";
import { store } from "store/store";
import { addTokenToInterceptor } from "../addTokenToInterceptor";
import { IPresignedFileUploadRequest, IPresignedFileUploadResponse } from "types/fileUpload";

export const postReqPresignedUrl = async (params: IPresignedFileUploadRequest) => {
  const axiosInstance = Axios.create();
  addTokenToInterceptor(axiosInstance);
  const presignedUrlAddress = `${store.getState().env.value.AUTH_SERVER}/api/v1/crs-file-object/presignedurl/issue`;
  const { data } = await axiosInstance.post<IPresignedFileUploadResponse>(presignedUrlAddress, {
    cmd: "1303",
    ...params,
  });

  // console.log("postReqPresignedUrl Result🥙", data);
  return data.data;
};
