import Axios from "axios";
import { store } from "store/store";
import { IPresignedFileUploadRequest } from "types/fileUpload";
import { addTokenToInterceptor } from "../addTokenToInterceptor";
import { postReqPresignedUrl } from "./postReqPresignedUrl";

export interface IDeleteRes {
  code: number;
  data: Data;
  message: string;
}

export interface Data {
  v: number;
  count: number;
}

export const deleteFromStorage = async (
  fileid: string,
  filename: string,
  filesize: number,
  strid: string = "UCWORKS.user.default.kr.v2",
): Promise<IDeleteRes> => {
  const deleteFileParam: IPresignedFileUploadRequest = {
    sid: store.getState().auth.sid,
    relsvcname: "colla",
    relkey: `vc-${store.getState().room.info.id}`,
    operation: "DELETE",
    country: "KR",
    usertenantid: store.getState().room.tenantId,
    what: [
      {
        filedir: "",
        filetenantid: store.getState().room.tenantId,
        filename,
        filesize,
        fileid,
        strid,
      },
    ],
  };

  const presignedRes = await postReqPresignedUrl(deleteFileParam);
  // console.log("delete presignedUrl", presignedRes.result[0].presignedurl);

  const axiosInstance = Axios.create();
  addTokenToInterceptor(axiosInstance);

  const res = await axiosInstance.delete(presignedRes.result[0].presignedurl);
  return res.data;
};
