import React from "react";
import RoomClient from "RoomClient";
import { withRoomContext } from "RoomContext";
import { Consumer, MediaKind } from "mediasoup-client/lib/types";
import { isMobile } from "react-device-detect";
import { AuthUserInfo } from "types/commonTypes";
import ClientVideoViewForMobile from "./ClientVideoViewForMobile";
import ClientVideoViewForPC from "./ClientVideoViewForPC";

export interface IClientVideoViewProps {
  isRemote: boolean;
  isHost: boolean;
  producerId: string;
  socketId: string;
  kind: MediaKind;
  stream: MediaStream | undefined;
  roomClient: RoomClient;
  consumer?: Consumer;
  userInfo: AuthUserInfo;
}

const ClientVideoView = (props: IClientVideoViewProps) => {
  if (!props.userInfo) return null;

  return <>{isMobile ? <ClientVideoViewForMobile {...props} /> : <ClientVideoViewForPC {...props} />}</>;
};

export default React.memo(withRoomContext(ClientVideoView));
