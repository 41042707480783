import RoomClient from "RoomClient";
import useRenderingPipeline from "../hooks/useRenderingPipeline";
import { withRoomContext } from "RoomContext";
import { BodyPix } from "@tensorflow-models/body-pix";
import { useEffect } from "react";
import { BackgroundConfig } from "../helpers/backgroundHelper";
import { PostProcessingConfig } from "../helpers/postProcessingHelper";
import { SegmentationConfig } from "../helpers/segmentationHelper";
import { SourcePlayback } from "../helpers/sourceHelper";
import { TFLite } from "../hooks/useTFLite";
import { useSelector } from "react-redux";
import { selectEnv } from "store/envSlice";
import { selectSelectedWebcam } from "store/settingSlice1";

type OutputViewerProps = {
  sourcePlayback: SourcePlayback;
  backgroundConfig: BackgroundConfig;
  segmentationConfig: SegmentationConfig;
  postProcessingConfig: PostProcessingConfig;
  bodyPix: BodyPix;
  tflite: TFLite;
  videoClassName: string;
  roomClient: RoomClient;
  isCamRotate: boolean;
};

const OutputViewer = (props: OutputViewerProps) => {
  const { roomClient } = props;
  const env = useSelector(selectEnv);
  const webcamId = useSelector(selectSelectedWebcam);

  const {
    pipeline,
    backgroundImageRef,
    canvasRef,
    fps,
    durations: [resizingDuration, inferenceDuration, postProcessingDuration],
  } = useRenderingPipeline(props.sourcePlayback, props.backgroundConfig, props.segmentationConfig, props.bodyPix, props.tflite);

  useEffect(() => {
    if (pipeline) {
      pipeline.updatePostProcessingConfig(props.postProcessingConfig);
    }
  }, [pipeline, props.postProcessingConfig, props.segmentationConfig.pipeline]);

  useEffect(() => {
    if (canvasRef.current) {
      setTimeout(() => {
        const stream = canvasRef.current.captureStream();
        roomClient.setLocalVideoStreamWaintingForSendTransport(stream);
      }, 500);
    }
  }, [canvasRef, roomClient, props.backgroundConfig?.url, props.backgroundConfig.type, webcamId]);

  const statDetails = [`resizing ${resizingDuration}ms`, `inference ${inferenceDuration}ms`, `post-processing ${postProcessingDuration}ms`];
  const stats = `${Math.round(fps)} fps (${statDetails.join(", ")})`;

  return (
    <div className="bg-[#555] h-full w-full">
      {props.backgroundConfig.type === "image" && (
        <img
          ref={backgroundImageRef}
          className="object-cover w-0"
          src={props.backgroundConfig.url}
          alt=""
          hidden={props.segmentationConfig.pipeline === "webgl2"}
        />
      )}
      <canvas
        ref={canvasRef}
        key={props.segmentationConfig.pipeline}
        width={props.sourcePlayback.width}
        height={props.sourcePlayback.height}
        className={props.videoClassName}
        style={props.isCamRotate ? { transform: "rotateY(180deg)" } : {}}
      />
      {env.NODE_ENV === "development" && <div className="absolute bottom-1 left-2">{stats}</div>}
    </div>
  );
};

export default withRoomContext(OutputViewer);
