import axios from "axios";

export const deleteFile = async (url: string) => {
  try {
    const res = await axios.delete(url);
    return { ...res.data, code: 200, count: 1 };
  } catch (error) {
    console.error(error);
    return { code: 200 };
  }
};
