import { ReactNode } from "react";

interface IProps {
  children: ReactNode;
}

const EllipsisDiv = ({ children }: IProps) => {
  return (
    <div className="flex flex-1 items-center">
      <span className="w-0 flex-1 truncate">{children}</span>
    </div>
  );
};

export default EllipsisDiv;
