import RoomClient from "../../RoomClient";
import ClickAwayListener from "../ClickAwayListener";
import SwitchComp from "../Switch";
import { withRoomContext } from "../../RoomContext";
import { selectRoomSetting, setRoomSetting } from "store/roomInfoSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { RoomSetting } from "types/commonTypes";

interface IProps {
  onCloseView: () => void;
  roomClient: RoomClient;
  buttonRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}

const HostModeSetting = (props: IProps) => {
  const { onCloseView, roomClient, buttonRef, isMobile } = props;
  const { t } = useTranslation();
  const roomSetting = useSelector(selectRoomSetting);
  const dispatch = useDispatch();

  const sendChangeRoomSetting = (newSetting: RoomSetting) => {
    const prevSetting = { ...roomSetting };
    dispatch(setRoomSetting(newSetting));

    roomClient.sendChangeRoomSetting(newSetting, (result: boolean) => {
      if (!result) dispatch(setRoomSetting(prevSetting));
    });
  };

  const onChangedSelfMicOn = (checked: boolean) => {
    sendChangeRoomSetting({ ...roomSetting, isSelfMicOn: checked });
  };

  const onChangedMicOffStart = (checked: boolean) => {
    sendChangeRoomSetting({ ...roomSetting, isMicOnStartUp: !checked });
  };

  const onChangedCamOffStart = (checked: boolean) => {
    sendChangeRoomSetting({ ...roomSetting, isCamOnStartUp: !checked });
  };

  const onChangedDocSharingOnlyHost = (checked: boolean) => {
    sendChangeRoomSetting({ ...roomSetting, isDocSharingOnlyHost: checked });
  };

  const onChangedOnlyHostUseWhiteBoard = (checked: boolean) => {
    sendChangeRoomSetting({ ...roomSetting, isWhiteBoardOnlyHost: checked });
  };

  return (
    <ClickAwayListener onClickAway={onCloseView} buttonRef={buttonRef}>
      {ref => (
        <div
          ref={ref}
          className={`${
            isMobile ? "" : "md:absolute md:bottom-1 md:left-0 md:right-0 md:-translate-x-2/4 md:mx-9 max-h-[70vh]"
          } overflow-y-auto overflow-x-hidden flex flex-col items-center w-[22.5rem] z-50 rounded shadow bg-[#333] select-none text-white`}
        >
          <div>
            <div className="flex w-full h-9 justify-start items-center">
              <div className="w-full text-base font-bold text-white ml-4 pt-1">{t("host.사회자 상세 설정")}</div>
            </div>
            <div className="w-[22.5rem] h-px bg-[#555]" />

            <div className="text-sm font-bold flex items-center mt-[0.719rem] ml-4">{t("host.참여자 장치 제어")}</div>

            <div className="h-10 flex items-center px-6 justify-between">
              <span className="text-sm ">{t("host.스스로 마이크 켜기")}</span>
              <SwitchComp enabled={roomSetting.isSelfMicOn} onChange={onChangedSelfMicOn} />
            </div>

            <div className="h-10 flex items-center px-6 justify-between">
              <span className="text-sm ">{t("host.마이크 OFF로 참여하기")}</span>
              <SwitchComp enabled={!roomSetting.isMicOnStartUp} onChange={onChangedMicOffStart} />
            </div>

            <div className="h-10 flex items-center px-6 justify-between mb-[0.625rem]">
              <span className="text-sm ">{t("host.카메라 OFF로 참여하기")}</span>
              <SwitchComp enabled={!roomSetting.isCamOnStartUp} onChange={onChangedCamOffStart} />
            </div>
            {process.env.REACT_APP_ENABLE_DOC_SHARING === "true" && (
              <>
                <div className="text-sm font-bold flex items-center mt-[0.719rem] ml-4">{t("host.문서 공유")}</div>

                <div className="h-10 flex items-center px-6 justify-between">
                  <span className="text-sm ">{t("host.사회자만 문서 공유하기")}</span>
                  <SwitchComp enabled={roomSetting.isDocSharingOnlyHost} onChange={onChangedDocSharingOnlyHost} />
                </div>

                <div className="h-10 flex items-center px-6 justify-between mb-[0.25rem]">
                  <span className="text-sm ">{t("host.사회자만 화이트보드 도구 사용하기")}</span>
                  <SwitchComp enabled={roomSetting.isWhiteBoardOnlyHost} onChange={onChangedOnlyHostUseWhiteBoard} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </ClickAwayListener>
  );
};

export default withRoomContext(HostModeSetting);
