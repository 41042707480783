import Axios from "axios";
import { store } from "../../store/store";
import { MemoData } from "../../types/commonTypes";
import { getConfig } from "./config";

interface IMemoDto {
  meetingId: number;
  content: string;
  email?: string;
  msgSendDate: string;
}

export const addMemo = async (memoData: MemoData) => {
  try {
    const url = `${store.getState().env.value.MGR_SERVER}/api/video/meetings/memos`;
    console.log("addMemo url", url);

    let data: IMemoDto = {
      meetingId: memoData.roomId,
      content: memoData.text,
      msgSendDate: memoData.time ?? new Date().toISOString(),
    };

    if (!memoData.userInfo.isInsider) {
      data = { ...data, email: memoData.userInfo.bidOrEmail };
    }

    const config = getConfig(store.getState().auth.token);

    const axiosInstance = Axios.create({ timeout: 10000 });
    const response = await axiosInstance.post(url, data, config);
    console.log("addMemo res", response.data);
    return response.data;
  } catch (e) {
    console.log("error from memo api");
    console.log(e);
    return e;
  }
};
