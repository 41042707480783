import React, { useEffect, useRef, useState, useCallback, MouseEvent, TouchEvent } from "react";
import LoadingSimple from "components/LoadingSimple";
import cursorPointerImage from "assets/docsharing/cursor-pointer.svg";
import cursorPenImage from "assets/docsharing/cursor-pen.svg";
import cursorEraserImage from "assets/docsharing/cursor-eraser.svg";
import RoomClient from "RoomClient";
import { store } from "store/store";
import { withRoomContext } from "RoomContext";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCurrentIndex,
  selectConvertedFileList,
  selectEditMode,
  selectRefreshTime,
  selectPenColor,
  selectZoomType,
  selectIsShowColorPicker,
  selectIsWhiteBoard,
  selectPenPath,
  selectHostBgStartPos,
  selectIsBgImageMoving,
  selectBgDrawSize,
  selectCurrentFileId,
  setIsShowColorPicker,
  setHostBgStartPos,
  setZoomType,
  setBgDrawSize,
  setIsBgImageMoving,
  createPenPath,
  addPenPath,
  clearAllPenPath,
  clearPenPath,
} from "store/docSharingSlice";
import { Point, ISize, Line } from "../docSharingType";
import { checkLinesIntersect } from "../lineIntersect";
import { useMeasure } from "react-use";
import { useMobileOrientation } from "react-device-detect";
import { usePinch } from "@use-gesture/react";

interface IProps {
  roomClient: RoomClient;
}

let startCoords: Point = { x: 0, y: 0 };

const DocSharingHostBoard = ({ roomClient }: IProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cursor, setCursor] = useState("");
  const [ctx, setCtx] = useState<CanvasRenderingContext2D>();
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEraser, setIsEraser] = useState(false);
  const [bgImg, setBgImg] = useState<HTMLImageElement>();
  const [canvasScale, setCanvasScale] = useState<number>(1.0);
  const [canvasSize, setCanvasSize] = useState<ISize>({ width: 0, height: 0 });
  const [refreshTime, setRefreshTime] = useState(0);
  const [pinchZoom, setPinchZoom] = useState<number>(1.0);
  const [pinchOrigin, setPinchOrigin] = useState<Point>({ x: 0, y: 0 });
  const curIndex = useSelector(selectCurrentIndex);
  const fileList = useSelector(selectConvertedFileList);
  const editMode = useSelector(selectEditMode);
  const docRefreshTime = useSelector(selectRefreshTime);
  const penColor = useSelector(selectPenColor);
  const penPath = useSelector(selectPenPath);
  const zoomType = useSelector(selectZoomType);
  const bgStartPos = useSelector(selectHostBgStartPos);
  const isShowColorPicker = useSelector(selectIsShowColorPicker);
  const isWhiteBoard = useSelector(selectIsWhiteBoard);
  const isMoving = useSelector(selectIsBgImageMoving);
  const bgDrawSize = useSelector(selectBgDrawSize);
  const curFileId = useSelector(selectCurrentFileId);
  const { isPortrait } = useMobileOrientation();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [rootRef, { width: parentWidth, height: parentHeight }] = useMeasure<HTMLDivElement>();
  const dispatch = useDispatch();
  const eraserSize = 20;
  const penWidth = 10;
  const halfWidthOfCursor = 15; // svg와 일치시킬것

  const bind = usePinch(state => {
    const {
      offset: [d], // [scale, angle] offsets (starts withs scale=1)
      origin,
      type,
    } = state;
    console.log("pinch state", type, d);
  
    if(type !== "wheel") {
      if (type === "pointerdown") {
        console.log("pinch origin", origin);
        setPinchOrigin({ x: origin[0], y: origin[1] });
      }
      setPinchZoom(d);
    }
  });

  useEffect(() => {
    return () => {
      roomClient.sendDocSharingHostRemove();
    };
  }, [roomClient]);

  useEffect(() => {
    setCanvasSize({ width: parentWidth - 4, height: parentHeight - 4 });
  }, [parentWidth, parentHeight, isWhiteBoard]);

  useEffect(() => {
    dispatch(setZoomType("auto-fit"));
  }, [dispatch]);

  useEffect(() => {
    if (editMode === "CURSOR") {
      setCursor(isMoving ? "move" : "auto");
    } else if (editMode === "POINTER") {
      setCursor(`url(${cursorPointerImage}) ${halfWidthOfCursor} ${halfWidthOfCursor}, auto`);
    } else if (editMode === "PEN") {
      setCursor(`url(${cursorPenImage}) 0 ${halfWidthOfCursor * 2 - 1}, auto`);
    } else if (editMode === "ERASER") {
      setCursor(`url(${cursorEraserImage}) ${halfWidthOfCursor} ${halfWidthOfCursor}, auto`);
    } else {
      setCursor("auto");
    }

    roomClient.sendDocSharingCursorPos(startCoords.x, startCoords.y, editMode);
  }, [editMode, isMoving, roomClient]);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) setCtx(ctx);
    }
  }, [canvasRef]);

  const redrawingPenPath = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !ctx) {
      return;
    }

    ctx.globalCompositeOperation = "source-over";
    ctx.lineCap = "round";

    for (let i = 0; i < penPath.length; i++) {
      ctx.strokeStyle = penPath[i].color;
      ctx.lineWidth = (penPath[i].width * canvasScale) / penPath[i].canvasScale;
      const ratio = canvasScale / penPath[i].canvasScale;

      ctx.beginPath();

      if (penPath[i].path.length > 1) {
        for (const line of penPath[i].path) {
          ctx.moveTo(line.p1.x * ratio + bgStartPos.x, line.p1.y * ratio + bgStartPos.y);
          ctx.lineTo(line.p2.x * ratio + bgStartPos.x, line.p2.y * ratio + bgStartPos.y);
        }
      } else {
        ctx.arc(penPath[i].path[0].p1.x * ratio + bgStartPos.x, penPath[i].path[0].p1.y * ratio + bgStartPos.y, 1, 0, 1 * Math.PI, false);
      }

      ctx.stroke();
    }
  }, [bgStartPos.x, bgStartPos.y, canvasScale, ctx, penPath]);

  useEffect(() => {
    redrawingPenPath();
  }, [penPath, redrawingPenPath]);

  const handleTouchStart = (e: TouchEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    e.preventDefault();

    const x = e.touches[0].clientX - canvas.getBoundingClientRect().left;
    const y = e.touches[0].clientY - canvas.getBoundingClientRect().top;

    processMouseDown(x, y);
  };

  const handleMouseDown = (e: MouseEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    processMouseDown(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  };

  const processMouseDown = (startX: number, startY: number) => {
    if (isShowColorPicker) {
      dispatch(setIsShowColorPicker(false));
    }

    if (editMode === "CURSOR") {
      dispatch(setIsBgImageMoving(true));
    } else if (editMode === "PEN") {
      setIsDrawing(true);

      const newPenPath = {
        width: penWidth,
        color: penColor,
        path: [
          {
            p1: { x: startX - bgStartPos.x, y: startY - bgStartPos.y },
            p2: { x: startX - bgStartPos.x, y: startY - bgStartPos.y },
          },
        ],
        canvasScale,
      };

      dispatch(createPenPath(newPenPath));
      roomClient.sendDocSharingCreatePenPath(newPenPath);
    } else if (editMode === "ERASER") {
      setIsEraser(true);
      erasePenPath(startX, startY);
    } else return;

    startCoords = { x: startX, y: startY };
  };

  const handleTouchMove = (e: TouchEvent<HTMLCanvasElement>) => {
    if (!ctx) return;
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (e.touches.length > 1) {
      return;
    }

    const endX = e.touches[0].clientX - canvas.getBoundingClientRect().left;
    const endY = e.touches[0].clientY - canvas.getBoundingClientRect().top;

    processMouseMove(endX, endY);
  };

  const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
    if (!ctx) return;
    e.preventDefault();

    const endX = e.nativeEvent.offsetX;
    const endY = e.nativeEvent.offsetY;

    processMouseMove(endX, endY);
  };

  const processMouseMove = (endX: number, endY: number) => {
    if (editMode === "POINTER") {
      roomClient.sendDocSharingCursorPos(endX - bgStartPos.x, endY - bgStartPos.y, editMode);
    }

    if (isMoving) {
      const dx = endX - startCoords.x;
      const dy = endY - startCoords.y;
      dispatch(setHostBgStartPos({ x: bgStartPos.x + dx, y: bgStartPos.y + dy }));
    } else if (isDrawing) {
      const newPath: Line = {
        p1: { x: startCoords.x - bgStartPos.x, y: startCoords.y - bgStartPos.y },
        p2: { x: endX - bgStartPos.x, y: endY - bgStartPos.y },
      };

      dispatch(addPenPath(newPath));
      roomClient.sendDocSharingAddPenPath(newPath);
    } else if (isEraser) {
      erasePenPath(endX, endY);
    }

    startCoords = { x: endX, y: endY };
  };

  const handleMouseUp = () => {
    if (isMoving) {
      dispatch(setBgDrawSize({ ...bgDrawSize, x: bgStartPos.x, y: bgStartPos.y }));
    }
    dispatch(setIsBgImageMoving(false));
    setIsDrawing(false);
    setIsEraser(false);
  };

  const handleTouchEnd = (e: TouchEvent<HTMLCanvasElement>) => {
    handleMouseUp();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!ctx || !bgImg || !canvas) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(bgImg, 0, 0, bgImg.width, bgImg.height, bgStartPos.x, bgStartPos.y, bgDrawSize.width, bgDrawSize.height);
    redrawingPenPath();
  }, [bgDrawSize.height, bgDrawSize.width, bgImg, bgStartPos, ctx, parentHeight, parentWidth, redrawingPenPath]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const bg = new Image();

    bg.onload = () => {
      // console.log(`bg onload~~~~~~~~ width:${bg.width}, height:${bg.height}`);
      setBgImg(bg);
      setIsLoading(false);

      let scale = 1.0;

      if (zoomType === "auto-fit") {
        scale = Math.min(parentWidth / bg.width, parentHeight / bg.height);
      } else if (zoomType === "width-fit") {
        scale = parentWidth / bg.width;
      } else if (zoomType === "height-fit") {
        scale = parentHeight / bg.height;
      } else {
        scale = parseFloat(zoomType);
      }

      scale *= pinchZoom;
      setCanvasScale(scale);

      roomClient.sendDocShargingHostScale(scale);

      if (ctx && ctx && canvas) {
        const drawWidth = Math.trunc(bg.width * scale);
        const drawHeight = Math.trunc(bg.height * scale);

        const x = Math.trunc((canvas.width - drawWidth) / 2);
        const y = Math.trunc((canvas.height - drawHeight) / 2);
        // console.log(`bgStart ${x} ${y}, scale:${scale}`);

        dispatch(setBgDrawSize({ width: drawWidth, height: drawHeight, x, y }));
        dispatch(setHostBgStartPos({ x, y }));
      }
    };

    const imageUrl = isWhiteBoard
      ? "docsharing/white-1024.png"
      : `${store.getState().env.value.SERVER_URL}/docsharing/${curFileId}/converted/${curIndex + 1}.jpg`;

    const isSameFile = (bgImg?.src ?? "") === imageUrl;
    if (!isSameFile || refreshTime !== docRefreshTime) {
      dispatch(clearAllPenPath());
      roomClient.sendDocSharingClearAllPenPath();
      roomClient.sendDocSharingPageInfo({ url: imageUrl, total: fileList.length, current: curIndex + 1, zoomType, canvasScale });
      setIsLoading(true);
    }

    bg.src = imageUrl;
    bg.crossOrigin = "anonymous";

    setRefreshTime(docRefreshTime);
  }, [
    ctx,
    curIndex,
    fileList,
    docRefreshTime,
    zoomType,
    parentWidth,
    parentHeight,
    bgImg?.src,
    refreshTime,
    roomClient,
    dispatch,
    canvasScale,
    isWhiteBoard,
    pinchZoom,
    curFileId,
  ]);

  const erasePenPath = (offsetX: number, offsetY: number) => {
    const x = offsetX - bgStartPos.x;
    const y = offsetY - bgStartPos.y;

    penPath.forEach((path, idx) => {
      const ratio = canvasScale / penPath[idx].canvasScale;

      path.path.forEach(line => {
        const isCrose = checkLinesIntersect(
          { p1: { x: line.p1.x * ratio, y: line.p1.y * ratio }, p2: { x: line.p2.x * ratio, y: line.p2.y * ratio } },
          { p1: { x: startCoords.x - bgStartPos.x, y: startCoords.y - bgStartPos.y }, p2: { x, y } },
        );

        if (isCrose) {
          dispatch(clearPenPath(idx));
          roomClient.sendDocSharingClearPenPath(idx);
          return;
        } else if (line.p1.x === line.p2.x && line.p1.y === line.p2.y) {
          const distance = Math.sqrt((line.p1.x * ratio - x) ** 2 + (line.p1.y * ratio - y) ** 2);
          if (distance < (eraserSize * ratio) / 2) {
            dispatch(clearPenPath(idx));
            roomClient.sendDocSharingClearPenPath(idx);
          }
        }
      });
    });
  };

  return (
    <div
      ref={rootRef}
      className="relative w-full h-full flex justify-center items-center bg-[#222222] border-0 border-red-400 overflow-hidden z-20"
      style={{ touchAction: "none" }}
    >
      <div ref={divRef} className="w-fit h-fit">
        <canvas
          {...bind()}
          ref={canvasRef}
          width={canvasSize.width}
          height={isPortrait && bgImg ? parentWidth * (bgImg.height / bgImg.width) : bgImg ? parentHeight - 4 : parentWidth * 0.7}
          style={{ cursor: cursor }}
          className="border-0 border-red-400"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchEnd}
        />
      </div>
      {isLoading && (
        <div className="absolute inset-0 mx-auto my-auto w-[6rem] h-[6rem] bg-[#0007] flex justify-center items-center p-4 rounded-3xl">
          <LoadingSimple />
        </div>
      )}

      {process.env.NODE_ENV === "development" && (
        <>
          <div className="absolute top-5 left-5 text-base text-red-400 select-none">Canvas Scale: {canvasScale.toFixed(2)}</div>
          <div className="absolute left-20 w-fit h-20 bg-red-400 z-[999] p-4 flex justify-center items-center rounded border-4 border-green-400">
            Host Board
          </div>
        </>
      )}
    </div>
  );
};

export default withRoomContext(DocSharingHostBoard);
