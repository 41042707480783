import config from "config";
import { Producer, Transport, ProducerOptions } from "mediasoup-client/lib/types";
import { removeProducer } from "store/producerSlice";
import { Socket } from "socket.io-client";
import { EventEmitter } from "events";
import { store } from "store/store";
import { isSafari } from "react-device-detect";

class ScreenShare extends EventEmitter {
  sendTransport: Transport | null = null;
  screenProducer: Producer | null = null;
  screenAudioProducer: Producer | null = null;
  screenParams: ProducerOptions = {};
  screenAudioParams: ProducerOptions = {};
  socket: Socket | null = null;
  hasAudioTrack = false;

  constructor(socket: Socket, sendTransport: Transport) {
    super();
    this.socket = socket;
    this.sendTransport = sendTransport;
  }

  getDisplayMediaStream = async () => {
    try {
      const aspectRatioSupported = navigator.mediaDevices.getSupportedConstraints().aspectRatio;
      console.log("aspectRatioSupported", aspectRatioSupported);

      const chromeOptions = { ...config.displayMedia.chrome, systemAudio: "include", selfBrowserSurface: "include", cursor: "always" };
      const options: MediaStreamConstraints = !isSafari ? chromeOptions : config.displayMedia.safari;

      const stream = await navigator.mediaDevices.getDisplayMedia(options);
      if (!stream) return undefined;

      console.log("screen share audio track length", stream.getAudioTracks().length);
      // stream.getAudioTracks().forEach(track => track.stop());

      this.screenParams = { track: stream.getVideoTracks()[0] };

      if (stream.getAudioTracks().length > 0) {
        this.screenAudioParams = { track: stream.getAudioTracks()[0] };
        this.hasAudioTrack = true;
      }

      return stream;
    } catch (error) {
      console.error("getDisplayMediaStream error", error);
    }
  };

  createScreenShareProducer = async () => {
    if (!this.sendTransport) return;

    this.screenProducer = await this.sendTransport.produce({
      ...this.screenParams,
      appData: {
        source: "screen",
      },
    });

    this.screenProducer.observer.on("close", () => {
      console.log("screen producer closed!!");
    });

    this.screenProducer.on("transportclose", () => {
      console.log("screen producer transportclose");
    });

    this.screenProducer.on("trackended", () => {
      console.log("screen producer trackended");
      this.closeScreenShare();
    });

    if (this.hasAudioTrack) this.createScreenShareAudioProducer();
  };

  createScreenShareAudioProducer = async () => {
    if (!this.sendTransport) return;

    this.screenAudioProducer = await this.sendTransport.produce({
      ...this.screenAudioParams,
      appData: {
        source: "screen_audio",
      },
    });

    this.screenAudioProducer.observer.on("close", () => {
      console.log("screen audio producer closed!!");
    });

    this.screenAudioProducer.on("transportclose", () => {
      console.log("screen audio producer transportclose");
    });

    this.screenAudioProducer.on("trackended", () => {
      console.log("screen audio producer trackended");
    });

    this.screenAudioProducer.observer.on("pause", () => {
      console.log("screen audioProducer on pause");
      this.socket?.emit("producer-pause", { producerId: this.screenAudioProducer?.id });
    });

    this.screenAudioProducer.observer.on("resume", () => {
      console.log("screen audioProducer on resume");
      this.socket?.emit("producer-resume", { producerId: this.screenAudioProducer?.id });
    });
  };

  closeScreenShare = () => {
    console.debug("closeScreenShare()");
    if (!this.screenProducer) return;

    try {
      this.screenProducer.close();
      this.screenAudioProducer?.close();
      store.dispatch(removeProducer(this.screenProducer.id));

      this.socket?.emit("closeProducer", { producerId: this.screenProducer.id }, (currentCnt: number) => {
        console.log("screen 프로듀서 삭제후 콜백수신. 현재갯수:", currentCnt);
      });

      if (this.screenAudioProducer) {
        store.dispatch(removeProducer(this.screenAudioProducer.id));
        this.socket?.emit("closeProducer", { producerId: this.screenAudioProducer.id }, (currentCnt: number) => {
          console.log("screen audio 프로듀서 삭제후 콜백수신. 현재갯수:", currentCnt);
        });
      }

      this.socket?.emit("moderator:stopScreenSharing");
    } catch (error) {
      console.log("closeScreenShare error", error);
    }

    this.emit("close");
    this.screenProducer = null;
    this.screenParams?.track?.stop();
    this.screenParams = {};

    this.screenAudioProducer = null;
    this.screenAudioParams?.track?.stop();
    this.screenAudioParams = {};
  };
}

export default ScreenShare;
