import React, { useEffect, useRef, useState } from "react";
import useBodyPix from "../hooks/useBodyPix";
import useTFLite from "../hooks/useTFLite";
import OutputViewer from "./OutputViewer";
import SourceViewer from "./SourceViewer";
import styled from "styled-components";
import { PostProcessingConfig } from "../helpers/postProcessingHelper";
import { SegmentationConfig } from "../helpers/segmentationHelper";
import { SourceConfig, SourcePlayback } from "../helpers/sourceHelper";
import { BackgroundType } from "../helpers/backgroundHelper";

interface VirtualBgVideoViewProps {
  bgType: BackgroundType;
  bgUrl: string;
  stream?: MediaStream;
  videoClassName: string;
  isCamRotate: boolean;
  configVideo: MediaTrackConstraints;
  configAspectRatio: number;
  isEnable: boolean;
  isSmallSize?: boolean;
}

const VirtualBgVideoView = (props: VirtualBgVideoViewProps) => {
  const { bgType, bgUrl, stream, videoClassName, isCamRotate, configVideo, configAspectRatio, isEnable } = props;
  const [sourcePlayback, setSourcePlayback] = useState<SourcePlayback>();
  const [sourceConfig] = useState<SourceConfig>({ type: "camera" });
  const [sectionWidth, setSectionWidth] = useState(0);
  const rootRef = useRef<HTMLDivElement>(null);

  const postProcessingConfig: PostProcessingConfig = {
    smoothSegmentationMask: true,
    jointBilateralFilter: { sigmaSpace: 1, sigmaColor: 0.1 },
    coverage: [0.5, 0.75],
    lightWrapping: 0.3,
    blendMode: "screen",
  };

  const [segmentationConfig, setSegmentationConfig] = useState<SegmentationConfig>({
    model: "meet",
    backend: "wasm",
    inputResolution: "160x96",
    pipeline: "webgl2",
    targetFps: 30, // 60 introduces fps drop and unstable fps on Chrome
    deferInputResizing: true,
  });

  const bodyPix = useBodyPix();
  const { tflite, isSIMDSupported } = useTFLite(segmentationConfig);

  useEffect(() => {
    setSegmentationConfig(previousSegmentationConfig => {
      if (previousSegmentationConfig.backend === "wasm" && isSIMDSupported) {
        return { ...previousSegmentationConfig, backend: "wasmSimd" };
      } else {
        return previousSegmentationConfig;
      }
    });
  }, [isSIMDSupported]);

  useEffect(() => {
    setSourcePlayback(undefined);
  }, []);

  useEffect(() => {
    if (rootRef.current) {
      const temp = Math.ceil(rootRef.current.clientHeight * 1.6);
      setSectionWidth(temp);
    }
  }, [rootRef, rootRef?.current?.clientHeight, isEnable]);

  return (
    <>
      {isEnable ? (
        <div className="relative w-full h-full">
          <div className="absolute top-1/2 left-1/2 z-50 w-0">
            <SourceViewer
              sourceConfig={sourceConfig}
              onLoad={setSourcePlayback}
              stream={stream}
              configVideo={configVideo}
              configAspectRatio={configAspectRatio}
              isCamRotate={isCamRotate}
            />
          </div>

          {sourcePlayback && bodyPix && tflite && (
            <OutputViewer
              sourcePlayback={sourcePlayback}
              backgroundConfig={{ type: bgType, url: bgUrl }}
              segmentationConfig={segmentationConfig}
              postProcessingConfig={postProcessingConfig}
              bodyPix={bodyPix}
              tflite={tflite}
              videoClassName={videoClassName}
              isCamRotate={isCamRotate}
            />
          )}
        </div>
      ) : (
        <StyledNoCamDiv ref={rootRef} className={`${videoClassName}`} width={sectionWidth} />
      )}
    </>
  );
};

const StyledNoCamDiv = styled.div<{ width: number }>`
  display: flex;
  width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};

  @media screen and (max-width: 1024px) and (orientation: portrait) {
    width: 100vw;
    min-height: 200px;
  }
`;

export default React.memo(VirtualBgVideoView);
