import React, { useState } from "react";

interface IProps {
  SvgIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  isFill?: boolean;
  isActive?: boolean;
}

const UserCommandButton = ({ SvgIcon, isFill, isActive }: IProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="w-6 h-6" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      {isFill ? (
        <SvgIcon fill={isHovered || isActive ? "#4495ff" : "#fff"} />
      ) : (
        <SvgIcon style={isHovered ? { stroke: "#4495ff" } : { stroke: "#fff" }} />
      )}
    </div>
  );
};

export default UserCommandButton;
