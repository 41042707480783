import { useEffect, useState, useRef, useCallback } from "react";
import i18next from "translate/i18n";
import RoomClient from "RoomClient";
import ClickAwayListener from "components/ClickAwayListener";
import SelectListbox2 from "components/SelectListbox2";
import TestRoot from "components/setting/speakerMicTest/TestRoot";
import Tooltip from "./Tooltip";
import { Value } from "components/common/interfaces/TypeSelectListbox";
import { withRoomContext } from "RoomContext";
import {
  selectWebcams,
  selectSelectedWebcam,
  selectMics,
  selectSelectedMic,
  getSpeakers,
  getSelectedSpeaker,
  setIsCamRotate,
  selectIsCameRotate,
  selectLanguage,
  setLanguage,
} from "store/settingSlice1";
import { selectLocalStream, selectUserInfo } from "store/roomInfoSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconQuestion } from "assets/icon-question.svg";
import { ReactComponent as IconChecked } from "assets/check-box-checked.svg";
import { ReactComponent as IconUnChecked } from "assets/check-box-unchecked.svg";
import { Language } from "types/commonTypes";
import { isSafari, isIOS, isMobile, isAndroid } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import Button from "components/common/buttons/Button";

interface PropsDefault {
  onCloseView: () => void;
  roomClient: RoomClient;
}

export const options_lang = [
  { id: "ko-KR", name: "한국어" },
  { id: "en-US", name: "English" },
  { id: "ja-JP", name: "日本語" },
];

const Setting = (props: PropsDefault) => {
  const { onCloseView, roomClient } = props;
  const { t } = useTranslation();
  const [selectedOptionCam, setSelectedOptionCam] = useState<Value>({ id: "", name: "" });
  const [selectedOptionMic, setSelectedOptionMic] = useState<Value>({ id: "", name: "" });
  const [selectedOptionSpk, setSelectedOptionSpk] = useState<Value>({ id: "", name: "" });
  const [selectedOptionLang, setSelectedOptionLang] = useState<Value>(options_lang[0]);
  const [options_webcam, setOptionWebcam] = useState<Value[]>([]);
  const [options_mic, setOptionMic] = useState<Value[]>([]);
  const [options_spk, setOptionSpk] = useState<Value[]>([]);
  const [isOpenTestDialog, setIsOpenTestDialog] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const webcams = useSelector(selectWebcams);
  const mics = useSelector(selectMics);
  const selectedWebcam = useSelector(selectSelectedWebcam);
  const selectedMic = useSelector(selectSelectedMic);
  const speakers = useSelector(getSpeakers);
  const selectedSpk = useSelector(getSelectedSpeaker);
  const localStream = useSelector(selectLocalStream);
  const userInfo = useSelector(selectUserInfo);
  const language = useSelector(selectLanguage);
  const [userName, setUserName] = useState(userInfo.username);
  const [savedUserName] = useState(userInfo.username);
  const [savedLanguage] = useState(language);
  const [savedWebcamId] = useState(localStorage.getItem("selectedWebcomId"));
  const savedMicId = localStorage.getItem("selectedMicId");
  const savedSpeakerId = localStorage.getItem("selectedAudioOutputDeviceId");
  const savedIsCamRotate = useSelector(selectIsCameRotate);
  const [isRotate, setIsRotate] = useState(savedIsCamRotate);
  const testBtnRef = useRef<HTMLDivElement>(null);
  const btnApplyRef = useRef<HTMLButtonElement>(null);
  const isMaxMd = useMediaQuery({ query: "(max-width: 768px)" });
  const dispatch = useDispatch();

  const loadDevices = useCallback(async () => {
    console.log("Setting loadDevices()");
    await roomClient._updateWebcams({ init: true });
    await roomClient._updateAudioInputDevices({ init: true });
    await roomClient._updateAudioOutputDevices({ init: true });
  }, [roomClient]);

  useEffect(() => {
    if (isAndroid) loadDevices();
  }, [loadDevices]);

  useEffect(() => {
    if (btnApplyRef.current) {
      btnApplyRef.current.disabled = userName.length <= 0;
    }
  }, [userName]);

  useEffect(() => {
    const newWebcamNames: Value[] = [];
    webcams.forEach((values, key) => {
      const val = { id: key, name: values.label };
      newWebcamNames.push(val);

      if (key === selectedWebcam) {
        setSelectedOptionCam(val);
      }
    });
    setOptionWebcam(newWebcamNames);
  }, [webcams, selectedWebcam]);

  useEffect(() => {
    const newMics: Value[] = [];
    mics.forEach((values, key) => {
      const val = { id: key, name: values.label };
      newMics.push(val);
      if (key === selectedMic) {
        setSelectedOptionMic(val);
      }
    });

    setOptionMic(newMics);
  }, [mics, selectedMic, t]);

  useEffect(() => {
    const newSpks: Value[] = [];

    speakers.forEach((values, key) => {
      const val = { id: key, name: values.label };
      newSpks.push(val);
      if (key === selectedSpk) {
        setSelectedOptionSpk(val);
      }
    });

    setOptionSpk(newSpks);
  }, [selectedSpk, speakers, t]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (localStream !== undefined) {
      videoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    i18next.changeLanguage(language);

    options_lang.forEach(item => {
      if (item.id === language) {
        setSelectedOptionLang(item);
      }
    });
  }, [language]);

  const handleClose = () => {
    onCloseView();
  };

  const handleChangeWebcam = (v: Value) => {
    setSelectedOptionCam(v);
    roomClient.updateWebcam({ restart: true, newDeviceId: v.id });
  };

  const handleChangeLanguage = (v: Value) => {
    console.log("change language", v);
    setSelectedOptionLang(v);
    i18next.changeLanguage(v.id);
  };

  const handleCancel = () => {
    if (savedWebcamId && savedWebcamId !== selectedOptionCam.id) {
      roomClient.updateWebcam({ restart: true, newDeviceId: savedWebcamId });
    }

    if (savedLanguage && savedLanguage !== selectedOptionLang.id) {
      i18next.changeLanguage(savedLanguage);
    }

    handleClose();
  };

  const updateAndClose = async () => {
    // console.log("설정창 updateAndClose 실행");

    if (savedMicId !== selectedOptionMic.id) {
      await roomClient.updateMic({ restart: true, newDeviceId: selectedOptionMic.id });
    }

    if (savedSpeakerId !== selectedOptionSpk.id) {
      await roomClient.changeAudioOutputDevice(selectedOptionSpk.id);
    }

    if (savedUserName !== userName) {
      roomClient.changeName(roomClient.socket.id, userName);
    }

    if (savedIsCamRotate !== isRotate) {
      dispatch(setIsCamRotate(isRotate));
    }

    if (savedLanguage !== selectedOptionLang.id) {
      dispatch(setLanguage(selectedOptionLang.id as Language));
    }

    handleClose();
  };

  const onSpeakerDeviceId = (id: string) => {
    const value = options_spk.find(opt => opt.id === id);
    if (value) setSelectedOptionSpk(value);
  };

  const onMicDeviceId = (id: string) => {
    const value = options_mic.find(opt => opt.id === id);
    if (value) setSelectedOptionMic(value);
  };

  return (
    <div className="w-full m-2 md:w-auto max-h-full overflow-y-auto z-40 flex flex-col items-center rounded-lg shadow bg-[#333] text-white mb-0 p-6 md:py-8 select-none">
      <div className="flex w-full h-9 justify-start items-center">
        <div className="h-5 text-base font-bold leading-6 text-white">{t("menu.환경설정")}</div>
      </div>
      <div className="flex flex-col justify-around sm:flex-row sm:justify-center mt-[1.188rem] w-full">
        <div className="flex flex-col mr-0 mb-4 w-full items-center">
          <div className="w-[16.75rem] flex justify-center items-center flex-col">
            <div className="w-full h-[10.25rem] border border-[#222] bg-[#555] flex justify-center items-center overflow-hidden">
              <video
                className="bg-black"
                autoPlay
                muted
                ref={videoRef}
                width="100%"
                height="100%"
                style={isRotate ? { transform: "rotateY(180deg)" } : {}}
                playsInline
                disablePictureInPicture
              />
            </div>

            <div className="flex items-center w-full mt-2 cursor-pointer" onClick={() => setIsRotate(!isRotate)}>
              <div>{isRotate ? <IconChecked /> : <IconUnChecked />}</div>
              <div className="ml-2 text-sm select-none" onClick={() => setIsRotate(!isRotate)}>
                {t("setting.좌우반전")}
              </div>
            </div>
          </div>
        </div>

        {!isMaxMd && (
          <div className=" w-full flex flex-col items-end z-10">
            <div className="w-full flex items-center justify-between">
              <div className="flex flex-col whitespace-nowrap sm:ml-6 text-sm">
                <div className="flex items-center h-8">{t("카메라")}</div>
                <div className="flex items-center h-8 mt-2 ">{t("마이크")}</div>
                <div className="flex items-center h-8 mt-2">{t("스피커")}</div>
              </div>

              <div className="ml-[2.625rem] flex flex-col grow w-[5rem] md:w-[16.25rem]">
                <div className="h-8">
                  {options_webcam && (
                    <SelectListbox2 options={options_webcam} value={selectedOptionCam} onChange={v => handleChangeWebcam(v)} optionsHeight={40} />
                  )}
                </div>

                <div className="mt-2 h-8">
                  <SelectListbox2 options={options_mic} value={selectedOptionMic} onChange={v => setSelectedOptionMic(v)} optionsHeight={40} />
                </div>

                <div className="mt-2 h-8">
                  {!isSafari && !isIOS ? (
                    <SelectListbox2 options={options_spk} value={selectedOptionSpk} onChange={v => setSelectedOptionSpk(v)} optionsHeight={40} />
                  ) : (
                    <div className="border border-solid border-[#86878b] bg-[#555] h-8 rounded text-white pl-3 pr-2 text-sm flex flex-row items-center cursor-default">
                      {t("시스템 기본")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {webcams.size > 0 && mics.size > 0 && (
              <div className="flex mt-2 md:mt-2" onClick={() => setIsOpenTestDialog(!isOpenTestDialog)} ref={testBtnRef}>
                <div className="flex items-center h-8 border border-[#86878b] rounded text-white cursor-pointer px-6 text-sm hover:bg-[#555]">
                  {t("setting.스피커/마이크 테스트")}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="w-full relative mt-2 md:mt-6 flex justify-between">
        <div className="flex flex-col text-sm">
          {isMaxMd && (
            <>
              <div className="flex items-center h-8">{t("카메라")}</div>
              <div className="flex items-center h-8 mt-2 ">{t("마이크")}</div>
              <div className="flex items-center h-8 mt-2">{t("스피커")}</div>

              {!isMobile && <div className="h-10" />}
            </>
          )}
          <div className="h-8 flex mt-2 md:mt-0 items-center whitespace-nowrap">{t("setting.내 이름")}</div>
          <div className="h-8 flex mt-2 items-center">
            <div className="whitespace-nowrap">{t("setting.언어")}</div>
            <Tooltip content={t("setting.언어툴팁")} isCenter={false}>
              <IconQuestion className="ml-1 cursor-pointer" />
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-col grow ml-[2.625rem] w-[5rem] md:w-[16.25rem]">
          {isMaxMd && (
            <>
              <div className="h-8">
                {options_webcam && (
                  <SelectListbox2 options={options_webcam} value={selectedOptionCam} onChange={v => handleChangeWebcam(v)} optionsHeight={40} />
                )}
              </div>

              <div className="mt-2 h-8">
                <SelectListbox2 options={options_mic} value={selectedOptionMic} onChange={v => setSelectedOptionMic(v)} optionsHeight={40} />
              </div>

              <div className="my-2 h-8">
                {!isSafari && !isIOS ? (
                  <SelectListbox2 options={options_spk} value={selectedOptionSpk} onChange={v => setSelectedOptionSpk(v)} optionsHeight={40} />
                ) : (
                  <div className="border border-solid border-[#86878b] bg-[#555] h-8 rounded text-white pl-3 pr-2 text-sm flex flex-row items-center cursor-default">
                    {t("시스템 기본")}
                  </div>
                )}
              </div>

              {webcams.size > 0 && mics.size > 0 && (
                <div className="flex mt-0 mb-2 justify-end" onClick={() => setIsOpenTestDialog(!isOpenTestDialog)} ref={testBtnRef}>
                  <div className="flex items-center h-8 border border-[#86878b] rounded text-white cursor-pointer px-6 text-sm hover:bg-[#555]">
                    {t("setting.스피커/마이크 테스트")}
                  </div>
                </div>
              )}
            </>
          )}

          <input
            value={userName}
            onChange={e => setUserName(e.target.value.trimStart())}
            className="grow h-8 border border-[#86878b] rounded px-3 bg-[#555] flex items-center text-sm"
          />

          <div className="grow h-8 mt-2">
            <SelectListbox2 options={options_lang} value={selectedOptionLang} onChange={v => handleChangeLanguage(v)} optionsHeight={40} />
          </div>
        </div>
      </div>

      <div className="flex mt-8 text-sm text-white gap-6 justify-center">
        <Button kind={"cancel-dark"} label={t("취소")} className="inline-block min-w-[8.5rem]" onClick={handleCancel} />
        <Button kind={"primary"} label={t("적용")} className="inline-block min-w-[8.5rem]" onClick={updateAndClose} />
      </div>

      {isOpenTestDialog && (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-black/75 flex justify-center items-center">
          <ClickAwayListener onClickAway={() => setIsOpenTestDialog(false)} buttonRef={testBtnRef}>
            {ref => (
              <div ref={ref} className="z-50">
                <TestRoot
                  onSpeakerDeviceId={onSpeakerDeviceId}
                  onMicDeviceId={onMicDeviceId}
                  setIsOpen={(open: boolean) => setIsOpenTestDialog(open)}
                />
              </div>
            )}
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
};

export default withRoomContext(Setting);
