import { useEffect, useState } from "react";
import DefaultAvatar from "../icons/DefaultAvatar";
import DefaultTeamAvatar from "../icons/DefaultTeamAvatar";
// import { loadImageToBase64 } from "lib/loadImageToBase64";
import { store } from "store/store";

interface Props {
  url: string;
  width?: number;
  presence?: string;
  className?: string;
}

const Avatar = ({ url, width = 44, presence, className, ...props }: Props) => {
  const [imgSrc, setImgSrc] = useState<string>();

  let indicator;

  switch (presence) {
    case "online":
      indicator = "before:rounded-full before:border-2 before:border-white before:bg-C-04de71";
      break;

    case "telecommuting":
      indicator = "before:rounded-full before:border-2 before:border-white before:bg-C-1ac9fe";
      break;

    case "travel":
      indicator = "before:rounded-full before:border-2 before:border-white before:bg-C-522391";
      break;

    case "meal":
      indicator = "before:rounded-full before:border-2 before:border-white before:bg-C-fa164f";
      break;

    case "meeting":
      indicator = "before:rounded-full before:border-2 before:border-white before:bg-C-787afd";
      break;

    case "offline":
      indicator = "before:rounded-full before:border-2 before:border-white before:bg-C-c8cace";
      break;

    case "away":
      indicator = "before:bg-status-away";
      break;

    case "busy":
      indicator = "before:bg-status-busy";
      break;

    case "calling":
      indicator = "before:bg-status-calling";
      break;

    case "outwork":
      indicator = "before:bg-status-outwork";
      break;

    default:
      break;
  }

  useEffect(() => {
    async function load() {
      if (!url) return;
      if (url.startsWith("http://")) {
        // const result = await loadImageToBase64(url);
        // setImgSrc(result);

        const proxyUrl = `${store.getState().env.value.SERVER_URL}/imageProxy?url=${url}`;
        setImgSrc(proxyUrl);
      } else {
        setImgSrc(url);
      }
    }
    load();
  }, [url]);

  return (
    <div className={`relative ${className ?? ""}`} style={{ width: `${width / 16}rem`, height: `${width / 16}rem` }} {...props}>
      <div
        className={`rounded-full overflow-hidden border border-solid border-white ${
          presence && indicator ? "before:block before:absolute before:bottom-0 before:right-0 before:z-10 before:w-3.5 before:h-3.5" : ""
        } ${presence && indicator ? indicator : ""}`}
        style={{ width: `${width / 16}rem`, height: `${width / 16}rem` }}
      >
        {url === "team" ? (
          <DefaultTeamAvatar width={width} height={width} />
        ) : url.startsWith("http://") || url.startsWith("https://") ? (
          <img src={imgSrc} width={width} height={width} className="flex justify-center items-center w-full h-full object-cover" alt="" />
        ) : (
          <DefaultAvatar width={width} height={width} />
        )}
      </div>
    </div>
  );
};

export default Avatar;
