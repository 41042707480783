import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { useResizeDetector } from "react-resize-detector";

interface IProps {
  divClassName: string;
  aspectRatio: number;
  children: ReactNode;
}
const FixedAspectRatioDiv = ({ divClassName, children, aspectRatio }: IProps) => {
  const [calcHeight, setCalcHeight] = useState(0);
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    if (!width) return;
    setCalcHeight(Math.trunc(width / aspectRatio));
  }, [width, aspectRatio]);

  return (
    <StyledDiv ref={ref} className={divClassName} height={calcHeight}>
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{ height: number }>`
  display: flex;
  height: ${props => `${props.height}px`};
  max-height: ${props => `${props.height}px`};
`;

export default FixedAspectRatioDiv;
