import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { Attachment } from "components/docSharing/filelist/AttachmentTypes";
import { FileStatus } from "components/docSharing/filelist/AttachmentTypes";

interface IFileStatus {
  fileid: string;
  percent: number;
  status: FileStatus;
}

type AttachmentState = {
  attachments: Attachment[];
};

const initialState: AttachmentState = {
  attachments: [],
};

export const attachmentSlice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    setAttachments: (state: AttachmentState, action: PayloadAction<Attachment[]>) => {
      // console.log("store setAttachments", [...action.payload]);
      state.attachments = [...action.payload];
    },
    pushAttachments: (state: AttachmentState, action: PayloadAction<Attachment[]>) => {
      // console.log("store pushAttachments before", [...state.attachments]);
      state.attachments.push(...action.payload);
      // console.log("store pushAttachments after", [...state.attachments]);
    },
    setAttachment: (state: AttachmentState, action: PayloadAction<Attachment>) => {
      // console.log("store setAttachment", action.payload.filename, action.payload.status);

      const findIndex = state.attachments.findIndex(att => att.fileid === action.payload.fileid);
      state.attachments[findIndex] = { ...action.payload };
    },
    setFileStatus: (state: AttachmentState, action: PayloadAction<IFileStatus>) => {
      // console.log("store setFileStatus", action.payload.fileid, action.payload.status);
      const find = state.attachments.find(file => file.fileid === action.payload.fileid);
      if (find) {
        find.progress = action.payload.percent;
        find.status = action.payload.status;
      }
    },
    removeAttachment: (state: AttachmentState, action: PayloadAction<string>) => {
      state.attachments = state.attachments.filter(att => att.fileid !== action.payload);
    },
  },
});

export const { setAttachments, pushAttachments, setAttachment, setFileStatus, removeAttachment } = attachmentSlice.actions;
export const selectAttachments = (state: RootState) => state.attachment.attachments;

export default attachmentSlice.reducer;
