import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RoomClient from "RoomClient";
import BigViewRoot from "components/videoView/BigViewRoot";
import SmallOverlayVideoView from "components/videoView/SmallOverlayVideoView";
import RecordingControl from "components/recording/RecordingControl";
import HostNotConnected from "components/home/HostNotConnected";
import ClientVideoView from "components/videoView/ClientVideoView";
import PeerVideoList from "components/home/PeerVideoList";
import { selectIsLocalHost, selectUserInfo } from "store/roomInfoSlice";
import {
  selectIsShowRecordingControl,
  selectIsShowDocSharingHost,
  selectIsShowDocSharingClient,
  selectIsFullScreen,
  selectIsShowBottomVideoList,
  setIsShowMenuBar,
  setIsShowBottomVideoList,
} from "store/windowControlSlice";
import { selectPeers } from "store/peerSlice";
import { useSelector, useDispatch } from "react-redux";
import { withRoomContext } from "RoomContext";
import { useHostPeer } from "hooks/useHostPeer";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { AppData } from "types/commonTypes";
import { useDocSharingHostPeer } from "hooks/useDocSharingHostPeer";

interface HomeProps {
  roomClient: RoomClient;
  localVideoStream: MediaStream;
  screenShareStream: MediaStream | undefined;
  remoteHostVideoStream: MediaStream | undefined;
  remoteHostAppData: AppData;
  localAppData: AppData;
}

const HomeHighlite: React.FC<HomeProps> = props => {
  const { roomClient, localVideoStream, screenShareStream, remoteHostVideoStream, remoteHostAppData, localAppData } = props;
  const [bottomVideoListStyle, setBottomVideoListStyle] = useState("");
  const [includeHost, setIncludeHost] = useState(false);
  const [includeDocHost, setIncludeDocHost] = useState(false);
  const isShowDocSharingHost = useSelector(selectIsShowDocSharingHost);
  const isShowDocSharingClient = useSelector(selectIsShowDocSharingClient);
  const isShowBottomVideoList = useSelector(selectIsShowBottomVideoList);
  const isShowRecordingControl = useSelector(selectIsShowRecordingControl);
  const isFullScreen = useSelector(selectIsFullScreen);
  const isLocalHost = useSelector(selectIsLocalHost);
  const localUserInfo = useSelector(selectUserInfo);
  const peers = useSelector(selectPeers);
  const docHostPeer = useDocSharingHostPeer();
  const hostPeer = useHostPeer();
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const isLandscape = useMediaQuery({ orientation: "landscape" });
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("hostPeer || isShowDocSharingHost || isShowDocSharingClient", hostPeer, isShowDocSharingHost, isShowDocSharingClient);
  }, [hostPeer, isShowDocSharingClient, isShowDocSharingHost]);

  useEffect(() => {
    const styleBase = "transition-all p-0 gap-0 w-full shrink-0 bg-[#222] overflow-y-hidden overflow-x-auto ";
    const style1 = isMobile && isPortrait ? "grid grid-cols-2" : "flex h-[27.2vh] md:justify-start md:items-start ";
    const style2 = isMobile && isLandscape ? "h-[18.16vh] " : " ";
    const style = styleBase + style1 + style2;

    if (isShowBottomVideoList) {
      setBottomVideoListStyle(style);
    } else {
      setBottomVideoListStyle(style + `${isMobile && isLandscape ? "-mb-[18.16vh]" : "-mb-[27.2vh]"}`);
    }
  }, [isShowBottomVideoList, isPortrait, isLandscape]);

  useEffect(() => {
    if (!remoteHostVideoStream) {
      dispatch(setIsShowBottomVideoList(true));
    }
  }, [remoteHostVideoStream, dispatch]);

  useEffect(() => {
    if (isMobile && isPortrait && !isShowBottomVideoList) {
      dispatch(setIsShowBottomVideoList(true));
    }
  }, [dispatch, isPortrait, isShowBottomVideoList]);

  useEffect(() => {
    console.log("isShowDocSharingHost", isShowDocSharingHost);
    console.log("isShowDocSharingClient", isShowDocSharingClient);

    if (
      (!isShowDocSharingHost && !isShowDocSharingClient) ||
      (!(isMobile && isPortrait) && isShowDocSharingClient && hostPeer?.socketId === docHostPeer?.socketId)
    ) {
      console.log("사회자 숨김!!!!");
      setIncludeHost(false);
    } else {
      console.log("사회자 표시!!!!");
      setIncludeHost(true);
    }
  }, [docHostPeer?.socketId, hostPeer?.socketId, isPortrait, isShowDocSharingClient, isShowDocSharingHost]);

  useEffect(() => {
    setIncludeDocHost(isMobile && isPortrait && isShowDocSharingClient);
  }, [docHostPeer?.socketId, hostPeer?.socketId, isLandscape, isLocalHost, isPortrait, isShowDocSharingClient, localAppData?.socketId]);

  const handleScreenShareVideoClose = () => {
    roomClient.closeScreenShare();
  };

  return (
    // 강조모드
    <StyledHightlightRootDiv>
      {hostPeer || isShowDocSharingHost || isShowDocSharingClient ? (
        <div className={`relative ${isMobile && isPortrait ? "" : "grow overflow-y-hidden h-full"}`}>
          <BigViewRoot
            id="hostVideo"
            peer={hostPeer}
            stream={isLocalHost ? localVideoStream : screenShareStream ?? remoteHostVideoStream}
            hostName={isLocalHost ? localUserInfo.username : hostPeer?.userInfo.username}
            hostPosition={isLocalHost ? localUserInfo.position : hostPeer?.userInfo.position}
            isRemote={!isLocalHost}
            isScreen={screenShareStream !== undefined}
            screenShareStream={screenShareStream}
          />

          {screenShareStream && !isLocalHost && hostPeer && (!isMobile || isLandscape) && (
            <SmallOverlayVideoView
              stream={remoteHostVideoStream}
              onClose={handleScreenShareVideoClose}
              picurl={hostPeer?.userInfo.picurl}
              socketId={remoteHostAppData?.socketId ? remoteHostAppData?.socketId : ""}
              peer={hostPeer}
            />
          )}

          {isShowRecordingControl && <RecordingControl />}
        </div>
      ) : (
        <div className="relative flex w-full h-full">
          <HostNotConnected />
          {isShowRecordingControl && <RecordingControl />}
        </div>
      )}

      <div className={bottomVideoListStyle}>
        {/* 본인영상을 하단에 표시 - 로컬 사회자가 아니거나 문서공유 상태일때 */}
        {(!isLocalHost || isShowDocSharingHost || isShowDocSharingClient) && (
          <ClientVideoView
            isRemote={false}
            isHost={isLocalHost}
            producerId="localVideo"
            stream={localVideoStream}
            socketId={localAppData?.socketId}
            kind="video"
            userInfo={peers.find(p => p.socketId === localAppData?.socketId)?.userInfo}
          />
        )}

        <PeerVideoList localAppData={localAppData} includeHost={includeHost} includeDocHost={includeDocHost} />

        {isFullScreen && !isMobile && (
          <div className="absolute bottom-0 flex w-[calc(100%-10px)] h-[4.75rem]" onMouseOver={() => dispatch(setIsShowMenuBar(true))} />
        )}
      </div>
    </StyledHightlightRootDiv>
  );
};

const StyledHightlightRootDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  color: white;
  overflow-y: hidden;
  /* background-color: #ff00ff;
  border: 1px solid #ff00ff; */

  @media screen and (max-width: 768px) and (orientation: portrait) {
    overflow-y: auto;
    /* background-color: #ff0;
    border: 1px solid #ff0; */
  }

  @media screen and (max-width: 768px) and (orientation: landscape) {
    /* background-color: #ff0000;
    border: 1px solid #ff0000; */
  }
`;

export default withRoomContext(HomeHighlite);
