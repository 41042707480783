import React from "react";
interface IProps {
  children: React.ReactNode;
}

const DimDiv = ({ children }: IProps) => {
  return <div className="bg-black/75 h-full w-full absolute inset-0 z-50 flex md:justify-center items-center">{children}</div>;
};

export default DimDiv;
