import { useState, useEffect } from "react";
import { getRoomDetail, IRoomDetailRes } from "api/manager/room";
import { IInnerAttendee, IOuterAttendee } from "api/manager/invite";
import AuthApi from "api/common/API";

interface IAttendees {
  inner: IInnerAttendee[];
  outer: IOuterAttendee[];
}

export const useGetRoomAttendees = (roomId: number) => {
  const [attendees, setAttendees] = useState<IAttendees>();

  useEffect(() => {
    async function getInfo() {
      const { result, data } = (await getRoomDetail(roomId)) as IRoomDetailRes;

      if (result) {
        const bids: string[] = [];
        data.info.innerAttendees.forEach(u => bids.push(u.bid));

        if (bids.length === 0) return;

        const res = await AuthApi.getUsersInfo(bids);
        if (res.result) {
          setAttendees({ inner: res.data, outer: data.info.outerAttendees });
        }
      }
    }

    getInfo();
  }, [roomId]);

  return attendees;
};
