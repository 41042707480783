import { Line } from "./docSharingType";

export function checkLinesIntersect(line1: Line, line2: Line): boolean {
  const x1 = line1.p1.x;
  const y1 = line1.p1.y;
  const x2 = line1.p2.x;
  const y2 = line1.p2.y;
  const x3 = line2.p1.x;
  const y3 = line2.p1.y;
  const x4 = line2.p2.x;
  const y4 = line2.p2.y;

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));

  return ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1;
}
