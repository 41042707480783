import Axios, { AxiosResponse } from "axios";
import * as jose from "jose";
import { store } from "store/store";

export const authenticate = async (JWT: string) => {
  try {
    const certUrl = `${store.getState().env.value.AUTH_SERVER}/api/v1/crs-id-auth/certs`;
    console.log("authenticate url:", certUrl);
    const axiosInstance = Axios.create({ timeout: 10000 });
    const { alg } = jose.decodeProtectedHeader(JWT);

    if (alg !== "RS256") {
      throw new Error("JWT 알고리듬이 RS256 이 아닙니다.");
    }

    const { kid, aud, iss } = jose.decodeJwt(JWT);
    const { data }: AxiosResponse<jose.JWK> = await axiosInstance.post(certUrl, { kid });
    const publicKey = await jose.importJWK(data, alg);
    const { payload } = await jose.jwtVerify(JWT, publicKey, {
      issuer: iss,
      audience: aud,
    });
    const sid = payload.aud as string;

    if (!sid) throw new Error("JWT payload 에 aud 가 존재하지 않습니다.");

    const sidArr = sid.split(".");
    const bid = [sidArr[0], sidArr[1]].join(".");
    const userid = sidArr[1];

    return await Promise.resolve({ bid, userid, sid });
  } catch (error) {
    throw error;
  }
};
