import React from "react";
import ProgressCircle from "components/docSharing/filelist/ProgressCircle";
import LoadingSimple from "components/LoadingSimple";
import DeleteButton from "components/common/buttons/DeleteButton";
import { ReactComponent as AttachFileIcon } from "assets/ico-attach-file.svg";
import { ReactComponent as IconError } from "assets/icon-upload-error.svg";
import { getFileSize } from "lib/utils";
import { FileStatus } from "./AttachmentTypes";
import { useTranslation } from "react-i18next";

interface IProps {
  fileid: string;
  name: string;
  size: number;
  progress?: number;
  status: FileStatus;
  onRemoveFile: (fileid: string, filename: string) => void;
  onClick: () => void;
}

const AttachmentItem = (props: IProps) => {
  const { fileid, name, size, progress, status, onRemoveFile, onClick } = props;
  const { t } = useTranslation();

  const handleClick = () => {
    onClick();
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    onRemoveFile(fileid, name);
  };

  const StatusIcon = () => {
    if (status === "UPLOAD_ERROR" || status === "CONVERT_ERROR") {
      return <IconError />;
    } else if (status === "ADDED") {
      return <></>;
    } else if (status === "DELETING" || status === "CONVERTING" || status === "CHECKING") {
      return <LoadingSimple size={24} />;
    } else {
      return <AttachFileIcon width={16} height={16} className="h-4 w-4 flex-shrink-0" />;
    }
  };

  const StatusLabel = () => {
    if (!status) return <></>;
    let s = "";
    let textColor = "text-[#9d9ea2]";
    switch (status) {
      case "UPLOADING":
        s = t("doc.업로드중");
        break;
      case "UPLOADED":
        s = "";
        break;
      case "UPLOAD_ERROR":
        s = t("doc.업로드 실패");
        break;
      case "CHECKING":
        s = t("doc.확인중");
        break;
      case "CONVERTING":
        s = t("doc.변환중");
        break;
      case "CONVERTED":
        s = "";
        break;
      case "CONVERT_ERROR":
        s = t("doc.변환 실패");
        break;
      case "DELETING":
        s = t("doc.삭제 중");
        break;
      default:
        s = status;
        textColor = "text-white";
        break;
    }
    if (!s) return null;
    return <span className={`ml-2 text-xs rounded p-1 ${textColor}`}>{s}</span>;
  };

  return (
    <li className="flex items-center h-10 bg-[#444] rounded pl-2 cursor-pointer hover:bg-[#555]" onClick={handleClick}>
      <span className="flex items-center flex-1">
        <span className="w-4 h-4 flex items-center justify-center">
          {status === "UPLOADING" ? <ProgressCircle value={progress ?? 0} /> : <StatusIcon />}
        </span>

        <div className="flex flex-1">
          <div className="flex flex-1 w-0 truncate">
            <span
              className={`ml-2 flex-1 w-0 truncate ${
                status === "UPLOADING" || status === "UPLOADED" || status === "CONVERTING" || status === "ADDED" || status === "CONVERT_ERROR"
                  ? "text-[#9d9ea2]"
                  : "text-white"
              }`}
            >
              {name}
            </span>

            <span className="">
              <span className="text-[#9d9ea2] shrink-0 ml-1">{getFileSize(size)}</span>
              <StatusLabel />
            </span>
          </div>
        </div>
      </span>
      <span className="flex items-center justify-center shrink-0" onClick={handleDeleteClick}>
        <DeleteButton className="h-10 w-10 cursor-pointer z-10" />
      </span>
    </li>
  );
};

export default AttachmentItem;
