import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { useResizeDetector } from "react-resize-detector";
import { isMobile } from "react-device-detect";

interface IProps {
  divClassName: string;
  aspectRatio: number;
  children: ReactNode;
}
const FixedAspectRatioDivByHeight = ({ divClassName, children, aspectRatio }: IProps) => {
  const [calcWidth, setCalcWidth] = useState(0);
  const { height, ref } = useResizeDetector();

  useEffect(() => {
    if (!height) return;
    setCalcWidth(Math.trunc(height * aspectRatio));
  }, [height, aspectRatio]);

  return (
    <>
      {isMobile ? (
        <div className={divClassName}>{children}</div>
      ) : (
        <StyledDiv ref={ref} className={divClassName} width={calcWidth}>
          {children}
        </StyledDiv>
      )}
    </>
  );
};

const StyledDiv = styled.div<{ width: number }>`
  display: flex;
  width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};
  justify-content: center;
`;

export default FixedAspectRatioDivByHeight;
