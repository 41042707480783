import React from "react";
import { Switch } from "@headlessui/react";

type SwitchProps = {
  enabled: boolean;
  onChange(checked: boolean): void;
  readonly?: boolean;
};

const SwitchComp = (props: SwitchProps) => {
  const { enabled, onChange, readonly } = props;

  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      className={`${enabled ? "bg-[#00bf60]" : "bg-[#d5d7dc]"} relative inline-flex h-6 w-11 items-center rounded-full ${
        readonly ? "pointer-events-none" : "pointer-events-auto"
      }`}
    >
      <span className={`${enabled ? "translate-x-5" : "translate-x-1"} inline-block h-5 w-5 transform rounded-full bg-white`} />
    </Switch>
  );
};

export default SwitchComp;
