import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSelectedSpeaker } from "../../store/settingSlice1";

interface Props {
  id: string;
  stream: MediaStream | undefined;
  label?: string;
}

const PeerAudio = (props: Props) => {
  const { id, stream, label } = props;
  const audioRef = useRef<HTMLAudioElement>(null);
  const selectedSpeaker = useSelector(getSelectedSpeaker);

  useEffect(() => {
    if (!audioRef.current) return;
    if (stream !== undefined) audioRef.current.srcObject = stream;
  }, [stream]);

  useEffect(() => {
    const audio: any = audioRef.current;
    if (selectedSpeaker && typeof audio.setSinkId === "function") {
      audio.setSinkId(selectedSpeaker);
    }
  }, [selectedSpeaker]);

  return (
    <div hidden>
      <audio id={id} autoPlay ref={audioRef} />
      <div>PeerAudio {label}</div>
    </div>
  );
};

export default PeerAudio;
