import React, { useEffect, useState } from "react";
import ToolButton from "./ToolButton";
import Tooltip from "components/common/Tooltip";
import ZoomSelector from "./ZoomSelector";
import RoomClient from "RoomClient";
import { withRoomContext } from "RoomContext";
import { useTranslation } from "react-i18next";
import { setIsShowDocSharingHost, setIsShowBottomVideoList, selectIsShowDocSharingClient } from "store/windowControlSlice";
import { selectIsLocalHost, selectRoomSetting } from "store/roomInfoSlice";
import {
  setEditMode,
  setCurrentIndex,
  setRefreshTime,
  setPenColor,
  setIsShowColorPicker,
  setIsShowToolbar,
  selectEditMode,
  selectIsShowColorPicker,
  selectPenColor,
  selectCurrentIndex,
  selectConvertedFileList,
  selectHostPageInfo,
  selectIsShowToolbar,
  DocSharingEditMode,
} from "store/docSharingSlice";
import { useSelector, useDispatch } from "react-redux";
import { ColorResult, SketchPicker } from "react-color";
import { isMobile } from "react-device-detect";
import { ReactComponent as SvgCursor } from "assets/docsharing/btn-cursor.svg";
import { ReactComponent as SvgPointer } from "assets/docsharing/btn-pointer.svg";
import { ReactComponent as SvgPen } from "assets/docsharing/btn-pen.svg";
import { ReactComponent as SvgPenColor } from "assets/docsharing/btn-pen-color.svg";
import { ReactComponent as SvgEraser } from "assets/docsharing/btn-eraser.svg";
import { ReactComponent as SvgEraseAll } from "assets/docsharing/btn-erase-all.svg";
import { ReactComponent as SvgBackPage } from "assets/docsharing/btn-back-page.svg";
import { ReactComponent as SvgNextPage } from "assets/docsharing/btn-next-page.svg";
import { ReactComponent as SvgOpenToolbar } from "assets/docsharing/btn-open-toolbar.svg";
import { ReactComponent as SvgIconQuit } from "assets/btn-quit.svg";
import { ReactComponent as SvgIconQuitHover } from "assets/btn-quit-hover.svg";
import IconButton from "components/common/buttons/IconButton";

interface IProps {
  isHost: boolean;
  roomClient: RoomClient;
}

const DocSharingTool = ({ isHost, roomClient }: IProps) => {
  const [color, setColor] = useState<string>();
  const [isShowPageSelector, setIsShowPageSelector] = useState(false);
  const [isShowWhiteboardTool, setIsShowWhiteboardTool] = useState(false);
  const editMode = useSelector(selectEditMode);
  const isShowColorPicker = useSelector(selectIsShowColorPicker);
  const isShowToolbar = useSelector(selectIsShowToolbar);
  const penColor = useSelector(selectPenColor);
  const currentIdx = useSelector(selectCurrentIndex);
  const convertedFileList = useSelector(selectConvertedFileList);
  const isClient = useSelector(selectIsShowDocSharingClient);
  const hostPageInfo = useSelector(selectHostPageInfo);
  const roomSetting = useSelector(selectRoomSetting);
  const isLocalHost = useSelector(selectIsLocalHost);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("isWhiteBoardOnlyHost", roomInfo.setting.isWhiteBoardOnlyHost, isLocalHost);

    if (roomSetting.isWhiteBoardOnlyHost && !isLocalHost && isClient) {
      setIsShowWhiteboardTool(false);
    } else {
      setIsShowWhiteboardTool(true);
    }
  }, [roomSetting.isWhiteBoardOnlyHost, isLocalHost, isClient]);

  useEffect(() => {
    if (!isClient) {
      setIsShowPageSelector(convertedFileList.length > 1);
    } else {
      const total = hostPageInfo?.total || 1;
      setIsShowPageSelector(total > 1);
    }

    console.log("convertedFileList.length", convertedFileList.length);
  }, [convertedFileList.length, hostPageInfo?.total, isClient]);

  useEffect(() => {
    console.log("editMode", editMode, penColor);
    setColor(penColor);
  }, [editMode, penColor]);

  const handleColorChange = (newColor: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(newColor.hex);
  };

  const handleColorChangeComplete = (newColor: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPenColor(newColor.hex));
  };

  const handleSelectMenu = (mode: DocSharingEditMode, isManual: boolean = false) => {
    if (!isManual) dispatch(setIsShowColorPicker(false));
    dispatch(setEditMode(mode));
  };

  const handleRefresh = () => {
    dispatch(setRefreshTime(Date.now()));
    dispatch(setIsShowColorPicker(false));
  };

  const handleMoveToBackPage = () => {
    if (currentIdx > 0) dispatch(setCurrentIndex(currentIdx - 1));
  };

  const handleMoveToNextPage = () => {
    if (currentIdx < convertedFileList.length - 1) dispatch(setCurrentIndex(currentIdx + 1));
  };

  const handleColorPicker = () => {
    dispatch(setIsShowColorPicker(!isShowColorPicker));
    handleSelectMenu("PEN", true);
  };

  const handleClose = async () => {
    dispatch(setIsShowBottomVideoList(true));
    dispatch(setIsShowColorPicker(false));
    dispatch(setIsShowDocSharingHost(false));
    dispatch(setIsShowToolbar(false));
    await roomClient.sendDocSharingHostRemove();
    roomClient.sendGetDocSharingHosts();
  };

  return (
    <div className={`relative h-fit w-fit bg-black/75 rounded select-none mx-auto shadow-md ${!isMobile && "border border-[#555]"}`}>
      <div className="flex justify-between items-center w-full px-4">
        <ul className="flex justify-start flex-1 items-center py-2 flex-wrap">
          <li className="flex items-center">
            <ToolButton
              SvgIcon={SvgCursor}
              onClick={() => handleSelectMenu("CURSOR")}
              tooltipContent={t("whiteboard.커서")}
              isCheck={editMode === "CURSOR"}
            />
          </li>

          {isShowWhiteboardTool && (
            <>
              {!isClient && (
                <li className="ml-2 flex items-center">
                  <ToolButton
                    SvgIcon={SvgPointer}
                    onClick={() => handleSelectMenu("POINTER")}
                    tooltipContent={t("whiteboard.포인터")}
                    isCheck={editMode === "POINTER"}
                  />
                </li>
              )}

              <li className="ml-2 flex items-center">
                <ToolButton
                  SvgIcon={SvgPen}
                  onClick={() => handleSelectMenu("PEN")}
                  tooltipContent={t("whiteboard.펜")}
                  isCheck={editMode === "PEN"}
                />
              </li>

              <li>
                <Tooltip content={t("whiteboard.펜 컬러")}>
                  <button className="ml-2 flex items-center" onClick={handleColorPicker}>
                    <SvgPenColor style={{ color: penColor }} />
                  </button>
                </Tooltip>
              </li>

              <li className="ml-2 flex items-center">
                <ToolButton
                  SvgIcon={SvgEraser}
                  onClick={() => handleSelectMenu("ERASER")}
                  tooltipContent={t("whiteboard.지우개")}
                  isCheck={editMode === "ERASER"}
                />
              </li>

              <li className="ml-2 flex items-center justify-center">
                <ToolButton SvgIcon={SvgEraseAll} onClick={handleRefresh} tooltipContent={t("whiteboard.전체 지우기")} />
              </li>
            </>
          )}

          <li className="ml-2 w-px h-[1.5rem] rounded bg-white"></li>

          {isShowPageSelector && (
            <>
              <li className="ml-2 flex items-center justify-center text-white">
                {!isClient && <ToolButton SvgIcon={SvgBackPage} onClick={handleMoveToBackPage} tooltipContent={t("whiteboard.이전 페이지")} />}
                <span className="w-8 h-8 bg-[#555] border border-[#86878b] flex justify-center items-center rounded text-sm">
                  {!isClient ? currentIdx + 1 : hostPageInfo?.current}
                </span>
                <span className="mx-2 text-sm">/</span>
                <span className="w-8 h-8 bg-[#555] border border-[#86878b] flex justify-center items-center rounded text-sm">
                  {!isClient ? convertedFileList.length : hostPageInfo?.total}
                </span>
                {!isClient && <ToolButton SvgIcon={SvgNextPage} onClick={handleMoveToNextPage} tooltipContent={t("whiteboard.다음 페이지")} />}
              </li>
            </>
          )}

          <li className="mx-2 min-w-[7.938rem] h-9 mt-1">
            <ZoomSelector />
          </li>

          {isHost && <li className="mx-2 w-px h-[1.5rem] rounded bg-white"></li>}

          {isHost && (
            <>
              <li className="flex items-center">
                <IconButton onClick={handleClose} SvgIconNormal={SvgIconQuit} SvgIconHover={SvgIconQuitHover} tooltipContent={t("닫기")} />
              </li>
            </>
          )}
        </ul>
      </div>

      {isMobile && (
        <div
          className="absolute top-[1px] left-0 w-10 h-10 z-40 flex justify-center items-center bg-black/75 rounded-t-full -translate-y-full border-t-[#555] border-l-[#555] border-r-[#555]"
          onClick={() => dispatch(setIsShowToolbar(!isShowToolbar))}
        >
          <SvgOpenToolbar className={`transition-all ${isShowToolbar ? "rotate-0" : "rotate-180"}`} />
        </div>
      )}

      {isShowColorPicker && (
        <div className={`absolute left-1/2 transform -translate-x-1/2 overflow-visible z-50 ${!isMobile ? "top-16" : "top-0 -translate-y-full"}`}>
          <SketchPicker color={color} onChange={handleColorChange} onChangeComplete={handleColorChangeComplete} />
        </div>
      )}
    </div>
  );
};

export default withRoomContext(DocSharingTool);
