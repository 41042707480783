import { FileStatus } from "components/docSharing/filelist/AttachmentTypes";
import { FinishedTask, ConvertedFile } from "api/cloudconvert/types";
import { Socket, io } from "socket.io-client";
import { setFinishedTask } from "store/docSharingSlice";
import { setFileStatus } from "store/attachmentSlice";
import { store } from "store/store";

export class SocketIoConvertChecker {
  private static instance: SocketIoConvertChecker;
  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private socket: Socket | null = null;

  constructor() {
    console.log("##### FileConverter 생성 #####");
  }

  disconnectSocket = () => {
    if (this.socket) this.socket.disconnect();
  };

  async checkForCompletion(jobid: string, callback: (files: ConvertedFile[]) => void) {
    const socket = io("https://socketio.cloudconvert.com", { transports: ["websocket"] });
    this.socket = socket;

    socket.on("connect", async () => {
      console.log("FileConverter socket.io connect 성공!", socket?.id);

      socket.emit("subscribe", {
        channel: `private-job.${jobid}.tasks`,
        auth: {
          headers: {
            Authorization: `Bearer ${store.getState().env.value.CLOUD_CONVERT_APIKEY}`,
          },
        },
      });

      socket.on("task.created", (channel, data) => {
        console.log("task.created", data.task);
      });

      socket.on("task.updated", (channel, data) => {
        // console.log("task.updated", data.task);
        // if (data.task.operation === "convert" && data.task.status === "processing") {
        //   console.log("### 변환 시작");
        // }
      });

      socket.on("task.failed", (channel, data) => {
        console.log("task.failed", data.task);
        this.updateAttachmentStatus(data.task, "CONVERT_ERROR");
        socket.disconnect();
      });

      socket.on("task.finished", (channel, data) => {
        console.log("task.finished", data.task);

        const task = data.task as FinishedTask;
        if (task.name === "import-my-file") {
          console.log("FileConverter import 완료", task.operation);
        } else if (task.name === "convert-my-file") {
          console.log("FileConverter convert 완료", task.operation);
        } else if (task.name === "export-my-file") {
          console.log("FileConverter export 완료", task.operation);
          store.dispatch(setFinishedTask(task));
          callback(task.result.files);
          socket.disconnect();
        }
      });
    });

    socket.on("disconnect", () => {
      console.log("FileConverter disconnect!!!!!!!!!!!!");
    });
  }

  updateAttachmentStatus(task: FinishedTask, status: FileStatus) {
    const attachments = store.getState().attachment.attachments;
    const findAtt = attachments.find(att => att.convertJobId === task.job_id);

    if (findAtt && findAtt.convertJobId) {
      store.dispatch(setFileStatus({ fileid: findAtt?.fileid, percent: 0, status }));
    }
  }
}
