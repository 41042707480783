import { ReactNode } from "react";
import EllipsisDiv from "./EllipsisDiv";

interface IProps {
  children: ReactNode;
  onClick?: () => void;
}

const ReadOnlyBox = ({ children, onClick }: IProps) => {
  return (
    <div
      onClick={onClick}
      className="hover:bg-[#777] h-9 leading-9 rounded text-white bg-C-555555 px-3 text-sm border border-solid border-[#86878b] cursor-pointer select-none"
    >
      <EllipsisDiv>{children}</EllipsisDiv>
    </div>
  );
};

export default ReadOnlyBox;
