import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MyConsumer } from "types/clientTypes";
import { AppData } from "types/commonTypes";
import { selectConsumers } from "../store/consumerSlice";
import { useHostPeer } from "./useHostPeer";

export interface IHostInfo {
  isRemoteHost: boolean;
  hostAppData: AppData | undefined;
  hostVideoStream: MediaStream | undefined;
  hostAudioStream: MediaStream | undefined;
  screenShareStream: MediaStream | undefined;
}

export default function useHostInfoFromConsumers(isLocalHost: boolean) {
  const [hostInfo, setHostInfo] = useState<IHostInfo>();
  const consumers = useSelector(selectConsumers);
  const hostPeer = useHostPeer();

  useEffect(() => {
    let existRemoteHost = false;
    let screenShareStream;
    let hostVideoStream;
    let hostAudioStream;
    let hostAppData = undefined;
    let isRemoteHost = false;

    const screenConsumer = consumers.filter((item: MyConsumer) => item.consumer.appData.isScreen);
    if (!isLocalHost && screenConsumer.length === 0) {
      screenShareStream = undefined;
    }

    if (isLocalHost) {
      setHostInfo(undefined);
    } else {
      consumers.forEach((item: MyConsumer) => {
        if (item.consumer.appData.socketId === hostPeer?.socketId) {
          hostAppData = item.consumer.appData;
          isRemoteHost = true;
          existRemoteHost = true;

          if (item.consumer.kind === "video") {
            if (!item.consumer.appData.isScreen) {
              hostVideoStream = item.stream;
              item.consumer.resume();
            } else {
              screenShareStream = item.stream;
            }
          } else if (item.consumer.kind === "audio" && item.consumer.appData.source === "mic") {
            hostAudioStream = item.stream;
          }
        }
      });

      if (!existRemoteHost) {
        setHostInfo(undefined);
      } else {
        const newHostInfo = { isRemoteHost, hostAppData, hostVideoStream, hostAudioStream, screenShareStream };
        // console.log("useHostInfo.. newHostInfo", newHostInfo);
        setHostInfo(newHostInfo);
      }
    }
  }, [consumers, isLocalHost, hostPeer]);

  return hostInfo;
}
