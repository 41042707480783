import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { format } from "date-fns";

// 캡처 및 저장 함수
export const captureScreen = async (targetElement: React.RefObject<HTMLDivElement>) => {
  if (!targetElement.current) return;

  const options = {
    allowTaint: true,
    foreignObjectRendering: false,
    useCORS: true,
  };

  html2canvas(targetElement.current, options).then((canvas: HTMLCanvasElement) => {
    canvas.toBlob(blob => {
      if (blob !== null) {
        const curDT = format(new Date(), "yyyyMMdd_HHmmss");
        const filename = `screen-shot_${curDT}.png`;
        saveAs(blob, filename);
      }
    });
  });
};
