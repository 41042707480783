import { FC, useEffect, useRef } from "react";

interface IProps {
  onClickAway: () => void;
  children: (ref: React.RefObject<HTMLDivElement>) => any;
  buttonRef?: React.RefObject<HTMLDivElement>;
  isOpenSubWindow?: boolean;
}

const ClickAwayListener: FC<IProps> = ({ children, onClickAway, buttonRef, isOpenSubWindow }) => {
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: Event) => {
      if (buttonRef) {
        if (buttonRef.current?.contains(e.target as Node)) {
          // console.log("ClickAwayListener 지정버튼 클릭이므로 무시", buttonRef.current);
          return;
        }
      }

      if (childrenRef.current && !childrenRef.current.contains(e.target as Node) && !isOpenSubWindow) {
        // console.log("ClickAwayListener: onClickAway", buttonRef?.current);
        onClickAway();
      }
    };

    window.addEventListener("mouseup", handler);

    return () => {
      window.removeEventListener("mouseup", handler);
    };
  }, [buttonRef, isOpenSubWindow, onClickAway]);

  return <>{children(childrenRef)}</>;
};

export default ClickAwayListener;
