import React, { useEffect, useRef } from "react";
import LoadingSimple from "components/LoadingSimple";
import { useOnScreen } from "hooks/useOnScreen";
// import { loadImageToBase64 } from "lib/loadImageToBase64";
// import { store } from "store/store";

interface IProps {
  url: string;
  isSelected: boolean;
  isAlreadyScrolled: boolean;
}

const PageThumbnail = (props: IProps) => {
  const { url, isSelected, isAlreadyScrolled } = props;
  // const [base64, setBase64] = React.useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    // loadImageToBase64(url).then(base64 => {
    //   setBase64(base64);
    // });
    // const proxyUrl = `${store.getState().env.value.SERVER_URL}/imageProxy?url=${url}`;
    // setBase64(proxyUrl);
  }, [url]);

  useEffect(() => {
    if (!isVisible && isSelected && !isAlreadyScrolled) {
      ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [isAlreadyScrolled, isSelected, isVisible]);

  return (
    <div ref={ref} className="h-full">
      {isVisible ? (
        <img crossOrigin="anonymous" src={url} alt="img" className="h-full" loading="lazy" />
      ) : (
        <div className="h-full">
          <LoadingSimple size={32} />
        </div>
      )}
    </div>
  );
};

export default PageThumbnail;
