import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import i18next from "translate/i18n";
import RoomClient from "RoomClient";
import SelectViewMode from "./SelectViewMode";
import LockSetting from "./LockSetting";
import MenuButton from "./MenuButton";
import MenuButtonSimple from "./MenuButtonSimple";
import HostModeSetting from "./HostModeSetting";
import DocSharingFileList from "components/docSharing/filelist/DocSharingFileList";
import Users from "./users/Users";
import screenfull from "screenfull";
import ArrowDownButton from "components/common/icons/ArrowDownButton";
import BackgroundSelector from "components/virtualBackground/BackgroundSelector";
import RecordingFileList from "components/recording/RecordingFileList";
import { store } from "store/store";
import { withRoomContext } from "RoomContext";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { selectLayoutType, selectRoomInfo, selectStartTime, selectIsLocalHost, selectIsHostMode, selectIsPrivate } from "store/roomInfoSlice";
import { selectWaitingInfos } from "store/waitingRoomSlice";
import { setIsShowSettingWindow, selectIsShowSettingWindow, selectLanguage, selectIsLocalCamOn, selectIsLocalMicOn } from "store/settingSlice1";
import {
  setIsShowHostModeEnable,
  setIsShowHostModeRequest,
  setIsShowDocList,
  setIsShowHostSetting,
  setIsShowVirtualBgSetting,
  setIsShowStopDocSharingPopup,
  setIsShowMenuBar,
  setIsShowUsers,
  setIsShowRecordingStoreList,
  selectIsShowDocList,
  selectIsShowRecordingStoreList,
  selectIsShowHostSetting,
  selectIsShowVirtualBgSetting,
  selectIsShowDocSharingClient,
  selectIsShowDocSharingHost,
  selectIsShowUsers,
  selectIsShowSubWindow,
  setIsShowMobileMenu,
  setIsShowLockSetting,
  setIsShowChangeView,
  setIsShowCaption,
  selectIsFullScreen,
  selectIsShowChangeView,
  selectIsShowCaption,
  selectIsShowLockSetting,
} from "store/windowControlSlice";
import { executeScreenshotFunc } from "store/utilSlice";
import { toast } from "react-toastify";
import { ReactComponent as IconLock } from "assets/icon-lock.svg";
import { ReactComponent as IconUnLock } from "assets/icon-unlock.svg";
import { ReactComponent as IconPerson } from "assets/icon-person.svg";
import { ReactComponent as IconMobileMenu } from "assets/toggle-lnb-handler.svg";
import { ReactComponent as IconCC } from "assets/icon-cc.svg";
import { ReactComponent as IconCCx } from "assets/icon-cc-x.svg";
import { ReactComponent as IconCamera } from "assets/icon-camera-on.svg";
import { ReactComponent as IconCameraOff } from "assets/icon-camera-off.svg";
import { ReactComponent as IconMic } from "assets/icon-mic-on.svg";
import { ReactComponent as IconMicOff } from "assets/icon-mic-off.svg";
import { ReactComponent as IconEching } from "assets/icon-eching.svg";
import { ReactComponent as IconShareDoc } from "assets/icon-doc-normal.svg";
import { ReactComponent as IconScreenShot } from "assets/icon-screenshot.svg";
import { ReactComponent as IconRecording } from "assets/icon-record-normal.svg";
import { ReactComponent as IconMonitor } from "assets/icon-monitor.svg";
import { ReactComponent as IconFullScreen } from "assets/icon-full-screen.svg";
import { ReactComponent as IconWindowMode } from "assets/icon-window-mode.svg";
import { ReactComponent as IconSetting } from "assets/icon-setting.svg";
import { ReactComponent as IconSettingBlue } from "assets/icon-setting-blue.svg";
import { ReactComponent as IconQuit } from "assets/icon-quit.svg";
import { ReactComponent as IconQuitBlue } from "assets/icon-quit-blue.svg";
import { ReactComponent as IconHost } from "assets/icon-host.svg";
import { useTranslation } from "react-i18next";
import { usePeerSetting } from "hooks/usePeerSetting";
import { useCanVirtualBg } from "hooks/useCanVirtualBg";
import { useMediaQuery } from "react-responsive";
import { isMobile, isSafari, browserVersion } from "react-device-detect";

interface MenuBarProps {
  roomClient: RoomClient;
}

export enum Menu {
  None,
  LockSetting,
  Members,
  DocSharing,
  Recording,
  HostMode,
  ViewType,
  FullScreen,
  Setting,
  VirtualBackgroundSetting,
  Exit,
}

const MenuBar = (props: MenuBarProps) => {
  const { roomClient } = props;
  const { t } = useTranslation();
  const [isHoverRoot, setIsHoverRoot] = useState(false);
  const [isHoveredLock, setIsHoveredLock] = useState(false);
  const [isHoveredSetting, setIsHoveredSetting] = useState(false);
  const [isHoveredCc, setIsHoveredCc] = useState(false);
  const [isHoveredCam, setIsHoveredCam] = useState(false);
  const [isHoveredMic, setIsHoveredMic] = useState(false);
  const [isHoveredVb, setIsHoveredVb] = useState(false);
  const language = useSelector(selectLanguage, shallowEqual);
  const layoutType = useSelector(selectLayoutType, shallowEqual);
  const isFullScreen = useSelector(selectIsFullScreen, shallowEqual);
  const isShowLockSetting = useSelector(selectIsShowLockSetting, shallowEqual);
  const isShowUsers = useSelector(selectIsShowUsers, shallowEqual);
  const isShowChangeView = useSelector(selectIsShowChangeView, shallowEqual);
  const isShowCaption = useSelector(selectIsShowCaption, shallowEqual);
  const roomInfo = useSelector(selectRoomInfo, shallowEqual);
  const isPrivate = useSelector(selectIsPrivate, shallowEqual);
  const startTime = useSelector(selectStartTime);
  const isShowSetting = useSelector(selectIsShowSettingWindow, shallowEqual);
  const isLocalHost = useSelector(selectIsLocalHost, shallowEqual);
  const isHostMode = useSelector(selectIsHostMode, shallowEqual);
  const isShowHostSetting = useSelector(selectIsShowHostSetting, shallowEqual);
  const isShowDocList = useSelector(selectIsShowDocList, shallowEqual);
  const isShowRecordingStoreList = useSelector(selectIsShowRecordingStoreList, shallowEqual);
  const waitingInfos = useSelector(selectWaitingInfos, shallowEqual);
  const isLocalCamOn = useSelector(selectIsLocalCamOn, shallowEqual);
  const isLocalMicOn = useSelector(selectIsLocalMicOn, shallowEqual);
  const isShowVirtualBgSetting = useSelector(selectIsShowVirtualBgSetting, shallowEqual);
  const isShowDocSharingClient = useSelector(selectIsShowDocSharingClient, shallowEqual);
  const isShowDocSharingHost = useSelector(selectIsShowDocSharingHost, shallowEqual);
  const isShowSubWindow = useSelector(selectIsShowSubWindow, shallowEqual);
  const usersButtonRef = useRef(null);
  const lockButtonRef = useRef(null);
  const docSharingButtonRef = useRef(null);
  const hostModeButtonRef = useRef(null);
  const changeViewButtonRef = useRef(null);
  const virtualBackgroundButtonRef = useRef(null);
  const recordingButtonRef = useRef(null);
  const isMd = useMediaQuery({ query: "(min-width: 768px)" });
  const canVirtualBg = useCanVirtualBg();
  const dispatch = useDispatch();
  const peerSetting = usePeerSetting(roomClient.socket.id);

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  const changeMenu = (menu: Menu) => {
    dispatch(setIsShowUsers(false));
    dispatch(setIsShowChangeView(false));
    dispatch(setIsShowLockSetting(false));
    dispatch(setIsShowSettingWindow(false));
    dispatch(setIsShowDocList(false));
    dispatch(setIsShowRecordingStoreList(false));
    dispatch(setIsShowHostModeEnable(false));
    dispatch(setIsShowHostSetting(false));
    dispatch(setIsShowVirtualBgSetting(false));

    switch (menu) {
      case Menu.None:
        break;
      case Menu.LockSetting:
        dispatch(setIsShowLockSetting(!isShowLockSetting));
        break;
      case Menu.Members:
        dispatch(setIsShowUsers(!isShowUsers));
        break;
      case Menu.DocSharing:
        dispatch(setIsShowDocList(!isShowDocList));
        break;
      case Menu.Recording:
        dispatch(setIsShowRecordingStoreList(!isShowRecordingStoreList));
        break;
      case Menu.HostMode:
        if (isLocalHost) {
          dispatch(setIsShowHostSetting(!isShowHostSetting));
        } else if (isHostMode) {
          dispatch(setIsShowHostModeRequest(true));
        } else {
          dispatch(setIsShowHostModeEnable(true));
        }
        break;
      case Menu.ViewType:
        dispatch(setIsShowChangeView(!isShowChangeView));
        break;

      case Menu.FullScreen:
        if (screenfull.isEnabled) {
          screenfull.toggle();
        }

        break;
      case Menu.Setting:
        dispatch(setIsShowSettingWindow(!isShowSetting));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(setIsShowChangeView(false));
  }, [dispatch, layoutType]);

  useEffect(() => {
    if (!isShowCaption) {
      roomClient.stt?.stop();
    } else {
      roomClient.stt?.start();
    }
  }, [isShowCaption, roomClient.stt]);

  useEffect(() => {
    if (isFullScreen) {
      dispatch(setIsShowMenuBar(false));
    }
  }, [dispatch, isFullScreen]);

  useEffect(() => {
    if (isFullScreen && !isShowSubWindow && !isHoverRoot) {
      dispatch(setIsShowMenuBar(false));
    }
  }, [dispatch, isFullScreen, isHoverRoot, isShowSubWindow]);

  const handlerExit = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    roomClient.close();
  };

  const handlerScreenShare = async () => {
    changeMenu(Menu.None);
    if (isLocalHost) {
      if (isShowDocSharingHost || isShowDocSharingClient) {
        dispatch(setIsShowStopDocSharingPopup(true));
      } else {
        roomClient.closeScreenShare();
        roomClient.screenShare();
      }
    } else {
      toast.warn(t("msg.화면공유 권한없음"));
    }
  };

  const handleCaptureScreen = () => {
    dispatch(executeScreenshotFunc());
  };

  const handleShowCaption = () => {
    dispatch(setIsShowCaption(!isShowCaption));
    roomClient.sendSubtitleShow(!isShowCaption);
  };

  const handleCameraOnOff = (enable: boolean) => {
    enable ? console.log("Cam On") : console.log("Cam Off");
    roomClient.pauseWebcam(!enable);
  };

  const handleMicOnOff = async (enable: boolean) => {
    const isLocalHost = store.getState().room.isLocalHost;
    const isSelfMicOn = store.getState().room.setting.isSelfMicOn;

    if (!isLocalHost && !isSelfMicOn && enable) toast.warn(t("msg.마이크 ON/OFF 권한없음"));
    else roomClient.muteAudio(!enable);
  };

  const handleVirtualBgSettingShow = (enable: boolean) => {
    dispatch(setIsShowVirtualBgSetting(enable));
    setIsHoveredCam(enable);
  };

  const handleOnCloseVirtualBgSetting = () => {
    changeMenu(Menu.None);
    setIsHoveredCam(false);
  };

  const handleMouseLeave = () => {
    setIsHoverRoot(false);
    if (isFullScreen && !isShowSubWindow) dispatch(setIsShowMenuBar(false));
  };

  const VBar = () => {
    return <div className="w-[0.19rem] h-[1.4rem] bg-white"></div>;
  };

  const btn_common = "flex flex-col w-[4.75rem] h-[4.75rem] justify-center items-center cursor-pointer";
  const btn_label = "text-[0.8rem] font-bold hidden text-center text-white mt-1 md:flex";

  return (
    <div
      className="flex justify-between items-center w-full h-[4.75rem] text-white bg-[#333] z-50"
      onMouseEnter={() => setIsHoverRoot(true)}
      onMouseLeave={handleMouseLeave}
    >
      {isShowLockSetting && isMd && !isMobile && (
        <div className="absolute left-[50%] md:left-5 -translate-x-2/4 bottom-[4.75rem] shadow min-h-full md:min-h-0 flex items-center">
          <LockSetting onCloseView={() => changeMenu(Menu.None)} buttonRef={lockButtonRef} isMobile={isMobile} />
        </div>
      )}

      {/* 회의실 잠금 */}
      <div className="flex items-center h-full grow">
        <div
          ref={lockButtonRef}
          className="flex h-full md:w-[4.75rem] flex-col justify-center items-center ml-2 md:ml-6 shrink-0 select-none cursor-pointer"
          onMouseEnter={() => setIsHoveredLock(true)}
          onMouseLeave={() => setIsHoveredLock(false)}
          onClick={() => changeMenu(Menu.LockSetting)}
        >
          {isShowLockSetting && <div className="hidden md:block absolute top-0 w-[4.75rem] h-[0.2rem] bg-blue-10" />}

          {isPrivate ? (
            <>
              <IconLock fill={isHoveredLock || isShowLockSetting ? "#4495ff" : "#fff"} />
              <div className={`text-xs hidden md:flex font-bold ${isHoveredLock || isShowLockSetting ? "text-blue-10" : "text-white"} `}>
                {t("menu.회의실 잠김")}
              </div>
            </>
          ) : (
            <>
              <IconUnLock fill={isHoveredLock || isShowLockSetting ? "#4495ff" : "#fff"} />
              <div className={`text-xs hidden md:flex font-bold ${isHoveredLock || isShowLockSetting ? "text-blue-10" : "text-white"} `}>
                {t("menu.회의실 잠금")}
              </div>
            </>
          )}
        </div>

        <div className="md:ml-[0.75rem] text-[1.375rem] shrink-0">{roomInfo.accessCode}</div>
        <div className="ml-[0.75rem] w-[10vw] grow text-[1.375rem] text-ellipsis overflow-hidden  whitespace-nowrap">{roomInfo.title}</div>
      </div>

      <div className="hidden md:flex items-center select-none">
        <div ref={usersButtonRef} className="relative">
          <MenuButton SvgIcon={IconPerson} isCheck={isShowUsers} label={t("menu.참여자목록")} onClick={() => changeMenu(Menu.Members)} />

          {!isShowUsers && waitingInfos.length > 0 && (
            <>
              <div className="absolute top-0 right-0 bg-[#fa114f] rounded-full w-[0.938rem] h-[0.938rem] text-[0.625rem] flex items-center justify-center font-bold">
                {waitingInfos.length}
              </div>

              <div className="absolute bottom-[6rem] -translate-x-2/4 ml-[2.37rem]">
                <div className="relative bg-black/75 rounded-lg shadow after:contents-[''] after:absolute after:bottom-0 after:left-[50%] after:w-0 after:h-0 after:border-[1rem] after:border-transparent after:border-t-black/75 after:border-b-0 after:ml-[-1rem] after:mb-[-1rem]">
                  <div className="h-[2rem] mx-2 py-1 text-sm whitespace-nowrap">{t("msg.대기자 알림")}</div>
                </div>
              </div>
            </>
          )}

          {isShowUsers && isMd && !isMobile && (
            <>
              <div className="absolute left-[50%] md:left-auto -translate-x-2/4 bottom-[4.75rem] shadow min-h-full md:min-h-0 flex items-center">
                <Users onCloseView={() => changeMenu(Menu.None)} buttonRef={usersButtonRef} isMobile={isMobile} />
              </div>
              <div className="hidden md:block absolute top-0 w-[4.75rem] h-[0.2rem] bg-blue-10" />
            </>
          )}
        </div>

        <VBar />

        <div>
          <MenuButtonSimple SvgIcon={IconMonitor} label={t("menu.화면공유")} onClick={handlerScreenShare} />
        </div>

        {process.env.REACT_APP_ENABLE_DOC_SHARING === "true" && (
          <div ref={docSharingButtonRef} className="relative">
            <MenuButton SvgIcon={IconShareDoc} isCheck={isShowDocList} label={t("menu.문서공유")} onClick={() => changeMenu(Menu.DocSharing)} />

            {isShowDocList && isMd && !isMobile && (
              <>
                <div className="absolute left-[50%] md:left-auto -translate-x-2/4 bottom-[4.75rem] shadow min-h-full md:min-h-0 flex items-center">
                  <DocSharingFileList onCloseView={() => changeMenu(Menu.None)} buttonRef={docSharingButtonRef} isMobile={isMobile} />
                </div>
                <div className="hidden md:block absolute top-0 w-[4.75rem] h-[0.2rem] bg-blue-10" />
              </>
            )}
          </div>
        )}

        <div>
          <MenuButtonSimple SvgIcon={IconScreenShot} label={t("menu.스크린샷")} onClick={handleCaptureScreen} />
        </div>

        {!isSafari || (isSafari && Number(browserVersion) >= 15) ? (
          <div ref={recordingButtonRef}>
            {isShowRecordingStoreList && isMd && !isMobile && <div className="absolute top-0 w-[4.75rem] h-[0.2rem] bg-blue-10"></div>}

            <MenuButton
              SvgIcon={IconRecording}
              isCheck={isShowRecordingStoreList}
              label={t("menu.녹화")}
              onClick={() => changeMenu(Menu.Recording)}
            />

            {isShowRecordingStoreList && isMd && !isMobile && (
              <>
                <div className="absolute left-[50%] md:left-auto -translate-x-2/4 bottom-[4.75rem] shadow min-h-full md:min-h-0 flex items-center">
                  <RecordingFileList onCloseView={() => changeMenu(Menu.None)} buttonRef={recordingButtonRef} isMobile={isMobile} />
                </div>
                <div className="hidden md:block absolute top-0 w-[4.75rem] h-[0.2rem] bg-blue-10" />
              </>
            )}
          </div>
        ) : (
          <></>
        )}

        <VBar />

        <div ref={hostModeButtonRef} className="relative">
          <MenuButton SvgIcon={IconHost} isCheck={isShowHostSetting} label={t("menu.사회자모드")} onClick={() => changeMenu(Menu.HostMode)} />

          {isShowHostSetting && isMd && !isMobile && (
            <>
              <div className="absolute left-[50%] md:left-auto -translate-x-2/4 bottom-[4.75rem] shadow min-h-full md:min-h-0 flex items-center">
                <HostModeSetting onCloseView={() => changeMenu(Menu.None)} buttonRef={hostModeButtonRef} isMobile={isMobile} />
              </div>
              <div className="hidden md:block absolute top-0 w-[4.75rem] h-[0.2rem] bg-blue-10" />
            </>
          )}
        </div>

        <VBar />

        <div>
          <div
            className={btn_common}
            onClick={handleShowCaption}
            onMouseEnter={() => setIsHoveredCc(true)}
            onMouseLeave={() => setIsHoveredCc(false)}
          >
            <div className="w-9 h-9">
              {isShowCaption ? <IconCC fill={isHoveredCc ? "#4495ff" : "#fff"} /> : <IconCCx fill={isHoveredCc ? "#4495ff" : "#fff"} />}
            </div>
            <div className={`text-[0.8rem] font-bold hidden text-center text-white mt-1 md:flex ${isHoveredCc ? "text-blue-10" : "text-white"} `}>
              {t("menu.자막")}
            </div>
          </div>
        </div>

        <div ref={changeViewButtonRef}>
          {isShowChangeView && isMd && !isMobile && (
            <div>
              <div className="absolute z-20 items-center bottom-[5rem] -translate-x-2/4 mx-9">
                <SelectViewMode onCloseView={() => changeMenu(Menu.None)} buttonRef={changeViewButtonRef} />
              </div>
              <div className="absolute top-0 w-[4.75rem] h-[0.2rem] bg-blue-10"></div>
            </div>
          )}

          <MenuButton SvgIcon={IconEching} isCheck={isShowChangeView} label={t("menu.화면보기")} onClick={() => changeMenu(Menu.ViewType)} />
        </div>

        <div className="relative flex items-center">
          {isShowVirtualBgSetting && <div className="absolute top-0 left-0 w-[4.75rem] h-[0.2rem] bg-blue-10" />}
          <div
            className={btn_common}
            onClick={() => handleCameraOnOff(!peerSetting?.isCamEnable)}
            onMouseEnter={() => setIsHoveredCam(true)}
            onMouseLeave={() => setIsHoveredCam(false)}
          >
            <div className="w-9 h-9">
              {peerSetting?.isCamEnable && isLocalCamOn ? (
                <IconCamera fill={isHoveredCam || isShowVirtualBgSetting ? "#4495ff" : "#fff"} />
              ) : (
                <IconCameraOff fill={isHoveredCam || isShowVirtualBgSetting ? "#4495ff" : "#fff"} />
              )}
            </div>
            <div
              className={`text-[0.8rem] font-bold hidden text-center mt-1 md:flex ${
                isHoveredCam || isShowVirtualBgSetting ? "text-blue-10" : "text-white"
              } `}
            >
              {t("menu.카메라")}
            </div>
          </div>

          {canVirtualBg && (
            <div
              ref={virtualBackgroundButtonRef}
              className="cursor-pointer"
              onClick={() => handleVirtualBgSettingShow(!isShowVirtualBgSetting)}
              onMouseEnter={() => setIsHoveredVb(true)}
              onMouseLeave={() => setIsHoveredVb(false)}
            >
              <ArrowDownButton
                className={`block flex-none mr-1 ${isShowVirtualBgSetting && "rotate-180"}`}
                stroke={`${isShowVirtualBgSetting || isHoveredVb ? "#4495ff" : "#fff"} `}
              />
            </div>
          )}
          {isShowVirtualBgSetting && isMd && !isMobile && (
            <div className="absolute bottom-0 left-[2.375rem] -translate-x-2/4 mb-[4.75rem]">
              <div className="mb-1.5">
                <BackgroundSelector onCloseView={handleOnCloseVirtualBgSetting} buttonRef={virtualBackgroundButtonRef} />
              </div>
            </div>
          )}
        </div>

        <div>
          <div
            className={btn_common}
            onClick={() => handleMicOnOff(!peerSetting?.isMicEnable)}
            onMouseEnter={() => setIsHoveredMic(true)}
            onMouseLeave={() => setIsHoveredMic(false)}
          >
            <div className="w-9 h-9">
              {peerSetting?.isMicEnable && isLocalMicOn ? (
                <IconMic fill={isHoveredMic ? "#4495ff" : "#fff"} />
              ) : (
                <IconMicOff fill={isHoveredMic ? "#4495ff" : "#fff"} />
              )}
            </div>
            <div className={`text-[0.8rem] font-bold hidden text-center text-white mt-1 md:flex ${isHoveredMic ? "text-blue-10" : "text-white"} `}>
              {t("menu.마이크")}
            </div>
          </div>
        </div>

        {!isFullScreen ? (
          <MenuButtonSimple SvgIcon={IconFullScreen} label={t("menu.전체화면")} onClick={() => changeMenu(Menu.FullScreen)} />
        ) : (
          <MenuButtonSimple SvgIcon={IconWindowMode} label={t("menu.작은화면")} onClick={() => changeMenu(Menu.FullScreen)} />
        )}

        <div>
          <div
            className={btn_common}
            onClick={() => changeMenu(Menu.Setting)}
            onMouseEnter={() => setIsHoveredSetting(true)}
            onMouseLeave={() => setIsHoveredSetting(false)}
          >
            {isHoveredSetting || isShowSetting ? <IconSettingBlue /> : <IconSetting />}
            <div className={`${btn_label} ${isHoveredSetting || isShowSetting ? "text-blue-10" : "text-white"} `}>{t("menu.환경설정")}</div>
          </div>
        </div>

        <MenuButtonSimple SvgIcon={IconQuit} SvgIconHover={IconQuitBlue} label={t("menu.나가기")} onClick={handlerExit} />
      </div>

      <div className="flex justify-center ml-4 mr-6 w-[7rem] text-[1.375rem] md:text-[1.571rem] font-bold text-right text-white tabular-nums">
        <Moment date={startTime} interval={1000} format={"HH:mm:ss"} durationFromNow />
      </div>
      <div
        className="md:hidden mr-4 cursor-pointer"
        onClick={() => {
          dispatch(setIsShowMobileMenu(true));
        }}
      >
        <IconMobileMenu />
      </div>
    </div>
  );
};

export default withRoomContext(MenuBar);
