import { AuthUserInfo } from "./../types/commonTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { enableMapSet } from "immer";

enableMapSet();

interface State {
  userMap: Map<string, AuthUserInfo>; // key:bid
}

const initialState: State = {
  userMap: new Map(),
};

export const waitingRoomSlice = createSlice({
  name: "waitingRoom",
  initialState,
  reducers: {
    setWaitingInfos: (state: State, action: PayloadAction<AuthUserInfo[]>) => {
      state.userMap.clear();
      action.payload.map(item => state.userMap.set(item.bid, item));
    },
    addWaitingInfo: (state: State, action: PayloadAction<AuthUserInfo>) => {
      state.userMap.set(action.payload.bid, action.payload);
    },
    removeWaitingInfo: (state: State, action: PayloadAction<string>) => {
      state.userMap.delete(action.payload);
    },
  },
});

export const { setWaitingInfos, addWaitingInfo, removeWaitingInfo } = waitingRoomSlice.actions;

export const selectWaitingInfos = (state: RootState) => [...state.waiting.userMap.values()];

export default waitingRoomSlice.reducer;
