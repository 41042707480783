import React from "react";
import { useSelector } from "react-redux";
import { selectConsumers } from "store/consumerSlice";
import { MyConsumer } from "types/clientTypes";
import PeerAudio from "../audio/PeerAudio";
import { SimpleUserInfo } from "types/commonTypes";

const ConsumerAudioList = () => {
  const consumers = useSelector(selectConsumers);

  if (consumers.length === 0) {
    return <></>;
  }

  return (
    <>
      {consumers.map((item: MyConsumer) =>
        item.consumer.kind === "audio" ? (
          <PeerAudio
            key={item.consumer.id}
            id={item.consumer.producerId}
            stream={item.stream}
            label={(item.consumer.appData.userInfo as SimpleUserInfo).username}
          />
        ) : null,
      )}
    </>
  );
};

export default React.memo(ConsumerAudioList);
