import { PeerInfo } from "../types/clientTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

type PeerState = {
  peers: PeerInfo[];
};

const initialState: PeerState = {
  peers: [],
};

export interface ChangeNameProp {
  socketId: string;
  name: string;
}

export interface ISettingProp {
  socketId: string;
  enable: boolean;
}

export const peerSlice = createSlice({
  name: "peer",
  initialState,
  reducers: {
    setPeers: (state: PeerState, action: PayloadAction<PeerInfo[]>) => {
      state.peers = [...action.payload];
    },
    addPeer: (state: PeerState, action: PayloadAction<PeerInfo>) => {
      state.peers.push(action.payload);
    },
    removePeer: (state: PeerState, action: PayloadAction<string>) => {
      state.peers = state.peers.filter(item => item.socketId !== action.payload);
    },
    clearPeer: (state: PeerState) => {
      state.peers = [];
    },
    changeName: (state: PeerState, action: PayloadAction<ChangeNameProp>) => {
      const find = state.peers.filter(item => item.socketId === action.payload.socketId);
      if (find.length > 0) {
        state.peers = state.peers.filter(item => item.socketId !== action.payload.socketId);
        find.forEach(item => {
          item.userInfo.username = action.payload.name;
          state.peers.push(item);
        });
      }
    },
    changeHostBid: (state: PeerState, action: PayloadAction<string>) => {
      const newArray = state.peers;

      newArray.forEach(item => {
        item.isHost = item.peerId === action.payload;
      });

      state.peers = [...newArray];
    },
    setCamEnabled: (state: PeerState, action: PayloadAction<ISettingProp>) => {
      // console.log('store setCamEnabled', action.payload);
      // state.array.map(item => (item.socketId === action.payload.socketId ? { ...item, isCamEnable: action.payload.enable } : item));

      state.peers.forEach(item => {
        if (item.socketId === action.payload.socketId) {
          item.isCamEnable = action.payload.enable;
        }
      });

      // state.peers = [...state.peers];
    },
    setMicEnabled: (state: PeerState, action: PayloadAction<ISettingProp>) => {
      // state.array.map(item => (item.socketId === action.payload.socketId ? { ...item, isMicEnable: action.payload.enable } : item));
      state.peers.forEach(item => {
        if (item.socketId === action.payload.socketId) {
          item.isMicEnable = action.payload.enable ?? false;
        }
      });

      // state.peers = [...state.peers];
    },
  },
});

export const { setPeers, addPeer, removePeer, clearPeer, changeName, changeHostBid, setCamEnabled, setMicEnabled } = peerSlice.actions;
export const selectPeers = (state: RootState) => state.peer.peers;

export default peerSlice.reducer;
