import { ReactElement } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

interface ITooltip {
  children: ReactElement;
  content?: string;
  classNameTooltip?: string;
  arrow?: boolean;
}

const Tooltip = ({ children, content, classNameTooltip, arrow }: ITooltip) => (
  <Tippy
    content={content}
    className={classNameTooltip}
    arrow={arrow}
    disabled={!!!content}
    onShow={instance => {
      setTimeout(() => instance.hide(), 500);
    }}
  >
    {children}
  </Tippy>
);

Tooltip.defaultProps = {
  arrow: false,
};

export default Tooltip;
