import "./App.css";
import "components/common/dialogStyles/RCtree.css";
import StartPage from "./pages/StartPage";
import RoomContext from "./RoomContext";
import RoomClient from "./RoomClient";
import { ToastContainer, Slide } from "react-toastify";

function App() {
  let roomClient = RoomClient.getInstance();

  return (
    <RoomContext.Provider value={roomClient}>
      <StartPage />
      <ToastContainer
        position="top-center"
        autoClose={800}
        hideProgressBar
        closeButton={false}
        newestOnTop
        closeOnClick
        draggable={false}
        transition={Slide}
        theme="colored"
      />
    </RoomContext.Provider>
  );
}

export default App;
