import _ from "lodash";
import * as jose from "jose";
import { options_lang } from "components/setting/Setting";

export const rand = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randName = () => {
  let text = "";
  let first =
    "김이박최정강조윤장임한오서신권황안송류전홍고문양손배조백허유남심노정하곽성차주우구신임나전민유진지엄채원천방공강현함변염양변여추노도소신석선설마주연방위표명기반왕모장남탁국여진구";
  let last =
    "가강건경고관광구규근기길나남노누다단달담대덕도동두라래로루리마만명무문미민바박백범별병보사산상새서석선설섭성세소솔수숙순숭슬승시신아안애엄여연영예오옥완요용우원월위유윤율으은의이익인일자잔장재전정제조종주준중지진찬창채천철초춘충치탐태택판하한해혁현형혜호홍화환회효훈휘희운모배부림봉혼황량린을비솜공면탁온디항후려균묵송욱휴언들견추걸삼열웅분변양출타흥겸곤번식란더손술반빈실직악람권복심헌엽학개평늘랑향울련";

  for (let i = 0; i < 1; i++) text += first.charAt(Math.floor(Math.random() * first.length));
  for (let i = 0; i < 2; i++) text += last.charAt(Math.floor(Math.random() * last.length));

  return text;
};

export const isNonEmptyString = (_str: string) => {
  return _.isString(_str) && !!_str.trim().length;
};

/**
 * 이메일의 유효성을 판단합니다.
 * @param _email 이메일
 * @returns 정상 true, 비정상 false
 */
export const isEmailValid = (_email: string) => {
  if (isNonEmptyString(_email)) {
    const email = _email.trim();
    const regexEmail =
      /^(?:[\w!#$%&'*+\-/=?^`{|}~]+\.)*[\w!#$%&'*+\-/=?^`{|}~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    if (!email.match(regexEmail)) {
      return false;
    }
    return true;
  }
  return false;
};

export const getCurrentLocalTimeString = () => {
  const today = new Date().toLocaleTimeString();
  return today;
};

export const setFullScreenCssProperty = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
};

/**
 * Error produced when a socket request has a timeout.
 */
export class SocketTimeoutError extends Error {
  constructor(message: string) {
    super(message);

    this.name = "SocketTimeoutError";

    if (Error.hasOwnProperty("captureStackTrace"))
      // Just in V8.
      Error.captureStackTrace(this, SocketTimeoutError);
    else this.stack = new Error(message).stack;
  }
}

export const getTenantIdFromJwt = (jwt: string) => {
  let tenantId = "";
  if (jwt) {
    const { aud } = jose.decodeJwt(jwt);
    const audString = aud as string;
    const audArr = audString.split(".");
    tenantId = audArr[0];
  }

  // console.log("getTenantIdFromJwt tenantId", tenantId);
  return tenantId;
};

export const getAudFromJwt = (jwt: string) => {
  let audString = "";
  if (jwt) {
    const { aud } = jose.decodeJwt(jwt);
    audString = aud as string;
  }

  // console.log("getTenantIdFromJwt tenantId", tenantId);
  return audString;
};

export const getIssuerFromJwt = (jwt: string) => {
  let issString = "";
  if (jwt) {
    const { iss } = jose.decodeJwt(jwt);
    issString = iss as string;
  }

  console.log("getIssFromJwt issString", issString);
  return issString;
};

export const getFullLanguageCode = (shortCode: string) => {
  const find = options_lang.find(lang => lang.id.startsWith(shortCode));
  if (find) return find.id;
  else return null;
};

export const getFileSize = (size: number): string => {
  if (size >= 1024 * 1024 * 1024) {
    return `${Math.floor(size / (1024 * 1024 * 1024))}GB`;
  }
  if (size >= 1024 * 1024) {
    return `${Math.floor(size / (1024 * 1024))}MB`;
  }
  if (size >= 1024) {
    return `${Math.floor(size / 1024)}KB`;
  }
  return `${size}B`;
};

export const getFileNameFromUrl = (url: string) => {
  const lastSlashIndex = url.lastIndexOf("/");
  const extractedString = url.substring(lastSlashIndex + 1);
  return extractedString;
};

export const secondsToTimeFormat = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // 패딩 추가 (01, 07, 00 등으로 만들기 위해)
  const paddedHours = String(hours).padStart(2, "0");
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};
