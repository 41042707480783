import Axios from "axios";
import { store } from "../../store/store";
import { IRoomDto, RoomSetting } from "../../types/commonTypes";
import { IInnerAttendee, IOuterAttendee } from "./invite";
import { getConfig } from "./config";

interface IRoomDetail {
  id: number;
  accessCode: string;
  title: string;
  creatorBid: string;
  isPrivate: boolean;
  password: string;
  isMicOn: boolean;
  isCamOn: boolean;
  hasWaitingRoom: boolean;
  innerAttendees: IInnerAttendee[];
  outerAttendees: IOuterAttendee[];
}

export interface IRoomDetailRes {
  result: boolean;
  data: { info: IRoomDto; setting: RoomSetting };
}

export const getRoomDetail = async (roomId: number) => {
  try {
    const url = `${store.getState().env.value.MGR_SERVER}/api/video/meetings/detail`;
    console.log("getRoomDetail. url:", url);

    const data = {
      meetingId: roomId,
    };

    const config = getConfig(store.getState().auth.token);

    const axiosInstance = Axios.create({ timeout: 10000 });
    const response = await axiosInstance.post(url, data, config);
    const res: IRoomDetail = response.data;

    const info: IRoomDto = {
      id: res.id,
      accessCode: res.accessCode,
      title: res.title,
      creator: res.creatorBid,
      innerAttendees: [...res.innerAttendees],
      outerAttendees: [...res.outerAttendees],
    };

    const setting: RoomSetting = {
      isPrivate: res.isPrivate,
      password: res.password,
      isMicOnStartUp: res.isMicOn,
      isCamOnStartUp: res.isCamOn,
      isSelfMicOn: true,
      hasWaitingRoom: res.hasWaitingRoom,
      isSttEnable: false,
      isAutoSaveNote: false,
      isDocSharingOnlyHost: false,
      isWhiteBoardOnlyHost: false,
    };

    return { result: true, data: { info, setting } };
  } catch (e) {
    console.log("error from roomDetail api", e);
    return { result: false };
  }
};

export interface IChangeRoomSettingRes {
  result: boolean;
}
export const changeRoomSetting = async (roomId: number, setting: RoomSetting): Promise<IChangeRoomSettingRes> => {
  // console.log('api changeRoomInfo:', roomInfo);
  try {
    const url = `${store.getState().env.value.MGR_SERVER}/api/video/meetings/settings`;

    const data = {
      meetingId: roomId,
      isPrivate: setting.isPrivate,
      password: setting.password,
      isMicOn: setting.isMicOnStartUp,
      isCamOn: setting.isCamOnStartUp,
      hasWaitingRoom: setting.hasWaitingRoom,
    };

    const config = getConfig(store.getState().auth.token);

    const axiosInstance = Axios.create({ timeout: 10000 });
    const response = await axiosInstance.patch(url, data, config);
    return response.data;
  } catch (e) {
    console.log("error from changeRoomInfo api");
    console.log(e);
    return { result: false };
  }
};

export interface IInviteUrl {
  res: boolean;
  url: string;
}

export const getInviteUrl = async (roomId: Number): Promise<IInviteUrl> => {
  try {
    const url = `${store.getState().env.value.MGR_SERVER}/api/video/meetings/access_url`;

    const data = {
      meetingId: roomId,
    };

    const config = getConfig(store.getState().auth.token);

    const axiosInstance = Axios.create({ timeout: 10000 });
    const response = await axiosInstance.post(url, data, config);
    return { res: true, url: response.data.accessUrl };
  } catch (e) {
    console.log("error from getInviteUrl api");
    console.log(e);

    return { res: false, url: "error" };
  }
};
