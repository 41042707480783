import React, { useCallback, useEffect, useState } from "react";
import RoomClient from "RoomClient";
import ClickAwayListener from "components/ClickAwayListener";
import RecordingFileItem from "./RecordingFileItem";
import { useTranslation } from "react-i18next";
import { withRoomContext } from "RoomContext";
import { ReactComponent as IconPlus } from "assets/icon-plus.svg";
import { RecordingState, selectLocalRecordingState } from "store/recordingSlice";
import { setIsShowRecordingControl, setIsShowRecordingStoreList, selectIsShowRecordingControl } from "store/windowControlSlice";
import { useSelector, useDispatch } from "react-redux";
import { IRecListItem, recordingListDb } from "lib/recording/RecordingListDb";
import { recordingDb } from "lib/recording/RecordingDb";

interface IProps {
  onCloseView: () => void;
  roomClient: RoomClient;
  buttonRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}

const RecordingFileList = (props: IProps) => {
  const { onCloseView, buttonRef, isMobile } = props;
  const [list, setList] = useState<IRecListItem[]>([]);
  const [isOpenSubWindow, setIsOpenSubWindow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const recordingState = useSelector(selectLocalRecordingState);
  const isShowRecordingControl = useSelector(selectIsShowRecordingControl);
  const dispatch = useDispatch();

  const getList = useCallback(async () => {
    const recordingList = await recordingListDb.getDataList();
    if (recordingList.length === 0 && recordingState === RecordingState.Stop) {
      recordingDb.clearAll();
    }

    setList(recordingList);
    setIsLoading(false);
  }, [recordingState]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleNewRecording = () => {
    dispatch(setIsShowRecordingControl(true));
    dispatch(setIsShowRecordingStoreList(false));
  };

  return (
    <ClickAwayListener onClickAway={onCloseView} buttonRef={buttonRef} isOpenSubWindow={isOpenSubWindow}>
      {ref => (
        <div
          ref={ref}
          className={`${
            !isMobile && "md:absolute md:bottom-1 md:left-0 md:right-0 md:-translate-x-2/4 md:mx-9 max-h-[70vh]"
          } overflow-y-auto overflow-x-hidden flex flex-col items-center min-w-[20rem] w-fit z-50 rounded shadow bg-[#333] select-none text-white`}
        >
          <div className="w-full">
            <div className="text-sm">
              <div className="flex w-full h-9 justify-start items-center border-b border-[#555]">
                <div className="w-full font-bold text-white ml-4">{t("menu.녹화")}</div>
              </div>

              <div className={`${isMobile && "max-h-[60vh]"} h-[16.219rem] text-[#fff] overflow-auto p-2 bg-[#333]`}>
                {list.map(item => (
                  <RecordingFileItem key={item.key} item={item} onRefresh={getList} onSubWindowOpen={setIsOpenSubWindow} />
                ))}

                {!isLoading && list.length === 0 && (
                  <div className="flex justify-center items-center h-full text-base font-bold text-[#86878b]">
                    {t("recording.녹화된 파일이 없습니다.")}
                  </div>
                )}
              </div>
            </div>
          </div>

          {!isShowRecordingControl && (
            <div className="w-full flex items-center justify-center border-t border-[#555]" onClick={e => e.stopPropagation()}>
              <IconPlus />
              <button className="text-sm h-[2.75rem] flex justify-center items-center hover:text-blue-30 ml-2" onClick={handleNewRecording}>
                {t("recording.새 녹화 시작")}
              </button>
            </div>
          )}
        </div>
      )}
    </ClickAwayListener>
  );
};

export default withRoomContext(RecordingFileList);
