import { MemoData } from "./../types/commonTypes";
import { SpeechToTextData } from "../types/commonTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

type NoteState = {
  stts: SpeechToTextData[];
  memos: MemoData[];
  canWindowClose: boolean;
  lastReadCnt: number;
  notiCnt: number;
  tempStt?: SpeechToTextData;
};

const initialState: NoteState = {
  stts: [],
  memos: [],
  canWindowClose: true,
  lastReadCnt: 0,
  notiCnt: 0,
};

export const noteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {
    setTempSttData: (state: NoteState, action: PayloadAction<SpeechToTextData>) => {
      // console.log('noteSlice setTempSttData', action.payload);
      state.tempStt = action.payload;
    },
    addSttData: (state: NoteState, action: PayloadAction<SpeechToTextData>) => {
      // const date = new Date(action.payload.time as string);
      // const timeString = `${date.getHours()}:${date.getMinutes()}`;
      // const newData = { ...action.payload, time: timeString };
      state.stts.push(action.payload);
      // console.log('noteSlice addSttData', action.payload);
    },
    addMemoData: (state: NoteState, action: PayloadAction<MemoData>) => {
      // const date = new Date(action.payload.time as string);
      // const timeString = `${date.getHours()}:${date.getMinutes()}`;
      // const newData = { ...action.payload, time: timeString };
      state.memos.push(action.payload);
    },
    setCanClose: (state: NoteState, action: PayloadAction<boolean>) => {
      state.canWindowClose = action.payload;
    },
    setLastReadCnt: (state: NoteState, action: PayloadAction<number>) => {
      state.lastReadCnt = action.payload - state.notiCnt;
    },
  },
});

export const { addSttData, addMemoData, setCanClose, setLastReadCnt, setTempSttData } = noteSlice.actions;
export const selectAllSttData = (state: RootState) => state.note.stts;
export const selectAllMemoData = (state: RootState) => state.note.memos;
export const selectCanClose = (state: RootState) => state.note.canWindowClose;
export const selectUnreadCnt = (state: RootState) => state.note.stts.length - state.note.lastReadCnt - state.note.notiCnt;
export const selectTempStt = (state: RootState) => state.note.tempStt;

export default noteSlice.reducer;
