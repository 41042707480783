import { ReactComponent as IconSpinning } from "assets/gif-spinning-wheel.svg";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

interface IProps {
  bg?: string;
  isShowLabel?: boolean;
  size?: number;
}

const LoadingSimple = ({ bg, isShowLabel, size }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={"h-full w-full flex justify-center items-center " + bg}>
      <div className="h-full w-full flex flex-col justify-center items-center">
        {/* <div className="animate-[spin_1s_steps(8,end)_infinite]"> */}
        <RotatingDiv>
          <IconSpinning width={size ?? 64} height={size ?? 64} />
        </RotatingDiv>

        {isShowLabel && (
          <div className="flex flex-col justify-center items-center mt-4">
            <div className="font-bold text-base text-white">{t("로딩 중")}</div>
            <div className="font-bold text-base text-white">{t("잠시만 기다려주세요")}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingDiv = styled.div`
  animation: ${spin} 1s steps(8, end) infinite;
`;

export default LoadingSimple;
