import React, { useState } from "react";
import RenameBox from "./RenameBox";
import RoomClient from "RoomClient";
import YesNoDialog from "components/YesNoDialog";
import ClickAwayListener from "components/ClickAwayListener";
import { useTranslation } from "react-i18next";
import { PeerInfo } from "types/clientTypes";
import { selectUserInfo } from "store/roomInfoSlice";
import { useSelector, useDispatch } from "react-redux";
import { changeHostBid } from "store/peerSlice";
import { setHostBid, selectHostBid } from "store/roomInfoSlice";
import { toast } from "react-toastify";
import { selectIsShowScreenSharingHost, selectIsShowScreenSharingClient } from "store/windowControlSlice";

export interface IProps {
  callback: (menu: number) => void;
  peer: PeerInfo;
  onClose: () => void;
  buttonRef: React.RefObject<HTMLDivElement>;
}

const UserCommandMore = (props: IProps) => {
  const { callback, peer, onClose, buttonRef } = props;
  const { t } = useTranslation();
  const [isOpenRenameBox, setIsOpenRenameBox] = useState(false);
  const [isOpenKickOutYesNo, setIsOpenKickOutYesNo] = useState(false);
  const [isOpenHostTransferYesNo, setIsOpenHostTransferYesNo] = useState(false);
  const [isOpenSubWindow, setIsOpenSubWindow] = useState(false);
  const localUserInfo = useSelector(selectUserInfo);
  const hostBid = useSelector(selectHostBid);
  const isShowScreenSharingHost = useSelector(selectIsShowScreenSharingHost);
  const isShowScreenSharingClient = useSelector(selectIsShowScreenSharingClient);
  const dispatch = useDispatch();

  const handleRename = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpenRenameBox(true);
    setIsOpenSubWindow(true);
    callback(1);
  };

  const handleKickOut = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpenKickOutYesNo(true);
    setIsOpenSubWindow(true);
    callback(2);
  };

  const handleHostTransfer = (e: React.MouseEvent<HTMLDivElement>) => {
    console.log("handleHostTransfer", isShowScreenSharingHost, isShowScreenSharingClient);

    if (isShowScreenSharingHost || isShowScreenSharingClient) {
      RoomClient.getInstance().closeScreenShare();
    }

    e.stopPropagation();
    setIsOpenHostTransferYesNo(true);
    setIsOpenSubWindow(true);
    callback(3);
  };

  const RenameCallback = (res: boolean, newName: string) => {
    if (res) {
      RoomClient.getInstance().changeName(peer.socketId, newName);
    }

    setIsOpenRenameBox(false);
    setIsOpenSubWindow(false);
    onClose();
  };

  const KickOutYesNoCallback = (res: boolean) => {
    console.log("YesNo callback:", res);
    if (res) {
      RoomClient.getInstance().exitRoom(peer.socketId);
    }

    setIsOpenKickOutYesNo(false);
    setIsOpenSubWindow(false);
    onClose();
  };

  const HostTransferYesNoCallback = (res: boolean) => {
    console.log("HostTransferYesNoCallback callback:", res);
    if (res) {
      RoomClient.getInstance().sendChangeHost(peer.peerId, (result: boolean) => {
        if (result) {
          dispatch(changeHostBid(peer.peerId));
          dispatch(setHostBid(peer.peerId));
          toast.success(t("msg.님께 사회자가 양도되었습니다.", { user: peer?.userInfo.username }));
        } else {
          toast.warn(t("msg.사회자 양도 실패"));
        }
      });
    }

    setIsOpenHostTransferYesNo(false);
    setIsOpenSubWindow(false);
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={onClose} buttonRef={buttonRef} isOpenSubWindow={isOpenSubWindow}>
      {ref => (
        <div ref={ref} className="w-[10rem] h-fit bg-[#333333] flex flex-col z-[999] shadow text-sm py-1">
          <div className="h-7 leading-7 pl-5 hover:bg-[#555] cursor-pointer" onClick={handleRename}>
            {t("users.이름 변경")}
          </div>

          {localUserInfo.bid !== peer.userInfo.bid && (!hostBid || localUserInfo.bid === hostBid) && (
            <div className="h-7 leading-7 pl-5 hover:bg-[#555] cursor-pointer" onClick={handleKickOut}>
              {t("users.강퇴버튼")}
            </div>
          )}

          {localUserInfo.bid === hostBid && (
            <>
              {localUserInfo.bid !== peer.userInfo.bid && (
                <>
                  <div className="w-full h-[1px] my-2 px-2">
                    <div className="w-full h-full bg-[#555]"></div>
                  </div>
                  <div className="h-7 leading-7 pl-5 hover:bg-[#555] cursor-pointer" onClick={handleHostTransfer}>
                    {t("users.사회자 양도")}
                  </div>
                </>
              )}
            </>
          )}

          {peer && isOpenRenameBox && <RenameBox isOpen={isOpenRenameBox} peer={peer} callback={RenameCallback} />}

          <YesNoDialog msg={t("users.강제퇴장")} isOpen={isOpenKickOutYesNo} callback={KickOutYesNoCallback} setIsOpen={() => {}} />

          <YesNoDialog
            msg={t("msg.님께 사회자를 양도하시겠습니까?", { user: peer?.userInfo.username })}
            isOpen={isOpenHostTransferYesNo}
            leftButtonText={t("취소")}
            rightButtonText={t("host.양도")}
            callback={HostTransferYesNoCallback}
            setIsOpen={() => {}}
          />
        </div>
      )}
    </ClickAwayListener>
  );
};

export default React.memo(UserCommandMore);
