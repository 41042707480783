import { useState, useCallback, SetStateAction } from "react";
import InputText from "../inputs/InputText";
import SearchResult from "./SearchResult";
import { Key } from "rc-tree/lib/interface";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  setSelectedMember: Function;
  checkedKeys: Key[];
  setCheckedKeys: Function;
}

const SearchBox = ({ className, setSelectedMember, checkedKeys, setCheckedKeys, ...props }: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  return (
    <div className={`rounded border border-solid border-C-c8cace basis-1/2 pt-0.5 flex flex-col ${className ?? ""}`} {...props}>
      <InputText
        isSearch
        placeholder={t("adduser.사용자 검색")} // "사용자 검색"
        value={searchValue}
        handleChangeInput={useCallback((e: { persist: () => void; currentTarget: { value: SetStateAction<string> } }) => {
          e.persist();
          return setSearchValue(e.currentTarget.value as string);
        }, [])}
        handleClear={() => setSearchValue("")}
        className="flex-none"
      />
      <SearchResult searchValue={searchValue} setSelectedMember={setSelectedMember} checkedKeys={checkedKeys} setCheckedKeys={setCheckedKeys} />
    </div>
  );
};

export default SearchBox;
