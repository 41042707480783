import { io, Socket } from "socket.io-client";
import * as mediasoupClient from "mediasoup-client";
import config from "config";
import i18next from "translate/i18n";
import SpeechRecognitionApiUse from "./components/speechToText/SpeechRecognitionApiUse";
import ScreenShare from "lib/screenShare/ScreenShare";
import { RtpCapabilities } from "mediasoup-client/lib/RtpParameters";
import { Producer, Transport, ProducerOptions } from "mediasoup-client/lib/types";
import { MyConsumer, CallbackJoin, PeerInfo, ICloseParam } from "types/clientTypes";
import {
  callbackTransport,
  callbackTransportProduce,
  callbackMute,
  ChatData,
  SpeechToTextData,
  MemoData,
  JoinRequestRes,
  IRoomDto,
  IRoomInfo,
  IAzureTranslation,
  ChatMsgType,
  IDocSharingHost,
  IDuplicatePeerInfo,
  RoomSetting,
} from "types/commonTypes";
import { useDispatch } from "react-redux";
import { addProducer, removeProducer } from "store/producerSlice";
import { addConsumer, removeConsumer, pauseConsumer, resumeConsumer, changeConsumerIsHost, clearConsumer } from "store/consumerSlice";
import { addPeer, removePeer, clearPeer, setPeers, changeName, changeHostBid, setCamEnabled, setMicEnabled } from "store/peerSlice";
import {
  setRoomState,
  setLocalUserInfo,
  setLocalStream,
  setRoomInfo,
  setStartTime,
  setHostBid,
  setActiveSpeaker,
  setRoomSetting,
} from "store/roomInfoSlice";
// import { setRoomSetting } from "store/roomSettingSlice";
import { setWaitingInfos, addWaitingInfo, removeWaitingInfo } from "store/waitingRoomSlice";
import {
  setWebcams,
  setAudioInputDevices,
  setAudioOutputDevices,
  setIsLocalCamOn,
  setIsLocalMicOn,
  selectWebcam,
  selectMic,
  selectAudioOutputDevice,
  setIsWebcamPermissionError,
  setIsMicPermissionError,
} from "store/settingSlice1";
import { addChatData } from "store/chatSlice";
import { addSttData, addMemoData, setTempSttData } from "store/noteSlice";
import { setAssignHostPopup, setIsShowDocSharingClient, setIsShowDocSharingHost, setIsShowBottomVideoList } from "store/windowControlSlice";
import {
  setHostPageInfo,
  addPenPath,
  createPenPath,
  clearAllPenPath,
  clearPenPath,
  setZoomType,
  setCurrentHostSocketId,
  setHostCursorPos,
  setIsWhiteBoard,
  setPenPath,
  removeHostPageInfo,
  clearHostPageInfo,
  DocSharingEditMode,
  setLocalHostPageInfo,
} from "store/docSharingSlice";
import { setRecordingMicId } from "store/recordingSlice";
import { AuthUserInfo } from "types/commonTypes";
import { store } from "store/store";
import { addMemo } from "api/manager/memo";
import { sendSttResultToMgr } from "api/manager/stt";
import { isSafari, osName, browserName } from "react-device-detect";
import { SocketTimeoutError } from "lib/utils";
import { ConvertedFile, ConvertRes } from "api/cloudconvert/types";
import { IDocSharingPageInfo } from "components/docSharing/docSharingType";
import { Line, IPenPath } from "./components/docSharing/docSharingType";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { recordingDb } from "lib/recording/RecordingDb";
import { recordingListDb } from "lib/recording/RecordingListDb";
import { saveJoinAllow, isSavedJoinAllow, removeJoinAllow } from "lib/joinAllowSave";

const opts = {
  path: "/mediasoup",
  transports: ["websocket"],
};

export interface UpdateAvDeviceParam {
  init?: boolean;
  start?: boolean;
  restart?: boolean;
  newDeviceId?: string;
}

export default class RoomClient {
  private static instance: RoomClient;

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  dispatch = useDispatch();
  navigate = useNavigate();

  userInfo: AuthUserInfo = {
    bid: "",
    username: "",
    position: "",
    deptname: "",
    picurl: "",
  };
  roomId: number = 0;
  creatorBid: string = "";
  setWebCamStreamCallback: any;
  setScreenShareStreamCallback: any;

  socket: Socket = io();
  mediasoupDevice: mediasoupClient.Device = new mediasoupClient.Device();
  rtpCapabilities: RtpCapabilities = {};
  _sendTransport: Transport | null = null;
  _recvTransport: Transport | null = null;

  audioParams: ProducerOptions = {};
  videoParams: ProducerOptions = config.videoParams;
  consumingTransports: string[] = [];
  audioProducer: Producer | null = null;
  videoProducer: Producer | null = null;
  _webcams: Map<string, MediaDeviceInfo> = new Map();
  _mics: Map<string, MediaDeviceInfo> = new Map();
  _speakers: Map<string, MediaDeviceInfo> = new Map();
  selectedWebcamId: string = "";
  stt: SpeechRecognitionApiUse | null = null;
  isInternalUser: boolean = true;
  isUseVirtualBg: boolean = false;
  isDuplicateJoin: boolean = false;
  duplicateJoinInfo: IDuplicatePeerInfo = { peerId: "", os: "", browser: "", ip: "" };
  screenShareInstance: ScreenShare | null = null;

  constructor() {
    this.startDevicesListener();
  }

  setWebCamStreamSetCallback(func: (stream: MediaStream) => void) {
    this.setWebCamStreamCallback = func;
  }

  setScreenShareStreamSetCallback(func: (stream: MediaStream) => void) {
    this.setScreenShareStreamCallback = func;
  }

  public init = async (roomId: number, userInfo: AuthUserInfo, creatorBid: string) => {
    this.roomId = roomId;
    this.creatorBid = creatorBid;
    this.userInfo = userInfo;
    this.socket.close();
  };

  public join = async () => {
    const tenantId = store.getState().room.tenantId;
    const url = `${store.getState().env.value.SERVER_URL}?roomId=${this.roomId}&peerId=${encodeURIComponent(this.userInfo.bid)}&tenantId=${tenantId}`;
    console.log("### RoomClient join() - url", url);

    this.socket = io(url, opts);
    this.isDuplicateJoin = false;

    this.socket.on("connect", async () => {
      console.log("socket.io connect 성공!", this.socket.id);
    });

    this.socket.on("disconnect", () => {
      console.log("socket.io disconnect");

      if (this.videoProducer) {
        this.videoProducer.close();
        this.videoProducer = null;
      }

      if (this.audioProducer) {
        this.audioProducer.close();
        this.audioProducer = null;
      }

      if (this.screenShareInstance) {
        this.screenShareInstance.closeScreenShare();
        this.screenShareInstance = null;
      }

      if (this._sendTransport) {
        this._sendTransport.close();
        this._sendTransport = null;
      }

      if (this._recvTransport) {
        this._recvTransport.close();
        this._recvTransport = null;
      }

      this.videoParams = config.videoParams;
      this.audioParams = {};
      this.dispatch(clearPeer());
      this.dispatch(clearConsumer());
      this.dispatch(clearHostPageInfo());
      this.dispatch(setRoomState(this.isDuplicateJoin ? "duplicate" : "disconnected"));
    });

    this.socket.on("init", async () => {
      console.log("socket.io on init");
      this.sendGetRoomInfo();
    });

    this.socket.on("join-allow", async ({ result }) => {
      if (result) {
        saveJoinAllow(this.roomId, this.userInfo.bid);
        this.dispatch(setRoomState("connected"));
        this.sendJoinRoom();
      } else {
        this.dispatch(setRoomState("rejected"));
      }
    });

    this.socket.on("newConsumer", async ({ peerId, producerId, id, kind, rtpParameters, type, appData, producerPaused, score }) => {
      // console.log("### newConsumer 수신. kind:", kind, peerId, score);

      if (store.getState().room.curState !== "connected") return;
      if (!this._recvTransport) return;

      const consumer = await this._recvTransport.consume({
        id,
        producerId,
        kind,
        rtpParameters,
        appData: { ...appData, peerId }, // Trick.
      });

      consumer.observer.on("pause", () => {
        console.log("consumer-pause 전송", consumer.kind, consumer.appData.source);
        this.socket.emit("consumer-pause", { serverConsumerId: consumer.id });
      });

      consumer.observer.on("resume", () => {
        console.log("consumer-resume 전송", consumer.kind, consumer.appData.source);
        this.socket.emit("consumer-resume", { serverConsumerId: consumer.id });
      });

      const { track } = consumer;

      const newMyConsumer: MyConsumer = {
        consumer,
        stream: new MediaStream([track]),
        socketId: consumer.appData.socketId as string,
        source: consumer.appData.source as string,
      };

      this.dispatch(addConsumer(newMyConsumer));

      consumer.on("transportclose", () => {
        this.dispatch(removeConsumer(consumer.id));
      });

      this.socket.emit("consumer-resume", { serverConsumerId: consumer.id });

      if (consumer.kind === "video" && !consumer.appData.isScreen) {
        const peer = store.getState().peer.peers.find(p => p.socketId === consumer.appData.socketId);

        this.sendNoti(`${peer?.userInfo.username} ${i18next.t("msg.입장 알림")}`, true);
      }
    });

    this.socket.on("consumerClosed", ({ consumerId }) => {
      this.consumingTransports = this.consumingTransports.filter(item => item !== consumerId);
      this.dispatch(removeConsumer(consumerId));
    });

    this.socket.on("mute", ({ isMute }) => {
      this.muteAudio(isMute);
    });

    this.socket.on("cam-enable", ({ isEnable }) => {
      this.pauseWebcam(!isEnable);
    });

    this.socket.on("exit-room", () => {
      removeJoinAllow(this.roomId);
      this.close({ isKickOut: true });
    });

    this.socket.on("notification", ({ socketId, type, value }) => {
      if (type === "mic-enable") {
        this.dispatch(setMicEnabled({ socketId, enable: value.enable }));

        if (value.enable) {
          this.dispatch(resumeConsumer({ socketId, kind: "audio", source: "mic" }));
        } else {
          this.dispatch(pauseConsumer({ socketId, kind: "audio", source: "mic" }));
        }
      } else if (type === "cam-enable") {
        this.dispatch(setCamEnabled({ socketId, enable: value.enable }));
      } else if (type === "change-name") {
        this.dispatch(changeName({ socketId: value.socketId, name: value.name }));

        if (this.socket.id === value.socketId) {
          this.dispatch(setLocalUserInfo({ ...this.userInfo, username: value.name }));
        }
      }
    });

    this.socket.on("chat", (chatData: ChatData) => {
      // console.log("chat 수신!", chatData.serverTime);
      chatData.isLocal = chatData.userInfo.bidOrEmail === this.userInfo.bid;
      this.dispatch(addChatData(chatData));
    });

    this.socket.on("stt-enable", enable => {
      this.dispatch(setRoomSetting({ ...store.getState().room.setting, isSttEnable: enable }));
    });

    this.socket.on("stt", (sttData: SpeechToTextData) => {
      sttData.isLocal = sttData.userInfo.bidOrEmail === this.userInfo.bid;

      if (!sttData.isFinal) this.dispatch(setTempSttData(sttData));
      else this.dispatch(addSttData(sttData));
    });

    this.socket.on("stt-trans", (transData: IAzureTranslation) => {
      console.log("stt-trans", transData);
    });

    this.socket.on("memo", (memoData: MemoData) => {
      memoData.isLocal = memoData.userInfo.bidOrEmail === this.userInfo.bid;
      this.dispatch(addMemoData(memoData));
    });

    this.socket.on("waiting-in", ({ roomId, userInfo }) => {
      this.dispatch(addWaitingInfo(userInfo));
    });

    this.socket.on("waiting-out", ({ roomId, bid }) => {
      this.dispatch(removeWaitingInfo(bid));
    });

    this.socket.on("waiting-list", ({ roomId, list }) => {
      this.dispatch(setWaitingInfos(list));
    });

    this.socket.on("changeRoomInfo", (roomDto: IRoomDto) => {
      console.log("on changeRoomInfo", roomDto);
      this.dispatch(setRoomInfo(roomDto));
    });

    this.socket.on("changeRoomSetting", (setting: RoomSetting) => {
      console.log("on changeRoomSetting", setting);
      this.dispatch(setRoomSetting(setting));
    });

    this.socket.on("changeRoomHost", (hostBid: string) => {
      console.log("사회자 변경~~~~~~~ to", hostBid);
      if (hostBid !== store.getState().room.hostBid) {
        this.dispatch(changeConsumerIsHost(hostBid));
        this.dispatch(changeHostBid(hostBid));
        this.dispatch(setHostBid(hostBid));
      }
    });

    this.socket.on("requestRoomHost", ({ hostBid, username }: { hostBid: string; username: string }) => {
      this.dispatch(
        setAssignHostPopup({
          isShow: true,
          username: username,
          bid: hostBid,
        }),
      );
    });

    this.socket.on("addPeerInfo", (userInfo: PeerInfo) => {
      console.log("addPeerInfo 수신!");
      this.dispatch(addPeer(userInfo));
    });

    this.socket.on("removePeer", (socketId: string) => {
      console.log("removePeer 수신!");
      this.dispatch(removePeer(socketId));
    });

    this.socket.on("moderator:stopScreenSharing", () => {
      console.log("사회자가 화면공유 중지");
      this.setScreenShareStreamCallback(undefined);
    });

    this.socket.on("docSharingPageInfo", (param: IDocSharingHost) => {
      // console.log("docSharingPageInfo 수신", param);
      // const isClient = param.info.url.length > 0;
      this.dispatch(setIsShowDocSharingClient(true));
      this.dispatch(setIsShowDocSharingHost(false));
      this.dispatch(clearHostPageInfo());
      this.dispatch(setIsWhiteBoard(!param.info.url.startsWith("http")));
      this.dispatch(clearAllPenPath());
      this.dispatch(setCurrentHostSocketId(param.socketId));
      this.dispatch(setHostPageInfo({ socketId: param.socketId, info: { ...param.info } }));
      this.dispatch(setZoomType("auto-fit"));
    });

    this.socket.on("docSharingCursorPos", data => {
      // console.log("docSharingCursorPos on data", data);
      this.dispatch(setHostCursorPos(data));
    });

    this.socket.on("docSharingCreatePenPath", (param: IPenPath) => {
      this.dispatch(createPenPath(param));
    });

    this.socket.on("docSharingAddPenPath", ({ p1, p2 }) => {
      this.dispatch(addPenPath({ p1, p2 }));
    });

    this.socket.on("docSharingClearPenPath", (idx: number) => {
      // console.log("on docSharingClearPenPath", idx);
      this.dispatch(clearPenPath(idx));
    });

    this.socket.on("docSharingClearAllPenPath", () => {
      // console.log("on docSharingClearAllPenPath");
      this.dispatch(clearAllPenPath());
    });

    this.socket.on("docSharingHostRemove", (socketId: string) => {
      // console.log("docSharingHostRemove 수신", socketId, store.getState().docSharing.hostPageInfo);
      this.dispatch(setCurrentHostSocketId(""));
      this.dispatch(removeHostPageInfo(socketId));
      if (store.getState().docSharing.hostPageInfo.size === 0) {
        this.dispatch(setIsShowDocSharingClient(false));
        this.dispatch(setIsShowBottomVideoList(true));
      }
    });

    this.socket.on("docSharingHostCanvasScale", ({ scale, socketId }) => {
      const info = store.getState().docSharing.hostPageInfo.get(socketId);
      if (info) {
        this.dispatch(setHostPageInfo({ socketId: socketId, info: { ...info, canvasScale: scale } }));
      }
    });

    this.socket.on("forceStopDocSharingHost", () => {
      if (store.getState().windowControl.isShowDocSharingHost) this.closeDocSharingHost();
    });

    this.socket.on("check-device", callback => {
      callback({ os: osName, browser: browserName });
    });

    this.socket.on("duplicate-join", ({ peerId, os, browser, ip }) => {
      console.log("동일 id로 중복접속됨", peerId, os, browser);
      this.isDuplicateJoin = true;
      this.duplicateJoinInfo = { peerId, os, browser, ip };
    });

    this.socket.on("activeSpeaker", ({ peerId, volume }) => {
      // console.log("activeSpeaker", peerId, volume);
      this.dispatch(setActiveSpeaker(peerId));
    });
  };

  sendJoinRoom = async () => {
    this.socket.emit(
      "joinRoom",
      { roomId: this.roomId, jwt: store.getState().auth.token, userInfo: this.userInfo },
      ({ rtpCapabilities, startTime, runningTime, isFirstClient }: CallbackJoin) => {
        this.rtpCapabilities = rtpCapabilities;
        const calcStartTime = new Date(new Date().getTime() - runningTime);
        this.dispatch(setStartTime(calcStartTime));

        this._joinRoom();

        if (isFirstClient) {
          this.sendChangeRoomSetting(store.getState().room.setting, (result: boolean) => {
            this.sendRoomJwt(store.getState().auth.token, (result: boolean) => {});
          });
        }
      },
    );

    this.dispatch(setRoomState("connected"));
  };

  _joinRoom = async () => {
    if (store.getState().room.isLocalHost) {
      await this.sendRequest("changeRoomHost", { roomId: this.roomId, hostBid: this.userInfo.bid });
    }

    try {
      if (!this.mediasoupDevice.loaded) {
        await this.mediasoupDevice.load({ routerRtpCapabilities: this.rtpCapabilities });
      }
    } catch (error: any) {
      console.error("device load Error", error);
      if (error.name === "UnsupportedError") {
        console.warn("browser not supported");
      }
    }

    this.socket.emit("createWebRtcTransport", { isConsumer: false }, ({ params, error }: callbackTransport) => {
      if (error) {
        console.log(error);
        return;
      }

      console.log("_joinRoom createSendTransport");
      this._sendTransport = this.mediasoupDevice.createSendTransport(params);
      this.startMicAndWebcamProduce();

      this._sendTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        try {
          this.socket.emit("connectWebRtcTransport", {
            dtlsParameters,
            transportId: this._sendTransport?.id,
          });
          callback();
        } catch (error) {
          errback(error as Error);
        }
      });

      this._sendTransport.on("produce", async ({ kind, rtpParameters, appData }, callback, errback) => {
        console.log("producerTransport.on produce - appData", appData, kind);
        appData = {
          roomId: this.roomId,
          isHost: store.getState().room.isLocalHost,
          userInfo: this.userInfo,
          socketId: this.socket.id,
          isMicEnable: store.getState().room.setting.isMicOnStartUp,
          isCamEnable: store.getState().room.setting.isCamOnStartUp,
          isScreen: appData.source === "screen",
          source: appData.source,
        };

        try {
          this.socket.emit(
            "produce",
            {
              transportId: this._sendTransport?.id,
              kind,
              rtpParameters,
              appData,
              rtpCapabilities: this.mediasoupDevice.rtpCapabilities,
            },
            ({ id }: callbackTransportProduce) => {
              // console.log("emit produce - response 수신", id, kind);
              callback({ id });

              if (kind === "video") {
                if (store.getState().setting.isLocalCamOn) {
                  this.videoProducer?.resume();
                }
              } else if (kind === "audio" && appData.source === "mic") {
                if (store.getState().setting.isLocalMicOn) {
                  this.audioProducer?.resume();
                }
              }
            },
          );
        } catch (error) {
          errback(error as Error);
        }
      });
    });

    this.socket.emit("createWebRtcTransport", { isConsumer: true, isScreen: false }, ({ params, error }: callbackTransport) => {
      if (error) {
        console.log(error);
        return;
      }

      try {
        console.log("_joinRoom createRecvTransport");
        this._recvTransport = this.mediasoupDevice.createRecvTransport(params);
      } catch (error) {
        console.log(error);
        return;
      }

      this._recvTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        try {
          if (!this._recvTransport) return;

          this.socket.emit("connectWebRtcTransport", {
            dtlsParameters,
            transportId: this._recvTransport.id,
          });
          callback();
        } catch (error) {
          errback(error as Error);
        }
      });
    });

    this.socket.emit("getPeers", (peerList: PeerInfo[]) => {
      // console.log("===> getPeers", peerList);
      this.dispatch(setPeers(peerList));
      this.socket.emit("addPeerInfo");
      this.socket.emit("createConsumersForExistingProducers", { rtpCapabilities: this.mediasoupDevice.rtpCapabilities });
    });

    this.sendGetDocSharingHosts();
    this.sendGetDocSharingPenPaths();
  };

  sendGetRoomInfo = async () => {
    this.socket.emit("getRoomInfo", (res: IRoomInfo) => {
      console.log("after init. getRoomInfo. res", res);

      if (res.hostBid) {
        this.dispatch(setHostBid(res.hostBid));
      } else if (this.creatorBid === this.userInfo.bid) {
        this.dispatch(setHostBid(this.userInfo.bid));
      }

      const savedJoinAllow = isSavedJoinAllow(this.roomId, this.userInfo.bid);

      console.log("savedJoinAllow", savedJoinAllow);
      console.log("store.getState().room", store.getState().room);

      if (store.getState().room.isLocalHost || !store.getState().room.setting.hasWaitingRoom || savedJoinAllow) {
        this.dispatch(setRoomState("connected"));
        this.sendJoinRoom();
      } else {
        console.log("waiting!!!!!");
        this.dispatch(setRoomState("waiting"));
        this.sendWaitingUserInfo();
      }

      if (res.setting) {
        this.dispatch(setRoomSetting(res.setting));

        if (res.setting.isMicOnStartUp && store.getState().setting.isLocalMicOn) {
          console.log("마이크ON으로 시작하도록 설정됨!");
          this.audioProducer?.resume();
        }
      }

      // 문서공유 호스트였다면 페이지정보, 펜 패스정보등 전송
      if (store.getState().windowControl.isShowDocSharingHost) {
        const pageInfo = store.getState().docSharing.localHostPageInfo;
        if (pageInfo) {
          // console.log("공유중이였다면 재접속 후 pageInfo 전송");
          this.sendDocSharingPageInfo(pageInfo);
          store.getState().docSharing.penPath.forEach(path => this.sendDocSharingCreatePenPath(path));
        }
      }

      const maxMicActivation = Number(process.env.REACT_APP_MAX_MIC_ACTIVATION) || 5;
      console.log("maxMicActivation", maxMicActivation);

      if (res.curPeerCnt > maxMicActivation) {
        this.dispatch(setIsLocalMicOn(false));
      }
    });
  };

  startMicAndWebcamProduce = async () => {
    await this.updateWebcam({ init: false, start: true });
    await this.updateMic({ init: true });
    await this._updateAudioOutputDevices();
  };

  close = ({ isReject = false, isKickOut = false }: ICloseParam = {}) => {
    console.log("room close", this.socket.id);
    removeJoinAllow(this.roomId);

    this.socket.close();

    if (this._sendTransport !== null) {
      console.log("producerTransport close");
      this._sendTransport.close();
    }

    if (this._recvTransport != null) {
      console.log("consumerTransport close");
      this._recvTransport.close();
    }

    this.dispatch(setRoomState("closed"));
    if (this.setWebCamStreamCallback) {
      this.setWebCamStreamCallback(null);
    }

    recordingDb.clearAll();
    recordingListDb.clearAll();

    if (isReject) {
      window.history.back();
    } else if (isKickOut) {
      this.navigate("/kickout", { replace: true });
    } else {
      this.navigate("/end", { replace: true });
    }
  };

  mute = async (isMute: boolean, isRemote: boolean, socketId: string) => {
    const isLocalHost = store.getState().room.isLocalHost;
    const isSelfMicOn = store.getState().room.setting.isSelfMicOn;

    if (isLocalHost) {
      this.socket.emit("mute", { socketId, isMute }, async ({ result }: callbackMute) => {
        // console.log(`mute(${isMute}) 송신결과: result:${result}`);
      });
      return true;
    }

    if (isRemote || (!isMute && !isSelfMicOn)) {
      return false;
    } else {
      this.socket.emit("mute", { socketId, isMute }, async ({ result }: callbackMute) => {
        // console.log(`mute(${isMute}) 송신결과: result:${result}`);
      });
      return true;
    }
  };

  exitRoom = (socketId: string) => {
    this.socket.emit("exit-room", { socketId });
    removeJoinAllow(this.roomId);
  };

  changeName = async (socketId: string, name: string) => {
    console.log(`changeName(${name}) socketId:${socketId}`);
    this.notificationSend("change-name", { socketId, name });
  };

  notificationSend = async (type: string, value: object) => {
    this.socket.emit("notification", {
      socketId: this.socket.id,
      roomId: this.roomId,
      type,
      value,
    });
  };

  screenShare = async () => {
    if (!this._sendTransport) return;

    this.screenShareInstance = new ScreenShare(this.socket, this._sendTransport);

    this.screenShareInstance.on("close", () => {
      console.log("screenShareInstance close event");
      this.setScreenShareStreamCallback(undefined);
      this.screenShareInstance = null;
    });

    const stream = await this.screenShareInstance.getDisplayMediaStream();
    if (!stream) return;

    if (this.setWebCamStreamCallback) {
      this.setScreenShareStreamCallback(stream);
    }

    this.socket?.emit("joinRoom", { roomId: this.roomId }, (data: any) => {
      this.rtpCapabilities = data.rtpCapabilities;
      this.screenShareInstance?.createScreenShareProducer();
    });
  };

  closeScreenShare = () => {
    this.screenShareInstance?.closeScreenShare();
  };

  sendWaitingUserInfo = () => {
    console.log("대기정보 전송 시작");
    this.socket.emit("addWaiting", { roomId: this.roomId, userInfo: this.userInfo }, (result: boolean) => {
      console.log("대기정보 전송결과 콜백수신", result);
    });
  };

  sendChat = (chatData: ChatData) => {
    this.socket.emit("chat", chatData);
  };

  sendSpeechToText = (sttData: SpeechToTextData) => {
    this.socket.emit("stt", sttData);

    if (store.getState().room.setting.isAutoSaveNote && sttData.isFinal) {
      sendSttResultToMgr(sttData).then(res => {
        // console.log('sendSttResultToMgr res', res);
      });
    }
  };

  sendTranslationConfig = (isEnable: boolean, toLanguage: string) => {
    this.socket.emit("trans-config", { enable: isEnable, toLanguage });
  };

  sendSubtitleShow = (isEnable: boolean) => {
    this.socket.emit("subtitle-enable", { enable: isEnable });
  };

  sendMemo = (memoData: MemoData) => {
    this.socket.emit("memo", memoData);
    addMemo(memoData).then(res => {
      console.log("sendMemo res", res);
    });
  };

  sendNoti = (noti: string, localOnly?: boolean, isInvite?: boolean) => {
    const today = new Date();
    const newNoti: ChatData = {
      roomId: this.roomId,
      userInfo: { isInsider: true, bidOrEmail: this.userInfo.bid },
      text: noti,
      time: today.toISOString(),
      msgType: isInvite ? ChatMsgType.INVITE : ChatMsgType.NOTI,
    };

    if (!localOnly) this.socket.emit("chat", newNoti);
    else this.dispatch(addChatData(newNoti));
  };

  sendGetWaitingList = (roomId: number, callback: any) => {
    this.socket.emit("getWaitingList", roomId, (list: string[]) => {
      return callback(list);
    });
  };

  sendJoinAllow = (roomId: number, bid: string, result: boolean) => {
    const joinReqRes: JoinRequestRes = { roomId, bid, result };
    this.socket.emit("joinAllow", joinReqRes, (isSuccess: boolean) => {
      console.log("입장허용 처리결과:", isSuccess);
    });
  };

  sendChangeRoomInfo = async (roomDto: IRoomDto, callback: { (result: boolean): void }) => {
    this.socket.emit("changeRoomInfo", roomDto, (result: boolean) => {
      // console.log(`sendChangeRoomInfo. result:${result}, setting`, roomDto.setting);
      callback(result);
    });
  };

  sendChangeRoomSetting = async (setting: RoomSetting, callback: { (result: boolean): void }) => {
    this.socket.emit("changeRoomSetting", setting, (result: boolean) => {
      // console.log(`sendChangeRoomSetting. result:${result}, setting`, roomDto.setting);
      callback(result);
    });
  };

  sendRoomJwt = async (jwt: string, callback: { (result: boolean): void }) => {
    const data = { jwt };

    this.socket.emit("roomJwt", data, (result: boolean) => {
      callback(result);
    });
  };

  sendChangeHost = async (hostBid: string, callback: { (result: boolean): void }) => {
    // console.log(`changeRoomHost emit. roomId:${this.roomId} hostBid:${hostBid}`);

    const res = await this.sendRequest("changeRoomHost", { roomId: this.roomId, hostBid });
    console.log("사회자 변경결과:", res.result);

    if (res.result) {
      if (hostBid !== store.getState().room.hostBid) {
        this.dispatch(changeConsumerIsHost(hostBid));
        this.dispatch(changeHostBid(hostBid));
        this.dispatch(setHostBid(hostBid));
      }
    }
    callback(res.result);
  };

  sendRequestHost = async (hostBid: string, username: string, callback: { (result: boolean): void }) => {
    this.socket.emit("requestRoomHost", { roomId: this.roomId, hostBid, username }, (result: boolean) => {
      callback(result);
    });
  };

  startDevicesListener() {
    navigator.mediaDevices.addEventListener("devicechange", async () => {
      console.log("_startDevicesListener() | navigator.mediaDevices.ondevicechange");
      await this._updateWebcams({ init: true });
      await this._updateAudioInputDevices({ init: true });
      await this._updateAudioOutputDevices({ init: true });
    });
  }

  updateWebcam = async ({ init = false, start = false, restart = false, newDeviceId }: UpdateAvDeviceParam = {}) => {
    console.log(`change webcam stream. init:${init}, start:${start}, restart:${restart}, newDeviceId:${newDeviceId?.slice(0, 5)}`);

    try {
      if (!this.mediasoupDevice.loaded) return;

      if (!this.mediasoupDevice.canProduce("video")) throw new Error("cannot produce video");

      if (newDeviceId && !restart) throw new Error("changing device requires restart");

      if (newDeviceId) store.dispatch(selectWebcam(newDeviceId));

      const deviceId = await this._getWebcamDeviceId();
      if (!deviceId) throw new Error("no webcam devices");

      const device = this._webcams.get(deviceId);
      if (!device) throw new Error("no webcam devices");

      this.selectedWebcamId = store.getState().setting.selectedWebcamId;

      if (this.videoProducer) {
        console.log("updateWebcam() | closing current videoProducer");
        this.videoProducer.track?.stop();
      }

      const stream = await this.getMediaStreamWithRetry(
        {
          video: {
            ...config.userMedia.video,
            deviceId: { ideal: deviceId },
            aspectRatio: { ideal: config.aspectRatio },
          },
        },
        10,
      );

      if (this.setWebCamStreamCallback) {
        this.setWebCamStreamCallback(stream);
        this.dispatch(setLocalStream(stream));
      }

      if (store.getState().setting.virtualBgType === "none") {
        console.log("updateWebcam 가상배경 사용 안 함");
        this.setLocalVideoStreamWaintingForSendTransport(stream);
      }

      await this._updateWebcams({ init, start });
    } catch (error) {
      console.error(error);
    }
  };

  async setLocalVideoStreamWaintingForSendTransport(stream: MediaStream, maxRetries = 30): Promise<void> {
    if (!stream) return;

    try {
      if (this._sendTransport) {
        console.log("setLocalVideoStreamWaintingForSendTransport setLocalVideoStream call");
        this.setLocalVideoStream(stream);
      } else {
        console.log("setLocalVideoStreamWaintingForSendTransport error. retry", maxRetries);
        if (maxRetries <= 0) {
          console.log("setLocalVideoStreamWaintingForSendTransport error. maxRetries");
          return;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        return this.setLocalVideoStreamWaintingForSendTransport(stream, maxRetries - 1);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async setLocalVideoStream(stream: MediaStream) {
    if (!stream) return;
    this.videoParams = { track: stream.getVideoTracks()[0], ...config.videoParams };

    if (this.videoProducer) {
      const track = stream.getVideoTracks()[0];
      await this.videoProducer.replaceTrack({ track });
    } else if (this._sendTransport) {
      this.videoProducer = await this._sendTransport.produce({
        ...this.videoParams,
        appData: {
          source: "webcam",
        },
        // codec: this.mediasoupDevice.rtpCapabilities.codecs?.find(codec => codec.mimeType.toLowerCase() === 'video/h264'),
      });

      this.dispatch(addProducer(this.videoProducer));

      this.videoProducer.on("transportclose", () => {
        console.log("video transport closed so producer closed");
        this.videoProducer = null;
      });

      this.videoProducer.on("trackended", () => {
        console.log("video track ended");
        this.disableWebcam();
      });

      this.videoProducer.on("@close", () => {
        this.setWebCamStreamCallback(null);
      });

      this.videoProducer.observer.on("pause", () => {
        this.dispatch(setIsLocalCamOn(false));
        this.notificationSend("cam-enable", { enable: false });
        this.dispatch(setCamEnabled({ socketId: this.socket.id, enable: false }));
        this.socket.emit("producer-pause", { producerId: this.videoProducer?.id });
      });

      this.videoProducer.observer.on("resume", () => {
        this.dispatch(setIsLocalCamOn(true));
        this.notificationSend("cam-enable", { enable: true });
        this.dispatch(setCamEnabled({ socketId: this.socket.id, enable: true }));
        this.socket.emit("producer-resume", { producerId: this.videoProducer?.id });
      });

      if (!store.getState().room.setting.isCamOnStartUp || !store.getState().setting.isLocalCamOn) {
        this.videoProducer.pause();
      } else {
        this.dispatch(setCamEnabled({ socketId: this.socket.id, enable: true }));
      }
    }
  }

  async changeAudioOutputDevice(deviceId: string) {
    try {
      const device = this._speakers.get(deviceId);

      if (!device) throw new Error("Selected audio output device no longer available");

      this.dispatch(selectAudioOutputDevice(deviceId));

      await this._updateAudioOutputDevices();
    } catch (error) {
      console.log('changeAudioOutputDevice() [error:"%o"]', error);
    }
  }

  async updateMic({ init, start = false, restart = true, newDeviceId }: UpdateAvDeviceParam = {}) {
    try {
      if (!this.mediasoupDevice.loaded) return;
      if (!this.mediasoupDevice.canProduce("audio")) throw new Error("cannot produce audio");
      if (newDeviceId && !restart) throw new Error("changing device requires restart");

      if (newDeviceId) this.dispatch(selectMic(newDeviceId));

      const deviceId = await this._getAudioDeviceId();
      if (!deviceId) throw new Error("no audio devices");

      const device = this._mics.get(deviceId);
      if (!device) throw new Error("no audio devices");

      if (this.audioProducer) {
        console.log("이미 audio producer 존재하므로 track stop", this.audioProducer.track?.readyState);
        this.audioProducer.track?.stop();
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { ideal: deviceId } } });
      this.audioParams = { track: stream.getAudioTracks()[0], ...this.audioParams };

      if (this.audioProducer) {
        console.log("이미 audio producer 존재하므로 track 교체");
        const track = stream.getAudioTracks()[0];
        await this.audioProducer.replaceTrack({ track });
      } else {
        if (this._sendTransport) {
          this.audioProducer = await this._sendTransport.produce({
            ...this.audioParams,
            appData: {
              source: "mic",
            },
          });

          this.dispatch(addProducer(this.audioProducer));
          this.dispatch(setIsLocalMicOn(store.getState().setting.isLocalMicOn));

          this.audioProducer.on("trackended", () => {
            console.log("audioProducer trackended");
          });

          this.audioProducer.observer.on("pause", () => {
            console.log("updateMic audioProducer on pause");
            this.dispatch(setIsLocalMicOn(false));
            this.notificationSend("mic-enable", { enable: false });
            this.socket.emit("producer-pause", { producerId: this.audioProducer?.id });
          });

          this.audioProducer.observer.on("resume", () => {
            console.log("updateMic audioProducer on resume");
            this.dispatch(setIsLocalMicOn(true));
            this.notificationSend("mic-enable", { enable: true });
            this.socket.emit("producer-resume", { producerId: this.audioProducer?.id });
          });

          if (!store.getState().room.setting.isMicOnStartUp || !store.getState().setting.isLocalMicOn) {
            console.log("updateMic audioProducer.pause()");
            this.audioProducer.pause();
          }

          await this._updateAudioInputDevices();
        }
      }

      this.dispatch(setRecordingMicId(deviceId));
    } catch (error) {
      console.error(error);
    }
  }

  async _updateWebcams({ init = false, start = false } = {}) {
    // console.log(`===== _updateWebcams() start ===== init:${init}, start:${start}`);
    this._webcams.clear();

    try {
      if (init) {
        await navigator.mediaDevices.getUserMedia({ video: true });
      }

      const devices = await navigator.mediaDevices.enumerateDevices();

      for (const device of devices) {
        if (device.kind === "videoinput" && device.deviceId !== "") {
          this._webcams.set(device.deviceId, device);
        }
      }

      this.dispatch(setWebcams(this._webcams));

      const prevId = store.getState().setting.selectedWebcamId;
      const prevDevice = this._webcams.get(prevId);

      if (prevId && prevDevice) {
        // console.log(`_updateWebcams 선택된:[${prevDevice?.label.slice(0, 15)}] 사용`);
        this.dispatch(selectWebcam(prevId));
      } else if (init) {
        if (this._webcams.size > 0) {
          const firstWebcamId = this._webcams.keys().next().value;
          // console.log("_updateWebcams() init. 첫 번째 webcam을 선택한다.", this._webcams.values().next().value.label);
          this.dispatch(selectWebcam(firstWebcamId));
          this.updateWebcam({ restart: true, newDeviceId: firstWebcamId });
        }
      }
      store.dispatch(setIsWebcamPermissionError(false));
    } catch (error) {
      console.error('_updateWebcams() [error:"%o"]', error);
      if (error instanceof Error) {
        console.log(`_updateWebcams() error.name:[${error.name}]`);
        if (error.name === "NotAllowedError") {
          store.dispatch(setIsWebcamPermissionError(true));
        } else if (error.name === "NotFoundError") {
          store.dispatch(setIsWebcamPermissionError(false));
        }
      }
    }
    // console.log("===== _updateWebcams() end =====");
  }

  async _updateAudioInputDevices({ init = false, start = false } = {}) {
    this._mics.clear();

    try {
      if (init) await navigator.mediaDevices.getUserMedia({ audio: true });
      const devices = await navigator.mediaDevices.enumerateDevices();

      for (const device of devices) {
        if (device.kind === "audioinput" && device.deviceId !== "") {
          // console.log("발견한 마이크", device);

          if (isMobile) {
            this._mics.set(device.deviceId, device);
          } else {
            // if (device.deviceId !== "communications" && device.deviceId !== "default") this._mics.set(device.deviceId, device);
            if (device.deviceId !== "communications") this._mics.set(device.deviceId, device);
          }
        }
      }

      this.dispatch(setAudioInputDevices(this._mics));

      const prevId = store.getState().setting.selectedMicId;
      const prevDevice = this._mics.get(prevId);

      if (prevId && this._mics.get(prevId)) {
        // console.log(`_updateAudioInputDevices prev mic:[${prevDevice?.label}]를 사용한다.`);
        this.dispatch(selectMic(prevId));
      } else if (init || !prevDevice) {
        if (this._mics.size > 0) {
          const firstMicId = this._mics.keys().next().value;
          // console.log("_updateAudioInputDevices 첫 번째 mic을 선택한다.", init, firstMicId, this._mics.get(firstMicId)?.label);
          this.dispatch(selectMic(firstMicId));
          this.updateMic({ restart: true, newDeviceId: firstMicId });
        }
      }

      store.dispatch(setIsMicPermissionError(false));
    } catch (error) {
      console.error('_updateAudioInputDevices() [error:"%o"]', error);
      if (error instanceof Error) {
        console.log(`_updateAudioInputDevices() error.name:[${error.name}]`);
        if (error.name === "NotAllowedError") {
          store.dispatch(setIsMicPermissionError(true));
        } else if (error.name === "NotFoundError") {
          store.dispatch(setIsMicPermissionError(false));
        }
      }
    }
  }

  async _updateAudioOutputDevices({ init = false } = {}) {
    this._speakers.clear();

    if (isSafari) {
      this.dispatch(setAudioOutputDevices(this._speakers));
      return;
    }

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      for (const device of devices) {
        if (device.kind === "audiooutput") {
          // console.log("발견한 스피커", device);
          if (isMobile) {
            this._speakers.set(device.deviceId, device);
          } else {
            if (device.deviceId !== "communications") this._speakers.set(device.deviceId, device);
          }
        }
      }

      this.dispatch(setAudioOutputDevices(this._speakers));

      if (store.getState().setting.selectedSpeakerId && this._speakers.get(store.getState().setting.selectedSpeakerId)) {
        this.dispatch(selectAudioOutputDevice(store.getState().setting.selectedSpeakerId));
      } else if (init) {
        const firstSpeakerId = this._speakers.keys().next().value;
        this.dispatch(selectAudioOutputDevice(firstSpeakerId));
      }
    } catch (error) {
      console.error('_updateAudioOutputDevices() [error:"%o"]', error);
    }
  }

  async _getAudioDeviceId() {
    try {
      await this._updateAudioInputDevices();
      const { selectedMicId: selectedAudioDevice } = store.getState().setting;

      if (selectedAudioDevice && this._mics.get(selectedAudioDevice)) return selectedAudioDevice;
      else {
        const audioDevices = Array.from(this._mics.values());
        return audioDevices[0] ? audioDevices[0].deviceId : "";
      }
    } catch (error) {
      console.error('_getAudioDeviceId() [error:"%o"]', error);
    }
  }

  async _getWebcamDeviceId() {
    try {
      await this._updateWebcams();
      const { selectedWebcamId } = store.getState().setting;

      if (selectedWebcamId && this._webcams.get(selectedWebcamId)) {
        return selectedWebcamId;
      } else {
        const webcams = Array.from(this._webcams.values());
        return webcams[0] ? webcams[0].deviceId : null;
      }
    } catch (error) {
      console.error('_getWebcamDeviceId() [error:"%o"]', error);
    }
  }

  async _getAudioOutputDeviceId() {
    try {
      await this._updateAudioOutputDevices();
      const { selectedSpeakerId } = store.getState().setting;

      if (selectedSpeakerId && this._speakers.get(selectedSpeakerId)) return selectedSpeakerId;
      else {
        const audioOutputDevices = Array.from(this._speakers.values());
        return audioOutputDevices[0] ? audioOutputDevices[0].deviceId : "";
      }
    } catch (error) {
      console.error('_getAudioOutputDeviceId() [error:"%o"]', error);
    }
  }

  async disableWebcam() {
    console.log("disableWebcam()");
    if (!this.videoProducer) return;

    this.videoProducer.close();
    this.dispatch(removeProducer(this.videoProducer.id));

    try {
      this.socket.emit("closeProducer", { producerId: this.videoProducer.id }, (currentCnt: number) => {
        console.log("프로듀서 삭제후 콜백수신. 현재갯수:", currentCnt);
      });
    } catch (error) {
      console.error('disableWebcam() [error:"%o"]', error);
    }

    this.videoProducer = null;
  }

  pauseWebcam = async (isPause: boolean) => {
    if (!this.videoProducer) return;
    // console.log("### pauseWebcam(). current paused:", this.videoProducer.paused);

    if (isPause) this.videoProducer.pause();
    else this.videoProducer.resume();
  };

  muteAudio = async (isMute: boolean) => {
    if (!this.audioProducer) return;
    isMute ? this.audioProducer.pause() : this.audioProducer.resume();
  };

  findPeer = (socketId: string) => {
    return store.getState().peer.peers.find(p => p.socketId === socketId);
  };

  dumpRouter = () => {
    this.socket.emit("dumpRouter", () => {});
  };

  getResourceUsage = () => {
    this.socket.emit("getResourceUsage", () => {});
  };

  dumpProducer = (id: string) => {
    this.socket.emit("dumpProducer", id, (res: any) => {
      console.log("res", res);
    });
  };

  startDocFileConvertJob = async (filename: string, url: string): Promise<ConvertRes> => {
    return new Promise(resolve => {
      this.socket.emit("startFileConvertJob", { filename, url }, (res: ConvertRes) => {
        resolve(res);
      });
    });
  };

  docFileConvertCheck = async (jobid: string): Promise<ConvertRes> => {
    // console.log("RoomClient docFileConvertCheck. jobid", jobid);
    return new Promise(resolve => {
      this.socket.emit("checkFileConvertJobStatus", jobid, (res: ConvertRes) => {
        // console.log("RoomClient docFileConvertCheck res", res);
        resolve(res);
      });
    });
  };

  checkThumbnailImages = async (fileid: string): Promise<boolean> => {
    return new Promise(resolve => {
      this.socket.emit("isExistThumbnailImages", { fileid }, (res: boolean) => {
        console.log("isExistThumbnailImages res", res);
        resolve(res);
      });
    });
  };

  downloadFromConvertServer = async (fileid: string, convertedFiles: ConvertedFile[]): Promise<boolean> => {
    return new Promise(resolve => {
      console.log("downloadFromConvertServer emit", new Date().toLocaleTimeString());
      this.socket.emit("downloadFromConvertServer", { fileid, convertedFiles, width: 300, height: 200 }, (res: boolean) => {
        console.log("downloadFromConvertServer res", res, new Date().toLocaleTimeString());
        resolve(res);
      });
    });
  };

  makeThumbnailImages = async (fileid: string, convertedFiles: ConvertedFile[]): Promise<boolean> => {
    return new Promise(resolve => {
      console.log("makeThumbnailImage emit", new Date().toLocaleTimeString());
      this.socket.emit("makeThumbnailImages", { fileid, convertedFiles, width: 300, height: 200 }, (res: boolean) => {
        console.log("makeThumbnailImage res", res, new Date().toLocaleTimeString());
        resolve(res);
      });
    });
  };

  deleteConvertedFiles = async (fileid: string): Promise<boolean> => {
    return new Promise(resolve => {
      this.socket.emit("deleteConvertedFiles", fileid, (res: boolean) => {
        console.log("deleteConvertedFiles res", res);
        resolve(res);
      });
    });
  };

  sendDocSharingPageInfo = (param: IDocSharingPageInfo) => {
    this.socket.emit("docSharingPageInfo", { ...param });
    this.dispatch(setLocalHostPageInfo(param));
  };

  sendDocSharingHostRemove = async (): Promise<boolean> => {
    return new Promise(resolve => {
      this.socket.emit("docSharingHostRemove", (res: boolean) => {
        console.log("emit docSharingHostRemove res", res);
        resolve(res);
      });
    });
  };

  sendDocSharingCursorPos = (x: number, y: number, mode: DocSharingEditMode) => {
    // console.log("docSharingCursorPos emit", { x, y, mode });
    this.socket.emit("docSharingCursorPos", { x, y, mode });
  };

  sendDocSharingCreatePenPath = (path: IPenPath) => {
    this.socket.emit("docSharingCreatePenPath", path);
  };

  sendDocSharingAddPenPath = (line: Line) => {
    this.socket.emit("docSharingAddPenPath", line);
  };

  sendDocSharingClearPenPath = (idx: number) => {
    this.socket.emit("docSharingClearPenPath", idx);
  };

  sendDocSharingClearAllPenPath = () => {
    this.socket.emit("docSharingClearAllPenPath");
  };

  sendGetDocSharingHosts = async () => {
    this.socket.emit("getDocSharingHosts", (list: IDocSharingHost[]) => {
      // console.log("getDocSharingHosts res", list);
      if (list.length > 0) {
        this.dispatch(setIsShowDocSharingClient(true));
        this.dispatch(clearAllPenPath());
        this.dispatch(setCurrentHostSocketId(list[0].socketId));
        this.dispatch(setIsWhiteBoard(!list[0].info.url.startsWith("http")));
        this.dispatch(setHostPageInfo({ socketId: list[0].socketId, info: { ...list[0].info } }));
        this.dispatch(setZoomType("auto-fit"));
      } else {
        this.dispatch(setIsShowDocSharingClient(false));
        this.dispatch(setIsShowDocSharingHost(false));
      }
    });
  };

  sendGetDocSharingPenPaths = () => {
    this.socket.emit("getDocSharingPenPaths", (list: IPenPath[]) => {
      if (list.length > 0) this.dispatch(setPenPath(list));
    });
  };

  sendDocShargingHostScale = (scale: number) => {
    this.socket.emit("docSharingHostCanvasScale", scale);
  };

  sendForceStopDocSharing = async () => {
    return new Promise(resolve => {
      this.socket.emit("forceStopDocSharingHost", (res: boolean) => {
        console.log("forceStopDocSharingHost res", res);
        resolve(res);
      });
    });
  };

  async sendRequest(method: string, data: any = {}): Promise<any> {
    // console.log('sendRequest() [method:"%s", data:"%o"]', method, data);

    for (let tries = 0; tries < config.requestRetries; tries++) {
      try {
        return await this._sendRequest(method, data);
      } catch (error) {
        if (error instanceof SocketTimeoutError && tries < config.requestRetries)
          console.log('sendRequest() | timeout, retrying [attempt:"%s"]', tries);
        else {
          throw error;
        }
      }
    }
  }
  _sendRequest(method: string, data: any) {
    return new Promise((resolve, reject) => {
      if (!this.socket) {
        reject("No socket connection");
      } else {
        this.socket.emit(
          "request",
          { method, data },
          this.timeoutCallback((err: any, response: any) => {
            if (err) reject(err);
            else resolve(response);
          }),
        );
      }
    });
  }

  timeoutCallback(callback: any) {
    let called = false;

    const interval = setTimeout(() => {
      if (called) return;
      called = true;
      callback(new SocketTimeoutError("Request timed out"));
    }, config.requestTimeout);

    return (...args: any) => {
      if (called) return;
      called = true;
      clearTimeout(interval);

      callback(...args);
    };
  }

  async getMediaStreamWithRetry(constraints: MediaStreamConstraints, maxRetries = 3): Promise<MediaStream> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      return stream;
    } catch (error) {
      if (maxRetries <= 0) {
        throw error; // 모든 재시도 시도 후에도 실패한 경우 오류를 던집니다.
      }
      // 재시도 횟수를 1 줄이고, 1초 후에 다시 시도합니다.
      console.log("getMediaStreamWithRetry error. retry");

      await new Promise(resolve => setTimeout(resolve, 1000));
      return this.getMediaStreamWithRetry(constraints, maxRetries - 1);
    }
  }

  async closeDocSharingHost() {
    this.dispatch(setIsShowBottomVideoList(true));
    this.dispatch(setIsShowDocSharingHost(false));
    await this.sendDocSharingHostRemove();
    await this.sendGetDocSharingHosts();
  }
}
