// redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import producerReducer from "./producerSlice";
import consumerReducer from "./consumerSlice";
import roomReducer from "./roomInfoSlice";
// import roomSettingReducer from "./roomSettingSlice";
import settingReducer from "./settingSlice1";
import chatReducer from "./chatSlice";
import noteReducer from "./noteSlice";
import waitingReducer from "./waitingRoomSlice";
import authReducer from "./authSlice";
import windowControlReducer from "./windowControlSlice";
import usersReducer from "./usersSlice";
import envReducer from "./envSlice";
import peerReducer from "./peerSlice";
import inviteReducer from "./inviteSlice";
import recordingReducer from "./recordingSlice";
import docSharingReducer from "./docSharingSlice";
import attachmentReducer from "./attachmentSlice";
import utilReducer from "./utilSlice";

export const store = configureStore({
  reducer: {
    producer: producerReducer,
    consumer: consumerReducer,
    room: roomReducer,
    // roomSetting: roomSettingReducer,
    setting: settingReducer,
    chat: chatReducer,
    note: noteReducer,
    waiting: waitingReducer,
    auth: authReducer,
    windowControl: windowControlReducer,
    users: usersReducer,
    env: envReducer,
    peer: peerReducer,
    invite: inviteReducer,
    recording: recordingReducer,
    docSharing: docSharingReducer,
    attachment: attachmentReducer,
    util: utilReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
