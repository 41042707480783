import { useState, useEffect } from "react";
import { getInviteUrl } from "api/manager/room";

const useGetInviteUrl = (roomId: Number) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    async function getUrl() {
      const res = await getInviteUrl(roomId);

      if (res.res && res.url) {
        setUrl(res.url);
      }
    }

    getUrl();
  }, [roomId]);

  return url;
};

export default useGetInviteUrl;
