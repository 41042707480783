import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCanClose } from "store/chatSlice";
import { ReactComponent as IconPrev } from "assets/icon-before.svg";
import { ReactComponent as IconNext } from "assets/icon-next.svg";
import styles from "./EmoticonPicker.module.css";

const setInfo = [
  {
    img: "emoticon/tab/tab_01.png",
    name: "첫 번째",
    count: 24,
    ext: "gif",
  },
  {
    img: "emoticon/tab/tab_02.png",
    name: "두 번째",
    count: 20,
    ext: "gif",
  },
  {
    img: "emoticon/tab/tab_03.png",
    name: "세 번째",
    count: 20,
    ext: "gif",
  },
  {
    img: "emoticon/tab/tab_04.png",
    name: "네 번째",
    count: 20,
    ext: "png",
  },
];

enum Color {
  normal = "#b4b5b8",
  selected = "#4495ff",
}

interface SelectorProps {
  onSelect: (set: number, no: number, url: string) => void;
  onCloseView: () => void;
}

const EmoticonSelector = (props: SelectorProps) => {
  const { onSelect } = props;
  const [selectSet, setSelectSet] = useState(1);
  const [prevBtnColor, setPrevBtnColor] = useState(Color.normal);
  const [nextBtnColor, setNextBtnColor] = useState(Color.normal);
  const rootDivRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleLeftArrow = () => {
    setSelectSet(Math.max(1, selectSet - 1));
  };

  const handleRightArrow = () => {
    setSelectSet(Math.min(setInfo.length, selectSet + 1));
  };

  const handleTabClick = (tab: number) => {
    console.log("tab", tab);
    setSelectSet(tab + 1);
  };

  const handleImageClick = (idx: number) => {
    // console.log(`select set:${selectSet} no:${idx + 1}`);
    const url = `emoticon/set${selectSet}/set${selectSet}_${idx + 1}.${setInfo[selectSet - 1].ext}`;
    onSelect(selectSet, idx + 1, url);
  };

  useEffect(() => {
    dispatch(setCanClose(false));
    return () => {
      dispatch(setCanClose(true));
    };
  }, [dispatch]);

  const DrawList = () => {
    const result = [];
    for (let i = 0; i < setInfo[selectSet - 1].count; i++) {
      // console.log(`emoticon/set${selectSet}/set${selectSet}_${i + 1}.${tabImg[selectSet - 1].ext}`);
      result.push(
        <div key={i} className="h-[4.875rem] flex justify-center items-center hover:bg-[#333] cursor-pointer" onClick={() => handleImageClick(i)}>
          <img src={`emoticon/set${selectSet}/set${selectSet}_${i + 1}.${setInfo[selectSet - 1].ext}`} alt="" className="w-[4.375rem] h-[4.375rem]" />
        </div>,
      );
    }
    return result;
  };

  return (
    <div ref={rootDivRef} className="bg-[#707070] w-full rounded shadow">
      {/* 탭 */}
      <div className="h-11 flex justify-between items-center border-b-[1px] border-[#333]">
        <div
          className="w-11 h-full flex justify-center items-center cursor-pointer"
          onMouseEnter={() => setPrevBtnColor(Color.selected)}
          onMouseLeave={() => setPrevBtnColor(Color.normal)}
          onClick={handleLeftArrow}
        >
          <IconPrev style={{ color: prevBtnColor }} />
        </div>
        <div className="h-full grow flex justify-start items-center px-1">
          {setInfo.map((item, idx) => (
            <div
              key={idx}
              className="flex flex-col justify-between items-center pt-1 w-11 h-11 mb-px cursor-pointer"
              onClick={() => handleTabClick(idx)}
            >
              <img src={item.img} alt="" className="w-[2.125rem] h-[2.125rem]" />
              {selectSet === idx + 1 && <div className="w-11 h-1 bg-[#4495ff]" />}
            </div>
          ))}
        </div>
        <div
          className="w-11 h-full flex justify-center items-center cursor-pointer"
          onMouseEnter={() => setNextBtnColor(Color.selected)}
          onMouseLeave={() => setNextBtnColor(Color.normal)}
          onClick={handleRightArrow}
        >
          <IconNext style={{ color: nextBtnColor }} />
        </div>
      </div>

      {/* 이모티콘 목록 */}
      <div className={styles.EmotionList}>{DrawList()}</div>
    </div>
  );
};

export default EmoticonSelector;
