import { openDB, deleteDB, IDBPDatabase } from "idb";

export interface IRecListItem {
  key: string;
  name: string;
  secGap: number;
  startTime: number;
  endTime: number;
  startKey: number;
  endKey: number;
}

export default class RecordingListDb {
  private static instance: RecordingListDb;
  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  private idbName: string = "recordingList";
  private idbVersion: number = 1;
  private storeName: string = "defaultStore";
  private db: IDBPDatabase<unknown> | undefined;

  constructor() {
    this.createDb();
  }

  async createDb() {
    try {
      const idbStoreName = this.storeName;

      this.db = await openDB(this.idbName, this.idbVersion, {
        upgrade(db: IDBPDatabase) {
          if (!db.objectStoreNames.contains(idbStoreName)) {
            db.createObjectStore(idbStoreName, { autoIncrement: true });
          }
        },
      });
    } catch (error) {
      return false;
    }
  }

  async putData(value: object) {
    if (!this.db) return;
    await this.db?.put(this.storeName, value, Date.now());
  }

  async getList() {
    if (!this.db) return [];
    const data: IRecListItem[] = await this.db.getAll(this.storeName);
    return data;
  }

  async getDataList() {
    if (!this.db) return [];

    const allKeys = await this.db.getAllKeys(this.storeName);
    const tx = this.db.transaction(this.storeName, "readonly");
    const store = tx.objectStore(this.storeName);

    const list: IRecListItem[] = [];
    const promises = allKeys.map(async key => {
      const value = await store.get(key);
      const item = { key: key as number, ...value };
      list.push(item);
    });

    await Promise.all(promises);
    return list;
  }

  async clearAll() {
    if (!this.db) return;
    const tx = this.db.transaction(this.storeName, "readwrite");
    const store = tx.objectStore(this.storeName);
    if (store) {
      await store.clear();
    }
    return;
  }

  async delete(key: string) {
    try {
      await this.db?.delete(this.storeName, key);
      return true;
    } catch (error) {
      return false;
    }
  }

  public async cleanup() {
    if (!this.db) return;

    this.db.close();
    deleteDB(this.idbName);

    if (indexedDB.databases instanceof Function) {
      const r = await indexedDB.databases();

      r.forEach(dbdata => {
        if (dbdata.name === this.idbName) deleteDB(dbdata.name);
      });
    }
  }

  async getLastRecord() {
    if (!this.db) return null;

    let lastRecord = null;

    const tx = this.db.transaction(this.storeName, "readonly");
    const store = tx.objectStore(this.storeName);

    const cursor = await store.openCursor(null, "prev"); // 'prev'는 역순
    if (cursor) {
      lastRecord = { key: cursor.key, value: cursor.value }; // 마지막 레코드
    }

    await tx.done;

    return lastRecord;
  }
}

export const recordingListDb = RecordingListDb.getInstance();
