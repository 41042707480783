import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { DownloadResponseType } from "./types";
import { store } from "store/store";

export const uploadFile = async (file: File, abortController: AbortController, onProgress: (percent: number) => void) => {
  try {
    const formData = new FormData();
    formData.append("files", file, encodeURIComponent(file.name));

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      signal: abortController.signal,
      timeout: 120000,
      onUploadProgress: e => {
        const percent = Math.round((e.loaded * 100) / e.total);
        onProgress(percent);
      },
    };

    const url = `${process.env.REACT_APP_V5_FILE_SERVER_URL || store.getState().env.value.MGR_SERVER}/api/files`;
    console.log("v5 file upload url", url);

    const { data } = await axios.post(url, formData, config);
    const resData: DownloadResponseType[] = data;
    console.log("v5 file upload res data", resData[0]);

    return { data: { result: `${url}/${resData[0].name}` } };
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error("v5 fileupload error", error, file.name);
    }
  }
};
