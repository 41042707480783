import React, { useState } from "react";
import ScreenRecorder from "lib/recording/ScreenRecorder";
import YesNoDialog from "components/YesNoDialog";
import DeleteButton from "components/common/buttons/DeleteButton";
import CombinedSvgButton from "components/common/buttons/CombinedSvgButton";
import { useTranslation } from "react-i18next";
import { recordingDb } from "lib/recording/RecordingDb";
import { IRecListItem, recordingListDb } from "lib/recording/RecordingListDb";
import { format } from "date-fns";
import { secondsToTimeFormat } from "lib/utils";
import { ReactComponent as IconSave } from "assets/icon-save.svg";

interface IProps {
  item: IRecListItem;
  onRefresh: () => void;
  onSubWindowOpen: (isOpen: boolean) => void;
}

const RecordingFileItem = ({ item, onRefresh, onSubWindowOpen }: IProps) => {
  const [isOpenDeleteYesNo, setIsOpenDeleteYesNo] = useState(false);
  const { t } = useTranslation();

  const handleDownload = async (item: IRecListItem) => {
    const recorder = ScreenRecorder.getInstance();
    recorder.downloadFile(item);
  };

  const handleDelete = async (e: React.MouseEvent<HTMLDivElement>, item: IRecListItem) => {
    e.stopPropagation();
    setIsOpenDeleteYesNo(true);
    onSubWindowOpen(true);
  };

  const DeleteYesNoCallback = async (res: boolean) => {
    console.log("delete YesNo callback:", res);
    onSubWindowOpen(false);
    setIsOpenDeleteYesNo(false);

    if (res) {
      recordingListDb.delete(item.key);
      const delCnt = await recordingDb.deleteRange(item.startKey, item.endKey);
      console.log("delCnt", delCnt);
      onRefresh();
    }
  };

  return (
    <div className="my-1 flex items-center bg-[#444] rounded h-10 tabular-nums text-sm whitespace-nowrap hover:bg-[#555]">
      <div className="flex justify-between items-center w-full">
        <div className="flex ml-3">
          <div className="">{format(item.startTime, "yy-MM-dd HH:mm:ss")}</div>
          <div className="ml-2 text-[#999]">{secondsToTimeFormat(item.secGap)}</div>
        </div>

        <div className="flex ml-4">
          <div className=" w-10 h-10 flex justify-center items-center cursor-pointer" onClick={() => handleDownload(item)}>
            <CombinedSvgButton SvgIcon={IconSave} normalColor="#86878b" tooltipContent={t("recording.다운로드")} />
          </div>
          <div className="ml-0 w-10 h-10 flex justify-center items-center cursor-pointer" onClick={e => handleDelete(e, item)}>
            <DeleteButton tooltipContent={t("recording.삭제")} />
          </div>
        </div>
      </div>

      <YesNoDialog
        msg={t("msg.선택한 녹화파일을 삭제하시겠습니까?")}
        isOpen={isOpenDeleteYesNo}
        leftButtonText={t("아니오")}
        rightButtonText={t("예")}
        callback={DeleteYesNoCallback}
        setIsOpen={() => {}}
      />
    </div>
  );
};

export default RecordingFileItem;
