import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import RoomClient from "RoomClient";
import ClientVideoView from "components/videoView/ClientVideoView";
import PeerVideoList from "components/home/PeerVideoList";
import SmallOverlayVideoView from "components/videoView/SmallOverlayVideoView";
import ScreenShareController from "components/videoView/ScreenShareController";
import RecordingControl from "components/recording/RecordingControl";
import useElementSize from "hooks/usehooks-ts/useElementSize";
import { useSelector, useDispatch } from "react-redux";
import { useHostPeer } from "hooks/useHostPeer";
import { useMediaQuery } from "react-responsive";
import { selectIsLocalHost } from "store/roomInfoSlice";
import { selectPeers } from "store/peerSlice";
import { selectGridCols, selectGridRows, setGridCols, setGridRows } from "store/settingSlice1";
import { withRoomContext } from "RoomContext";
import { AppData } from "types/commonTypes";
import {
  selectIsShowRecordingControl,
  selectIsFullScreen,
  selectIsShowScreenSharingHost,
  setIsShowMenuBar,
  setIsShowScreenSharingHost,
  setIsShowScreenSharingClient,
} from "store/windowControlSlice";
import { isMobile } from "react-device-detect";
import { selectEnv } from "store/envSlice";

interface HomeProps {
  roomClient: RoomClient;
  localVideoStream: MediaStream;
  screenShareStream: MediaStream | undefined;
  remoteHostVideoStream: MediaStream | undefined;
  remoteHostAppData: AppData;
  localAppData: AppData;
}

const HomeSplit: React.FC<HomeProps> = props => {
  const { roomClient, localVideoStream, screenShareStream, remoteHostVideoStream, remoteHostAppData, localAppData } = props;
  const [calcGridWidth, setCalcGridWidth] = useState("100%");
  const [calcGridHeight, setCalcGridHeight] = useState("100%");
  const isShowRecordingControl = useSelector(selectIsShowRecordingControl);
  const isShowScreenSharingHost = useSelector(selectIsShowScreenSharingHost);
  const isFullScreen = useSelector(selectIsFullScreen);
  const isLocalHost = useSelector(selectIsLocalHost);
  const gridCols = useSelector(selectGridCols);
  const gridRows = useSelector(selectGridRows);
  const peers = useSelector(selectPeers);
  const hostPeer = useHostPeer();
  const [gridParentRef, { width, height }] = useElementSize();
  const isLandscape = useMediaQuery({ orientation: "landscape" });
  const env = useSelector(selectEnv);
  const dispatch = useDispatch();

  const calcGridCols = useCallback(() => {
    const cols = Math.max(1, Math.ceil(Math.sqrt(peers.length)));
    const rows = Math.ceil(peers.length / cols);

    const cellWidthA = width / cols;
    const cellHeightA = height / rows;
    let gridWidthA = width;
    let gridHeightA = height;

    if (cellWidthA / cellHeightA < 1.6) {
      gridHeightA = Math.min(height, (cellWidthA / 1.6) * rows);
    } else {
      gridWidthA = Math.min(width, cellHeightA * 1.6 * cols);
    }

    const cellWidthB = width / rows;
    const cellHeightB = height / cols;
    let gridWidthB = width;
    let gridHeightB = height;

    if (cellWidthB / cellHeightB < 1.6) {
      gridHeightB = Math.min(height, (cellWidthB / 1.6) * cols);
    } else {
      gridWidthB = Math.min(width, cellHeightB * 1.6 * rows);
    }
    const areaA = gridWidthA * gridHeightA;
    const areaB = gridWidthB * gridHeightB;
    return areaA > areaB ? cols : rows; // 면적이 더 큰것을 선택

    // const ratioA = (cols * 1.6) / rows;
    // const ratioB = (rows * 1.6) / cols;
    // const parentRatio = width / height;
    // const diffA = Math.abs(parentRatio - ratioA);
    // const diffB = Math.abs(parentRatio - ratioB);
    // return diffA < diffB ? cols : rows;
  }, [height, peers.length, width]);

  useEffect(() => {
    const cols = calcGridCols();
    const rows = Math.ceil(peers.length / cols);

    const cellWidth = width / cols;
    const cellHeight = height / rows;

    if (cellWidth / cellHeight < 1.6) {
      const gridHeight = Math.min(height, (cellWidth / 1.6) * rows);
      setCalcGridHeight(`${gridHeight}px`);
      setCalcGridWidth("100%");
    } else {
      const gridWidth = Math.min(width, cellHeight * 1.6 * cols);
      setCalcGridWidth(`${gridWidth}px`);
      setCalcGridHeight("100%");
    }

    dispatch(setGridCols(cols));
    dispatch(setGridRows(rows));
  }, [width, height, isLandscape, peers.length, dispatch, calcGridCols]);

  useEffect(() => {
    const isScreen = screenShareStream !== undefined;
    console.log(`화면공유중인지 여부 판단. isLocalHost:${isLocalHost}, isScreen:${isScreen}`);

    dispatch(setIsShowScreenSharingHost(isLocalHost && isScreen));
    dispatch(setIsShowScreenSharingClient(!isLocalHost && isScreen));
  }, [dispatch, isLocalHost, screenShareStream]);

  const handleScreenShareVideoClose = () => {
    roomClient.closeScreenShare();
  };

  return (
    // 분할모드
    <>
      {isLandscape ? (
        <div ref={gridParentRef} className={"relative grow overflow-x-hidden h-full flex items-center justify-center"}>
          <StyledGrid cols={gridCols} width={calcGridWidth} height={calcGridHeight}>
            {hostPeer && (
              <ClientVideoView
                isRemote={!isLocalHost}
                isHost={true}
                producerId="removeHost"
                stream={isLocalHost ? localVideoStream : screenShareStream ?? remoteHostVideoStream}
                socketId={hostPeer.socketId}
                kind="video"
                userInfo={hostPeer.userInfo}
              />
            )}

            {!isLocalHost && (
              <ClientVideoView
                isRemote={false}
                isHost={false}
                producerId={"localVideo"}
                stream={localVideoStream}
                socketId={localAppData?.socketId}
                kind="video"
                userInfo={peers.find(p => p.socketId === localAppData?.socketId)?.userInfo}
              />
            )}
            <PeerVideoList localAppData={localAppData} includeDocHost={true} />
          </StyledGrid>

          {isFullScreen && !isMobile && (
            <div className="absolute bottom-0 flex w-[calc(100%-10px)] h-[4.75rem]" onMouseOver={() => dispatch(setIsShowMenuBar(true))} />
          )}
        </div>
      ) : (
        <div className="overflow-y-auto h-fit flex flex-col">
          {hostPeer && (
            <ClientVideoView
              isRemote={!isLocalHost}
              isHost={true}
              producerId="removeHost"
              stream={isLocalHost ? localVideoStream : screenShareStream ?? remoteHostVideoStream}
              socketId={hostPeer.socketId}
              kind="video"
              userInfo={hostPeer.userInfo}
            />
          )}

          {!isLocalHost && (
            <ClientVideoView
              isRemote={false}
              isHost={false}
              producerId="localVideo"
              stream={localVideoStream}
              socketId={localAppData?.socketId}
              kind="video"
              userInfo={peers.find(p => p.socketId === localAppData?.socketId)?.userInfo}
            />
          )}

          <PeerVideoList localAppData={localAppData} includeDocHost={true} />
        </div>
      )}

      {screenShareStream && !isLocalHost && hostPeer && (!isMobile || isLandscape) && (
        <SmallOverlayVideoView
          stream={remoteHostVideoStream}
          onClose={handleScreenShareVideoClose}
          picurl={hostPeer?.userInfo.picurl}
          socketId={remoteHostAppData?.socketId ? remoteHostAppData?.socketId : ""}
          peer={hostPeer}
        />
      )}

      {isShowScreenSharingHost && <ScreenShareController screenShareStream={screenShareStream} />}
      {isShowRecordingControl && <RecordingControl />}

      {env.NODE_ENV === "development" && (
        <div className="absolute top-0 left-0 right-0 text-center text-red-600">{`Grid cols:${gridCols}, row:${gridRows}, width:${width}, height:${height}, calcWidth:${calcGridWidth}, calcHeight:${calcGridHeight}`}</div>
      )}
    </>
  );
};

interface IGridProps {
  cols: number;
  width: string;
  height: string;
}

const StyledGrid = styled.div<IGridProps>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, minmax(0, 1fr));
  grid-auto-flow: row;
  grid-gap: 0rem;
  width: ${props => props.width};
  height: ${props => props.height};
  box-sizing: border-box;
  padding: 2px;

  @media screen and (max-width: 768px) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

export default withRoomContext(HomeSplit);
