import ProfileImg from "components/ProfileImg";
import { AuthUserInfo } from "types/commonTypes";
import { useTranslation } from "react-i18next";

interface IProps {
  m: AuthUserInfo;
  isHost: boolean;
}

const UserInfoRow = ({ m, isHost }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex h-full items-center">
      <div className="ml-1 w-9 h-9 shrink-0">
        <ProfileImg picurl={m?.picurl} borderWidth={1} />
      </div>

      <div className="flex h-full flex-col mx-1.5 text-sm whitespace-nowrap">
        <div className="flex items-center w-[10.4rem]">
          <div className="overflow-hidden flex">
            <span className={`inline-block overflow-hidden white-nowrap text-ellipsis font-bold ${isHost ? "max-w-[5.7rem]" : "max-w-[8.2rem]"}`}>
              {m?.username}
            </span>
            <span className="h-full ml-1 p-0 shrink-0">{m?.position}</span>
          </div>
          {isHost && (
            <span className="h-[1.062rem] text-[0.625rem] ml-2 bg-[#4495ff] rounded flex justify-center items-center px-1">{t("users.사회자")}</span>
          )}
        </div>
        <div className="text-xs">{m?.deptname}</div>
      </div>
    </div>
  );
};

export default UserInfoRow;
