import { ButtonProps } from "../interfaces/ButtonProps";

const DefaultTeamAvatar = ({ width = 44, height = 44, ...props }: ButtonProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width - 2} height={height - 2} viewBox="0 0 44 44" {...props}>
    <g fill="#fff" stroke="#dfe2e8">
      <circle cx="22" cy="22" r="22" stroke="none" />
      <circle cx="22" cy="22" r="21.5" fill="none" />
    </g>
    <g>
      <path transform="translate(10 10)" fill="none" d="M0 0h24v24H0z" />
      <g transform="translate(-84.715 -53.258)">
        <rect width="6" height="6" rx="1" transform="translate(103.715 65.258)" stroke="#9d9ea2" strokeWidth={2} fill="#9d9ea2" />
        <rect width="6" height="6" rx="1" transform="translate(96.714 79.257)" stroke="#9d9ea2" strokeWidth={2} fill="#9d9ea2" />
        <rect width="6" height="6" rx="1" transform="translate(110.714 79.257)" stroke="#9d9ea2" strokeWidth={2} fill="#9d9ea2" />
        <path d="M100.514 81.242s-.033-4 2.66-4h5.34" transform="translate(-.799 -1.983)" stroke="#9d9ea2" strokeWidth={2} fill="none" />
        <path d="M115.516 81.242s.034-4-2.66-4h-5.34" transform="translate(-1.803 -1.983)" stroke="#9d9ea2" strokeWidth={2} fill="none" />
        <path transform="translate(106.714 71.258)" stroke="#9d9ea2" strokeWidth={2} fill="none" d="M0 0v4" />
      </g>
    </g>
  </svg>
);

export default DefaultTeamAvatar;
