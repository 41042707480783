import { Listbox } from "@headlessui/react";
import { Value } from "./common/interfaces/TypeSelectListbox";
import { ReactComponent as IconArrowDown } from "assets/icon-arrow-down.svg";

interface Props {
  options: Value[];
  width?: number;
  optionsHeight?: number;
  value: Value;
  onChange: (value: Value) => void;
  isExpandUpper?: boolean;
}

const SelectListbox2 = ({ options, width, optionsHeight, value, isExpandUpper, onChange }: Props) => (
  <Listbox value={value} onChange={onChange}>
    {({ open }) => (
      <div className="flex flex-col" style={{ width: width ? `${width}rem` : "" }}>
        <Listbox.Button
          className={`${"border border-solid border-[#86878b] bg-[#555]"} h-8 rounded text-white pl-3 pr-2 text-sm flex flex-row items-center cursor-default ${
            open && "border-[#1f62b9]"
          }`}
        >
          <span className="block truncate grow text-left text-ellipsis overflow-hidden">{value.name}</span>
          <IconArrowDown className={`block flex-none mr-1 ${open && "rotate-180"}`} />
        </Listbox.Button>
        <Listbox.Options
          className={`z-10 text-[#555] text-sm cursor-default mt-1 p-1 rounded shadow-listbox-options bg-white overflow-auto relative ${
            isExpandUpper && "-translate-y-full"
          }`}
          style={{
            maxHeight: optionsHeight ? `${optionsHeight}rem` : "auto",
          }}
        >
          {options.map(option => (
            <Listbox.Option
              key={option.id}
              value={option}
              disabled={option.disabled}
              className={({ active }) => `h-7 px-2 leading-7 text-black text-ellipsis overflow-hidden ${active && "bg-[#f1f3f6]"}`}
            >
              {option.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    )}
  </Listbox>
);

SelectListbox2.defaultProps = {
  isExpandUpper: false,
};

export default SelectListbox2;
