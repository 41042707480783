import React, { useEffect, useRef, useState, useCallback, MouseEvent, TouchEvent } from "react";
import LoadingSimple from "components/LoadingSimple";
import cursorPointerImage from "assets/docsharing/cursor-pointer.svg";
import cursorPenImage from "assets/docsharing/cursor-pen.svg";
import cursorEraserImage from "assets/docsharing/cursor-eraser.svg";
import RoomClient from "RoomClient";
import { withRoomContext } from "RoomContext";
import { useSelector, useDispatch } from "react-redux";
import {
  selectHostPageInfo,
  selectZoomType,
  selectPenPath,
  selectIsShowColorPicker,
  selectLocalBgStartPos,
  selectHostCursorPos,
  selectRefreshTime,
  setIsShowColorPicker,
  selectEditMode,
  selectPenColor,
  selectBgDrawSize,
  setLocalBgStartPos,
  IDocSharingCurPos,
  setBgDrawSize,
  createPenPath,
  addPenPath,
  clearPenPath,
  clearAllPenPath,
  setEditMode,
} from "store/docSharingSlice";
import { selectIsLocalHost, selectRoomSetting } from "store/roomInfoSlice";
// import { selectRoomSetting } from "store/roomSettingSlice";
import { IPenPath, Line, Point } from "../docSharingType";
import { checkLinesIntersect } from "../lineIntersect";
import { useMeasure } from "react-use";
import { getFileNameFromUrl } from "lib/utils";
import { useMobileOrientation } from "react-device-detect";
import { usePinch } from "@use-gesture/react";
interface IProps {
  roomClient: RoomClient;
}

let startCoords: Point = { x: 0, y: 0 };

const DocSharingClientBoard = ({ roomClient }: IProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [cursor, setCursor] = useState("");
  const [ctx, setCtx] = useState<CanvasRenderingContext2D>();
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEraser, setIsEraser] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [canvasScale, setCanvasScale] = useState<number>(1.0);
  const [refreshTime, setRefreshTime] = useState(0);
  const [prevZoomType, setPrevZoomType] = useState("auto-fit");
  const [bgImg, setBgImg] = useState<HTMLImageElement>();
  const [position, setPosition] = useState<IDocSharingCurPos>({ x: 0, y: 0, mode: "CURSOR" });
  const [pinchZoom, setPinchZoom] = useState<number>(1.0);
  const [pinchOrigin, setPinchOrigin] = useState<Point>({ x: 0, y: 0 });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [rootRef, { width: parentWidth, height: parentHeight }] = useMeasure<HTMLDivElement>();
  const hostPageInfo = useSelector(selectHostPageInfo);
  const zoomType = useSelector(selectZoomType);
  const bgStartPos = useSelector(selectLocalBgStartPos);
  const penPath = useSelector(selectPenPath);
  const penColor = useSelector(selectPenColor);
  const isShowColorPicker = useSelector(selectIsShowColorPicker);
  const editMode = useSelector(selectEditMode);
  const docRefreshTime = useSelector(selectRefreshTime);
  const hostCursorPos = useSelector(selectHostCursorPos);
  const bgDrawSize = useSelector(selectBgDrawSize);
  const roomSetting = useSelector(selectRoomSetting);
  const isLocalHost = useSelector(selectIsLocalHost);
  const { isPortrait } = useMobileOrientation();
  const dispatch = useDispatch();

  const eraserSize = 20;
  const penWidth = 10;
  const halfWidthOfCursor = 15; // svg와 일치시킬것

  const bind = usePinch(state => {
    const {
      offset: [d], // [scale, angle] offsets (starts withs scale=1)
      origin,
      type,
    } = state;
    // console.log("pinch state", type, distance, angle);
    // console.log("pinch origin", origin, type);

    if(type !== "wheel") {
      if (type === "pointerdown") {
        console.log("pinch origin", origin);
        setPinchOrigin({ x: origin[0], y: origin[1] });
      }
      setPinchZoom(d);
    }
  });

  useEffect(() => {
    if (!isLocalHost && roomSetting.isWhiteBoardOnlyHost && editMode !== "CURSOR") {
      dispatch(setEditMode("CURSOR"));
    }
  }, [dispatch, editMode, isLocalHost, roomSetting.isWhiteBoardOnlyHost]);

  useEffect(() => {
    console.log("client view editMode", editMode, penColor);
  }, [editMode, penColor]);

  useEffect(() => {
    if (hostPageInfo) {
      const ratio = canvasScale / hostPageInfo.canvasScale;

      setPosition({
        x: (hostCursorPos.x - 20) * ratio + bgStartPos.x,
        y: (hostCursorPos.y - 20) * ratio + bgStartPos.y,
        mode: hostCursorPos.mode,
      });
    }
  }, [bgStartPos.x, bgStartPos.y, canvasScale, hostCursorPos, hostPageInfo]);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) setCtx(ctx);
    }
  }, [canvasRef]);

  useEffect(() => {
    if (editMode === "CURSOR") {
      setCursor(isMoving ? "move" : "auto");
    } else if (editMode === "POINTER") {
      setCursor(`url(${cursorPointerImage}) ${halfWidthOfCursor} ${halfWidthOfCursor}, auto`);
    } else if (editMode === "PEN") {
      setCursor(`url(${cursorPenImage}) 0 ${halfWidthOfCursor * 2 - 1}, auto`);
    } else if (editMode === "ERASER") {
      setCursor(`url(${cursorEraserImage}) ${halfWidthOfCursor} ${halfWidthOfCursor}, auto`);
    } else {
      setCursor("auto");
    }
  }, [editMode, isMoving]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const bg = new Image();

    bg.onload = () => {
      // console.log(`bg onload~~~~~~~~ width:${bg.width}, height:${bg.height}, zoomType:${zoomType}`);
      setBgImg(bg);
      setIsLoading(false);

      let scale = 1.0;

      if (zoomType === "auto-fit") {
        scale = Math.min(parentWidth / bg.width, parentHeight / bg.height);
      } else if (zoomType === "width-fit") {
        scale = parentWidth / bg.width;
      } else if (zoomType === "height-fit") {
        scale = parentHeight / bg.height;
      } else {
        scale = parseFloat(zoomType);
      }

      scale *= pinchZoom;
      setCanvasScale(scale);

      if (ctx && ctx && canvas) {
        const drawWidth = Math.trunc(bg.width * scale);
        const drawHeight = Math.trunc(bg.height * scale);

        const x = Math.trunc((canvas.width - drawWidth) / 2);
        const y = Math.trunc((canvas.height - drawHeight) / 2);

        dispatch(setBgDrawSize({ width: drawWidth, height: drawHeight, x, y }));
        dispatch(setLocalBgStartPos({ x, y }));
      }
    };

    console.log("bgImg.src", bgImg?.src);

    const isSameFile = getFileNameFromUrl(bgImg?.src ?? "") === getFileNameFromUrl(hostPageInfo ? hostPageInfo.url : "");
    if (!isSameFile || refreshTime !== docRefreshTime) {
      if (bgImg?.src) {
        console.log("clearAllPenPath");
        dispatch(clearAllPenPath());
        roomClient.sendDocSharingClearAllPenPath();
      }
      setIsLoading(true);
    }

    if (hostPageInfo?.url) {
      bg.src = hostPageInfo.url;
      bg.crossOrigin = "anonymous";
    }

    setRefreshTime(docRefreshTime);

    setPrevZoomType(zoomType);
  }, [
    ctx,
    zoomType,
    parentWidth,
    parentHeight,
    bgImg?.src,
    prevZoomType,
    dispatch,
    hostPageInfo,
    pinchZoom,
    refreshTime,
    docRefreshTime,
    roomClient,
  ]);

  const redrawingPenPath = useCallback(
    (path: IPenPath[]) => {
      const canvas = canvasRef.current;
      if (!canvas || !ctx) {
        return;
      }

      ctx.globalCompositeOperation = "source-over";
      ctx.lineCap = "round";

      try {
        for (let i = 0; i < path.length; i++) {
          ctx.strokeStyle = path[i].color;
          ctx.lineWidth = (path[i].width * canvasScale) / path[i].canvasScale;
          ctx.beginPath();

          const ratio = canvasScale / path[i].canvasScale;

          if (path[i].path.length > 1) {
            path[i].path.forEach(p => {
              ctx.moveTo(p.p1.x * ratio + bgStartPos.x, p.p1.y * ratio + bgStartPos.y);
              ctx.lineTo(p.p2.x * ratio + bgStartPos.x, p.p2.y * ratio + bgStartPos.y);
            });
          } else {
            ctx.arc(path[i].path[0].p1.x * ratio + bgStartPos.x, path[i].path[0].p1.y * ratio + bgStartPos.y, 1, 0, 1 * Math.PI, false);
          }

          ctx.stroke();
        }
      } catch (e) {
        console.error("redrawingPenPath error", e);
      }
    },
    [bgStartPos.x, bgStartPos.y, canvasScale, ctx],
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!ctx || !bgImg || !canvas) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(bgImg, 0, 0, bgImg.width, bgImg.height, bgStartPos.x, bgStartPos.y, bgDrawSize.width, bgDrawSize.height);
    redrawingPenPath(penPath);
  }, [bgDrawSize.height, bgDrawSize.width, bgImg, bgStartPos.x, bgStartPos.y, ctx, parentHeight, parentWidth, penPath, redrawingPenPath]);

  const handleTouchStart = (e: TouchEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    e.preventDefault();

    const x = e.touches[0].clientX - canvas.getBoundingClientRect().left;
    const y = e.touches[0].clientY - canvas.getBoundingClientRect().top;

    processMouseDown(x, y);
  };

  const handleMouseDown = (e: MouseEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    processMouseDown(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  };

  const processMouseDown = (startX: number, startY: number) => {
    if (isShowColorPicker) {
      dispatch(setIsShowColorPicker(false));
    }

    if (editMode === "CURSOR") {
      setIsMoving(true);
    } else if (editMode === "PEN") {
      setIsDrawing(true);

      const newPenPath = {
        width: penWidth,
        color: penColor,
        path: [
          {
            p1: { x: startX - bgStartPos.x, y: startY - bgStartPos.y },
            p2: { x: startX - bgStartPos.x, y: startY - bgStartPos.y },
          },
        ],
        canvasScale,
      };

      dispatch(createPenPath(newPenPath));
      roomClient.sendDocSharingCreatePenPath(newPenPath);
    } else if (editMode === "ERASER") {
      setIsEraser(true);
      erasePenPath(startX, startY);
    } else return;

    startCoords = { x: startX, y: startY };
  };

  const handleTouchMove = (e: TouchEvent<HTMLCanvasElement>) => {
    if (!ctx) return;
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (e.touches.length > 1) {
      return;
    }

    const endX = e.touches[0].clientX - canvas.getBoundingClientRect().left;
    const endY = e.touches[0].clientY - canvas.getBoundingClientRect().top;

    processMouseMove(endX, endY);
  };

  const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
    if (!ctx) return;
    e.preventDefault();

    const endX = e.nativeEvent.offsetX;
    const endY = e.nativeEvent.offsetY;

    processMouseMove(endX, endY);
  };

  const processMouseMove = (endX: number, endY: number) => {
    if (editMode === "POINTER") {
      roomClient.sendDocSharingCursorPos(endX - bgStartPos.x, endY - bgStartPos.y, editMode);
    } else if (isMoving) {
      const dx = endX - startCoords.x;
      const dy = endY - startCoords.y;

      dispatch(setLocalBgStartPos({ x: bgStartPos.x + dx, y: bgStartPos.y + dy }));
    } else if (isDrawing) {
      const newPath: Line = {
        p1: { x: startCoords.x - bgStartPos.x, y: startCoords.y - bgStartPos.y },
        p2: { x: endX - bgStartPos.x, y: endY - bgStartPos.y },
      };

      dispatch(addPenPath(newPath));
      roomClient.sendDocSharingAddPenPath(newPath);
    } else if (isEraser) {
      erasePenPath(endX, endY);
    }

    startCoords = { x: endX, y: endY };
  };

  const handleMouseUp = () => {
    if (isMoving) {
      dispatch(setBgDrawSize({ ...bgDrawSize, x: bgStartPos.x, y: bgStartPos.y }));
    }
    setIsMoving(false);
    setIsDrawing(false);
    setIsEraser(false);
  };

  const handleTouchEnd = (e: TouchEvent<HTMLCanvasElement>) => {
    handleMouseUp();
  };

  const erasePenPath = (offsetX: number, offsetY: number) => {
    const x = offsetX - bgStartPos.x;
    const y = offsetY - bgStartPos.y;

    penPath.forEach((path, idx) => {
      const ratio = canvasScale / penPath[idx].canvasScale;

      path.path.forEach(line => {
        const isCrose = checkLinesIntersect(
          { p1: { x: line.p1.x * ratio, y: line.p1.y * ratio }, p2: { x: line.p2.x * ratio, y: line.p2.y * ratio } },
          { p1: { x: startCoords.x - bgStartPos.x, y: startCoords.y - bgStartPos.y }, p2: { x, y } },
        );

        if (isCrose) {
          dispatch(clearPenPath(idx));
          roomClient.sendDocSharingClearPenPath(idx);
          return;
        } else if (line.p1.x === line.p2.x && line.p1.y === line.p2.y) {
          const distance = Math.sqrt((line.p1.x * ratio - x) ** 2 + (line.p1.y * ratio - y) ** 2);
          if (distance < (eraserSize * ratio) / 2) {
            dispatch(clearPenPath(idx));
            roomClient.sendDocSharingClearPenPath(idx);
          }
        }
      });
    });
  };

  return (
    <div
      ref={rootRef}
      className="relative w-full h-full flex justify-center items-center bg-[#222222] border-0 border-yellow-300 overflow-hidden z-20"
      style={{ touchAction: "none" }}
    >
      <div ref={divRef} className="w-fit h-fit">
        <canvas
          {...bind()}
          ref={canvasRef}
          width={parentWidth - 4}
          height={isPortrait && bgImg ? parentWidth * (bgImg.height / bgImg.width) - 4 : bgImg ? parentHeight - 4 : parentWidth * 0.7 - 4}
          style={{ cursor: cursor }}
          className="border-0 border-red-400"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchEnd}
        />
      </div>
      {isLoading && (
        <div className="absolute inset-0 mx-auto my-auto w-[6rem] h-[6rem] bg-[#0007] flex justify-center items-center p-4 rounded-3xl">
          <LoadingSimple />
        </div>
      )}

      {hostCursorPos.mode === "POINTER" && (
        <div
          className="absolute top-0 left-0 w-10 h-10 bg-[#f00] rounded-full"
          style={{ transform: `translate(${position.x}px, ${position.y}px)` }}
        />
      )}

      {process.env.NODE_ENV === "development" && (
        <>
          <div className="absolute top-5 left-5 text-base text-red-400 select-none">Canvas Scale: {canvasScale.toFixed(2)}</div>
          <div className="absolute left-20 w-fit h-20 bg-red-400 z-[999] p-4 flex justify-center items-center rounded border-4 border-green-400">
            Client Board
          </div>
        </>
      )}
    </div>
  );
};

export default withRoomContext(DocSharingClientBoard);
