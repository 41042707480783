import { useState } from "react";
import ScreenShareSmallPreview from "./ScreenShareSmallPreview";
import CloseButton from "components/common/buttons/CloseButton";
import RoomClient from "RoomClient";
import { ReactComponent as IconChangeScreen } from "assets/btn-change-screen.svg";
import { withRoomContext } from "RoomContext";
import { useTranslation } from "react-i18next";

interface IProps {
  screenShareStream: MediaStream | undefined;
  roomClient: RoomClient;
}

const ScreenShareController = (props: IProps) => {
  const { screenShareStream, roomClient } = props;
  const { t } = useTranslation();
  const [isHoveredChangeScreenShare, setIsHoveredChangeScreenShare] = useState(false);

  const handleScreenShare = () => {
    roomClient.closeScreenShare();
    roomClient.screenShare();
  };

  return (
    <div className="absolute z-50 top-6 left-0 right-0 mx-auto bg-black/70 p-4 w-fit text-white  rounded-md shadow shadow-[#000] border-0 border-[#555]">
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <div className="border border-[#555] h-8 mr-2 w-[3rem]">
            <ScreenShareSmallPreview stream={screenShareStream} />
          </div>

          <IconChangeScreen
            onMouseEnter={() => setIsHoveredChangeScreenShare(true)}
            onMouseLeave={() => setIsHoveredChangeScreenShare(false)}
            fill={isHoveredChangeScreenShare ? "#4495ff" : "#fff"}
            stroke={isHoveredChangeScreenShare ? "#4495ff" : "#fff"}
            className="cursor-pointer"
            onClick={handleScreenShare}
          />

          <div className="w-[0.1rem] h-[1.875rem] bg-white mx-[0.5rem] rounded"></div>
          <CloseButton width={30} height={30} classNameSVG="stroke-C-ffffff hover:stroke-C-1f62b9" onClick={() => roomClient.closeScreenShare()} />
        </div>
        <div className="mt-2 text-sm">
          <div className="text-[#fff]">{t("화면 공유 중")}</div>
        </div>
      </div>
    </div>
  );
};

export default withRoomContext(ScreenShareController);
