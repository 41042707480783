import DialogAssigneeV6 from "components/common/dialogsV6/DialogAssignee";
import DialogAssigneeV5 from "components/common/dialogsV6/DialogAssignee";
import { TreeDataNode } from "components/common/interfaces/OrgData";
import { Modal } from "components/Modal";
import { selectEnv } from "store/envSlice";
import { useSelector } from "react-redux";

interface IProps {
  onClose: () => void;
  onResult: (data: TreeDataNode[]) => void;
}

const AddUserPopup = ({ onClose, onResult }: IProps) => {
  const env = useSelector(selectEnv);

  return (
    <Modal isOpen={true}>
      <div className="dark">
        {env.UCWORKS_VER === "5" ? (
          <DialogAssigneeV5 isOpen={true} setIsOpen={onClose} onResult={onResult} />
        ) : (
          <DialogAssigneeV6 isOpen={true} setIsOpen={onClose} onResult={onResult} />
        )}
      </div>
    </Modal>
  );
};

export default AddUserPopup;
