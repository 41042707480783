import { Producer } from "mediasoup-client/lib/types";
// import { MyConsumer } from './../types/types';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

interface State {
  array: any[];
}

const initialState: State = {
  array: [],
};

export const producerSlice = createSlice({
  name: "producer",
  initialState,
  reducers: {
    addProducer: (state: State, action: PayloadAction<Producer>) => {
      state.array.push(action.payload);
    },
    removeProducer: (state: State, action: PayloadAction<string>) => {
      state.array = state.array.filter(item => item.id !== action.payload);
    },
  },
});

export const { addProducer, removeProducer } = producerSlice.actions;
export const selectProducers = (state: RootState): Producer[] => state.producer.array;

export default producerSlice.reducer;
