import { ButtonProps } from "../interfaces/ButtonProps";

const AddTeamButton = ({ onClick, className, ...props }: ButtonProps) => {
  return (
    <button {...props} onClick={onClick} className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="0 0 32 28">
        <path d="M0 0h32v28H0z" fill="none" />
        <g>
          <path transform="translate(9 14)" fill="none" strokeLinecap="round" strokeWidth={2} stroke="#9d9ea2" d="M0 0h14" />
          <path transform="translate(16.412 7)" fill="none" strokeLinecap="round" strokeWidth={2} stroke="#9d9ea2" d="M0 0v14" />
        </g>
      </svg>
    </button>
  );
};

export default AddTeamButton;
