import React, { CSSProperties, forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface IProps {
  videoClassName: string;
  videoStyle: CSSProperties;
  id: string;
  isEnable: boolean;
  refreshTrigger: boolean;
}

const BigVideoView = forwardRef<HTMLVideoElement, IProps>((props, ref) => {
  const { videoClassName, videoStyle, id, isEnable } = props;
  const [sectionWidth, setSectionWidth] = useState(0);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rootRef.current) {
      setSectionWidth(Math.ceil(rootRef.current.clientHeight * 1.6));
    }
  }, [rootRef, rootRef?.current?.clientHeight, props.isEnable]);

  return (
    <>
      {isEnable ? (
        <video
          ref={ref}
          className={videoClassName}
          style={videoStyle}
          id={id}
          autoPlay
          muted
          playsInline
          disablePictureInPicture
          crossOrigin="anonymous"
        />
      ) : (
        <StyledNoCamDiv ref={rootRef} className={`${props.videoClassName}`} width={sectionWidth} />
      )}
    </>
  );
});

const StyledNoCamDiv = styled.div<{ width: number }>`
  display: flex;
  width: ${props => `${props.width}px`};
  max-width: ${props => `${props.width}px`};
  min-height: 200px;

  @media screen and (max-width: 1024px) and (orientation: portrait) {
    width: 100vw;
    min-height: 200px;
  }
`;

export default React.memo(BigVideoView);
