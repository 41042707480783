import React, { useEffect, useState } from "react";
import DocSharingHostVideoView from "components/docSharing/hostvideo/DocSharingHostVideoView";
import DocSharingClientBoard from "components/docSharing/board/DocSharingClientBoard";
import DocSharingTool from "components/docSharing/tool/DocSharingTool";
import PipOnOffButton from "components/common/buttons/PipOnOffButton";
import { useSelector } from "react-redux";
import { selectBgDrawSize } from "store/docSharingSlice";
import { useMobileOrientation, isMobile } from "react-device-detect";
import { useMeasure } from "react-use";

const DocSharingClientViewRoot = () => {
  const [pipBtnStyle, setPipBtnStyle] = useState({});
  const img = useSelector(selectBgDrawSize);
  const { isPortrait } = useMobileOrientation();
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    const divStyle = {};
    const imgRight = img.x + img.width;
    const imgBottom = img.y + img.height;

    if (imgRight < width) {
      Object.assign(divStyle, { left: imgRight - 50 });
    }

    if (imgBottom < height) {
      Object.assign(divStyle, { top: imgBottom - 50 });
    }

    setPipBtnStyle(divStyle);
  }, [img, width, height]);

  return (
    <>
      {isMobile ? (
        <div ref={ref} className="relative w-full h-full flex flex-col justify-center overflow-hidden bg-[#333] border-0 border-yellow-400">
          <DocSharingClientBoard />

          {!isPortrait && <DocSharingHostVideoView />}

          {!isPortrait && <PipOnOffButton divStyle={pipBtnStyle} />}
        </div>
      ) : (
        <div ref={ref} className="relative h-full flex flex-1 justify-center items-center overflow-hidden bg-[#333] border-0 border-yellow-400">
          <DocSharingHostVideoView />
          <div className="absolute top-2 z-30 w-fit">
            <DocSharingTool isHost={false} />
          </div>
          <DocSharingClientBoard />

          <PipOnOffButton divStyle={pipBtnStyle} />
        </div>
      )}
    </>
  );
};

export default DocSharingClientViewRoot;
