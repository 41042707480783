import { useCallback, useEffect, useState } from "react";
import qs from "qs";
import HomeRoot from "./HomeRoot";
import RoomClient from "RoomClient";
import JoinPopupWithVideo from "pages/JoinPopupWithVideo";
import WaitingRoom from "./WaitingRoom";
import AccessDenied from "./AccessDenied";
import AuthApi from "api/common/API";
import NotSupportedBrowser from "./NotSupportedBrowser";
import i18next from "../translate/i18n";
import LoadingSimple from "components/LoadingSimple";
import { toast } from "react-toastify";
import { withRoomContext } from "RoomContext";
import { parseToken } from "lib/vcTokenUtils";
import { getClientEnv } from "api/server/clientEnv";
import { store } from "store/store";
import { setEnv } from "store/envSlice";
import {
  selectRoomState,
  setRoomInfo,
  selectRoomId,
  setRoomId,
  setLocalUserInfo,
  selectRoomInfo,
  selectIsLocalHost,
  setLayoutType,
  LayoutType,
  setTenantId,
  setRoomSetting,
} from "store/roomInfoSlice";
import { setWaitingInfos } from "store/waitingRoomSlice";
import { setAuthUserInfo, selectAuthUserInfo, setToken, setBid, setSid } from "../store/authSlice";
import { setIsKtccTest } from "store/roomInfoSlice";
import { AuthUserInfo } from "types/commonTypes";
import { authenticate } from "api/common/cert";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSupportedBrowser } from "hooks/useSupportedBrowser";
import { useGlobalPayload } from "components/common/hooks/useGlobalState";
import { useTitle } from "hooks/useTitle";
import { getRoomDetail, IRoomDetailRes } from "api/manager/room";
import { getFullLanguageCode, getTenantIdFromJwt, getIssuerFromJwt } from "lib/utils";
import { options_lang } from "components/setting/Setting";
import { browserName } from "react-device-detect";
import { axiosInstance } from "components/common/api/request";

interface IntroProps {
  roomClient: RoomClient;
}

function StartPage(props: IntroProps) {
  const { roomClient } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isEnvLoading, setIsEnvLoading] = useState(true);
  const [isShowWaitingRoom, setIsShowWaitingRoom] = useState<boolean>(false);
  const [isShowAccessDenied, setIsShowAccessDenied] = useState(false);
  const [isShowJoinDialog, setIsShowJoinDialog] = useState(true);
  const [, setGlobalPayload] = useGlobalPayload();
  const changeTitle = useTitle("");
  const roomState = useSelector(selectRoomState);
  const roomId = useSelector(selectRoomId);
  const roomInfo = useSelector(selectRoomInfo);
  const userInfo = useSelector(selectAuthUserInfo);
  const isHost = useSelector(selectIsLocalHost);
  const { isBrowserChecked, isSupportedBrowser } = useSupportedBrowser();
  const location = useLocation();
  const dispatch = useDispatch();

  const getEnv = useCallback(
    async (tenantId: string, issuer: string) => {
      if (!store.getState().env.value.SERVER_URL) {
        let res;
        res = await getClientEnv(tenantId, issuer);

        dispatch(setEnv(res));
      }
      setIsEnvLoading(false);
    },
    [dispatch],
  );

  const getRoomInfoFromMgrServer = useCallback(
    async (roomId: number) => {
      const { result, data } = (await getRoomDetail(roomId)) as IRoomDetailRes;
      if (result) {
        console.log("getRoomInfoFromMgrServer", data);
        dispatch(setRoomInfo({ ...data.info }));
        dispatch(setRoomSetting({ ...data.setting, isSelfMicOn: true, isSttEnable: false }));
        setIsLoading(false);
      }
    },
    [dispatch],
  );

  const getUserInfo = useCallback(
    async (bid: string): Promise<AuthUserInfo | null> => {
      const res = await AuthApi.getUserInfo(bid);
      const user: AuthUserInfo = {
        bid: res.bid,
        username: res.username,
        deptname: res.deptname,
        position: res.position,
        picurl: res.picurl,
        language: res?.language,
        gid: res?.gid,
        rootgid: res?.rootgid,
      };

      dispatch(setAuthUserInfo(user));
      return user;
    },
    [dispatch],
  );

  const procInternalAttendee = useCallback(
    async (query: qs.ParsedQs) => {
      if (query.isKtcc === "true") {
        dispatch(setIsKtccTest(true));
        dispatch(setLayoutType(LayoutType.Split));
      }

      const token = query.token as string;
      const tenantId = getTenantIdFromJwt(token);
      const issuer = getIssuerFromJwt(token);

      dispatch(setTenantId(tenantId));
      console.log("StartPage tenantId", tenantId);
      console.log("StartPage issuer", issuer);
      await getEnv(tenantId, issuer);

      authenticate(token)
        .then(async uinfo => {
          const roomId = Number.parseInt(window.atob(query.id as string));

          dispatch(setToken(token));
          dispatch(setRoomId(roomId));
          dispatch(setBid(uinfo.bid));
          dispatch(setSid(uinfo.sid));

          axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

          const userInfo = await getUserInfo(uinfo.bid);
          setGlobalPayload({ sid: uinfo.sid, bid: uinfo.bid, gid: userInfo?.gid, rootGid: userInfo?.rootgid });
          await getRoomInfoFromMgrServer(roomId);
        })
        .catch(error => {
          console.error("startPage procInternalAttendee error", error);
          setIsShowAccessDenied(true);
        });
    },
    [dispatch, getEnv, getRoomInfoFromMgrServer, getUserInfo, setGlobalPayload],
  );

  const procExternalAttendee = useCallback(
    async (vctoken: string, id: number) => {
      console.log(vctoken);
      const { email, name, tenantId } = await parseToken(vctoken as string);

      if (id && email && name) {
        console.log(`외부참여자 접속. email:${email}, name:${name}, tenantId:${tenantId}`);

        const user: AuthUserInfo = {
          bid: email,
          username: name,
          deptname: "",
          position: "",
          picurl: "",
        };

        roomClient.isInternalUser = false;
        dispatch(setAuthUserInfo(user));
        dispatch(setToken(vctoken));
        dispatch(setRoomId(id));
        dispatch(setBid(email));
        dispatch(setTenantId(tenantId));

        await getEnv(tenantId, "");
        await getRoomInfoFromMgrServer(id);
      }
    },
    [dispatch, getEnv, getRoomInfoFromMgrServer, roomClient],
  );

  const start = useCallback(async () => {
    console.log("StartPage start");
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    console.log("StartPage start query", query);

    const jwt = query.token as string;

    const lang = query.lang as string;
    if (lang) {
      console.log("StartPage lang", lang);
      const uiLanguageId = getFullLanguageCode(lang);
      if (uiLanguageId) {
        localStorage.setItem("language", uiLanguageId);
        const findLang = options_lang.find(item => uiLanguageId && item.id.startsWith(uiLanguageId));
        if (findLang) {
          i18next.changeLanguage(findLang.id);
        }
      }
    }

    if (jwt && query.id) {
      procInternalAttendee(query);
    } else if (query.vctoken && query.id) {
      const roomId = Number.parseInt(window.atob(query.id as string));
      await procExternalAttendee(query.vctoken as string, roomId);
    } else setIsShowAccessDenied(true);
  }, [location.search, procInternalAttendee, procExternalAttendee]);

  useEffect(() => {
    changeTitle(t("title"));
  }, [changeTitle, t]);

  useEffect(() => {
    if (isSupportedBrowser) start();
    else {
      console.log("StartPage isSupportedBrowser false", browserName);
    }
  }, [start, isSupportedBrowser]);

  useEffect(() => {
    if (roomState === "closed") {
      // window.location.href = '/';
    }
    if (roomState === "waiting") setIsShowWaitingRoom(true);

    if (roomState === "connected") {
      roomClient.sendGetWaitingList(roomId, (callback: { list: AuthUserInfo[] }) => {
        dispatch(setWaitingInfos(callback.list));
      });
      setIsShowWaitingRoom(false);
    }
  }, [dispatch, roomClient, roomId, roomState]);

  const join = async () => {
    if (userInfo && roomId) {
      dispatch(setLocalUserInfo(userInfo));

      await roomClient.init(roomId, userInfo, roomInfo.creator);
      await roomClient.join();
      setIsShowJoinDialog(false);
    } else {
      toast.error(t("msg.유효하지 않은 회의"));
    }
  };

  if (isShowAccessDenied) {
    return <AccessDenied />;
  }

  if (isBrowserChecked && !isSupportedBrowser) {
    return <NotSupportedBrowser />;
  }

  if (!isLoading && isShowJoinDialog) {
    return <JoinPopupWithVideo callback={join} />;
  }

  if (isLoading || isEnvLoading) {
    return <LoadingSimple isShowLabel={true} />;
  }

  return <div className="w-full h-full">{isShowWaitingRoom && !isHost ? <WaitingRoom /> : <HomeRoot isHost={isHost} />}</div>;
}

export default withRoomContext(StartPage);
