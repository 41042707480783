import SearchBox from "./SearchBox";
import SelectedBox from "./SelectedBox";
import { TreeDataNode } from "../interfaces/OrgData";
import { Key } from "rc-tree/lib/interface";

interface SearchSelectedBoxProps {
  selectedMember: TreeDataNode[];
  setSelectedMember: Function;
  checkedKeys: Key[];
  setCheckedKeys: Function;
}

const SearchSelectedBox = ({ selectedMember, setSelectedMember, checkedKeys, setCheckedKeys }: SearchSelectedBoxProps) => (
  <div className="flex flex-col md:flex-row md:gap-x-4 h-full">
    <SearchBox setSelectedMember={setSelectedMember} className="flex-auto" checkedKeys={checkedKeys} setCheckedKeys={setCheckedKeys} />
    <SelectedBox
      selectedMember={selectedMember}
      setSelectedMember={setSelectedMember}
      setCheckedKeys={setCheckedKeys}
      className="mt-3 md:mt-0 flex-auto"
    />
  </div>
);

export default SearchSelectedBox;
