import { useState } from "react";

interface ITooltip {
  delay?: number;
  children?: React.ReactNode;
  content: string;
  width?: number;
  classNameTooltip?: string;
  isCenter?: boolean;
}
type TimeType = NodeJS.Timeout;

const Tooltip = (props: ITooltip) => {
  let timeout: TimeType;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);

    timeout = setTimeout(() => {
      setActive(false);
    }, props.delay || 400);
  };

  if (!props.content) return <>{props.children}</>;

  return (
    <div
      className={`inline-block relative ${props.classNameTooltip ?? ""}`}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      style={{
        width: props.width ? `${props.width / 16}rem` : "",
        height: props.width ? `${props.width / 16}rem` : "",
      }}
    >
      {props.children}
      {active && (
        <div
          className={`absolute rounded p-2 text-white bg-[#333] text-sm leading-none z-40 whitespace-nowrap shadow ${
            props.isCenter && "left-1/2 -translate-x-1/2"
          }`}
        >
          <div className="block">
            {props.content.split("\n").map((line, idx) => (
              <span key={idx}>
                {line}
                <br />
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
