export interface IProps {
  width?: number;
  height?: number;
  className?: string;
  stroke?: string;
}

const ButtonMicOnLarge = (props: IProps) => {
  const { width = 36, height = 36, className, stroke = "#fff" } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36 36" className={className}>
      <g transform="translate(-2.26 -5.192)">
        <circle
          cx={18}
          cy={18}
          r={18}
          transform="translate(2.26 5.192)"
          style={{
            fill: "rgba(0,0,0,.75)",
          }}
        />
        <g transform="translate(538.575 -224.925)">
          <rect
            data-name="사각형 249"
            width={10}
            height={15.754}
            rx={5}
            transform="translate(-523.315 235.116)"
            style={{
              fill: "none",
              stroke,
              strokeWidth: 2,
            }}
          />
          <path
            data-name="선 89"
            transform="translate(-518.315 254.379)"
            style={{
              fill: "none",
              stroke,
              strokeWidth: 2,
            }}
            d="M0 0v6.337"
          />
          <path
            data-name="선 90"
            transform="translate(-524.315 260.716)"
            style={{
              strokeLinecap: "round",
              fill: "none",
              stroke,
              strokeWidth: 2,
            }}
            d="M0 0h11.939"
          />
          <path
            data-name="패스 550"
            d="M-510.815 240.648c0 3.807-3.583 6.892-8 6.892s-8-3.085-8-6.892"
            transform="translate(.5 7.268)"
            style={{
              strokeLinecap: "round",
              fill: "none",
              stroke,
              strokeWidth: 2,
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default ButtonMicOnLarge;
