import { useTranslation } from "react-i18next";

const HostNotConnected = () => {
  const { t } = useTranslation();
  return (
    <div className="grow flex justify-center items-center bg-[#222]">
      <div className="z-20 text-white m-1 p-4 rounded flex justify-center text-xl font-bold shadow-lg bg-[#333] select-none">
        {t("home.사회자 미접속")}
      </div>
    </div>
  );
};

export default HostNotConnected;
