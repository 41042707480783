import { IInnerAttendee, IOuterAttendee } from "./../api/manager/invite";
import { AuthUserInfo, IRoomDto, RoomSetting } from "../types/commonTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

type RoomState = "connecting" | "waiting" | "connected" | "closed" | "rejected" | "disconnected" | "duplicate";

export enum LayoutType {
  Split = 0,
  Highlight,
}

interface State {
  curState: RoomState;
  isLocalHost: boolean;
  localUserInfo: AuthUserInfo;
  localStream: MediaStream | null;
  layoutType: LayoutType;
  info: IRoomDto;
  setting: RoomSetting;
  startTime: Date;
  hostBid: string;
  isHostMode: boolean;
  isKtccTest: boolean;
  isSttTranslation: boolean;
  tenantId: string;
  activeSpeaker: string;
}

const initialState: State = {
  curState: "closed",
  isLocalHost: false,
  localUserInfo: { bid: "", username: "", position: "", deptname: "", picurl: "" },
  localStream: null,
  layoutType: LayoutType.Highlight,
  startTime: new Date(),
  hostBid: "",
  isHostMode: false,
  isKtccTest: false,
  isSttTranslation: false,
  info: {
    id: 0,
    accessCode: "",
    title: "",
    creator: "",
    innerAttendees: [],
    outerAttendees: [],
  },
  setting: {
    isPrivate: false,
    password: "",
    isMicOnStartUp: false,
    isCamOnStartUp: false,
    isSelfMicOn: false,
    hasWaitingRoom: true,
    isSttEnable: false,
    isAutoSaveNote: false,
    isDocSharingOnlyHost: false,
    isWhiteBoardOnlyHost: false,
  },
  tenantId: "",
  activeSpeaker: "",
};

export const roomInfoSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    setRoomInfo: (state: State, action: PayloadAction<IRoomDto>) => {
      // if (action.payload.setting.isAutoSaveNote) action.payload.setting.isSttEnable = true;
      state.info = action.payload;
      // console.log(`### store setRoomInfo. setting:${state.info.setting}`, action.payload);
    },
    setRoomState: (state: State, action: PayloadAction<RoomState>) => {
      state.curState = action.payload;
    },
    setRoomId: (state: State, action: PayloadAction<number>) => {
      state.info.id = action.payload;
    },
    setRoomTitle: (state: State, action: PayloadAction<string>) => {
      state.info.title = action.payload;
    },
    setLocalUserInfo: (state: State, action: PayloadAction<AuthUserInfo>) => {
      state.localUserInfo = action.payload;
    },
    setLocalStream: (state: State, action: PayloadAction<MediaStream>) => {
      state.localStream = action.payload;
    },
    setLayoutType: (state: State, action: PayloadAction<LayoutType>) => {
      state.layoutType = action.payload;
    },
    setStartTime: (state: State, action: PayloadAction<Date>) => {
      state.startTime = action.payload;
    },
    setHostBid: (state: State, action: PayloadAction<string>) => {
      // console.log(`store 사회자 변경전:[${state.hostBid}], 변경:[${action.payload}]`);
      state.hostBid = action.payload;
      state.isLocalHost = action.payload.length > 0 && state.localUserInfo.bid === action.payload;
      state.isHostMode = action.payload.length > 0;
      // console.log(`store isHost:${state.isHost}, userBid:${state.userInfo.bid}, hostBid:${state.hostBid}`);
    },
    setIsLocalHost: (state: State, action: PayloadAction<boolean>) => {
      state.isLocalHost = action.payload;
    },
    setIsKtccTest: (state: State, action: PayloadAction<boolean>) => {
      state.isKtccTest = action.payload;
    },

    setIsSttTranslation: (state: State, action: PayloadAction<boolean>) => {
      state.isSttTranslation = action.payload;
      localStorage.setItem("isSttTranslation", action.payload.toString());
    },

    setInnerAttendees: (state: State, action: PayloadAction<IInnerAttendee[]>) => {
      state.info.innerAttendees = [...action.payload];
    },
    setOuterAttendees: (state: State, action: PayloadAction<IOuterAttendee[]>) => {
      state.info.outerAttendees = [...action.payload];
    },
    setTenantId: (state: State, action: PayloadAction<string>) => {
      state.tenantId = action.payload;
    },
    setActiveSpeaker: (state: State, action: PayloadAction<string>) => {
      state.activeSpeaker = action.payload;
    },
    setRoomSetting: (state: State, action: PayloadAction<RoomSetting>) => {
      if (action.payload.isAutoSaveNote) action.payload.isSttEnable = true;
      state.setting = action.payload;
      // console.log(`### store setRoomSetting. setting:${state.setting}`, action.payload);
    },
    setHasWaitingRoom: (state: State, action: PayloadAction<boolean>) => {
      state.setting.hasWaitingRoom = action.payload;
    },
    setIsPrivate: (state: State, action: PayloadAction<boolean>) => {
      state.setting.isPrivate = action.payload;
    },
    setPassword: (state: State, action: PayloadAction<string>) => {
      state.setting.password = action.payload;
    },
    setIsSttEnable: (state: State, action: PayloadAction<boolean>) => {
      state.setting.isSttEnable = action.payload;
    },
    setIsCamOn: (state: State, action: PayloadAction<boolean>) => {
      state.setting = { ...state.setting, isCamOnStartUp: action.payload };
    },
  },
});

export const {
  setRoomInfo,
  setRoomState,
  setRoomId,
  setRoomTitle,
  setLocalStream,
  setLayoutType,
  setLocalUserInfo,
  setStartTime,
  setHostBid,
  setIsLocalHost,
  setIsKtccTest,
  setIsSttTranslation,
  setInnerAttendees,
  setOuterAttendees,
  setTenantId,
  setActiveSpeaker,
  setRoomSetting,
  setHasWaitingRoom,
  setIsPrivate,
  setPassword,
  setIsSttEnable,
  setIsCamOn,
} = roomInfoSlice.actions;

export const selectRoomState = (state: RootState): RoomState => state.room.curState;
export const selectRoomInfo = (state: RootState) => state.room.info;
export const selectRoomId = (state: RootState): number => state.room.info.id;
export const selectRoomTitle = (state: RootState): string => state.room.info.title;
export const selectUserInfo = (state: RootState): AuthUserInfo => state.room.localUserInfo;
export const selectUserName = (state: RootState): string => state.room.localUserInfo.username;
export const selectLayoutType = (state: RootState): LayoutType => state.room.layoutType;
export const selectIsLocalHost = (state: RootState): boolean => state.room.isLocalHost;
export const selectLocalStream = (state: RootState) => state.room.localStream;
export const selectStartTime = (state: RootState) => state.room.startTime;
export const selectHostBid = (state: RootState) => state.room.hostBid;
export const selectIsHostMode = (state: RootState) => state.room.isHostMode;
export const selectIsKtccTest = (state: RootState) => state.room.isKtccTest;
export const selectIsSttTranslation = (state: RootState) => state.room.isSttTranslation;
export const selectInnerAttendees = (state: RootState) => state.room.info.innerAttendees;
export const selectOuterAttendees = (state: RootState) => state.room.info.outerAttendees;
export const selectTenantId = (state: RootState) => state.room.tenantId;
export const selectActiveSpeaker = (state: RootState) => state.room.activeSpeaker;
export const selectRoomSetting = (state: RootState) => state.room.setting;
export const selectHasWaitingRoom = (state: RootState) => state.room.setting.hasWaitingRoom;
export const selectIsPrivate = (state: RootState) => state.room.setting.isPrivate;
export const selectPassword = (state: RootState) => state.room.setting.password;
export const selectIsSttEnable = (state: RootState) => state.room.setting.isSttEnable;
export const selectIsCamOn = (state: RootState) => state.room.setting.isCamOnStartUp;

export default roomInfoSlice.reducer;
