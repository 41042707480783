import { MyConsumer } from "../types/clientTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";

type ConsumersState = {
  array: any[];
};

export interface ChangeNameProp {
  socketId: string;
  name: string;
}

const initialState: ConsumersState = {
  array: [],
};

export interface IConsumerPauseParam {
  socketId: string;
  kind: "video" | "audio";
  source: "mic" | "webcam" | "screen" | "screen_audio";
}

export const consumerSlice = createSlice({
  name: "consumer",
  initialState,
  reducers: {
    addConsumer: (state: ConsumersState, action: PayloadAction<MyConsumer>) => {
      state.array.push(action.payload);
    },
    // remove: (state: State, action: { payload: string }) => {
    removeConsumer: (state: ConsumersState, action: PayloadAction<string>) => {
      const findMyConsumer = state.array.find(item => item.consumer.id === action.payload);
      if (findMyConsumer) {
        findMyConsumer.consumer.close();
      }

      // 목록에서 consumer 제거
      state.array = state.array.filter(item => item.consumer.id !== action.payload);
    },
    clearConsumer: (state: ConsumersState) => {
      for (const consumer of state.array) {
        consumer.close();
      }

      state.array = [];
    },
    changeConsumerIsHost: (state: ConsumersState, action: PayloadAction<string>) => {
      const newArray = state.array;

      newArray.forEach(item => {
        item.consumer.appData.isHost = item.consumer.appData.userInfo.bid === action.payload;
      });

      state.array = [...newArray];
    },
    pauseConsumer: (state: ConsumersState, action: PayloadAction<IConsumerPauseParam>) => {
      state.array.forEach((item: MyConsumer) => {
        if (
          item.consumer.appData.socketId === action.payload.socketId &&
          item.consumer.kind === action.payload.kind &&
          item.source === action.payload.source
        )
          item.consumer.pause();
      });
    },
    resumeConsumer: (state: ConsumersState, action: PayloadAction<IConsumerPauseParam>) => {
      state.array.forEach((item: MyConsumer) => {
        if (
          item.consumer.appData.socketId === action.payload.socketId &&
          item.consumer.kind === action.payload.kind &&
          item.source === action.payload.source
        )
          item.consumer.resume();
      });
    },
  },
});

export const { addConsumer, removeConsumer, clearConsumer, pauseConsumer, resumeConsumer, changeConsumerIsHost } = consumerSlice.actions;
export const selectConsumers = (state: RootState) => state.consumer.array;

export default consumerSlice.reducer;
