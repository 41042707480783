import { openDB, deleteDB, IDBPDatabase } from "idb";

interface IRecListItem {
  key: number;
  size: number;
  type: string;
}

export default class RecordingDb {
  private static instance: RecordingDb;
  public static getInstance() {
    if (!this.instance) {
      this.instance = new this();
    }

    return this.instance;

    // return this.instance || (this.instance = new this(name));
  }

  private idbName: string = "recordingData";
  private idbVersion: number = 1;
  public storeName: string = "defaultStore";
  private db: IDBPDatabase<unknown> | undefined;

  constructor() {
    this.createDb();
  }

  async createDb() {
    try {
      const idbStoreName = this.storeName;

      this.db = await openDB(this.idbName, this.idbVersion, {
        upgrade(db: IDBPDatabase) {
          if (!db.objectStoreNames.contains(idbStoreName)) {
            db.createObjectStore(idbStoreName, { autoIncrement: true });
          }
        },
      });
    } catch (error) {
      return false;
    }
  }

  async putData(value: object) {
    if (!this.db) return;
    const validKey = await this.db?.put(this.storeName, value);
    return validKey;
  }

  async getDataList() {
    if (!this.db) return [];

    const allKeys = await this.db.getAllKeys(this.storeName);
    const tx = this.db.transaction(this.storeName, "readonly");
    const store = tx.objectStore(this.storeName);

    const list: IRecListItem[] = [];
    const promises = allKeys.map(async key => {
      const value = await store.get(key);

      const item = {
        key: key as number,
        size: value.size,
        type: value.type,
      };
      list.push(item);
    });

    await Promise.all(promises);
    return list;
  }

  async getAllData(startKey: IDBValidKey, endKey: IDBValidKey) {
    if (!this.db) return [];

    const range = IDBKeyRange.bound(startKey, endKey);
    const data = await this.db.getAll(this.storeName, range);
    return data;
  }

  async clearAll() {
    if (!this.db) return;
    const tx = this.db.transaction(this.storeName, "readwrite");
    const store = tx.objectStore(this.storeName);
    if (store) {
      await store.clear();
    }
    return;
  }

  public getStoreList() {
    if (!this.db) return [];
    const storeNames = Array.from(this.db.objectStoreNames);
    return storeNames;
  }

  public async cleanup() {
    if (!this.db) return;

    this.db.close();
    deleteDB(this.idbName);

    if (indexedDB.databases instanceof Function) {
      const r = await indexedDB.databases();
      r.forEach(dbdata => {
        if (dbdata.name === this.idbName) deleteDB(dbdata.name);
      });
    }
  }

  close() {
    this.db?.close();
    this.db = undefined;
  }

  async getLastRecord() {
    if (!this.db) return null;

    let lastRecord = null;

    const tx = this.db.transaction(this.storeName, "readonly");
    const store = tx.objectStore(this.storeName);

    const cursor = await store.openCursor(null, "prev"); // 'prev'는 역순
    if (cursor) {
      lastRecord = { key: cursor.key, value: cursor.value }; // 마지막 레코드
    }

    await tx.done;

    return lastRecord;
  }

  async deleteRange(startKey: IDBValidKey, endKey: IDBValidKey) {
    if (!this.db) return;

    const tx = this.db.transaction(this.storeName, "readwrite");
    const store = tx.objectStore(this.storeName);

    const range = IDBKeyRange.bound(startKey, endKey);
    let cursor = await store.openCursor(range);
    let count = 0;

    while (cursor) {
      cursor.delete();
      cursor = await cursor.continue();
      count++;
    }

    tx.done.catch(err => {
      console.error("트랜잭션 에러:", err);
    });

    return { count };
  }
}

export const recordingDb = RecordingDb.getInstance();
