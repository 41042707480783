import { IClientEnv } from "api/server/clientEnv";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

interface IEvnState {
  value: IClientEnv;
}

const initialState: IEvnState = {
  value: {
    NODE_ENV: "",
    SERVER_URL: "",
    AUTH_SERVER: "",
    MGR_SERVER: "",
    UCWORKS_VER: "",
    CLOUD_CONVERT_APIKEY: "",
  },
};

export const envSlice = createSlice({
  name: "env",
  initialState,
  reducers: {
    setEnv: (state: IEvnState, action: PayloadAction<IClientEnv>) => {
      state.value = { ...action.payload };
    },
  },
});

export const { setEnv } = envSlice.actions;

export const selectEnv = (state: RootState) => state.env.value;

export default envSlice.reducer;
