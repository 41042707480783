import { forwardRef, ChangeEvent, MouseEvent } from "react";
import CloseButton from "../buttons/CloseButton";
import SearchIcon from "../icons/SearchIcon";

interface Props {
  placeholder?: string;
  disabled?: boolean;
  isReadOnly?: boolean;
  value?: string;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  handleChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClear: () => void;
  isSearch?: boolean;
  className?: string;
  classNameInput?: string;
  isShowCloseBtn?: boolean;
}

const InputText = forwardRef<HTMLInputElement, Props>(function textInput(
  {
    placeholder,
    disabled = false,
    isReadOnly = false,
    value,
    onClick,
    handleChangeInput,
    handleClear,
    isSearch = false,
    className,
    classNameInput,
    isShowCloseBtn = true,
  },
  ref,
) {
  return (
    <div className={`relative ${className ?? ""}`}>
      <input
        type="text"
        ref={ref}
        placeholder={placeholder}
        disabled={disabled}
        className={`h-9 rounded placeholder:text-C-9d9ea2 text-C-555555 dark:text-white disabled:bg-C-f1f3f6 disabled:text-C-b4b5b8 dark:bg-C-555555 px-3 text-sm w-full  ${
          classNameInput ?? ""
        } ${!isSearch ? "border border-solid border-C-c8cace dark:border-[#86878b] focus:border-C-1f62b9" : "pl-[2.375rem] focus:outline-none"}`}
        readOnly={isReadOnly}
        value={value}
        onClick={onClick}
        onChange={handleChangeInput}
      />
      {value && isShowCloseBtn && (
        <CloseButton
          width={13}
          height={13}
          className="absolute right-2 top-1/3"
          classNameSVG="stroke-C-c8cace hover:stroke-C-9d9ea2"
          onClick={handleClear}
        />
      )}
      {isSearch && <SearchIcon className="absolute left-3 top-1/4" />}
    </div>
  );
});

export default InputText;
