import ProfileImg from "../ProfileImg";
import ChatBox from "./ChatBox";
import useFindUser from "hooks/useFindUser";
import { useTranslation } from "react-i18next";
import { ChatData, ChatMsgType } from "../../types/commonTypes";
import { ReactComponent as IconNotice } from "../../assets/icon-notice.svg";
import { parseISO, format } from "date-fns";
import { useEffect, useState } from "react";

export interface ChatRowProps {
  chatData: ChatData;
  localName: string;
  locale?: Locale;
}

const ChatRow = (props: ChatRowProps) => {
  const { userInfo, text, isLocal, msgType, serverTime } = props.chatData;
  const { localName, locale } = props;
  const [msgTime, setMsgTime] = useState<string>("");
  const user = useFindUser(userInfo.bidOrEmail);
  const { t } = useTranslation();

  useEffect(() => {
    if (serverTime) {
      setMsgTime(format(parseISO(serverTime), "a h:mm", { locale: locale }));
    }
  }, [locale, serverTime]);

  if (msgType === ChatMsgType.NOTI) {
    return (
      <div className="flex justify-center items-start py-1 px-2">
        <IconNotice className="shrink-0" />
        <div className="ml-1 text-center text-white text-[0.813rem]">{text}</div>
      </div>
    );
  } else if (msgType === ChatMsgType.INVITE) {
    return (
      <div className="flex justify-center items-start py-1 px-2">
        <IconNotice className="shrink-0" />
        <div className="ml-1 text-white text-[0.813rem]">
          {text}
          {t("invite.명 초대")}
        </div>
      </div>
    );
  }

  if (!isLocal) {
    return (
      <div className="flex justify-start items-start w-full my-4 pl-6 py-0">
        <div className="w-11 h-11 shrink-0">
          <ProfileImg picurl={user?.picurl} borderWidth={1} />
        </div>

        <div className="flex flex-col ml-3">
          <div className="text-xs font-bold text-white">{user ? user.username : "-"}</div>
          <div className="flex items-end">
            <ChatBox {...props.chatData} user={user} localName={localName} />
            {msgTime && <div className="text-[#86878b] ml-1 text-xs">{msgTime}</div>}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-end items-start w-full my-4 py-0">
      <div className="flex items-end">
        {msgTime && <div className="text-[#86878b] mr-1 text-xs text-right">{msgTime}</div>}
        <ChatBox {...props.chatData} user={user} localName={localName} />
      </div>
    </div>
  );
};

export default ChatRow;
