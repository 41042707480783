import React, { useRef, useState } from "react";
import RoomClient from "RoomClient";
import UserCommandMore from "./UserCommandMore";
import UserCommandButton from "./UserCommandButton";
import { ReactComponent as IconCamera } from "assets/btn-camera.svg";
import { ReactComponent as IconCameraOff } from "assets/btn-camera-off.svg";
import { ReactComponent as IconMicOn } from "assets/btn-mic-s.svg";
import { ReactComponent as IconMicOff } from "assets/btn-mic-off_s.svg";
import { ReactComponent as IconMore } from "assets/btn-more.svg";
import { PeerInfo } from "types/clientTypes";
import { useSelector } from "react-redux";
import { selectHostBid } from "store/roomInfoSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
// import { useMobileOrientation } from "react-device-detect";

interface IProps {
  roomClient: RoomClient;
  m: PeerInfo;
  setIsOpenSubWindow: (isOpen: boolean) => void;
}

const UserCommand = (props: IProps) => {
  const { roomClient, m, setIsOpenSubWindow } = props;
  const { t } = useTranslation();
  const hostBid = useSelector(selectHostBid);
  const [selectedPeer, setSelectedPeer] = useState<PeerInfo | null>();
  const [isOpenMoreCommand, setIsOpenMoreCommand] = useState(false);
  const moreButtonRef = useRef<HTMLDivElement>(null);
  // const { isLandscape } = useMobileOrientation();

  const handleCameraOnOff = (m: PeerInfo, enable: boolean) => {
    enable ? console.log(m.userInfo.username, "Cam On") : console.log(m.userInfo.username, "Cam Off", m.userInfo.bid, hostBid);

    if (m.userInfo.bid === roomClient.userInfo.bid) {
      roomClient.pauseWebcam(!enable);
    } else if (hostBid === roomClient.userInfo.bid) {
      roomClient.socket.emit("cam-enable", {
        socketId: m.socketId,
        isEnable: enable,
        callback: ({ result }: { result: boolean }) => {
          console.log("cam-enable res", result);
        },
      });
    } else {
      toast.warn(t("msg.카메라 ON/OFF 권한없음"));
    }
  };

  const handleMicOnOff = async (m: PeerInfo, enable: boolean) => {
    enable ? console.log(m.userInfo.username, "Mic On") : console.log(m.userInfo.username, "Mic Off");
    const res = await roomClient.mute(!enable, m.userInfo.bid !== roomClient.userInfo.bid, m.socketId);
    if (!res) {
      toast.warn(t("msg.마이크 ON/OFF 권한없음"));
    }
  };

  const handleMore = (m: PeerInfo) => {
    if (selectedPeer?.peerId === m.peerId) {
      setSelectedPeer(null);
      setIsOpenMoreCommand(false);
    } else {
      setSelectedPeer(m);
      setIsOpenMoreCommand(true);
      setIsOpenSubWindow(true);
    }
  };

  return (
    <div className="flex cursor-pointer">
      <div className="flex justify-center items-center w-6 h-6 rounded-full bg-black mx-0.5" onClick={() => handleCameraOnOff(m, !m.isCamEnable)}>
        {m.isCamEnable ? <UserCommandButton SvgIcon={IconCamera} /> : <UserCommandButton SvgIcon={IconCameraOff} />}
      </div>

      <div className="flex justify-center items-center w-6 h-6 rounded-full bg-black mx-0.5" onClick={() => handleMicOnOff(m, !m.isMicEnable)}>
        {m.isMicEnable ? <UserCommandButton SvgIcon={IconMicOn} /> : <UserCommandButton SvgIcon={IconMicOff} />}
      </div>
      <div
        ref={moreButtonRef}
        key={m.socketId}
        className="relative flex justify-center items-center w-6 h-6 rounded-full bg-black mx-0.5"
        onClick={() => handleMore(m)}
      >
        <UserCommandButton SvgIcon={IconMore} isFill={true} isActive={isOpenMoreCommand} />

        {isOpenMoreCommand && selectedPeer?.peerId === m.peerId && (
          // <div className={`absolute bottom-7 ${isLandscape ? "left-0" : "right-0 border border-[#555]"}`}>
          <div className="absolute bottom-7 right-0 border border-[#555]">
            <UserCommandMore
              key={m.socketId}
              peer={selectedPeer}
              buttonRef={moreButtonRef}
              callback={() => {
                setIsOpenSubWindow(true);
              }}
              onClose={() => {
                setIsOpenSubWindow(false);
                setIsOpenMoreCommand(false);
                setSelectedPeer(null);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCommand;
