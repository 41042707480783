import React, { useRef, useState, Suspense, lazy, useEffect } from "react";
import EmoticonPicker from "./input/EmoticonPicker";
import ClickAwayListener from "../ClickAwayListener";
import MentionPicker from "./input/MentionPicker";
import UserPicker, { ChatTarget } from "./input/UserPicker";
import TextareaAutosize from "react-textarea-autosize";
import SendButton from "components/common/buttons/SendButton";
import RoomClient from "RoomClient";
import { ChatData } from "types/commonTypes";
import { setKeyDown } from "store/chatSlice";
import { withRoomContext } from "RoomContext";
import { selectUserInfo } from "store/roomInfoSlice";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as IconEmoji } from "assets/icon-emoji.svg";
import { ReactComponent as IconEmoticon } from "assets/icon-emoticon.svg";
import { ReactComponent as IconMention } from "assets/icon-mention.svg";
import { ReactComponent as IconWhisper } from "assets/icon-whisper.svg";
import Tooltip from "components/common/Tooltip";

const EmojiPicker = lazy(() => import("./input/EmojiPicker"));

interface DefProps {
  roomClient: RoomClient;
  onSend: (msg: ChatData) => void;
  onEsc: () => void;
}

enum Color {
  normal = "#9d9ea2",
  selected = "#4495ff",
}

const ChatInput = (props: DefProps) => {
  const { roomClient, onSend, onEsc } = props;
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [isShowEmoticonPicker, setIsShowEmoticonPicker] = useState(false);
  const [isShowEmojiPicker, setIsShowEmojiPicker] = useState(false);
  const [isShowMentionPicker, setIsShowMentionPicker] = useState(false);
  const [isShowUserPicker, setIsShowUserPicker] = useState(false);
  const [emoticonUrl, setEmoticonUrl] = useState("");
  const [whisperTargetSocketId, setWhisperTargetSocketId] = useState("");
  const [whisperTargetName, setWhisperTargetName] = useState("");
  const [arrowKeyDown, setArrowKeyDown] = useState("");
  const [excludePrefix, setExcludePrefix] = useState("");
  const [emojiBtnColor, setEmojiBtnColor] = useState(Color.normal);
  const [emoticonBtnColor, setEmoticonBtnColor] = useState(Color.normal);
  const [mentionBtnColor, setMentionBtnColor] = useState(Color.normal);
  const [whisperBtnColor, setWhisperBtnColor] = useState(Color.normal);
  const [isSendAble, setIsSendAble] = useState(false);
  const localUserInfo = useSelector(selectUserInfo);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const emojiBtnRef = useRef<HTMLDivElement>(null);
  const emoticonBtnRef = useRef<HTMLDivElement>(null);
  const memtionBtnRef = useRef<HTMLDivElement>(null);
  const whisperBtnRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Escape") {
      onEsc();
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
      if (isShowUserPicker || isShowMentionPicker) {
        e.preventDefault();
        setArrowKeyDown(e.key);
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.ctrlKey) {
        handleTextChange(text + "\n");
      } else {
        e.preventDefault();
        sendMsg();
      }
    }
  };

  const handleTextChange = (value: string) => {
    setIsSendAble(value.trim().length > 0 || emoticonUrl.length > 0);

    setText(value);

    if (value === "@") {
      setIsShowMentionPicker(true);
    } else if (value === "/w" || value === "/ㅈ") {
      setIsShowUserPicker(true);
    } else if (value.length === 0) {
      setIsShowMentionPicker(false);
      cancelWhisperChat();
    } else if (!value.startsWith("/w") && !value.startsWith("/ㅈ")) {
      setIsShowUserPicker(false);
    }

    if (excludePrefix.length > 0 && !value.startsWith(excludePrefix)) {
      cancelWhisperChat();
    }
  };

  const cancelWhisperChat = () => {
    setExcludePrefix("");
    setText("");
    setWhisperTargetSocketId("");
    setWhisperTargetName("");
    setIsShowUserPicker(false);
  };

  useEffect(() => {
    if (textareaRef.current) textareaRef.current.focus();
  }, []);

  useEffect(() => {
    setEmojiBtnColor(isShowEmojiPicker ? Color.selected : Color.normal);
    setEmoticonBtnColor(isShowEmoticonPicker ? Color.selected : Color.normal);
    setMentionBtnColor(isShowMentionPicker ? Color.selected : Color.normal);
    setWhisperBtnColor(isShowUserPicker ? Color.selected : Color.normal);
  }, [isShowEmojiPicker, isShowEmoticonPicker, isShowMentionPicker, isShowUserPicker]);

  useEffect(() => {
    dispatch(setKeyDown(arrowKeyDown));
    setArrowKeyDown("");
  }, [arrowKeyDown, dispatch]);

  const sendMsg = () => {
    const textToSend = text.trim();

    if (textToSend.length === 0 && emoticonUrl.length === 0) {
      setText("");
      return;
    }

    if (excludePrefix.length && textToSend.length <= excludePrefix.length + 1 && emoticonUrl.length === 0) {
      return;
    }

    if (!isSendAble) return;

    const today = new Date();
    const newChat: ChatData = {
      roomId: roomClient.roomId,
      userInfo: { isInsider: true, bidOrEmail: localUserInfo.bid },
      time: today.toISOString(),
      text: whisperTargetSocketId ? textToSend.replace(excludePrefix, "") : textToSend,
      isLocal: true,
      emoticon: emoticonUrl ? emoticonUrl : "",
      targetSocket: whisperTargetSocketId ? whisperTargetSocketId : "",
      targetName: whisperTargetName ? whisperTargetName : "",
    };

    onSend(newChat);
    setKeyDown("");
    setText("");
    handleTextChange("");
    setEmoticonUrl("");
  };

  const onEmoticonSelect = (set: number, no: number, url: string) => {
    setEmoticonUrl(url);
    setIsSendAble(true);
    setIsShowEmoticonPicker(false);
    textareaRef.current?.focus();
  };

  const onEmojiSelect = (value: string) => {
    setText(text + value);
    setIsSendAble(true);
    setIsShowEmojiPicker(false);
    textareaRef.current?.focus();
  };

  const onSelectMention = (target: string) => {
    console.log("mention select:", target);
    setText(`${target} ${text}`);
    setIsShowMentionPicker(false);
    textareaRef.current?.focus();
  };

  const onWhisperTargetSelect = (myConsumer: ChatTarget) => {
    console.log("onWhisperTargetSelect:", myConsumer);

    const prefix = `/w ${myConsumer.userInfo.username}`;
    setText(prefix + " ");
    setExcludePrefix(prefix);

    setWhisperTargetSocketId(myConsumer.socketId);
    setWhisperTargetName(myConsumer.userInfo.username);
    setIsShowUserPicker(false);
    textareaRef.current?.focus();
  };

  const onClickWhiper = () => {
    setIsShowUserPicker(!isShowUserPicker);
    textareaRef.current?.focus();
  };

  const btn_small = "flex flex-col w-[2rem] h-[2rem] justify-center items-center cursor-pointer";

  return (
    <div className="max-h-[80vh] shrink-0 border-b border-[#333] box-border w-full">
      <div className="flex flex-col p-1 h-full bg-[#555]">
        <div className="flex flex-col ">
          {/* 이모티콘이 선택된 상태라면 표시한다. */}
          {emoticonUrl && (
            <div className="relative flex justify-center items-start mb-1 bg-[#0005] p-2">
              <div className="text-sm text-white mx-[0.688rem]">{t("chat.이모티콘입력 도움말")}</div>
              <img src={emoticonUrl} alt="" className="w-[7.5rem] h-[7.5rem]" />
              <div className="absoulte top-0 right-0 p-2 flex justify-center items-center" onClick={() => setEmoticonUrl("")}>
                <button className=" w-[1.125rem] h-[1.125rem] bg-no-repeat bg-[url('./assets/btn-close.svg')]" />
              </div>
            </div>
          )}

          <TextareaAutosize
            ref={textareaRef}
            className="mt-px grow w-full p-1 bg-[#555] text-white resize-none text-sm leading-[1.25rem]"
            value={text}
            onKeyUp={e => handleKeyPress(e)}
            onKeyDown={handleKeyDown}
            onChange={e => handleTextChange(e.target.value)}
            placeholder={t("chat.대화입력 도움말")}
            minRows={2}
            maxRows={10}
          />
        </div>

        <div className="flex justify-between mt-2">
          <div className="flex">
            <Tooltip content={t("chat.이모지")}>
              <div
                ref={emojiBtnRef}
                className={btn_small}
                onMouseEnter={() => setEmojiBtnColor(Color.selected)}
                onMouseLeave={() => !isShowEmojiPicker && setEmojiBtnColor(Color.normal)}
                onClick={() => setIsShowEmojiPicker(!isShowEmojiPicker)}
              >
                <IconEmoji fill={emojiBtnColor} />
              </div>
            </Tooltip>

            <Tooltip content={t("chat.이모티콘")}>
              <div
                ref={emoticonBtnRef}
                className={btn_small}
                onMouseEnter={() => setEmoticonBtnColor(Color.selected)}
                onMouseLeave={() => !isShowEmoticonPicker && setEmoticonBtnColor(Color.normal)}
                onClick={() => setIsShowEmoticonPicker(!isShowEmoticonPicker)}
              >
                <IconEmoticon fill={emoticonBtnColor} />
              </div>
            </Tooltip>

            <Tooltip content={t("chat.멘션")}>
              <div
                ref={memtionBtnRef}
                className={btn_small}
                onMouseEnter={() => setMentionBtnColor(Color.selected)}
                onMouseLeave={() => !isShowMentionPicker && setMentionBtnColor(Color.normal)}
                onClick={() => setIsShowMentionPicker(!isShowMentionPicker)}
              >
                <IconMention fill={mentionBtnColor} />
              </div>
            </Tooltip>
            <Tooltip content={t("chat.귓속말")}>
              <div
                ref={whisperBtnRef}
                className={btn_small}
                onMouseEnter={() => setWhisperBtnColor(Color.selected)}
                onMouseLeave={() => !isShowUserPicker && setWhisperBtnColor(Color.normal)}
                onClick={onClickWhiper}
              >
                <IconWhisper fill={whisperBtnColor} />
              </div>
            </Tooltip>
          </div>

          <div className="mb-1 ml-2">
            <SendButton onSend={sendMsg} isSendAble={isSendAble} />
          </div>
        </div>
      </div>

      {isShowEmojiPicker && (
        <ClickAwayListener onClickAway={() => setIsShowEmojiPicker(false)} buttonRef={emojiBtnRef}>
          {ref => (
            <div ref={ref} className="absolute right-0 bottom-[2.5rem] w-full">
              <Suspense
                fallback={<div className="rounded h-[5rem] mb-[5rem] text-[#777] flex justify-center items-center bg-[#555]">Emoji Loading...</div>}
              >
                <EmojiPicker onSelect={(emoji: any) => onEmojiSelect(emoji.native)} />
              </Suspense>
            </div>
          )}
        </ClickAwayListener>
      )}

      {isShowEmoticonPicker && (
        <ClickAwayListener onClickAway={() => setIsShowEmoticonPicker(false)} buttonRef={emoticonBtnRef}>
          {ref => (
            <div ref={ref} className="absolute right-0 bottom-[2.5rem] w-full px-2 md:px-[1.9rem]">
              <EmoticonPicker onSelect={onEmoticonSelect} onCloseView={() => setIsShowEmoticonPicker(false)} />
            </div>
          )}
        </ClickAwayListener>
      )}

      {isShowMentionPicker && (
        <ClickAwayListener onClickAway={() => setIsShowMentionPicker(false)} buttonRef={memtionBtnRef}>
          {ref => (
            <div ref={ref} className="absolute right-0 bottom-[6rem] w-full">
              <MentionPicker onSelect={onSelectMention} />
            </div>
          )}
        </ClickAwayListener>
      )}

      {isShowUserPicker && (
        <ClickAwayListener onClickAway={() => setIsShowUserPicker(false)} buttonRef={whisperBtnRef}>
          {ref => (
            <div ref={ref} className="absolute right-0 bottom-[6rem] w-full">
              <UserPicker onSelect={onWhisperTargetSelect} localSocketId={roomClient.socket.id} />
            </div>
          )}
        </ClickAwayListener>
      )}
    </div>
  );
};

export default withRoomContext(ChatInput);
