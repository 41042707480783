import { Tab } from "@headlessui/react";
import { ReactNode } from "react";

interface Props {
  tabList: {
    id: string;
    name: string;
  }[];
  tabPanels: {
    id: string;
    content: ReactNode;
  }[];
  className?: string;
  panelClassName?: string;
  tabListClassName?: string;
  inputSearchBar?: ReactNode;
  setViewType?: (viewType: string) => void;
}

const Tabs = ({ tabList, tabPanels, className, panelClassName, tabListClassName, inputSearchBar, setViewType }: Props) => (
  <Tab.Group
    as="div"
    className={className}
    onChange={index => {
      if (setViewType) {
        setViewType(tabList[index].id);
      }
    }}
  >
    <Tab.List as="ul" className={`inline-flex flex-row flex-nowrap flex-none cursor-pointer h-[1.875rem] overflow-x-auto ${tabListClassName ?? ""}`}>
      {tabList.map(tab => (
        <Tab
          key={tab.id}
          as="li"
          className={({ selected }) =>
            `px-3 text-sm h-[1.875rem] leading-[1.875rem] hover:text-C-1f62b9 focus:outline-none whitespace-nowrap ${
              selected ? "text-C-1f62b9 font-bold border-b-4 border-solid border-C-1f62b9" : "text-C-86878b"
            }`
          }
        >
          {tab.name}
        </Tab>
      ))}
    </Tab.List>
    <Tab.Panels className={`flex-auto border-t border-solid border-C-dfe2e8 ${panelClassName ? panelClassName : ""}`}>
      {tabPanels.map(tabPanel => (
        <Tab.Panel className="flex flex-col h-full" key={tabPanel.id}>
          {inputSearchBar}
          {tabPanel.content}
        </Tab.Panel>
      ))}
    </Tab.Panels>
  </Tab.Group>
);

export default Tabs;
