import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPeers } from "store/peerSlice";

export interface IPeerSetting {
  isCamEnable: boolean;
  isMicEnable: boolean;
}

export function usePeerSetting(socketId: string) {
  const [setting, setSetting] = useState<IPeerSetting>();
  const peers = useSelector(selectPeers);

  useEffect(() => {
    const peer = peers.find(item => item.socketId === socketId);
    if (peer) {
      const newSet = { isCamEnable: peer.isCamEnable, isMicEnable: peer.isMicEnable };
      setSetting(newSet);
    } else {
      const newSet = { isCamEnable: false, isMicEnable: false };
      setSetting(newSet);
    }
  }, [socketId, peers]);

  return setting;
}
