import { useEffect, useState } from "react";
import ExternalAttendeeBox from "./ExternalAttendeeBox";
import Button from "components/common/buttons/Button";
import ExternalAttendeeInput from "./ExternalAttendeeInput";
import AddUserPopup from "components/home/AddUserPopup";
import InternalAttendeeBox from "./InternalAttendeeBox";
import RoomClient from "RoomClient";
import inviteExec, { extractGroup } from "./inviteExec";
import useGetInviteUrl from "hooks/useGetInviteUrl";
import ReadOnlyBox from "components/common/ReadOnlyBox";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconReceiver } from "assets/icon-receiver.svg";
import { ReactComponent as IconPeoplePlus } from "assets/btn-people-plus.svg";
import { ReactComponent as IconCode } from "assets/icon-code.svg";
import { TreeDataNode } from "components/common/interfaces/OrgData";
import { selectRoomInfo, setOuterAttendees, selectOuterAttendees } from "store/roomInfoSlice";
import { setInnerAttendees, selectInnerAttendees } from "store/inviteSlice";
import { Modal } from "components/Modal";
import { toast } from "react-toastify";
import { withRoomContext } from "RoomContext";
import { IInnerAttendee, IOuterAttendee } from "api/manager/invite";
import { useGetRoomAttendees } from "hooks/useGetRoomAttendees";

interface IProps {
  onClose: () => void;
  roomClient: RoomClient;
}

const InviteAttendeeRoot = (props: IProps) => {
  const { onClose, roomClient } = props;
  const { t } = useTranslation();
  const [isShowAddInternalAttendee, setIsShowAddInternalAttendee] = useState(false);
  const [isShowAddExternalAttendee, setIsShowAddExternalAttendee] = useState(false);
  const [addedInnerAttendees, setAddedInnerAttendees] = useState<IInnerAttendee[]>([]);
  const [addedOuterAttendees, setAddedOuterAttendees] = useState<IOuterAttendee[]>([]);
  const roomInfo = useSelector(selectRoomInfo);
  const innerAttendees = useSelector(selectInnerAttendees);
  const outerAttendees = useSelector(selectOuterAttendees);
  const inviteUrl = useGetInviteUrl(roomClient.roomId);
  const attendees = useGetRoomAttendees(roomClient.roomId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (attendees) {
      attendees.inner.map(item => (item.readonly = true));
      attendees.outer.map(item => (item.readonly = true));
      dispatch(setInnerAttendees(attendees.inner));
      dispatch(setOuterAttendees(attendees.outer));
    }
  }, [attendees, dispatch]);

  const handleClose = () => {
    onClose();
  };

  const onResultAddInternalUser = (data: TreeDataNode[]) => {
    console.log("내부참여자 추가:", data);
    let newArray: IInnerAttendee[] = [];

    data.forEach(item => {
      const extracts = extractGroup(item);
      extracts.forEach(item => {
        if (!innerAttendees.find(inn => inn.bid === item.bid)) {
          const inner: IInnerAttendee = {
            bid: item.bid ?? "",
            name: item.title,
            picurl: item.url ?? "",
            position: item.position ?? "",
            team: item.team ?? "",
          };

          newArray.push(inner);
        }
      });
    });

    setAddedInnerAttendees([...addedInnerAttendees, ...newArray]);
  };

  const onResultAddExternalUser = (data: IOuterAttendee[]) => {
    console.log("외부참여자 추가:", data);
    setAddedOuterAttendees(data.filter(item => !outerAttendees.find(att => att.email === item.email)));
  };

  const removeInternalAttendee = (bid: string) => {
    setAddedInnerAttendees(addedInnerAttendees.filter(user => user.bid !== bid));
  };

  const removeExternalAttendee = (email: string) => {
    setAddedOuterAttendees(addedOuterAttendees.filter(user => user.email !== email));
  };

  const handleInvite = async () => {
    if (addedInnerAttendees.length === 0 && addedOuterAttendees.length === 0) {
      toast.warn(t("invite.추가된 참여자가 없습니다."));
      return;
    }

    const res = await inviteExec({
      roomId: roomInfo.id,
      internalAttendees: [...innerAttendees, ...addedInnerAttendees],
      externalAttendees: [...outerAttendees, ...addedOuterAttendees],
    });

    if (res.message === "success") {
      const addedInterNames = addedInnerAttendees.reduce<string[]>((acc, cur) => {
        if (!cur.readonly) acc.push(cur.name);
        return acc;
      }, []);

      const addedExterNames = addedOuterAttendees.reduce<string[]>((acc, cur) => {
        if (!cur.readonly) acc.push(cur.name);
        return acc;
      }, []);

      const addedNames = [...addedInterNames, ...addedExterNames];
      const names = addedNames.join(", ");
      const msg = `${names} - ${addedNames.length}`;

      toast.info(msg + t("invite.명 초대"));
      roomClient.sendNoti(msg, false, true);

      addedInnerAttendees.map(item => (item.readonly = true));
      addedOuterAttendees.map(item => (item.readonly = true));
      dispatch(setInnerAttendees([...innerAttendees, ...addedInnerAttendees]));
      dispatch(setOuterAttendees([...outerAttendees, ...addedOuterAttendees]));

      handleClose();
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.info(t("msg.클립보드에 복사했습니다."));
  };

  return (
    <Dialog open={true} onClose={handleClose} className="relative">
      <div className="dark fixed inset-0 flex items-center justify-center z-40 shadow-lg bg-black/50" aria-hidden="true">
        <Dialog.Panel className="flex flex-col rounded-md shadow px-4 py-6 md:px-6 bg-[#333] m-2 w-full max-w-[42.5rem] max-h-[41.188rem] overflow-auto text-white">
          <div className="flex justify-between items-center">
            <span className="font-bold text-base select-none">{t("users.참여자 초대")}</span>
          </div>

          <table className="table table-fixed mt-[1.438rem]">
            <tbody className="">
              <tr className="h-9 border-b-8 border-b-[#0000]">
                <td className="w-1 h-9 pr-2 whitespace-nowrap leading-9">
                  <IconCode className="inline-block w-5 h-5" />
                  <span className="hidden md:inline-block w-fit h-9 leading-9 ml-2 select-none">URL</span>
                </td>
                <td>
                  <ReadOnlyBox onClick={() => copyToClipboard(inviteUrl)}>{inviteUrl}</ReadOnlyBox>
                </td>
              </tr>
              <tr className="h-9 pt-4 border-b-8 border-b-[#fff0]">
                <td className="h-9 pr-2 whitespace-nowrap leading-9">
                  <IconCode className="inline-block w-5 h-5" />
                  <span className="hidden md:inline-block w-fit h-9 leading-9 ml-2 select-none">{t("invite.접속코드")}</span>
                </td>
                <td>
                  <ReadOnlyBox onClick={() => copyToClipboard(roomInfo.accessCode)}>{roomInfo.accessCode}</ReadOnlyBox>
                </td>
              </tr>

              {roomClient.isInternalUser && (
                <tr className="h-9 border-b-8 border-b-[#fff0]">
                  <td className="h-9 pr-2 whitespace-nowrap leading-9">
                    <IconReceiver className="inline-block w-5 h-5" />
                    <span className="hidden md:inline-block w-fit h-9 leading-9 ml-2 select-none">{t("invite.내부참여자")}</span>
                  </td>
                  <td className="flex">
                    <IconPeoplePlus className="inline-block shrink-0 cursor-pointer" onClick={() => setIsShowAddInternalAttendee(true)} />
                    <div className="overflow-auto flex flex-wrap ml-1 max-h-[20rem]">
                      {[...innerAttendees, ...addedInnerAttendees].map(user => (
                        <InternalAttendeeBox key={user.bid} data={user} onDelete={bid => removeInternalAttendee(bid)} />
                      ))}
                    </div>
                  </td>
                </tr>
              )}
              <tr className="h-9">
                <td className="h-9 pr-2 whitespace-nowrap leading-9">
                  <IconReceiver className="inline-block w-5 h-5" />
                  <span className="hidden md:inline-block w-fit h-9 leading-9 ml-2 select-none">{t("invite.외부참여자")}</span>
                </td>
                <td className="flex">
                  <IconPeoplePlus className="inline-block shrink-0 cursor-pointer" onClick={() => setIsShowAddExternalAttendee(true)} />
                  <div className="overflow-auto flex flex-wrap ml-1 max-h-[20rem]">
                    {[...outerAttendees, ...addedOuterAttendees].map(user => (
                      <ExternalAttendeeBox key={user.email} data={user} onDelete={email => removeExternalAttendee(email)} />
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="mt-[1.875rem] flex gap-6 justify-center">
            <Button kind={"cancel-dark"} label={t("취소")} className="inline-block min-w-[8.5rem]" onClick={handleClose} />
            <Button kind={"primary"} label={t("invite.초대")} className="inline-block min-w-[8.5rem]" onClick={handleInvite} />
          </div>
        </Dialog.Panel>
      </div>

      {isShowAddInternalAttendee && <AddUserPopup onClose={() => setIsShowAddInternalAttendee(false)} onResult={onResultAddInternalUser} />}

      {isShowAddExternalAttendee && (
        <Modal isOpen={true}>
          <div className="dark">
            <ExternalAttendeeInput
              closeModal={() => setIsShowAddExternalAttendee(false)}
              onResult={onResultAddExternalUser}
              prevOuterAttendees={outerAttendees}
            />
          </div>
        </Modal>
      )}
    </Dialog>
  );
};

export default withRoomContext(InviteAttendeeRoot);
