import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { MemoData } from "../../types/commonTypes";
import { selectUserInfo } from "../../store/roomInfoSlice";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import SendButton from "components/common/buttons/SendButton";

interface DefProps {
  roomId: number;
  onSend: (newMemo: MemoData) => void;
}

const MemoInput = (props: DefProps) => {
  const { roomId, onSend } = props;
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [isSendAble, setIsSendAble] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const localUserInfo = useSelector(selectUserInfo);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.ctrlKey) {
        setText(text + "\n");
      } else {
        e.preventDefault();
        sendMsg();
      }
    }
  };

  const handleTextChange = (value: string) => {
    setIsSendAble(value.trim().length > 0);
    setText(value);
  };

  const sendMsg = () => {
    const textToSend = text.trim();
    if (textToSend.length === 0) {
      setText("");
      return;
    }

    const newMemo: MemoData = {
      roomId,
      userInfo: { isInsider: true, bidOrEmail: localUserInfo.bid },
      time: new Date().toISOString(),
      text: textToSend,
      isLocal: true,
    };

    onSend(newMemo);
    setText("");
    handleTextChange("");
  };

  return (
    <div className="flex flex-col items-end bg-[#555] p-1">
      <TextareaAutosize
        ref={textareaRef}
        className="mt-px grow w-full p-1 bg-[#555] text-white resize-none text-sm"
        value={text}
        onKeyUp={e => handleKeyPress(e)}
        onChange={e => handleTextChange(e.target.value)}
        placeholder={t("note.메모입력 도움말")}
        minRows={2}
        maxRows={10}
      />

      <div className="mb-1 mt-2 mr-1">
        <SendButton onSend={sendMsg} isSendAble={isSendAble} />
      </div>
    </div>
  );
};

export default MemoInput;
