import Axios from "axios";
import CryptoJS from "crypto-js";

export interface IClientEnv {
  NODE_ENV: string;
  SERVER_URL: string;
  AUTH_SERVER: string;
  MGR_SERVER: string;
  UCWORKS_VER: string;
  CLOUD_CONVERT_APIKEY: string;
}

export const getClientEnv = async (tenantId: string, issuer: string): Promise<IClientEnv> => {
  try {
    const url =
      process.env.NODE_ENV === "production"
        ? `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}/client-env`
        : `${window.location.protocol}//${window.location.hostname}:3001/client-env`;

    const referrer = document.referrer.replaceAll("/test", "");

    console.log("########## getClientEnv url:", url);
    console.log("########## getClientEnv issuer:", issuer);
    console.log("########## getClientEnv referrer:", referrer);

    const axiosInstance = Axios.create({ timeout: 30000 });
    const response = await axiosInstance.post(url, { tenantId, issuer, referrer });

    const key = process.env.REACT_APP_CLIENT_ENV_KEY || "";
    const { env } = response.data;
    const bytes = CryptoJS.AES.decrypt(env, key);
    const res: IClientEnv = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("getClientEnv res", res);
    return res;
  } catch (e) {
    console.log("error from getClientEnv api", e);
    return {
      NODE_ENV: "",
      SERVER_URL: "",
      AUTH_SERVER: "",
      MGR_SERVER: "",
      UCWORKS_VER: "",
      CLOUD_CONVERT_APIKEY: "",
    };
  }
};
