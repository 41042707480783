import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { SourceConfig, SourcePlayback } from "../helpers/sourceHelper";
import { useMobileOrientation } from "react-device-detect";
import { selectLayoutType } from "store/roomInfoSlice";
import { useSelector } from "react-redux";

type SourceViewerProps = {
  sourceConfig: SourceConfig;
  onLoad: (sourcePlayback: SourcePlayback) => void;
  stream?: MediaStream;
  configVideo: MediaTrackConstraints;
  configAspectRatio: number;
  classNameDiv?: string;
  isCamRotate: boolean;
};

const SourceViewer = (props: SourceViewerProps) => {
  const [sourceUrl, setSourceUrl] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [isCameraError, setCameraError] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isPortrait } = useMobileOrientation();
  const layoutType = useSelector(selectLayoutType);

  useEffect(() => {
    if (videoRef.current) {
      if (videoRef.current.videoWidth > 0 && videoRef.current.videoHeight > 0) {
        console.log("SourceViewer video width, height", videoRef.current.videoWidth, videoRef.current.videoHeight);
        videoRef.current.load();
      }
    }
  }, [isPortrait, layoutType]);

  useEffect(() => {
    setSourceUrl(undefined);
    setLoading(true);
    setCameraError(false);

    // 리소스를 다시 로드하도록 강제합니다. 그렇지 않으면 onLoad 이벤트가 항상 전달되지 않고 진행률 표시기가 사라지지 않습니다.
    setTimeout(() => setSourceUrl(props.sourceConfig.url));
  }, [props.sourceConfig]);

  useEffect(() => {
    if (videoRef.current && props.stream) {
      videoRef.current.srcObject = props.stream;
      return;
    }
  }, [props.stream]);

  function handleVideoLoad(event: SyntheticEvent) {
    const video = event.target as HTMLVideoElement;
    props.onLoad({
      htmlElement: video,
      width: video.videoWidth,
      height: video.videoHeight,
    });
    setLoading(false);
  }

  return (
    <div className={`${props.classNameDiv ?? "flex bg-[#555] flex-col p-0 w-full"}`} hidden>
      {isCameraError ? (
        <div>CameraError</div>
      ) : (
        <video
          ref={videoRef}
          src={sourceUrl}
          hidden={isLoading}
          autoPlay
          playsInline
          controls={false}
          muted
          loop
          onLoadedData={handleVideoLoad}
          style={props.isCamRotate ? { transform: "rotateY(180deg)" } : {}}
          disablePictureInPicture
        />
      )}
    </div>
  );
};

export default SourceViewer;
