import { useEffect, useState } from "react";
import { isSafari, browserVersion } from "react-device-detect";
import { useSelector } from "react-redux";
import { selectWebcams } from "store/settingSlice1";

export function useCanVirtualBg() {
  const [canVirtualBg, setCanVirtualBg] = useState(false);
  const webcams = useSelector(selectWebcams);

  useEffect(() => {
    if (webcams.size === 0) {
      setCanVirtualBg(false);
    } else {
      setCanVirtualBg(!isSafari || (isSafari && Number(browserVersion) >= 15));
    }
  }, [webcams]);

  return canVirtualBg;
}
