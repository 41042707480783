import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPeers } from "store/peerSlice";
import { selectCurrentHostSocketId } from "store/docSharingSlice";
import { PeerInfo } from "types/clientTypes";

export function useDocSharingHostPeer() {
  const [docHostPeer, setDocHostPeer] = useState<PeerInfo>();
  const peers = useSelector(selectPeers);
  const docSharingHostSocketId = useSelector(selectCurrentHostSocketId);

  useEffect(() => {
    const findPeer = peers.find(item => item.socketId === docSharingHostSocketId);
    setDocHostPeer(findPeer);
  }, [docSharingHostSocketId, peers]);

  return docHostPeer;
}
