import React, { useState } from "react";

interface IProps {
  SvgIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  onClick: any;
  SvgIconHover?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const MenuButtonSimple = (props: IProps) => {
  const { label, SvgIcon, onClick, SvgIconHover } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="flex flex-col w-[4.75rem] h-[4.75rem] justify-center items-center cursor-pointer"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {SvgIconHover && isHovered ? <SvgIconHover className="w-9 h-9" /> : <SvgIcon fill={isHovered ? "#4495ff" : "#fff"} className="w-9 h-9" />}

      <div className={`text-xs font-bold hidden text-center text-white mt-1 md:flex ${isHovered ? "text-blue-10" : "text-white"} `}>{label}</div>
    </div>
  );
};

export default MenuButtonSimple;
