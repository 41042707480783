import React, { useState, useEffect, useRef } from "react";
import PageThumbnail from "./PageThumbnail";
import { selectConvertedFileList, selectCurrentIndex, setCurrentIndex, selectCurrentFileId, setEditMode, setZoomType } from "store/docSharingSlice";
import { useSelector, useDispatch } from "react-redux";
import { store } from "store/store";
import { isMobile } from "react-device-detect";

const DocSharingPageList = () => {
  const [thumbUrls, setThumbUrls] = useState<string[]>([]);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [prevIndex, setPrevIndex] = useState<number>(0);
  const imgUrls = useSelector(selectConvertedFileList);
  const curIndex = useSelector(selectCurrentIndex);
  const curFileId = useSelector(selectCurrentFileId);
  const parentRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(true);
    };

    if (parentRef.current) {
      parentRef.current.addEventListener("scroll", handleScroll);
    }

    const divEle = parentRef.current;
    return () => {
      if (divEle) {
        divEle.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (curIndex !== prevIndex) {
      setIsScrolled(false);
      setPrevIndex(curIndex);
    }
  }, [curIndex, prevIndex]);

  useEffect(() => {
    const newThumbUrls: string[] = [];
    imgUrls.forEach((url, idx) => {
      const thumbUrl = `${store.getState().env.value.SERVER_URL}/docsharing/${curFileId}/thumbnail/${idx + 1}.jpg`;
      newThumbUrls.push(thumbUrl);
    });

    setThumbUrls(newThumbUrls);
    dispatch(setEditMode("CURSOR"));
  }, [curFileId, dispatch, imgUrls]);

  const handleSelect = (index: number) => {
    dispatch(setZoomType("auto-fit"));
    dispatch(setCurrentIndex(index));
  };

  return (
    <div ref={parentRef} className="w-full h-full flex flex-col items-center overflow-y-scroll bg-[#333] pb-4 select-none">
      {thumbUrls.map((url, index) => (
        <div className="" key={index}>
          <div className="mt-4 text-white text-sm">{index + 1}</div>
          <div
            className={`w-[3.312rem] h-[2.438rem] ${!isMobile && "md:w-[10.188rem] md:h-[7.625rem]"} overflow-hidden cursor-pointer ${
              curIndex !== index ? "border-2" : "border-2 border-[#FA114F]"
            } `}
            onClick={() => handleSelect(index)}
          >
            <PageThumbnail url={url} isSelected={curIndex === index} isAlreadyScrolled={isScrolled} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(DocSharingPageList);
