import Tree, { TreeNode } from "rc-tree";
import { Key, EventDataNode, DataNode } from "rc-tree/lib/interface";
import { useCallback, useEffect, useRef, useState } from "react";
import SelectDown from "../icons/SelectDown";
import Member from "../member/Member";
import AddTeamButton from "../buttons/AddTeamButton";
import _ from "lodash";
import { TreeDataNode } from "../interfaces/OrgData";
import { toast } from "react-toastify";
import { useGlobalPayload } from "../hooks/useGlobalState";
import useFinalOrgFlatData from "../hooks/useFinalOrgFlatData";

interface Props {
  className?: string;
  gid: string;
  // requestOrgParent: RequestOrgList;
  // requestOrgChildren: RequestOrgList;
  selectedMember: TreeDataNode[];
  setSelectedMember: Function;
  checkedKeys: Key[];
  setCheckedKeys: Function;
}

const flatToTree = (list: TreeDataNode[], parentdeptcode: string) => {
  const map: Record<string, number> = {};
  const roots = [];
  let node;
  let i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].key] = i;
    list[i].children = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];

    if (node.parentdeptcode !== parentdeptcode) {
      // list[
      //   map[`${node.key.split(".")[0]}.${node.parentdeptcode}`]
      // ].children?.push(node);
      list[map[node.parentdeptcode]].children?.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
};

const loop = (data: TreeDataNode[]) => {
  return data.map((item: TreeDataNode) => {
    if (!item.isLeaf) {
      if (item.children && item.children.length) {
        return (
          <TreeNode
            key={item.key}
            title={
              <div className="flex flex-row items-center">
                <div className="flex-auto">{item.title}</div>
                <AddTeamButton className="flex-none hidden group-hover:inline-block" />
              </div>
            }
            className="flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6 h-7 group text-sm text-C-676767"
          >
            {loop(item.children)}
          </TreeNode>
        );
      } else {
        return (
          <TreeNode
            key={item.key}
            title={
              <div className="flex flex-row items-center">
                <div className="flex-auto">{item.title}</div>
                <AddTeamButton className="flex-none hidden group-hover:inline-block" />
              </div>
            }
            className="flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6 h-7 group text-sm text-C-676767"
          ></TreeNode>
        );
      }
    }

    return (
      <TreeNode
        key={item.key}
        title={<Member name={item.title} position={item.position} team={item.team ?? ""} url={item.url ?? ""} />}
        className="flex flex-row px-2 items-center hover:bg-C-f1f3f6 active:bg-C-f1f3f6 h-14"
        isLeaf
      />
    );
  });
};

const getFlatMembers = (treeNode: TreeDataNode[]) => {
  const flatMembers: TreeDataNode[] = [];

  const traverse = (tree: TreeDataNode[]) => {
    tree.forEach(node => {
      if (node.isLeaf) {
        flatMembers.push(node);
      } else if (node.children?.length) {
        traverse(node.children);
      }
    });
  };

  traverse(treeNode);

  return flatMembers;
};

const findKeyInTree: (key: string, tree: TreeDataNode[] | TreeDataNode) => boolean = (key, tree) => {
  if (Array.isArray(tree)) {
    for (let i = 0; i < tree.length; i++) {
      if (findKeyInTree(key, tree[i])) {
        return true;
      }
    }
  } else if (typeof tree === "object" && tree !== null) {
    if (tree.key === key) {
      return true;
    }

    if (tree.children && tree.children.length) {
      return findKeyInTree(key, tree.children);
    }
  }

  return false;
};

const extractKeysFromTree = (tree: TreeDataNode[] | TreeDataNode, keys: Key[] = []) => {
  if (Array.isArray(tree)) {
    tree.forEach(item => extractKeysFromTree(item, keys));
  } else if (typeof tree === "object" && tree !== null) {
    keys.push(tree.key);

    if (tree.children && tree.children.length) {
      extractKeysFromTree(tree.children, keys);
    }
  }

  return keys;
};

const OrgTree = ({
  className,
  gid,
  // requestOrgParent,
  // requestOrgChildren,
  selectedMember,
  setSelectedMember,
  checkedKeys,
  setCheckedKeys,
  ...props
}: Props) => {
  const [globalPayload] = useGlobalPayload();
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  // const [autoExpandParent, setAutoExpandParent] = useState(true);
  const isSelectedMemberHasKey = selectedMember[0]?.key === gid.split(".").pop();
  const [requestOrgParent, setRequestOrgParent] = useState({
    cmd: "1103",
    sid: globalPayload.sid as string,
    what: [{ gid }],
    kind: isSelectedMemberHasKey ? "CHILDREN" : "DEPT",
    include: isSelectedMemberHasKey ? "DEPTUSER" : "DEPT",
  });
  // console.log("🚀 ~ file: OrgTree.tsx:217 ~ requestOrgParent:", requestOrgParent);
  const { orgFlatParent, finalOrgFlatData } = useFinalOrgFlatData(requestOrgParent);
  const finalOrgPureFlatData = finalOrgFlatData.map(node => ({
    ...node,
    children: [],
  }));
  // console.log("🚀 ~ file: OrgTree.tsx:149 ~ finalOrgPureFlatData:", finalOrgPureFlatData);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [otherOrgKeys, setOtherOrgKeys] = useState<Key[]>([]);
  const [sortedFinalOrgTreeData, setSortedFinalOrgTreeData] = useState<TreeDataNode[]>([]);

  const loadData = useCallback(
    (treeNode: EventDataNode<DataNode>, isChildren?: boolean) => {
      // console.log("🚀 ~ file: OrgTree.tsx:225 ~ loadData ~ treeNode:", treeNode);

      if (globalPayload.bid) {
        const gidArr = gid.split(".");

        gidArr.pop();

        const prefixGid = gidArr.join(".");

        setRequestOrgParent(old => ({
          ...old,
          what: [{ gid: `${prefixGid}.${treeNode.key}` }],
          kind: isChildren || selectedMember[0]?.parentdeptcode === treeNode.key ? "CHILDREN" : "CHILD",
          include: "DEPTUSER",
        }));

        scrollRef.current?.scrollTo(0, scrollTop);
      }

      return Promise.resolve();
    },
    [scrollRef, scrollTop, globalPayload.bid, selectedMember],
  );

  function sortTreeByIsLeaf(tree: TreeDataNode[]) {
    return tree.map(node => {
      // 재귀적으로 children을 정렬
      if (node.children && node.children.length > 0) {
        node.children = sortTreeByIsLeaf(node.children);
      }

      // isLeaf가 true인 것을 먼저 오도록 정렬
      node.children = (node.children as TreeDataNode[]).sort((a, b) => Number(b.isLeaf) - Number(a.isLeaf));

      return node;
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        // console.log("handleScroll y:", scrollRef.current.scrollTop);
        setScrollTop(scrollRef.current.scrollTop);
      }
    };

    const refCurrent = scrollRef.current;

    if (refCurrent) {
      refCurrent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollRef]);

  useEffect(() => {
    const duplicateFreeCheckedMembers = finalOrgPureFlatData
      .filter(user => checkedKeys.includes(user.key))
      .filter(user => !checkedKeys.includes(user.parentdeptcode));

    if (_.some(duplicateFreeCheckedMembers, ["parentdeptcode", ""])) {
      _.remove(duplicateFreeCheckedMembers, user => user.parentdeptcode);
    }

    // console.log("🚀 ~ file: OrgTree.tsx:278 ~ duplicateFreeCheckedMembers:", duplicateFreeCheckedMembers);

    const flatMembers = getFlatMembers(duplicateFreeCheckedMembers);
    // console.log("🚀 ~ file: OrgTree.tsx:301 ~ flatMembers:", flatMembers);
    const uniqMembers = _.uniqWith(flatMembers, (a, b) => a.bid === b.bid);
    // console.log("uniqMembers", uniqMembers);
    const duplicatedMembers = _.xor(flatMembers, uniqMembers);
    // console.log("duplicatedMembers", duplicatedMembers);
    const finalRemovedDuplicatedMembers = _.xorWith(duplicateFreeCheckedMembers, duplicatedMembers, (a, b) => _.isEqual(a, b)).filter(
      user => !checkedKeys.includes(user.parentdeptcode),
    );

    if (_.some(finalRemovedDuplicatedMembers, ["parentdeptcode", ""])) {
      _.remove(finalRemovedDuplicatedMembers, user => user.parentdeptcode);
    }

    // console.log("🚀 ~ file: OrgTree.tsx:307 ~ finalRemovedDuplicatedMembers:", finalRemovedDuplicatedMembers);

    // setCheckedKeys(removedDuplicatedKeys);
    // setCheckedKeys(selectedKeys);
    // console.log("finalOrgPureFlatData", finalOrgPureFlatData);

    const finalOrgTreeData = flatToTree(finalOrgPureFlatData, finalOrgPureFlatData[0]?.parentdeptcode);
    // console.log("🚀 ~ file: OrgTree.tsx:210 ~ finalOrgTreeData:", finalOrgTreeData);
    setSortedFinalOrgTreeData(
      /* _.cloneDeepWith(finalOrgTreeData, (value) => {
        const booleanArray: boolean[] = [];

        if (Array.isArray(value)) {
          (value as TreeDataNode[]).forEach((item) => {
            booleanArray.push(item.isLeaf);
          });

          const duplicateFreeBooleanArray = _.uniq(booleanArray);

          if (duplicateFreeBooleanArray.length === 2) {
            return _.sortBy(value, [(o: TreeDataNode) => o.isLeaf === false]);
          }
        }
      }) as TreeDataNode[], */
      sortTreeByIsLeaf(finalOrgTreeData),
    );
    const finalOrgFlatDataKeys = finalOrgPureFlatData.map(user => user.key);

    setOtherOrgKeys(_.difference(checkedKeys, finalOrgFlatDataKeys));

    const otherOrgMembers = selectedMember.filter(member => !finalOrgFlatDataKeys.includes(member.key));

    let hasKey = false;

    finalRemovedDuplicatedMembers.forEach(node => {
      if (findKeyInTree(node.key, otherOrgMembers)) {
        hasKey = true;

        return;
      }
    });

    if (hasKey) {
      setSelectedMember([...otherOrgMembers]);
    } else {
      setSelectedMember([...otherOrgMembers, ...finalRemovedDuplicatedMembers]);
    }
  }, [checkedKeys, finalOrgFlatData]);

  useEffect(() => {
    if (!selectedMember.length) return;

    if (!_.isEqual(checkedKeys, extractKeysFromTree(selectedMember))) {
      setCheckedKeys(extractKeysFromTree(selectedMember));
    }
  }, [selectedMember]);

  // if (orgFlatParent.isLoading || orgFlatChildren.isLoading) {
  /* if (orgFlatParent.isLoading) {
    return (
      <Loader className="rounded border border-solid border-C-c8cace basis-1/2 pt-0.5 min-h-[5.25rem] max-h-[13.908rem] md:max-h-[28.563rem]" />
    );
  } */

  // if (orgFlatParent.isError || orgFlatChildren.isError) {
  if (orgFlatParent.isError) {
    if (orgFlatParent.error instanceof Error) {
      toast.error(orgFlatParent.error.message);
      return <></>;
    }

    // if (orgFlatChildren.error instanceof Error) {
    //   toast.error(orgFlatChildren.error.message);
    //   return <></>;
    // }
  }

  // if (orgFlatChildren.data) {
  //   orgFlatChildrenData = generateTreeData(
  //     orgFlatChildren.data as ResponseOrgData
  //   );
  // }

  return (
    <div
      ref={scrollRef}
      className={`rounded border border-solid border-C-c8cace basis-1/2 flex flex-col min-h-[5.25rem] overflow-auto max-h-[13.908rem] md:max-h-[28.563rem] ${
        className ?? ""
      }`}
      {...props}
    >
      <Tree
        onExpand={keys => {
          setExpandedKeys(keys);
          // setAutoExpandParent(false);
        }}
        expandedKeys={expandedKeys}
        // autoExpandParent={autoExpandParent}
        defaultExpandParent={false}
        onCheck={(keys, info) => {
          const selectedKeys = [...otherOrgKeys, ...(keys as Key[])];
          // console.log("🚀 ~ file: OrgTree.tsx:270 ~ selectedKeys:", selectedKeys);
          // bid 중복 제거
          const removedDuplicatedKeys = _.uniqWith(selectedKeys, (a, b) => {
            if ((a as string).includes("|") && (b as string).includes("|")) {
              return (a as string).split("|")[1] === (b as string).split("|")[1];
            } else {
              return a === b;
            }
          });
          // console.log("🚀 ~ file: OrgTree.tsx:261 ~ removedDuplicatedKeys:", removedDuplicatedKeys);
          setCheckedKeys(removedDuplicatedKeys);

          loadData(info.node, true).catch(error => {
            console.error(error);
          });
          // const checkedMembers = finalOrgFlatData.filter((user) =>
          //   removedDuplicatedKeys.includes(user.key)
          // );
          // const checkedMembers = finalOrgFlatData.filter((user) =>
          //   selectedKeys.includes(user.key)
          // );
          // console.log(
          //   "🚀 ~ file: OrgTree.tsx:274 ~ checkedMembers:",
          //   checkedMembers
          // );
          // const removedDuplicatedMembers = checkedMembers.filter(
          //   (user) => !removedDuplicatedKeys.includes(user.parentdeptcode)
          // );
          // const removedDuplicatedMembers = checkedMembers.filter(
          //   (user) => !selectedKeys.includes(user.parentdeptcode)
          // );
        }}
        checkedKeys={checkedKeys}
        switcherIcon={obj => {
          if (!obj.isLeaf) {
            return <SelectDown className={`${obj.expanded ? "" : "-rotate-90"}`} />;
          }
        }}
        checkable
        selectable={false}
        loadData={loadData}
      >
        {loop(sortedFinalOrgTreeData)}
      </Tree>
    </div>
  );
};

export default OrgTree;
