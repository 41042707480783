import { useEffect } from "react";
import { ReactComponent as ImgNoData } from "assets/img-no-data.svg";
import { useTranslation } from "react-i18next";

import i18next from "translate/i18n";

const NotSupportedBrowser = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const lang = navigator.language;
    i18next.changeLanguage(lang);
  }, []);

  return (
    <div className="absolute inset-0 w-full h-full flex flex-col justify-center items-center bg-white">
      <ImgNoData />
      <div className="mt-[1.312rem] text-lg font-bold text-[#555]">{t("browser.미지원")}</div>
      <div className="mt-6 text-sm text-[#555] flex flex-col items-center px-2 md:px-4">
        <div className="text-center">{t("browser.크롬사용유도")}</div>
        <div className="text-center">{t("browser.페이지비정상작동안내")}</div>
      </div>
    </div>
  );
};

export default NotSupportedBrowser;
