import { useEffect, useState } from "react";
import SelectListbox2 from "components/SelectListbox2";
import { Value } from "components/common/interfaces/TypeSelectListbox";
import { useDispatch, useSelector } from "react-redux";
import { setZoomType, ZoomType, selectZoomType } from "store/docSharingSlice";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export const options_zoom = [
  { id: "auto-fit", name: "" },
  { id: "height-fit", name: "" },
  { id: "width-fit", name: "" },
  { id: "0.25", name: "25%" },
  { id: "0.5", name: "50%" },
  { id: "0.75", name: "75%" },
  { id: "1.0", name: "100%" },
  { id: "1.25", name: "125%" },
  { id: "1.5", name: "150%" },
  { id: "1.75", name: "175%" },
  { id: "2.0", name: "200%" },
];

const ZoomSelector = () => {
  const [selectedOptionZoom, setSelectedOptionZoom] = useState<Value>(options_zoom[0]);
  const zoomType = useSelector(selectZoomType);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    options_zoom.find(opt => opt.id === "auto-fit")!.name = t("whiteboard.자동 맞춤");
    options_zoom.find(opt => opt.id === "height-fit")!.name = t("whiteboard.높이에 맞춤");
    options_zoom.find(opt => opt.id === "width-fit")!.name = t("whiteboard.너비에 맞춤");
  }, [t]);

  useEffect(() => {
    const idx = options_zoom.findIndex(opt => opt.id === zoomType);
    if (idx !== -1) setSelectedOptionZoom(options_zoom[idx]);
  }, [zoomType]);

  const handleChangeZoomType = (v: Value) => {
    setSelectedOptionZoom(v);
    console.log("handleChangeZoomType in ZoomSelector", v.id);
    dispatch(setZoomType(v.id as ZoomType));
  };

  return (
    <SelectListbox2
      options={options_zoom}
      value={selectedOptionZoom}
      onChange={v => handleChangeZoomType(v)}
      optionsHeight={40}
      isExpandUpper={isMobile}
    />
  );
};

export default ZoomSelector;
