import { useState, useEffect } from "react";
import { ReactComponent as ProfileNoImg } from "assets/profile-no-img.svg";
import { store } from "store/store";

interface ProfileImgProps {
  borderWidth: number;
  picurl?: string;
  padding?: number;
  borderColor?: string;
}
const ProfileImg = (props: ProfileImgProps) => {
  const { picurl, borderWidth, padding, borderColor } = props;
  const [imgLoadError, setImgLoadError] = useState(false);
  const [imgSrc, setImgSrc] = useState<string>();

  useEffect(() => {
    async function load() {
      if (!picurl) return;
      if (picurl.startsWith("http://")) {
        // const result = await loadImageToBase64(picurl);
        // setImgSrc(result);
        const proxyUrl = `${store.getState().env.value.SERVER_URL}/imageProxy?url=${picurl}`;
        setImgSrc(proxyUrl);
      } else {
        setImgSrc(picurl);
      }
    }
    load();
  }, [picurl]);

  return (
    <div
      className="bg-black rounded-full overflow-hidden w-full h-full border border-[#707070] shrink-0 justify-center items-center"
      style={{
        padding: padding ? `${padding}rem` : "0",
        borderWidth: borderWidth ? `${borderWidth}px` : "0",
        borderColor: borderColor ? `${borderColor}` : "#707070",
      }}
    >
      {imgLoadError || !picurl ? (
        <ProfileNoImg fill="#555" className="p-0" />
      ) : (
        <img className="flex justify-center items-center w-full h-full object-cover" src={imgSrc} alt="" onError={() => setImgLoadError(true)} />
      )}
    </div>
  );
};

export default ProfileImg;
