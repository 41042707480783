import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUtilState {
  screenShotFunc: () => void;
}

const initialState: IUtilState = {
  screenShotFunc: () => {},
};

export const utilSlice = createSlice({
  name: "util",
  initialState,
  reducers: {
    setScreenshotFunc: (state: IUtilState, action: PayloadAction<() => void>) => {
      state.screenShotFunc = action.payload;
    },
    executeScreenshotFunc: (state: IUtilState) => {
      state.screenShotFunc();
    },
  },
});

export const { setScreenshotFunc, executeScreenshotFunc } = utilSlice.actions;

export default utilSlice.reducer;
