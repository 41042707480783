import RoomClient from "../../../RoomClient";
import { withRoomContext } from "../../../RoomContext";
import { AuthUserInfo } from "../../../types/commonTypes";
import { useTranslation } from "react-i18next";
import UserInfoDraw from "./UserInfoRow";

interface IProps {
  roomClient: RoomClient;
  members: AuthUserInfo[];
}

const LobbyMemberList = ({ roomClient, members }: IProps) => {
  const { t } = useTranslation();

  const handleReject = (m: AuthUserInfo) => {
    console.log("참여거절", m);
    roomClient.sendJoinAllow(roomClient.roomId, m.bid, false);
  };

  const handleAccept = (m: AuthUserInfo) => {
    console.log("참여수락", m);
    roomClient.sendJoinAllow(roomClient.roomId, m.bid, true);
  };

  return (
    <div className="w-full">
      {members.map(m => (
        <div key={m.bid} className="relative flex w-full h-12 justify-between items-center py-[5px] px-[10px] bg-[#333]">
          <UserInfoDraw m={m} isHost={false} />
          <div className="absolute right-4 flex cursor-pointer">
            <button
              className="flex justify-center items-center min-w-[2.875rem] h-[1.5rem] bg-red-20 hover:bg-red-30 rounded cursor-pointer mx-0.5 text-sm px-1"
              color="#fa114f"
              onClick={() => handleReject(m)}
            >
              {t("users.거절")}
            </button>
            <button
              className="flex justify-center items-center min-w-[2.875rem] h-[1.5rem] bg-blue-20 hover:bg-blue-30 rounded cursor-pointer mx-0.5 text-sm px-1"
              onClick={() => handleAccept(m)}
            >
              {t("users.수락")}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
export default withRoomContext(LobbyMemberList);
