import Tooltip from "../Tooltip";
import { ITooltipProps } from "../interfaces/ITooltipProps";

interface Props extends ITooltipProps {
  classNameSVG?: string;
  tooltipContent?: string;
}

const CloseButton = ({ onClick, width = 24, height = 24, className, classNameSVG, tooltipContent }: Props) => {
  return (
    <Tooltip content={tooltipContent}>
      <button onClick={onClick} className={className}>
        <svg
          className={`stroke-2 ${classNameSVG ? classNameSVG : "stroke-C-555555 hover:stroke-C-1f62b9"}`}
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <g transform="translate(-13.996)">
            <g transform="translate(18.997 5)">
              <path d="m0 0 14 14" strokeLinecap="round" strokeLinejoin="round" />
              <path strokeLinecap="round" strokeLinejoin="round" transform="rotate(90 7 7)" d="m0 0 14 14" />
            </g>
          </g>
        </svg>
      </button>
    </Tooltip>
  );
};

export default CloseButton;
