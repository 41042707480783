import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPeers } from "store/peerSlice";
import { setCanClose, selectKeyDown } from "store/chatSlice";
import { AuthUserInfo } from "types/commonTypes";
import ProfileImg from "../../ProfileImg";

interface PickerProps {
  onSelect: (myConsumer: ChatTarget) => void;
  localSocketId: string;
}

export interface ChatTarget {
  userInfo: AuthUserInfo;
  socketId: string;
}

const UserPicker = (props: PickerProps) => {
  const { onSelect, localSocketId } = props;
  const dispatch = useDispatch();
  const peers = useSelector(selectPeers);
  const keyDown = useSelector(selectKeyDown);
  const [targetList, setTargetList] = useState<ChatTarget[]>([]);
  const [selectId, setSelectId] = useState("");

  useEffect(() => {
    let newTarget: ChatTarget[] = [];
    peers.forEach(item => {
      if (localSocketId !== item.socketId) {
        newTarget = [
          ...newTarget,
          {
            userInfo: item.userInfo,
            socketId: item.socketId,
          },
        ];
      }
    });

    setTargetList(newTarget);
    if (newTarget.length > 0) setSelectId(newTarget[0].socketId);
  }, [peers, localSocketId]);

  useEffect(() => {
    // console.log('UserPicker-키누름 수신:', keyDown);
    // todo: 누른키에 따라서 목록을 이동시키며 포커스를 준다.
    if (targetList && keyDown.length > 0) {
      if (keyDown === "Enter") {
        // console.log('enter 키 눌림', selectId);
        const find = targetList.find(item => item.socketId === selectId);
        if (find) {
          // console.log('선택된 사용자:', find?.userInfo.username, find.socketId);
          onSelect(find);
        }
      } else {
        if (targetList.length === 1) return;
        const direction = keyDown === "ArrowDown" ? 1 : -1;

        // console.log(' ================ 대상 찾기 todo:', keyDown, selectId);
        const selectIdx = targetList.findIndex(item => item.socketId === selectId);
        // console.log(`기존 selectIdx:${selectIdx}, 크기:${targetList.length}, 방향:${direction}`);
        let i = Math.max(selectIdx, 0);

        while (true) {
          if (i < 0 || i >= targetList.length) {
            break;
          }

          if (targetList[i].socketId !== selectId) {
            // console.log('선택', targetList[i].userInfo.username);
            setSelectId(targetList[i].socketId);
            break;
          }

          i += direction;
          if (i >= targetList.length) {
            i = 0;
          } else if (i < 0) {
            i = targetList.length - 1;
          }
        }
      }
    }
  }, [keyDown, onSelect, selectId, targetList]);

  useEffect(() => {
    dispatch(setCanClose(false));

    return () => {
      dispatch(setCanClose(true));
    };
  }, [dispatch]);

  const handlePick = (myConsumer: ChatTarget) => {
    console.log("userPick", myConsumer.userInfo.username);
    onSelect(myConsumer);
  };

  const DrawRow = (item: ChatTarget) => {
    return (
      <li
        key={item.socketId}
        className={`${item.socketId === selectId && "bg-[#777]"} h-[2.75rem] px-4 flex justify-start items-center cursor-pointer hover:bg-[#777]`}
        onClick={() => handlePick(item)}
        onMouseEnter={() => setSelectId(item.socketId)}
      >
        <div className="ml-1 w-8 h-8">
          <ProfileImg picurl={item.userInfo.picurl} borderWidth={1} />
        </div>
        <div className="ml-4">{item.userInfo.username}</div>
      </li>
    );
  };

  return (
    <div className="bg-[#555] h-full text-white text-sm shadow-md px-[0rem] py-1">
      <ul className="h-full max-h-[50vh] overflow-y-auto">
        {targetList.map((item: ChatTarget) => (
          <DrawRow key={item.socketId} userInfo={item.userInfo} socketId={item.socketId} />
        ))}
      </ul>
    </div>
  );
};

export default UserPicker;
