import { createGlobalState } from "react-use";
import { TreeDataNode } from "../interfaces/OrgData";
import { isBrowser } from "react-device-detect";
import { IInputCheckboxState } from "../interfaces/Collaboration";

export interface Payload {
  sid?: string;
  bid?: string;
  // isOperator: boolean;
  name?: string;
  department?: string;
  position?: string;
  isProjectAdmin?: boolean;
  picUrl?: string;
  gid?: string;
  rootGid?: string;
}

interface Env {
  HOST: string | null;
  AZURE: string | null;
}

export interface ParentLevel {
  key: string;
  level: number;
}

export const useGlobalAssignee = createGlobalState<TreeDataNode[]>([]);
export const useGlobalPayload = createGlobalState<Payload>({
  sid: undefined,
  bid: undefined,
  // isOperator: false,
  name: undefined,
  department: undefined,
  position: undefined,
  isProjectAdmin: false,
  picUrl: undefined,
  gid: undefined,
  rootGid: undefined,
});
export const useGlobalStorageId = createGlobalState("");
export const useGlobalEnv = createGlobalState<Env>({
  HOST: null,
  AZURE: null,
});
export const useGlobalAsideOpen = createGlobalState(isBrowser);
export const useGlobalScheduleInputRadioState = createGlobalState({
  scheduleKind: "BOTH",
  todoKind: "BOTH",
  projectKind: "BOTH",
});
export const useGlobalScheduleCheckboxes = createGlobalState<IInputCheckboxState>({});
export const useGlobalScheduleSubscription = createGlobalState<Record<string, boolean>>({});
export const useGlobalParentLevel = createGlobalState<ParentLevel[]>([]);
