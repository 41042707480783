import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store/store";
import { SimpleUserInfo } from "types/commonTypes";

export interface IUsers {
  map: Map<string, SimpleUserInfo>;
}

const initialState: IUsers = {
  map: new Map(),
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUser: (state: IUsers, action: PayloadAction<SimpleUserInfo>) => {
      // console.log("store users.addUser", action.payload);
      state.map.set(action.payload.bidOrEmail, action.payload);
    },
  },
});

export const { addUser } = usersSlice.actions;
export const selectUsers = (state: RootState) => state.users.map;

export default usersSlice.reducer;
