import { Dialog } from "@headlessui/react";
import Tabs from "../Tabs";
import Button from "../buttons/Button";
import SearchSelectedBox from "./SearchSelectedBox";
import OrgTreeBox from "./OrgTreeBox";
import { useEffect, useState } from "react";
import { TreeDataNode } from "../interfaces/OrgData";
import { Key } from "rc-tree/lib/interface";
import { useGlobalAssignee } from "../hooks/useGlobalState";
import { useGlobalPayload } from "../hooks/useGlobalState";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  title?: string;
  onResult: (data: TreeDataNode[]) => void;
}

const DialogAssignee = ({ isOpen, setIsOpen, title, onResult }: Props) => {
  const { t } = useTranslation();
  const [selectedMember, setSelectedMember] = useState<TreeDataNode[]>([]);
  const [isBtnConfirmDisabled, setIsBtnConfirmDisabled] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [, setGlobalAssignee] = useGlobalAssignee();
  const [globalPayload] = useGlobalPayload();

  const tabList = [
    { id: "tab1", name: t("adduser.통합 검색") },
    { id: "tab2", name: t("adduser.내 부서") },
    { id: "tab3", name: t("adduser.조직도") },
  ];

  useEffect(() => {
    if (!isOpen) {
      setSelectedMember([]);
      setCheckedKeys([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedMember?.length) {
      setIsBtnConfirmDisabled(false);
    } else {
      setIsBtnConfirmDisabled(true);
    }
  }, [selectedMember?.length]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative">
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <Dialog.Panel className="flex flex-col rounded-md shadow-listbox-options pt-5 pr-5 pl-5 pb-[1.875rem] bg-white m-2.5 w-[99%] h-[99%] max-w-[45.25rem] max-h-[41.188rem] overflow-auto">
          <Dialog.Title className="flex-none font-bold text-C-555555">{title ? title : t("adduser.참석자 선택")}</Dialog.Title>

          <Tabs
            tabList={tabList}
            tabPanels={[
              {
                content: (
                  <SearchSelectedBox
                    selectedMember={selectedMember}
                    setSelectedMember={setSelectedMember}
                    checkedKeys={checkedKeys}
                    setCheckedKeys={setCheckedKeys}
                  />
                ),
                id: "panel1",
              },
              {
                content: (
                  <>
                    {globalPayload.gid && (
                      <OrgTreeBox
                        gid={globalPayload.gid}
                        selectedMember={selectedMember}
                        setSelectedMember={setSelectedMember}
                        checkedKeys={checkedKeys}
                        setCheckedKeys={setCheckedKeys}
                      />
                    )}
                  </>
                ),
                id: "panel2",
              },
              {
                content: (
                  <>
                    {globalPayload.rootGid && (
                      <OrgTreeBox
                        gid={globalPayload.rootGid}
                        selectedMember={selectedMember}
                        setSelectedMember={setSelectedMember}
                        checkedKeys={checkedKeys}
                        setCheckedKeys={setCheckedKeys}
                      />
                    )}
                  </>
                ),
                id: "panel3",
              },
            ]}
            className="flex-auto pt-5 flex flex-col"
            panelClassName="pt-3"
          />

          <div className="flex flex-row flex-nowrap flex-none pt-[1.875rem] justify-center">
            <Button kind="cancel" label={t("취소")} onClick={() => setIsOpen(false)} />
            <Button
              disabled={isBtnConfirmDisabled}
              kind="primary"
              label={t("확인")}
              className="ml-6"
              onClick={() => {
                setGlobalAssignee(selectedMember);
                onResult(selectedMember);
                setIsOpen(false);
              }}
            />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default DialogAssignee;
