import * as jose from "jose";
interface IProps {
  email: string;
  name: string;
  tenantId: string;
}

export const parseToken = async (token: string): Promise<IProps> => {
  const secretKey = new TextEncoder().encode(process.env.REACT_APP_MGR_TOKEN_KEY);
  const { payload } = await jose.jwtVerify(token, secretKey);
  const result = { email: payload.email as string, name: payload.name as string, tenantId: payload.tenantId as string };
  return result;
};

export const makeToken = async ({ email, name, tenantId }: IProps): Promise<string> => {
  const secretKey = new TextEncoder().encode(process.env.REACT_APP_MGR_TOKEN_KEY);
  const token = await new jose.SignJWT({ email, name, tenantId }).setProtectedHeader({ typ: "JWT", alg: "HS256" }).sign(secretKey);
  return token;
};
