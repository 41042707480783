export type BackgroundConfig = {
  type: BackgroundType;
  url?: string;
};

export type BackgroundType = "none" | "blur" | "image";

const IMG_CNT = 15;

export const backgroundImageUrls = [...Array(IMG_CNT).keys()].map(
  idx => `${process.env.PUBLIC_URL}/virtualbg/backgrounds/${String(idx + 1).padStart(2, "0")}.jpg`,
);

export const backgroundThumbUrls = [...Array(IMG_CNT).keys()].map(
  idx => `${process.env.PUBLIC_URL}/virtualbg/backgrounds/${String(idx + 1).padStart(2, "0")}_thumb.jpg`,
);
