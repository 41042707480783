export interface ArrowDownButtonProps {
  width?: number;
  height?: number;
  onClick?: () => void;
  className?: string;
  stroke?: string;
}

const ArrowDownButton = (props: ArrowDownButtonProps) => {
  const { width = 16, height = 16, className, stroke } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" className={className}>
      <path d="M0 0h16v16H0z" className="fill-transparent" />
      <path
        d="m3.4 3.753 5 5 5-5"
        transform="translate(-.404 2.247)"
        className="stroke-2 fill-transparent"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={stroke ?? "#fff"}
      />
    </svg>
  );
};

export default ArrowDownButton;
