import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "components/common/buttons/Button";
import { ReactComponent as IconEtching } from "assets/icon-eching.svg";
import { ReactComponent as IconSplit } from "assets/icon-split-ex.svg";
import { useDispatch, useSelector } from "react-redux";
import { LayoutType, selectLayoutType, setLayoutType } from "store/roomInfoSlice";
import { useTranslation } from "react-i18next";

interface ModalDefaultType {
  onCloseView: () => void;
  buttonRef?: React.RefObject<HTMLDivElement>;
}

enum Color {
  normal = "#fff",
  selected = "#4495ff",
}

const SelectViewMode = ({ onCloseView, buttonRef }: ModalDefaultType) => {
  const { t } = useTranslation();
  const rootDivRef = useRef<HTMLDivElement>(null);
  const layoutType = useSelector(selectLayoutType);
  const [newLayoutType, setNewLayoutType] = useState<LayoutType>(layoutType);
  const [colorSplit, setColorSplit] = useState(Color.normal);
  const [colorEtching, setColorEtching] = useState(Color.normal);
  const dispatch = useDispatch();

  const handleClickOutside = useCallback(
    (ev: Event) => {
      if (buttonRef && buttonRef.current?.contains(ev.target as Node)) {
        return;
      }

      if (rootDivRef.current && !rootDivRef.current.contains(ev.target as Node)) {
        onCloseView();
      }
    },
    [buttonRef, onCloseView],
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (layoutType === LayoutType.Split) setColorSplit(Color.selected);
    else setColorEtching(Color.selected);
  }, [layoutType]);

  const handleSelect = (type: LayoutType) => {
    if (type === LayoutType.Highlight) {
      setColorEtching(Color.selected);
      setColorSplit(Color.normal);
    } else {
      setColorEtching(Color.normal);
      setColorSplit(Color.selected);
    }

    setNewLayoutType(type);
  };

  const handleApply = () => {
    dispatch(setLayoutType(newLayoutType));
    onCloseView();
  };

  return (
    <div ref={rootDivRef} className="w-96 h-64 p-0 rounded shadow bg-[#333]">
      <div className="flex w-full h-9 justify-start items-center">
        <div className="h-5 ml-4 text-sm font-bold leading-6 text-white">{t("layout.화면 보기 변경")}</div>
      </div>
      <div className="w-full h-px bg-zinc-600" />

      <div className="flex flex-row justify-center items-center mt-6">
        <button
          className="flex flex-col justify-center items-center w-40 h-32 mx-1.5 pt-5 rounded border border-blue-400"
          onClick={() => handleSelect(LayoutType.Split)}
        >
          <IconSplit fill={colorSplit} />
          <div className="text-base font-bold text-white mt-5">{t("layout.분할보기")}</div>
        </button>
        <button
          className="flex flex-col justify-center items-center w-40 h-32 mx-1.5 pt-5 rounded border border-blue-400"
          onClick={() => handleSelect(LayoutType.Highlight)}
        >
          <IconEtching fill={colorEtching} />
          <div className="text-base font-bold text-white mt-5">{t("layout.강조보기")}</div>
        </button>
      </div>

      <div className="flex justify-center mt-4">
        <Button kind={"primary"} label={t("적용")} onClick={handleApply} />
        {/* <button className="rounded flex justify-center items-center w-24 h-8 bg-sky-500 hover:bg-sky-700" onClick={handleApply}>
          <div className="text-base text-white">{t("적용")}</div>
        </button> */}
      </div>
    </div>
  );
};

export default SelectViewMode;
