import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store/store";

export enum RecordingState {
  Stop = "Stop",
  Prepare = "Prepare",
  Recording = "Recording",
  Pause = "Pause",
  Complete = "Complete",
}

interface State {
  localRecordingState: RecordingState;
  micDeviceId: string;
  latestStateTime: Date;
}

const initialState: State = {
  localRecordingState: RecordingState.Stop,
  micDeviceId: "",
  latestStateTime: new Date(),
};

export const recordingSlice = createSlice({
  name: "recording",
  initialState,
  reducers: {
    setLocalRecordingState: (state: State, action: PayloadAction<RecordingState>) => {
      // console.log("store localRecordingState", action.payload);
      state.localRecordingState = action.payload;
    },
    setRecordingMicId: (state: State, action: PayloadAction<string>) => {
      state.micDeviceId = action.payload;
    },
    setLatestRecordingStartTime: (state: State, action: PayloadAction<Date>) => {
      state.latestStateTime = action.payload;
      console.log("store setLastestRecordingStartTime", action.payload);
    },
  },
});

export const { setLocalRecordingState, setRecordingMicId, setLatestRecordingStartTime } = recordingSlice.actions;
export const selectLocalRecordingState = (state: RootState) => state.recording.localRecordingState;
export const selectRecordingMicId = (state: RootState) => state.recording.micDeviceId;
export const selectLatestRecordingStartTime = (state: RootState) => state.recording.latestStateTime;
export default recordingSlice.reducer;
