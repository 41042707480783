import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconError } from "assets/icon-error.svg";

export type IErrorDeviceType = "webcam" | "mic";

interface IProps {
  type: IErrorDeviceType;
  isShowTooltip: boolean;
}

const PermissionError = ({ type, isShowTooltip }: IProps) => {
  const [isShowHelp, setIsShowHelp] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("PermissionError isShowTooltip", isShowTooltip);
  }, [isShowTooltip]);

  return (
    <div className="relative text-black text-sm translate-x-0 z-50" onClick={() => setIsShowHelp(!isShowHelp)}>
      <button className="z-40 select-none px-1 font-bold text-white">
        <IconError />
      </button>
      {isShowHelp ||
        (isShowTooltip && (
          <div className="absolute bottom-0 -translate-y-6 left-0 w-fit">
            <div className="p-2 bg-black/75 text-white rounded relative whitespace-nowrap">
              {type === "webcam" ? (
                <div className="flex justify-center">{t("msg.브라우저가 카메라에 대한 액세스를 차단하고 있습니다.")}</div>
              ) : (
                <div className="flex justify-center whitespace-nowrap">{t("msg.브라우저가 마이크에 대한 액세스를 차단하고 있습니다.")}</div>
              )}

              {type === "webcam" ? (
                <a href="/help/webcam" target="_blank" rel="noreferrer">
                  <div className="flex justify-center text-[#4495ff] ml-1">[{t("msg.카메라에 대한 액세스를 허용하는 방법")}]</div>
                </a>
              ) : (
                <a href="/help/webcam" target="_blank" rel="noreferrer">
                  <div className="flex justify-center text-[#4495ff] ml-1">[{t("msg.마이크에 대한 액세스를 허용하는 방법")}]</div>
                </a>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default PermissionError;
