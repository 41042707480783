import React, { useState } from "react";

interface IMenuButton {
  SvgIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  onClick: any;
  isCheck?: boolean;
}

const MenuButton = React.forwardRef((props: IMenuButton, ref) => {
  const { isCheck, label, SvgIcon, onClick } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="flex flex-col w-[4.75rem] h-[4.75rem] justify-center items-center cursor-pointer"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <SvgIcon fill={isHovered || isCheck ? "#4495ff" : "#fff"} className="w-9 h-9" />
      <div className={`text-xs font-bold hidden text-center text-white mt-1 md:flex ${isHovered || isCheck ? "text-blue-10" : "text-white"} `}>
        {label}
      </div>
    </div>
  );
});

export default MenuButton;
