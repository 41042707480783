import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconError } from "assets/icon-error.svg";

const DeviceNotFoundError = () => {
  const [isShowHelp, setIsShowHelp] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="relative text-black text-sm translate-x-0 z-50" onClick={() => setIsShowHelp(!isShowHelp)}>
      <button className="z-40 select-none px-1 font-bold text-white">
        <IconError />
      </button>
      {isShowHelp && (
        <div className="absolute bottom-0 -translate-y-6 left-0 w-fit">
          <div className="p-2 bg-black/75 text-white rounded relative whitespace-nowrap">
            <div className="flex justify-center">{t("msg.장치를 찾을 수 없습니다.")}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceNotFoundError;
