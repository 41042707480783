import React, { useEffect, useState } from "react";
import DocSharingPageList from "components/docSharing/pagelist/DocSharingPageList";
import DocSharingHostBoard from "components/docSharing/board/DocSharingHostBoard";
import DocSharingTool from "components/docSharing/tool/DocSharingTool";
import PipOnOffButton from "components/common/buttons/PipOnOffButton";
import { selectConvertedFileList } from "store/docSharingSlice";
import { useSelector } from "react-redux";
import { selectBgDrawSize } from "store/docSharingSlice";
import { useMobileOrientation } from "react-device-detect";
import { useMeasure } from "react-use";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

const DocSharingHostViewRoot = () => {
  const [pipBtnStyle, setPipBtnStyle] = useState({});
  const img = useSelector(selectBgDrawSize);
  const convertedFileList = useSelector(selectConvertedFileList);
  const { isPortrait } = useMobileOrientation();
  const [ref, { width, height }] = useMeasure<HTMLDivElement>();
  const isLandscape = useMediaQuery({ orientation: "landscape" });

  useEffect(() => {
    const divStyle = {};
    const imgRight = img.x + img.width;
    const imgBottom = img.y + img.height;

    if (imgRight < width) {
      Object.assign(divStyle, { left: imgRight - 50 });
    }

    if (imgBottom < height) {
      Object.assign(divStyle, { top: imgBottom - 50 });
    }

    setPipBtnStyle(divStyle);
  }, [img, width, height]);

  return (
    <>
      {isMobile ? (
        <>
          {isLandscape ? (
            <div className="h-full w-full flex justify-center items-center overflow-hidden bg-[#333]">
              {convertedFileList.length > 1 && (
                <div className="w-[6.75rem] h-full z-20">
                  <DocSharingPageList />
                </div>
              )}
              <div ref={ref} className="relative flex grow w-full h-full justify-center">
                <DocSharingHostBoard />
                <PipOnOffButton divStyle={pipBtnStyle} />
              </div>
            </div>
          ) : (
            <div ref={ref} className="relative w-full h-full flex flex-col justify-center overflow-hidden bg-[#333]">
              <DocSharingHostBoard />

              {!isPortrait && <PipOnOffButton divStyle={pipBtnStyle} />}
            </div>
          )}
        </>
      ) : (
        <div className="h-full w-full flex overflow-hidden bg-[#333]">
          {convertedFileList.length > 1 && (
            <div className="w-[6.75rem] md:w-[13.75rem] h-full z-20 shrink-0">
              <DocSharingPageList />
            </div>
          )}
          <div ref={ref} className="relative flex grow w-full h-full justify-center">
            <div className="absolute top-2 z-30 w-fit">
              <DocSharingTool isHost={true} />
            </div>
            <DocSharingHostBoard />

            {!(isMobile && isPortrait) && <PipOnOffButton divStyle={pipBtnStyle} />}
          </div>
        </div>
      )}
    </>
  );
};

export default DocSharingHostViewRoot;
