import { IInnerAttendee, invite, IOuterAttendee } from "api/manager/invite";
import { TreeDataNode } from "components/common/interfaces/OrgData";

interface IProps {
  roomId: number;
  internalAttendees: IInnerAttendee[];
  externalAttendees: IOuterAttendee[];
}

export const extractGroup = (data: TreeDataNode): TreeDataNode[] => {
  let result: TreeDataNode[] = [];

  if (data.children === null || data.children?.length === 0) {
    return [data];
  } else {
    data.children?.forEach(child => {
      const ext = extractGroup(child);
      result = [...result, ...ext];
    });
  }
  return result;
};

const inviteExec = async ({ roomId, internalAttendees, externalAttendees }: IProps) => {
  let innerAttendees: IInnerAttendee[] = [...internalAttendees];
  let outerAttendees: IOuterAttendee[] = [];

  externalAttendees.forEach(item => {
    const outer: IOuterAttendee = {
      email: item.email,
      name: item.name,
    };

    outerAttendees.push(outer);
  });

  const res = await invite(roomId, innerAttendees, outerAttendees);
  return res;
};

export default inviteExec;
