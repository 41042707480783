import { useState } from "react";
import ButtonMicOnLarge from "./ButtonMicOnLarge";
import ButtonMicOffLarge from "./ButtonMicOffLarge";

interface IProps {
  isEnable: boolean;
  onClick: () => void;
}

const ButtonMic = ({ isEnable, onClick }: IProps) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div onClick={onClick} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      {isEnable ? <ButtonMicOnLarge stroke={isHover ? "#4495ff" : "#fff"} /> : <ButtonMicOffLarge stroke={isHover ? "#4495ff" : "#fff"} />}
    </div>
  );
};

export default ButtonMic;
