export const saveJoinAllow = (roomId: number, bid: string) => {
  window.sessionStorage.setItem(`join-allow-${roomId}`, bid);
};

export const isSavedJoinAllow = (roomId: number, bid: string) => {
  const savedBid = window.sessionStorage.getItem(`join-allow-${roomId}`);
  return savedBid === bid;
};

export const removeJoinAllow = (roomId: number) => {
  window.sessionStorage.removeItem(`join-allow-${roomId}`);
};
