import { useTranslation } from "react-i18next";

interface IProps {
  onSend: () => void;
  isSendAble: boolean;
}

const SendButton = (props: IProps) => {
  const { onSend, isSendAble } = props;
  const { t } = useTranslation();

  return (
    <button
      className={`w-[5.25rem] h-[2rem] px-2 rounded ${
        isSendAble ? "bg-[#1f62b9] hover:bg-[#3f82d9] text-[#ffffff]" : "bg-[#444] border border-[#555] text-[#636465] cursor-default"
      }`}
      onClick={onSend}
    >
      {t("전송")}
    </button>
  );
};

export default SendButton;
