import axios, { AxiosRequestConfig } from "axios";

export const axiosInstance = axios.create();

const request = {
  get: <T>(url: string, config?: AxiosRequestConfig<any>) => axiosInstance.get<T>(url, config),
  post: <T>(url: string, data: unknown, config?: AxiosRequestConfig<any>) => axiosInstance.post<T>(url, data, config),
  patch: <T>(url: string, data: unknown, config?: AxiosRequestConfig<any>) => axiosInstance.patch<T>(url, data, config),
  put: <T>(url: string, data: unknown, config: AxiosRequestConfig<any>) => axiosInstance.put<T>(url, data, config),
  delete: <T>(url: string, config?: AxiosRequestConfig<any>) => axiosInstance.delete<T>(url, config),
};

export default request;
