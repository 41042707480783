import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { AuthUserInfo } from "../types/commonTypes";

interface State {
  token: string;
  sid: string;
  bid: string;
  user: AuthUserInfo | null;
}

const initialState: State = {
  token: "",
  sid: "",
  bid: "",
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state: State, action: PayloadAction<string>) => {
      state.token = action.payload;
      // console.log('setToken ==============', action.payload.slice(0, 10));
    },
    setSid: (state: State, action: PayloadAction<string>) => {
      state.sid = action.payload;
      // console.log('setSid: ', action.payload);
    },
    setBid: (state: State, action: PayloadAction<string>) => {
      state.bid = action.payload;
      // console.log('setSid: ', action.payload);
    },
    setAuthUserInfo: (state: State, action: PayloadAction<AuthUserInfo>) => {
      state.user = action.payload;
      // console.log('setAuthUserInfo', state.user);
    },
  },
});

export const { setToken, setSid, setBid, setAuthUserInfo } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;
export const selectSid = (state: RootState) => state.auth.sid;
export const selectBid = (state: RootState) => state.auth.bid;
export const selectAuthUserInfo = (state: RootState) => state.auth.user;

export default authSlice.reducer;
