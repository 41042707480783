import ReactDom from "react-dom";

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
}

export const Modal = ({ isOpen, children }: ModalProps) => {
  if (!isOpen) {
    return null;
  }

  const element = typeof window !== "undefined" && document.querySelector("#modal");
  // console.log('modal element', element);
  return element && children ? ReactDom.createPortal(children, element) : null;
};
