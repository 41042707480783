import { useInfiniteQuery, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import request, { axiosInstance } from "./request";
import { store } from "store/store";

export type QueryKeyT = ["get" | "post", string, object | undefined];

export interface GetInfinitePagesInterface<T> {
  data?: T & { eof?: boolean };
  cursorId?: number;
  hasNext?: boolean;
  results?: T[];
  totalCount?: number;
}

interface FetcherProps {
  queryKey: QueryKeyT;
  pageParam?: number;
  globalJWT?: string;
}

const fetcher = ({ queryKey, pageParam }: FetcherProps) => {
  const [method, url, params] = queryKey;
  const commonHost = store.getState().env.value.AUTH_SERVER;

  if (method === "get") {
    return request.get<any>(url, { params: { ...params, pageParam } }).then(res => res.data);
  } else if (method === "post" && commonHost) {
    // console.log("fetcher url", url);
    // console.log("fetcher commonHost", commonHost);

    return request
      .post<any>(url, url.startsWith(commonHost) ? { ...params, reqpage: pageParam } : { ...params, cursorId: pageParam }, {
        baseURL: commonHost,
      })
      .then(res => res.data);
  }
};

export const useLoadMore = <T>(method: "get" | "post", url: string, params?: object, enabled?: boolean) => {
  const commonHost = store.getState().env.value.AUTH_SERVER;

  return useInfiniteQuery<GetInfinitePagesInterface<T>, Error, GetInfinitePagesInterface<T>, QueryKeyT>(
    [method, url, params],
    commonHost && url.startsWith(commonHost)
      ? async ({ queryKey, pageParam = 1 }: FetcherProps) => fetcher({ queryKey, pageParam })
      : async ({ queryKey, pageParam }: FetcherProps) => fetcher({ queryKey, pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.data && !lastPage.data.eof) {
          return allPages.length + 1;
        } else if (lastPage.hasNext) {
          return lastPage.cursorId;
        } else {
          return undefined;
        }
      },
      enabled: enabled ?? true,
    },
  );
};

export const useFetch = <T>(
  method: "get" | "post",
  url: string,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>,
  enabled?: boolean,
) =>
  useQuery<T, Error, T, QueryKeyT>([method, url, params], async ({ queryKey }) => fetcher({ queryKey }), {
    enabled: enabled && !!axiosInstance.defaults.headers.common.Authorization,
    ...config,
  });

export const usePost = <P, T = void>(url: string, options?: UseMutationOptions<AxiosResponse<T>, unknown, P>) => {
  const commonHost = store.getState().env.value.AUTH_SERVER;
  return useMutation<AxiosResponse<T>, unknown, P>(
    data =>
      request.post<T>(url, data, {
        baseURL: commonHost,
      }),
    options,
  );
};

export const useUpdate = <P, T = void>(url: string, options?: UseMutationOptions<AxiosResponse<T>, unknown, P>) => {
  const commonHost = store.getState().env.value.AUTH_SERVER;
  return useMutation<AxiosResponse<T>, unknown, P>(
    data =>
      request.patch<T>(url, data, {
        baseURL: commonHost,
      }),
    options,
  );
};

export const useDelete = <P, T = void>(url: string, options?: UseMutationOptions<AxiosResponse<T>, unknown, P>) => {
  const commonHost = store.getState().env.value.AUTH_SERVER;
  return useMutation<AxiosResponse<T>, unknown, P>(
    data =>
      request.delete<T>(url, {
        data,
        baseURL: commonHost,
      }),
    options,
  );
};
