import { useEffect, useRef } from "react";

interface IProps {
  stream?: MediaStream;
}

const ScreenShareSmallPreview = (props: IProps) => {
  const { stream } = props;
  const screenShareRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!screenShareRef.current) return;
    if (stream !== undefined) screenShareRef.current.srcObject = stream;
  }, [stream]);

  if (!stream) {
    return <></>;
  }

  return <video className="max-h-full bg-black" autoPlay muted ref={screenShareRef} width="100%" height="100%" playsInline disablePictureInPicture />;
};

export default ScreenShareSmallPreview;
