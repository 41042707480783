import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { FinishedTask } from "api/cloudconvert/types";
import { Point, IDocSharingPageInfo, Line, IPenPath } from "components/docSharing/docSharingType";
import { IDocSharingHost } from "types/commonTypes";

export type DocSharingEditMode = "CURSOR" | "POINTER" | "PEN" | "ERASER" | "SELECT_COLOR";
export type ZoomType = "auto-fit" | "height-fit" | "width-fit" | "0.5" | "0.75" | "1.0" | "1.25" | "1.5" | "1.75" | "2.0";

export interface IDocSharingCurPos {
  x: number;
  y: number;
  mode: DocSharingEditMode;
}

export interface IBgDrawSize {
  width: number;
  height: number;
  x: number;
  y: number;
}

type DocSharingState = {
  finishedTask: Map<string, FinishedTask>;
  convertedFileList: string[];
  currentIndex: number;
  currentFileId: string;
  editMode: DocSharingEditMode;
  refreshTime: number;
  penColor: string;
  penPath: IPenPath[];
  localHostPageInfo: IDocSharingPageInfo | undefined;
  zoomType: ZoomType;
  hostPageInfo: Map<string, IDocSharingPageInfo>;
  hostBgStartPos: Point;
  localBgStartPos: Point;
  hostCursorPos: IDocSharingCurPos;
  currentHostSocketId: string;
  bgDrawSize: IBgDrawSize;
  isShowColorPicker: boolean;
  isWhiteBoard: boolean;
  isBgImageMoving: boolean;
  isShowToolbar: boolean;
};

const initialState: DocSharingState = {
  finishedTask: new Map<string, FinishedTask>(),
  convertedFileList: [],
  currentIndex: 0,
  currentFileId: "",
  editMode: "CURSOR",
  refreshTime: 0,
  penColor: "#ff0000ff",
  penPath: [],
  localHostPageInfo: undefined,
  zoomType: "auto-fit",
  hostPageInfo: new Map<string, IDocSharingPageInfo>(),
  hostBgStartPos: { x: 0, y: 0 },
  localBgStartPos: { x: 0, y: 0 },
  hostCursorPos: { x: 0, y: 0, mode: "CURSOR" },
  currentHostSocketId: "",
  bgDrawSize: { width: 0, height: 0, x: 0, y: 0 },
  isShowColorPicker: false,
  isWhiteBoard: false,
  isBgImageMoving: false,
  isShowToolbar: true,
};

export const docSharingSlice = createSlice({
  name: "convert",
  initialState,
  reducers: {
    setFinishedTask: (state: DocSharingState, action: PayloadAction<FinishedTask>) => {
      state.finishedTask.set(action.payload.job_id as string, action.payload);
    },
    setConvertedFileList: (state: DocSharingState, action: PayloadAction<string[]>) => {
      state.convertedFileList = [...action.payload];
      state.isWhiteBoard = false;
    },
    setCurrentIndex: (state: DocSharingState, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
    },
    setCurrentFileId: (state: DocSharingState, action: PayloadAction<string>) => {
      state.currentFileId = action.payload;
    },
    setEditMode: (state: DocSharingState, action: PayloadAction<DocSharingEditMode>) => {
      state.editMode = action.payload;
    },
    setRefreshTime: (state: DocSharingState, action: PayloadAction<number>) => {
      state.refreshTime = action.payload;
    },
    setPenColor: (state: DocSharingState, action: PayloadAction<string>) => {
      state.penColor = action.payload;
    },
    setZoomType: (state: DocSharingState, action: PayloadAction<ZoomType>) => {
      state.zoomType = action.payload;
    },
    setHostPageInfo: (state: DocSharingState, action: PayloadAction<IDocSharingHost>) => {
      state.hostPageInfo.set(action.payload.socketId, action.payload.info);
    },
    removeHostPageInfo: (state: DocSharingState, action: PayloadAction<string>) => {
      // console.log("store removeHostPageInfo before size", state.hostPageInfo.size, action.payload);
      // console.log("store map", state.hostPageInfo);
      state.hostPageInfo.delete(action.payload);

      // console.log("store removeHostPageInfo after size", state.hostPageInfo.size);
    },
    clearHostPageInfo: (state: DocSharingState) => {
      state.hostPageInfo.clear();
    },
    setLocalHostPageInfo: (state: DocSharingState, action: PayloadAction<IDocSharingPageInfo>) => {
      // console.log("store setLocalHostPageInfo", action.payload);
      state.localHostPageInfo = action.payload;
    },
    setHostBgStartPos: (state: DocSharingState, action: PayloadAction<Point>) => {
      state.hostBgStartPos = action.payload;
      // state.bgImageSize = { ...state.bgImageSize, x: action.payload.x, y: action.payload.y };
    },
    setLocalBgStartPos: (state: DocSharingState, action: PayloadAction<Point>) => {
      state.localBgStartPos = action.payload;
    },
    setHostCursorPos: (state: DocSharingState, action: PayloadAction<IDocSharingCurPos>) => {
      state.hostCursorPos = action.payload;
    },
    createPenPath: (state: DocSharingState, action: PayloadAction<IPenPath>) => {
      state.penPath.push(action.payload);
    },
    setPenPath: (state: DocSharingState, action: PayloadAction<IPenPath[]>) => {
      state.penPath = action.payload;
    },
    addPenPath: (state: DocSharingState, action: PayloadAction<Line>) => {
      if (state.penPath.length > 0) {
        state.penPath[state.penPath.length - 1].path.push(action.payload);
      }
    },
    clearPenPath: (state: DocSharingState, action: PayloadAction<number>) => {
      try {
        if (action.payload < state.penPath.length) {
          state.penPath[action.payload].path = [];
          state.penPath = state.penPath.filter(p => p.path.length > 0);
        }
      } catch (e) {
        console.error("clearPenPath error", e);
      }
    },
    clearAllPenPath: (state: DocSharingState, action: PayloadAction<void>) => {
      state.penPath = [];
    },
    setCurrentHostSocketId: (state: DocSharingState, action: PayloadAction<string>) => {
      state.currentHostSocketId = action.payload;
    },
    setBgDrawSize: (state: DocSharingState, action: PayloadAction<IBgDrawSize>) => {
      state.bgDrawSize = action.payload;
    },
    setIsShowColorPicker: (state: DocSharingState, action: PayloadAction<boolean>) => {
      state.isShowColorPicker = action.payload;
    },
    setIsWhiteBoard: (state: DocSharingState, action: PayloadAction<boolean>) => {
      console.log("store setIsWhiteBoard", action.payload);
      state.isWhiteBoard = action.payload;
      if (action.payload) {
        state.currentIndex = 0;
        state.convertedFileList = [];
      }
    },
    setIsBgImageMoving: (state: DocSharingState, action: PayloadAction<boolean>) => {
      state.isBgImageMoving = action.payload;
    },
    setIsShowToolbar: (state: DocSharingState, action: PayloadAction<boolean>) => {
      state.isShowToolbar = action.payload;
    },
  },
});

export const {
  setFinishedTask,
  setConvertedFileList,
  setCurrentIndex,
  setCurrentFileId,
  setEditMode,
  setRefreshTime,
  setPenColor,
  createPenPath,
  addPenPath,
  clearPenPath,
  clearAllPenPath,
  setZoomType,
  setHostPageInfo,
  removeHostPageInfo,
  clearHostPageInfo,
  setLocalHostPageInfo,
  setHostBgStartPos,
  setLocalBgStartPos,
  setHostCursorPos,
  setCurrentHostSocketId,
  setBgDrawSize,
  setPenPath,
  setIsShowColorPicker,
  setIsBgImageMoving,
  setIsWhiteBoard,
  setIsShowToolbar,
} = docSharingSlice.actions;
export const selectFinishedTask = (state: RootState) => state.docSharing.finishedTask;
export const selectConvertedFileList = (state: RootState) => state.docSharing.convertedFileList;
export const selectCurrentIndex = (state: RootState) => state.docSharing.currentIndex;
export const selectCurrentFileId = (state: RootState) => state.docSharing.currentFileId;
export const selectHostPageInfo = (state: RootState) => state.docSharing.hostPageInfo.get(state.docSharing.currentHostSocketId);
export const selectHostBgStartPos = (state: RootState) => state.docSharing.hostBgStartPos;
export const selectHostCursorPos = (state: RootState) => state.docSharing.hostCursorPos;
export const selectLocalBgStartPos = (state: RootState) => state.docSharing.localBgStartPos;
export const selectEditMode = (state: RootState) => state.docSharing.editMode;
export const selectRefreshTime = (state: RootState) => state.docSharing.refreshTime;
export const selectPenColor = (state: RootState) => state.docSharing.penColor;
export const selectPenPath = (state: RootState) => state.docSharing.penPath;
export const selectZoomType = (state: RootState) => state.docSharing.zoomType;
export const selectCurrentHostSocketId = (state: RootState) => state.docSharing.currentHostSocketId;
export const selectBgDrawSize = (state: RootState) => state.docSharing.bgDrawSize;
export const selectIsWhiteBoard = (state: RootState) => state.docSharing.isWhiteBoard;
export const selectIsShowColorPicker = (state: RootState) => state.docSharing.isShowColorPicker;
export const selectIsBgImageMoving = (state: RootState) => state.docSharing.isBgImageMoving;
export const selectIsShowToolbar = (state: RootState) => state.docSharing.isShowToolbar;

export default docSharingSlice.reducer;
