import Draggable from "react-draggable";
import ProfileImg from "components/ProfileImg";
import { useEffect, useRef } from "react";
import { usePeerSetting } from "hooks/usePeerSetting";
import { useMeasure } from "react-use";
import { PeerInfo } from "types/clientTypes";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

interface IProps {
  onClose?: () => void;
  stream?: MediaStream;
  picurl?: string;
  socketId: string;
  peer?: PeerInfo;
}

const SmallOverlayVideoView = (props: IProps) => {
  const { stream, picurl, socketId, peer } = props;
  const { t } = useTranslation();
  const screenShareRef = useRef<HTMLVideoElement>(null);
  const peerSetting = usePeerSetting(socketId);
  const [ref, { right, bottom }] = useMeasure<HTMLDivElement>();
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // console.log("SmallOverlayVideoView stream", stream, peerSetting?.isCamEnable);
    if (!screenShareRef.current) return;
    if (stream !== undefined) screenShareRef.current.srcObject = stream;
  }, [stream, peerSetting?.isCamEnable]);

  return (
    <div ref={ref} className="absolute top-0 left-0 w-full h-full">
      <div ref={parentRef} className="absolute top-0 left-0 z-50">
        <Draggable
          defaultPosition={{ x: 0, y: 0 }}
          grid={[1, 1]}
          bounds={{
            left: 0,
            top: 0,
            right: right - (parentRef.current ? parentRef.current.offsetWidth : 0),
            bottom: bottom - (parentRef.current ? parentRef.current.offsetHeight : 0),
          }}
        >
          {/* <div className="relative w-[10rem] lg:w-[14vw] xl:w-[15.8vw] min-h-[7rem] aspect-video m-0 z-40 shadow-lg border-4 border-[#18c0fd] p-pt cursor-move bg-[#333]"> */}
          <div className="relative w-[15.8vw] h-[10vw] m-0 z-40 shadow-lg border-2 border-[#18c0fd] p-pt cursor-move bg-[#333]">
            {stream && peerSetting?.isCamEnable ? (
              <video
                className="max-h-full bg-black"
                autoPlay
                muted
                ref={screenShareRef}
                width="100%"
                height="100%"
                playsInline
                disablePictureInPicture
              />
            ) : (
              <div className="absolute inset-0 flex justify-center items-center py-6 z-40 h-[10vw]">
                <div className="w-[5vw] h-[5vw] select-none">
                  <ProfileImg picurl={picurl} borderWidth={1} />
                </div>
              </div>
            )}

            {!isMobile && (
              <div className="absolute top-0 z-10 flex items-center justify-start ml-1 mt-1">
                <div className="hidden xl:flex items-center justify-center h-10 px-3 overflow-x-hidden text-white rounded min-w-min bg-black/75">
                  <span className="max-w-[15rem] overflow-hidden whitespace-nowrap text-ellipsis">{peer?.userInfo.username}</span>
                  {peer?.userInfo?.position && peer.userInfo.position.length > 0 && <span className="ml-2 shrink-0">{peer?.userInfo.position}</span>}
                </div>

                {peer?.isHost && (
                  <div className="hidden xl:flex items-center justify-center h-10 px-3 ml-2 text-white rounded min-w-min bg-blue-500/75">
                    <span>{t("home.사회자")}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default SmallOverlayVideoView;
