import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPeers } from "store/peerSlice";
import { PeerInfo } from "types/clientTypes";

export function usePeer(socketId: string) {
  const [peer, setPeer] = useState<PeerInfo>();
  const peers = useSelector(selectPeers);

  useEffect(() => {
    const find = peers.find(item => item.socketId === socketId);
    setPeer(find);
  }, [socketId, peers]);

  return peer;
}
