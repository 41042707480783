import React, { useCallback, useEffect, useRef, useState } from "react";
import SubtitleView from "components/speechToText/SubtitleView";
import ProfileImg from "components/ProfileImg";
import config from "config";
import VirtualBgVideoView from "lib/virtualbg/core/components/VirtualBgVideoView";
import FixedAspectRatioDiv from "components/common/FixedAspectRatioDiv";
import ButtonMic from "./buttons/ButtonMic";
import ActiveSpeakerEffect from "./ActiveSpeakerEffect";
import { withRoomContext } from "RoomContext";
import { LayoutType, selectLayoutType } from "store/roomInfoSlice";
import { selectIsCameRotate, selectVirtualBgType, selectVirtualBgUrl } from "store/settingSlice1";
import { selectIsShowCaption } from "store/windowControlSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePeerSetting } from "hooks/usePeerSetting";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { useOnScreen } from "hooks/useOnScreen";
import { useCanVirtualBg } from "hooks/useCanVirtualBg";
import { isMobile } from "react-device-detect";
import { selectEnv } from "store/envSlice";
import { IClientVideoViewProps } from "./ClientVideoView";
import ContextMenu, { IContextMenuProps } from "./ContextMenu";

const ClientVideoViewForPC = (props: IClientVideoViewProps) => {
  const { isRemote, isHost, producerId, socketId, stream, roomClient, consumer, userInfo } = props;
  const { t } = useTranslation();
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [contextMenu, setContextMenu] = useState<IContextMenuProps | null>();
  const layoutType = useSelector(selectLayoutType);
  const isCamRotate = useSelector(selectIsCameRotate);
  const env = useSelector(selectEnv);
  const virtualBgType = useSelector(selectVirtualBgType);
  const virtualBgUrl = useSelector(selectVirtualBgUrl);
  const isShowCaption = useSelector(selectIsShowCaption);
  const videoRef = useRef<HTMLVideoElement>(null);
  const isVisible = useOnScreen(videoRef);
  const peerSetting = usePeerSetting(socketId);
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const canVirtualBg = useCanVirtualBg();
  let _videoResolutionPeriodicTimer: NodeJS.Timer | null = null;

  useEffect(() => {
    // console.log(`## ClientVideoView mount. ${userInfo?.username}, isVisible:${isVisible}`);
    return () => {
      // console.log(`## ClientVideoView unmount. ${userInfo?.username}, isVisible:${isVisible}`);
      if (consumer) consumer.resume();
    };
  }, [consumer]);

  const handleMicBtn = async (enable: boolean) => {
    const res = await roomClient.mute(!enable, isRemote, socketId);
    if (!res) {
      toast.warn(t("msg.마이크 ON/OFF 권한없음"));
    }
  };

  const startVideoResolution = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    _videoResolutionPeriodicTimer = setInterval(() => {
      if (videoRef.current) {
        if (videoRef.current.videoWidth !== videoWidth || videoRef.current.videoHeight !== videoHeight) {
          setVideoWidth(videoRef.current.videoWidth);
          setVideoHeight(videoRef.current.videoHeight);
        }
      }
    }, 1000);
  }, []);

  const stopVideoResolution = useCallback(() => {
    if (_videoResolutionPeriodicTimer) clearInterval(_videoResolutionPeriodicTimer);

    setVideoWidth(0);
    setVideoHeight(0);
  }, [_videoResolutionPeriodicTimer]);

  useEffect(() => {
    return () => {
      stopVideoResolution();
    };
  }, [stopVideoResolution]);

  useEffect(() => {
    // console.log(`isHost:${isHost}, ${userInfo?.username}, layoutType:${layoutType}, isVisible:${isVisible}`);

    if (consumer) {
      if (!isVisible && !isHost && layoutType === LayoutType.Highlight) {
        // console.log(`@#$%^&* clientVideoView pause. isHost:${isHost}, layoutType:${layoutType}, ${userInfo?.username}`);
        consumer.pause();
      } else {
        // console.log(`@#$%^&* clientVideoView resume. isHost:${isHost}, layoutType:${layoutType}, ${userInfo?.username}`);
        consumer.resume();
      }
    }
  }, [consumer, isHost, isVisible, layoutType, userInfo?.username]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (stream !== undefined) {
      videoRef.current.srcObject = stream;

      if (env.NODE_ENV === "development" && layoutType === LayoutType.Split) startVideoResolution();
    }
  }, [env.NODE_ENV, layoutType, startVideoResolution, stream]);

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      closeMenu: () => {
        console.log("closeMenu called");
        setContextMenu(null);
      },
      socketId: socketId,
    });
  };

  return (
    <>
      {layoutType === LayoutType.Highlight ? (
        <div className="relative bg-[#222] shrink-0 h-full flex items-center select-none mx-[0.125rem]" onContextMenu={handleContextMenu}>
          {contextMenu && <ContextMenu {...contextMenu} />}
          {isRemote || virtualBgType === "none" || !canVirtualBg ? (
            <div className={"h-full w-full bg-[#111] flex items-center"}>
              <video
                className={`bg-[#333] w-auto ${isMobile && isPortrait ? "h-auto" : "h-full"} ${
                  isCamRotate && !isRemote && "transform -scale-x-100"
                } `}
                id={producerId}
                autoPlay
                muted
                ref={videoRef}
                playsInline
                disablePictureInPicture
                crossOrigin="anonymous"
              />
            </div>
          ) : (
            <div className={"h-full w-full"}>
              <VirtualBgVideoView
                bgType={virtualBgType}
                bgUrl={virtualBgUrl}
                stream={stream}
                videoClassName={`bg-[#333] h-full ${isMobile && isPortrait ? "w-full" : "aspect-video"}`}
                isCamRotate={isCamRotate}
                configVideo={config.userMedia.video}
                configAspectRatio={config.aspectRatio}
                isEnable={peerSetting?.isCamEnable ?? false}
              />
            </div>
          )}

          <div
            className={`absolute ${isMobile && !isPortrait ? "bottom-1 right-1" : "bottom-4 right-4"} w-[2.25rem] h-[2.25rem] cursor-pointer z-30`}
          >
            <ButtonMic isEnable={peerSetting?.isMicEnable ?? false} onClick={() => handleMicBtn(!peerSetting?.isMicEnable)} />
          </div>

          {(!peerSetting?.isCamEnable || !stream) && (
            <div className="absolute inset-0 flex justify-center items-center bg-[#333] border-0 border-[#222]">
              <div className="w-[5.5rem] h-[5.5rem]">
                <ProfileImg picurl={userInfo?.picurl} borderWidth={2} />
              </div>
            </div>
          )}

          <div className="absolute top-0 z-10 flex items-center justify-start mt-4 ml-4">
            <div className="hidden md:flex items-center justify-center h-10 px-3 overflow-x-hidden text-white rounded min-w-min bg-black/75">
              <span className="max-w-[20rem] overflow-hidden whitespace-nowrap text-ellipsis">{userInfo?.username}</span>
              {userInfo?.position && userInfo.position.length > 0 && <span className="ml-2 shrink-0">{userInfo?.position}</span>}
            </div>

            {isHost && (
              <div className="hidden md:flex items-center justify-center h-10 px-3 ml-2 text-white rounded min-w-min bg-blue-500/75">
                <span>{t("home.사회자")}</span>
              </div>
            )}
          </div>

          {isShowCaption && (
            <div className="absolute bottom-1 flex justify-center w-full text-[1rem] px-1">
              <SubtitleView isSmall={true} bidOrEmail={userInfo?.bid} isRemote={isRemote} />
            </div>
          )}

          <ActiveSpeakerEffect bid={userInfo?.bid} />
        </div>
      ) : (
        <div className="relative px-px bg-[#222] flex items-center p-px select-none w-full h-full" onContextMenu={handleContextMenu}>
          {contextMenu && <ContextMenu {...contextMenu} />}
          <div className="flex justify-center w-full h-full overflow-hidden">
            <div className="relative w-full items-center">
              {isRemote || virtualBgType === "none" || !canVirtualBg ? (
                <FixedAspectRatioDiv divClassName="w-full h-full relative" aspectRatio={1.6}>
                  <video
                    className="bg-[rgb(51,51,51)] h-full w-full"
                    id={producerId}
                    autoPlay
                    muted
                    ref={videoRef}
                    style={isCamRotate && !isRemote ? { transform: "rotateY(180deg)" } : {}}
                    playsInline
                    disablePictureInPicture
                    crossOrigin="anonymous"
                  />
                </FixedAspectRatioDiv>
              ) : (
                <FixedAspectRatioDiv divClassName="w-full h-full relative" aspectRatio={1.6}>
                  <VirtualBgVideoView
                    bgType={virtualBgType}
                    bgUrl={virtualBgUrl}
                    stream={stream}
                    videoClassName={`bg-[#333] w-full h-full border-0 ${isMobile && isPortrait ? "border-red-400" : "border-green-400"}`}
                    isCamRotate={isCamRotate}
                    configVideo={config.userMedia.video}
                    configAspectRatio={config.aspectRatio}
                    isEnable={peerSetting?.isCamEnable ?? false}
                  />
                </FixedAspectRatioDiv>
              )}

              <div
                className={`absolute ${
                  isMobile && !isPortrait ? "bottom-1 right-1" : "bottom-4 right-4"
                } w-[2.25rem] h-[2.25rem] cursor-pointer z-30`}
              >
                <ButtonMic isEnable={peerSetting?.isMicEnable ?? false} onClick={() => handleMicBtn(!peerSetting?.isMicEnable)} />
              </div>

              <div className="absolute top-0 z-10 flex items-center justify-start mx-4 mt-4">
                <div className="flex items-center justify-center h-10 px-3 overflow-x-hidden text-white rounded min-w-min bg-black/75">
                  <span className="max-w-[15rem] overflow-hidden whitespace-nowrap text-ellipsis">{userInfo.username}</span>
                  {userInfo?.position && userInfo.position.length > 0 && <span className="ml-2 shrink-0">{userInfo.position}</span>}
                </div>

                {isHost && (
                  <div className="flex items-center justify-center h-10 px-3 ml-2 text-white rounded min-w-min bg-blue-500/75">
                    <span>{t("home.사회자")}</span>
                  </div>
                )}
              </div>

              {(!peerSetting?.isCamEnable || !stream) && (
                <div className="absolute inset-0 flex justify-center items-center bg-[#333] border-0 border-[#222]">
                  <div className="w-[5.5rem] h-[5.5rem] md:w-[7rem] md:h-[7rem]">
                    <ProfileImg picurl={userInfo?.picurl} borderWidth={1} />
                  </div>
                </div>
              )}

              {env.NODE_ENV === "development" && (
                <div className="absolute bottom-0 left-0 p-2 text-xs text-white bg-black/60">
                  <div>
                    {videoWidth}x{videoHeight}
                  </div>
                  <div>{videoHeight > 0 ? videoWidth / videoHeight : 0}</div>
                </div>
              )}
            </div>
          </div>

          {isShowCaption && (
            <div className="absolute flex justify-center w-full bottom-px">
              <div className="px-[3.5rem] flex mb-1 text-[2rem] mx-px">
                <SubtitleView isSmall={true} bidOrEmail={userInfo.bid} isRemote={isRemote} />
              </div>
            </div>
          )}
          <ActiveSpeakerEffect bid={userInfo?.bid} />
        </div>
      )}
    </>
  );
};

export default React.memo(withRoomContext(ClientVideoViewForPC));
