import React, { useEffect, useCallback, useRef, useState } from "react";
import RoomClient from "RoomClient";
import ChatInput from "components/chat/ChatInput";
import ChatRow from "components/chat/ChatRow";
import i18next from "i18next";
import CloseButton from "components/common/buttons/CloseButton";
import { withRoomContext } from "RoomContext";
import { selectAllChatData, selectCanClose, setLastReadCnt } from "store/chatSlice";
import { useAppDispatch } from "store/hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ChatData } from "types/commonTypes";
import { isMobile } from "react-device-detect";
import { ko, ja, enUS } from "date-fns/locale";

interface PropsDefault {
  roomClient: RoomClient;
  onCloseView: () => void;
  buttonRef: React.RefObject<HTMLDivElement>;
}

const ChatMain = (props: PropsDefault) => {
  const { roomClient, onCloseView, buttonRef } = props;
  const { t } = useTranslation();
  const [locale, setLocale] = useState<Locale>();
  const chatData = useSelector(selectAllChatData);
  const canWindowClose = useSelector(selectCanClose);
  const messagesEndRef = React.createRef<HTMLDivElement>();
  const rootDivRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  let canClose = false;

  useEffect(() => {
    const currentLng = i18next.language === "ko-KR" ? ko : i18next.language === "ja-JP" ? ja : enUS;
    setLocale(currentLng ?? ko);
  }, []);

  const scrollToBottom = useCallback(
    (behavior: ScrollBehavior) => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior, block: "end", inline: "end" });
      }
    },
    [messagesEndRef],
  );

  useEffect(() => {
    scrollToBottom("auto");

    const handleClickOutside = (ev: Event) => {
      if (buttonRef) {
        if (buttonRef.current?.contains(ev.target as Node)) {
          return;
        }
      }
      if (rootDivRef.current && !rootDivRef.current.contains(ev.target as Node) && canWindowClose) {
        onCloseView();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [buttonRef, canClose, canWindowClose, onCloseView, rootDivRef, scrollToBottom]);

  useEffect(() => {
    scrollToBottom("smooth");
    dispatch(setLastReadCnt(chatData.length));
  }, [chatData, dispatch, scrollToBottom]);

  const handleClose = () => {
    onCloseView();
  };

  const onSend = (newChat: ChatData) => {
    roomClient.sendChat(newChat);
  };

  const onEsc = () => {
    onCloseView();
  };

  return (
    <div
      ref={rootDivRef}
      className={`${
        isMobile ? "h-full" : "h-fit min-h-[30%] max-h-[calc(100%-50px)] sm:min-h-[40%] sm:max-h-[70%] sm:w-[26.5rem]"
      } relative flex flex-col justify-between items-center bottom-0 right-0 w-full shadow bg-[#333] box-border select-none`}
    >
      {/* TitleBar + 닫기버튼 */}
      <div className="flex w-full h-[4.125rem] min-h-[4.125rem] justify-start items-center">
        <CloseButton width={24} height={24} className="ml-5" classNameSVG="stroke-C-c8cace hover:stroke-C-1f62b9" onClick={handleClose} />
        <div className="ml-4 text-lg font-bold leading-6 text-white">{t("chat.대화")}</div>
      </div>

      {/* 대화내용 + 입력창 */}
      <div className="h-full w-full p-px flex flex-col overflow-y-hidden">
        {/* 대화내용 */}
        <div className="overflow-y-auto grow">
          {chatData.map((item: ChatData) => (
            <ChatRow key={item.userInfo.bidOrEmail + item.time} chatData={item} localName={roomClient.userInfo.username} locale={locale} />
          ))}
          <div ref={messagesEndRef} />
        </div>

        {/* 입력창 */}
        <div className="flex flex-col">
          <ChatInput onSend={onSend} onEsc={onEsc} />
        </div>
      </div>
    </div>
  );
};

export default withRoomContext(ChatMain);
