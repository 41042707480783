import React, { useState } from "react";
import Tooltip from "components/common/Tooltip";

interface IProps {
  SvgIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  isCheck?: boolean;
  tooltipContent?: string;
  normalColor?: string;
  hoverColor?: string;
}

const CombinedSvgButton = (props: IProps) => {
  const { isCheck, SvgIcon, onClick, tooltipContent, normalColor, hoverColor } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip content={tooltipContent || ""}>
      <button onClick={onClick} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <SvgIcon style={{ color: isHovered || isCheck ? hoverColor || "#4495ff" : normalColor || "#fff" }} />
      </button>
    </Tooltip>
  );
};

export default CombinedSvgButton;
