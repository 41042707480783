import { AxiosInstance } from "axios";
import { store } from "store/store";

export const addTokenToInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    config => {
      try {
        if (config.headers) config.headers.Authorization = `Bearer ${store.getState().auth.token}`;
      } catch (err) {
        console.error("[_axios.interceptors.request] config : " + err);
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );
};
