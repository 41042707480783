import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPeers } from "store/peerSlice";
import { PeerInfo } from "types/clientTypes";

export function useHostPeer() {
  const [hostPeer, setHostPeer] = useState<PeerInfo>();
  const peers = useSelector(selectPeers);

  useEffect(() => {
    const findPeer = peers.find(item => item.isHost);
    setHostPeer(findPeer);
  }, [peers]);

  return hostPeer;
}
