import { IInnerAttendee, IOuterAttendee } from "api/manager/invite";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
// import { TreeDataNode } from "components/common/interfaces/OrgData";

type InviteState = {
  inner: IInnerAttendee[];
  outer: IOuterAttendee[];
};

const initialState: InviteState = {
  inner: [],
  outer: [],
};

export const inviteSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {
    setInnerAttendees: (state: InviteState, action: PayloadAction<IInnerAttendee[]>) => {
      state.inner = [...action.payload];
    },
    setOuterAttendees: (state: InviteState, action: PayloadAction<IOuterAttendee[]>) => {
      state.outer = [...action.payload];
    },
  },
});

export const { setInnerAttendees, setOuterAttendees } = inviteSlice.actions;
export const selectInnerAttendees = (state: RootState) => state.invite.inner;
export const selectOuterAttendees = (state: RootState) => state.invite.outer;

export default inviteSlice.reducer;
