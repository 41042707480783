import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language } from "../types/commonTypes";
import type { RootState } from "./store";

export type VirtualBgType = "none" | "blur" | "image";

interface ISettingState {
  isShowSettingWindow: boolean;
  webcams: Map<string, MediaDeviceInfo>;
  mics: Map<string, MediaDeviceInfo>;
  speakers: Map<string, MediaDeviceInfo>;
  selectedWebcamId: string;
  selectedMicId: string;
  selectedSpeakerId: string;
  isCamRotate: boolean;
  isLocalCamOn: boolean;
  isLocalMicOn: boolean;
  isWebcamPermissionError: boolean;
  isMicPermissionError: boolean;
  language: Language;
  sttFromLanguage: string;
  sttToLanguage: string;
  gridCols: number;
  gridRows: number;
  virtualBgType: VirtualBgType;
  virtualBgUrl: string;
}

const initialState: ISettingState = {
  // array: [],
  isShowSettingWindow: false,
  webcams: new Map(),
  mics: new Map(),
  speakers: new Map(),
  selectedWebcamId: "",
  selectedMicId: "",
  selectedSpeakerId: "",
  isCamRotate: false,
  isLocalCamOn: true,
  isLocalMicOn: true,
  isWebcamPermissionError: true,
  isMicPermissionError: true,
  language: Language.KO,
  sttFromLanguage: "ko-KR",
  sttToLanguage: "ko-KR",
  gridCols: 1,
  gridRows: 1,
  virtualBgType: "none",
  virtualBgUrl: "",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setIsShowSettingWindow: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.isShowSettingWindow = action.payload;
    },
    setWebcams: (state: ISettingState, action: PayloadAction<Map<string, MediaDeviceInfo>>) => {
      state.webcams = new Map(action.payload);
    },
    setAudioInputDevices: (state: ISettingState, action: PayloadAction<Map<string, MediaDeviceInfo>>) => {
      state.mics = new Map(action.payload);
    },
    setAudioOutputDevices: (state: ISettingState, action: PayloadAction<Map<string, MediaDeviceInfo>>) => {
      state.speakers = new Map(action.payload);
    },
    selectWebcam: (state: ISettingState, action: PayloadAction<string>) => {
      state.selectedWebcamId = action.payload;
      localStorage.setItem("selectedWebcomId", action.payload);
      // console.log('store webcam 사용설정됨');
    },
    selectMic: (state: ISettingState, action: PayloadAction<string>) => {
      state.selectedMicId = action.payload;
      localStorage.setItem("selectedMicId", action.payload);
      // console.log('store selectedMicId 저장', action.payload);
    },
    selectAudioOutputDevice: (state: ISettingState, action: PayloadAction<string>) => {
      state.selectedSpeakerId = action.payload;
      localStorage.setItem("selectedAudioOutputDeviceId", action.payload);
      // console.log('store speaker 저장~~~~', action.payload);
    },
    setIsCamRotate: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.isCamRotate = action.payload;
      localStorage.setItem("isCamRotate", JSON.stringify(action.payload));
      // console.log('settingslice: isCamRotate 저장~~~~', action.payload);
    },
    setIsLocalCamOn: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.isLocalCamOn = action.payload;
    },
    setIsLocalMicOn: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.isLocalMicOn = action.payload;
    },
    setIsWebcamPermissionError: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.isWebcamPermissionError = action.payload;
    },
    setIsMicPermissionError: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.isMicPermissionError = action.payload;
    },
    setLanguage: (state: ISettingState, action: PayloadAction<Language>) => {
      localStorage.setItem("language", action.payload);
      state.language = action.payload;
    },
    setSttFromLanguage: (state: ISettingState, action: PayloadAction<string>) => {
      state.sttFromLanguage = action.payload;
      localStorage.setItem("sttFromLanguage", action.payload.toString());
    },
    setSttToLanguage: (state: ISettingState, action: PayloadAction<string>) => {
      state.sttToLanguage = action.payload;
      localStorage.setItem("sttToLanguage", action.payload.toString());
    },
    setGridCols: (state: ISettingState, action: PayloadAction<number>) => {
      state.gridCols = action.payload;
    },
    setGridRows: (state: ISettingState, action: PayloadAction<number>) => {
      state.gridRows = action.payload;
    },
    setVirtualBgType: (state: ISettingState, action: PayloadAction<VirtualBgType>) => {
      state.virtualBgType = action.payload;
      localStorage.setItem("virtualBgType", action.payload);
    },
    setVirtualBgUrl: (state: ISettingState, action: PayloadAction<string>) => {
      state.virtualBgUrl = action.payload;
      localStorage.setItem("virtualBgUrl", action.payload);
    },
  },
});

export const {
  // setMicEnabled,
  // setCamEnabled,
  setIsShowSettingWindow,
  setWebcams,
  setAudioInputDevices,
  setAudioOutputDevices,
  selectWebcam,
  selectMic,
  selectAudioOutputDevice,
  setIsCamRotate,
  setIsLocalCamOn,
  setIsLocalMicOn,
  setIsWebcamPermissionError,
  setIsMicPermissionError,
  setLanguage,
  setSttFromLanguage,
  setSttToLanguage,
  setGridCols,
  setGridRows,
  setVirtualBgUrl,
  setVirtualBgType,
} = settingSlice.actions;

export const selectSettings = (state: RootState) => state.setting;
export const selectIsShowSettingWindow = (state: RootState) => state.setting.isShowSettingWindow;
export const selectWebcams = (state: RootState) => state.setting.webcams;
export const selectSelectedWebcam = (state: RootState) => state.setting.selectedWebcamId;
export const selectSelectedMic = (state: RootState) => state.setting.selectedMicId;
export const selectMics = (state: RootState) => state.setting.mics;
export const getSpeakers = (state: RootState) => state.setting.speakers;
export const getSelectedSpeaker = (state: RootState) => state.setting.selectedSpeakerId;
export const selectIsCameRotate = (state: RootState) => state.setting.isCamRotate;
export const selectIsLocalCamOn = (state: RootState) => state.setting.isLocalCamOn;
export const selectIsLocalMicOn = (state: RootState) => state.setting.isLocalMicOn;
export const selectIsWebcamPermissionError = (state: RootState) => state.setting.isWebcamPermissionError;
export const selectIsMicPermissionError = (state: RootState) => state.setting.isMicPermissionError;
export const selectLanguage = (state: RootState) => state.setting.language;
export const selectSttFromLanguage = (state: RootState) => state.setting.sttFromLanguage;
export const selectSttToLanguage = (state: RootState) => state.setting.sttToLanguage;
export const selectGridCols = (state: RootState) => state.setting.gridCols;
export const selectGridRows = (state: RootState) => state.setting.gridRows;
export const selectVirtualBgType = (state: RootState) => state.setting.virtualBgType;
export const selectVirtualBgUrl = (state: RootState) => state.setting.virtualBgUrl;

export default settingSlice.reducer;
