import React, { useEffect, useRef, useState } from "react";
import RoomClient from "RoomClient";
import styled from "styled-components";
import { withRoomContext } from "RoomContext";
import { setVirtualBgUrl, setVirtualBgType, selectVirtualBgType, selectVirtualBgUrl } from "store/settingSlice1";
import { useDispatch, useSelector } from "react-redux";
import { backgroundThumbUrls, backgroundImageUrls } from "lib/virtualbg/core/helpers/backgroundHelper";
import { useTranslation } from "react-i18next";

interface IProps {
  onCloseView: () => void;
  roomClient: RoomClient;
  buttonRef: React.RefObject<HTMLDivElement>;
}

const BackgroundSelector = (props: IProps) => {
  const { t } = useTranslation();
  const { onCloseView, buttonRef, roomClient } = props;
  const [selectedIdx, setSelectedIdx] = useState(-1);
  const rootDivRef = useRef<HTMLDivElement>(null);
  const virtualBgType = useSelector(selectVirtualBgType);
  const virtualBgUrl = useSelector(selectVirtualBgUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    backgroundImageUrls.forEach((url, idx) => {
      if (url === virtualBgUrl) setSelectedIdx(idx);
    });
  }, [virtualBgUrl]);

  useEffect(() => {
    const handleClickOutside = (ev: Event) => {
      if (buttonRef && buttonRef.current?.contains(ev.target as Node)) {
        return;
      }

      if (rootDivRef.current && !rootDivRef.current.contains(ev.target as Node)) {
        onCloseView();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [buttonRef, dispatch, onCloseView]);

  const handleNoneClick = () => {
    dispatch(setVirtualBgType("none"));
    roomClient.updateWebcam({ restart: true });
    onCloseView();
  };

  const handleBlurClick = () => {
    dispatch(setVirtualBgType("blur"));
    roomClient.updateWebcam({ restart: true });
    onCloseView();
  };

  const handleSelectIncludeImage = (idx: number) => {
    setSelectedIdx(idx);
    dispatch(setVirtualBgType("image"));
    dispatch(setVirtualBgUrl(backgroundImageUrls[idx]));
    roomClient.updateWebcam({ restart: true });
    onCloseView();
  };

  const DrawImageList = () => {
    const result = [];

    for (let i = 0; i < backgroundThumbUrls.length; i++) {
      result.push(
        <div key={i} className="relative h-16 w-16 rounded cursor-pointer hover:scale-95 transition-all" onClick={() => handleSelectIncludeImage(i)}>
          <img src={backgroundThumbUrls[i]} alt="" className="w-full h-full rounded" />
          {virtualBgType === "image" && i === selectedIdx && <div className="absolute inset-0 h-16 w-16 rounded border-2 border-[#4495ff] z-10" />}
        </div>,
      );
    }
    return result;
  };

  return (
    <div ref={rootDivRef} className="flex flex-col w-[21.5rem] h-fit pb-[1px] bg-[#333] rounded shadow-lg text-white text-sm">
      <div className="h-9 border-b border-[#555] leading-9 px-4 font-bold">{t("menu.가상 배경화면 설정")}</div>

      <div className="grow bg-[#333] p-1">
        <StyledGrid cols={5} width={"100%"} height={"100%"}>
          {/* <div className="w-16 h-16 cursor-pointer p-1">
            <IconPlus />
          </div> */}
          <div
            className={`relative w-16 h-16 border-2 rounded text-sm flex justify-center items-center cursor-pointer hover:scale-95 transition-all 
            ${virtualBgType === "none" ? "border-[#4495ff]" : "border-[#555]"}`}
            onClick={handleNoneClick}
          >
            None
          </div>
          <div
            className={`relative w-16 h-16 border-2 rounded text-sm flex justify-center items-center cursor-pointer hover:scale-95 transition-all 
            ${virtualBgType === "blur" ? "border-[#4495ff]" : "border-[#555]"}`}
            onClick={handleBlurClick}
          >
            Blur
          </div>
          {DrawImageList()}
        </StyledGrid>
      </div>
    </div>
  );
};

interface IGridProps {
  cols: number;
  width: string;
  height: string;
}

const StyledGrid = styled.div<IGridProps>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, minmax(0, 1fr));
  grid-auto-flow: row;
  grid-gap: 0.25rem;
  overflow-y: auto;
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: 20rem;
  box-sizing: border-box;
  justify-items: start;
  align-items: start;
`;

export default withRoomContext(BackgroundSelector);
