import React, { useState } from "react";

interface IMobileMenuButtonEx {
  EnabledIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  DisabledIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  onClick: () => void;
  isEnabled: boolean;
}

const MobileMenuButtonEx = (props: IMobileMenuButtonEx) => {
  const { EnabledIcon, DisabledIcon, label, onClick, isEnabled } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className="px-6 h-[2.75rem] flex items-center cursor-pointer"
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="w-6 h-6">
          {isEnabled ? <EnabledIcon fill={isHovered ? "#4495ff" : "#fff"} /> : <DisabledIcon fill={isHovered ? "#4495ff" : "#fff"} />}
        </div>
        <div className={`text-base ml-3 ${isHovered ? "text-blue-10" : "text-white"} overflow-x-hidden whitespace-nowrap text-ellipsis`}>{label}</div>
      </div>
    </>
  );
};

export default MobileMenuButtonEx;
