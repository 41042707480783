import React from "react";
import LoadingSimple from "components/LoadingSimple";
import { useTranslation } from "react-i18next";

const ServerDisconnected = () => {
  const { t } = useTranslation();

  return (
    <div className="absolute inset-0 bg-black/50 z-[999]">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-fit h-fit p-4 text-white border-0 flex items-center">
        <div className="flex flex-col items-center justify-start w-fit">
          <LoadingSimple size={64} />
          <div className="mt-4 flex flex-col items-center">
            <div className="flex shrink-0 text-base">{t("msg.서버와의 연결이 끊어졌습니다.")}</div>
            <div className="flex shrink-0 text-base">{t("msg.재연결 시도 중...")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerDisconnected;
