import YesNoDialog from "../YesNoDialog";
import { useDispatch } from "react-redux";
import { setIsShowHostModeEnable } from "../../store/windowControlSlice";
import RoomClient from "../../RoomClient";
import { setHostBid } from "../../store/roomInfoSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface IProps {
  roomClient: RoomClient;
  isOpen: boolean;
}

const ActiveHostMode = (props: IProps) => {
  const { isOpen, roomClient } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlerHostSet = (res: boolean) => {
    if (!res) return;

    roomClient.sendChangeHost(roomClient.userInfo.bid, (result: boolean) => {
      if (result) {
        dispatch(setHostBid(roomClient.userInfo.bid));
      } else {
        console.log("사회자모드 변경 실패");
        toast.error(t("msg.사회자모드 변경 실패"));
      }
    });
  };

  const setIsOpen = (open: boolean) => {
    // console.log("hostModeEnable: setIsOpen", open);
    dispatch(setIsShowHostModeEnable(open));
  };

  return (
    <YesNoDialog
      msg={t("msg.사회자모드 활성화")}
      isOpen={isOpen}
      callback={(res: boolean) => handlerHostSet(res)}
      setIsOpen={(open: boolean) => setIsOpen(open)}
      leftButtonText={t("취소")}
      rightButtonText={t("활성화")}
    />
  );
};

export default ActiveHostMode;
