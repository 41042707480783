import React, { useState } from "react";
import Tooltip from "components/common/Tooltip";

interface IToolButton {
  SvgIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick: any;
  isCheck?: boolean;
  tooltipContent?: string;
}

const ToolButton = (props: IToolButton) => {
  const { isCheck, SvgIcon, onClick, tooltipContent } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip content={tooltipContent || ""}>
      <button onClick={onClick} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <SvgIcon style={{ color: isHovered || isCheck ? "#4495ff" : "#fff" }} />
      </button>
    </Tooltip>
  );
};

export default ToolButton;
