import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import RoomClient from "RoomClient";
import HomeHighlite from "./HomeHighlite";
import HomeSplit from "./HomeSplit";
import HomeCommon from "components/home/common/HomeCommon";
import ConsumerAudioList from "components/home/ConsumerAudioList";
import useHostInfoFromConsumers from "hooks/useHostInfoFromConsumers";
import screenfull from "screenfull";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHostPeer } from "hooks/useHostPeer";
import { useMediaQuery } from "react-responsive";
import { withRoomContext } from "RoomContext";
import { AppData } from "types/commonTypes";
import {
  LayoutType,
  selectRoomState,
  selectLayoutType,
  selectIsLocalHost,
  selectUserInfo,
  selectRoomInfo,
  setLayoutType,
  selectRoomSetting,
} from "store/roomInfoSlice";
import {
  selectIsShowDocSharingHost,
  selectIsShowDocSharingClient,
  selectIsFullScreen,
  setIsFullScreen,
  setIsShowBottomVideoList,
} from "store/windowControlSlice";
import { setScreenshotFunc } from "store/utilSlice";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { captureScreen } from "lib/captureScreen";

interface HomeProps {
  roomClient: RoomClient;
}

const HomeRoot: React.FC<HomeProps> = props => {
  const { roomClient } = props;
  const { t } = useTranslation();
  const [localAppData, setLocalAppDatga] = useState<AppData>();
  const [remoteHostVideoStream, setRemoteHostVideoStream] = useState<MediaStream | undefined>();
  const [localVideoStream, setLocalVideoStream] = useState<MediaStream>();
  const [screenShareStream, setScreenShareStream] = useState<MediaStream | undefined>();
  const [remoteHostAppData, setRemoteHostAppData] = useState<AppData>();
  const [prevIsLocalHost, setPrevIsLocalHost] = useState<boolean>(false);
  const isLocalHost = useSelector(selectIsLocalHost);
  const roomState = useSelector(selectRoomState);
  const localUserInfo = useSelector(selectUserInfo);
  const layoutType = useSelector(selectLayoutType);
  const isFullScreen = useSelector(selectIsFullScreen);
  const isShowDocSharingHost = useSelector(selectIsShowDocSharingHost);
  const isShowDocSharingClient = useSelector(selectIsShowDocSharingClient);
  const roomInfo = useSelector(selectRoomInfo);
  const roomSetting = useSelector(selectRoomSetting);
  const remoteHostInfo = useHostInfoFromConsumers(isLocalHost);
  const hostPeer = useHostPeer();
  const isLandscape = useMediaQuery({ orientation: "landscape" });
  const rootRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile && isLandscape && (isShowDocSharingHost || isShowDocSharingClient)) {
      dispatch(setIsShowBottomVideoList(false));
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        setTimeout(() => {
          screenfull.toggle();
        }, 100);
      }
    }
  }, [dispatch, isLandscape, isShowDocSharingClient, isShowDocSharingHost]);

  useEffect(() => {
    if (isShowDocSharingHost || isShowDocSharingClient) {
      dispatch(setLayoutType(LayoutType.Highlight));
    }
  }, [isShowDocSharingHost, isShowDocSharingClient, dispatch]);

  useEffect(() => {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);

  useEffect(() => {
    console.log("***** Home isLocalHost", isLocalHost);
    console.log("***** Home hostPeer", hostPeer);
    console.log("***** Home localUserInfo", localUserInfo);
  }, [hostPeer, localUserInfo, isLocalHost]);

  useEffect(() => {
    document.onfullscreenchange = event => {
      if (document.fullscreenElement) {
        dispatch(setIsFullScreen(true));
      } else {
        dispatch(setIsFullScreen(false));
      }
    };
  }, [dispatch]);

  useEffect(() => {
    roomClient.setScreenShareStreamSetCallback(setScreenShareStream);
    roomClient.setWebCamStreamSetCallback(stream => setLocalVideoStream(stream));

    if (roomState === "connected") {
      const appData = {
        isHost: isLocalHost,
        userInfo: localUserInfo,
        socketId: roomClient.socket.id,
        isMicEnable: false,
        isCamEnable: false,
        isScreen: false,
      };

      setLocalAppDatga(appData);

      roomClient.setScreenShareStreamSetCallback(setScreenShareStream);
    }
  }, [roomState, isLocalHost, roomClient, roomClient.userInfo, roomClient.socket.id, localUserInfo, roomInfo]);

  useEffect(() => {
    if (isLocalHost || !remoteHostInfo) {
      setRemoteHostVideoStream(undefined);
      setRemoteHostAppData(undefined);
      return;
    }

    setRemoteHostAppData(remoteHostInfo.hostAppData);
    setRemoteHostVideoStream(remoteHostInfo.hostVideoStream);
    setScreenShareStream(remoteHostInfo.screenShareStream);
  }, [remoteHostInfo, dispatch, hostPeer, remoteHostAppData, isLocalHost]);

  useEffect(() => {
    if (roomSetting.isAutoSaveNote) {
      toast.info(`${t("note.회의록 자동기록")} ON`, { autoClose: 2000 });
    }
  }, [roomSetting.isAutoSaveNote, t]);

  useEffect(() => {
    if (rootRef.current) {
      dispatch(
        setScreenshotFunc(async () => {
          if (rootRef.current) captureScreen(rootRef);
        }),
      );
    }
  }, [dispatch, rootRef]);

  useEffect(() => {
    if (isLocalHost && !prevIsLocalHost) {
      toast.success(t("msg.사회자로 설정되었습니다."));
    }
    setPrevIsLocalHost(isLocalHost);
  }, [isLocalHost, prevIsLocalHost, t]);

  return (
    <>
      <div
        ref={rootRef}
        className={"relative dark flex flex-col w-full h-full items-start bg-[#222] " + (!isFullScreen && !isMobile && "pb-[4.75rem]")}
      >
        <div className={"relative flex flex-col w-full h-full"}>
          {layoutType === LayoutType.Highlight ? (
            // 강조모드
            <HomeHighlite
              localVideoStream={localVideoStream}
              screenShareStream={screenShareStream}
              remoteHostVideoStream={remoteHostVideoStream}
              remoteHostAppData={remoteHostAppData}
              localAppData={localAppData}
            />
          ) : (
            // 분할모드
            <HomeSplit
              localVideoStream={localVideoStream}
              screenShareStream={screenShareStream}
              remoteHostVideoStream={remoteHostVideoStream}
              remoteHostAppData={remoteHostAppData}
              localAppData={localAppData}
            />
          )}

          <HomeCommon />
          <ConsumerAudioList />
        </div>
      </div>
    </>
  );
};

export default withRoomContext(HomeRoot);
