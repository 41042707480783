import Axios from "axios";
import { store } from "../../store/store";
import { AuthUserInfo } from "../../types/commonTypes";
import { IInnerAttendee } from "api/manager/invite";

interface IUserInfoDto {
  bid: string;
  username: string;
  position: string;
  deptname: string;
  picurl: string;
}

interface IBid {
  bid: string;
}

class AuthApi {
  HEADER_JSON: { headers: { "content-type": string } };

  axiosInstance = Axios.create({
    timeout: 10000,
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  });

  constructor() {
    this.HEADER_JSON = { headers: { "content-type": "application/json" } };

    this.axiosInstance.interceptors.request.use(
      config => {
        try {
          if (config.headers) config.headers.Authorization = `Bearer ${store.getState().auth.token}`;
        } catch (err) {
          console.error("[_axios.interceptors.request] config : " + err);
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }

  async getUserInfo(bid: string) {
    let language = store.getState().setting.language.slice(0, 2);
    if (language === "ja") language = "ko";

    const data = {
      cmd: "1101",
      sid: store.getState().auth.sid,
      who: [{ bid }],
      fields: "FULL",
      language,
    };

    const url = `${store.getState().env.value.AUTH_SERVER}/api/v1/crs-id-hrs/users/list`;
    const response = await this.axiosInstance.post(url, data, this.HEADER_JSON);

    let userInfo: AuthUserInfo = { bid: "", username: "", picurl: "" };

    try {
      if (response.data.code === 200) {
        userInfo = response.data.data.result[0];
      }
    } catch (e) {
      console.error(e);
    } finally {
      return userInfo;
    }
  }

  async getUsersInfo(bids: string[]) {
    let language = store.getState().setting.language.slice(0, 2);
    if (language === "ja") language = "ko";

    const who: IBid[] = [];
    bids.forEach(item => {
      who.push({ bid: item });
    });

    const data = {
      cmd: "1101",
      sid: store.getState().auth.sid,
      who,
      fields: "SHORT",
      language,
    };

    // console.log("getUsersInfo data", data);

    const url = `${store.getState().env.value.AUTH_SERVER}/api/v1/crs-id-hrs/users/list`;
    const response = await this.axiosInstance.post(url, data, this.HEADER_JSON);

    const resArray: IInnerAttendee[] = [];

    if (response.data.code === 200) {
      const result = response.data.data.result as IUserInfoDto[];

      const resMap = result.reduce((map, user) => {
        map.set(user.bid, {
          bid: user.bid,
          name: user.username,
          position: user.position,
          team: user.deptname,
          picurl: user.picurl,
        });
        return map;
      }, new Map<string, IInnerAttendee>());

      for (let bid of bids) {
        const info = resMap.get(bid);
        if (info) resArray.push(info);
      }
    } else {
      console.log("getUsersInfo res error", response);
    }
    return { result: response.data.code === 200, data: resArray };
  }
}

export default new AuthApi();
