import { useEffect, useState } from "react";
import RoomClient from "RoomClient";
import LoadingSimple from "components/LoadingSimple";
import { useSelector } from "react-redux";
import { selectRoomState } from "../store/roomInfoSlice";
import { useTranslation } from "react-i18next";
import { withRoomContext } from "RoomContext";

interface IProps {
  roomClient: RoomClient;
}

const WaitingRoom = (props: IProps) => {
  const { roomClient } = props;
  const { t } = useTranslation();
  const roomState = useSelector(selectRoomState);
  const [isShowReject, setIsShowReject] = useState(false);

  useEffect(() => {
    if (roomState === "rejected") setIsShowReject(true);
  }, [roomState]);

  const returnToRoot = () => {
    roomClient.close({ isReject: true });
  };

  return (
    <div className="h-full w-full bg-[#222] flex justify-center items-center">
      {roomState === "waiting" && (
        <div className="h-full w-full flex flex-col justify-center items-center">
          <div className="w-fit h-fit">
            <LoadingSimple size={64} />
          </div>

          <div className="flex flex-col justify-center items-center mt-4">
            <div className="font-bold text-base text-white">{t("msg.참여승인대기")}</div>
            <div className="font-bold text-base text-white">{t("msg.잠시대기")}</div>
          </div>
        </div>
      )}

      {isShowReject && (
        <div className="bg-[#333] w-[21.875rem] h-[12.313rem] shadow-lg rounded flex flex-col justify-between items-center">
          <div className="text-white mt-[3.625rem] text-sm">{t("msg.참여거절")}</div>
          <button onClick={returnToRoot} className="text-white mb-8 bg-blue-20 hover:bg-blue-30 w-[6.25rem] h-8 rounded text-sm">
            {t("확인")}
          </button>
        </div>
      )}
    </div>
  );
};

export default withRoomContext(WaitingRoom);
