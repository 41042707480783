import { IOuterAttendee } from "api/manager/invite";
import CloseButton from "components/common/buttons/CloseButton";

interface IProps {
  data: IOuterAttendee;
  onDelete: (email: string) => void;
}

const ExternalAttendeeBox = (props: IProps) => {
  const { data, onDelete } = props;

  return (
    <span className="h-[2.25rem] border border-[#86878b] text-sm rounded p-2 ml-1 mb-2 bg-[#333] flex shrink-0 items-center select-none">
      <div className="overflow-x-hidden whitespace-nowrap text-ellipsis max-w-[7.25rem] md:max-w-[13.9rem]">{data.name}</div>
      <span className="overflow-x-hidden whitespace-nowrap text-ellipsis max-w-[7.25rem] md:max-w-[13.9rem]">({data.email})</span>
      {!data.readonly && <CloseButton width={13} height={13} className="ml-1" classNameSVG="stroke-C-c8cace" onClick={() => onDelete(data.email)} />}
    </span>
  );
};

export default ExternalAttendeeBox;
