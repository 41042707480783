import { useEffect, useRef, useState } from "react";
import RoomClient from "RoomClient";
import LobbyMemberList from "./LobbyMemberList";
import JoinMemberList from "./JoinMemberList";
import { withRoomContext } from "RoomContext";
import { ReactComponent as IconPlus } from "assets/icon-plus.svg";
import { AuthUserInfo } from "types/commonTypes";
import { selectWaitingInfos } from "store/waitingRoomSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setIsShowInvitePopup } from "store/windowControlSlice";
import { PeerInfo } from "types/clientTypes";
import { selectPeers } from "store/peerSlice";
import SearchUser from "./SearchUser";

interface IProps {
  onCloseView: () => void;
  roomClient: RoomClient;
  buttonRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}

const Users = (props: IProps) => {
  const { onCloseView, buttonRef, isMobile } = props;
  const { t } = useTranslation();
  const [joinMembers, setJoinMembers] = useState<PeerInfo[]>([]);
  const [isOpenSubWindow, setIsOpenSubWindow] = useState(false);
  const [searchUserName, setSearchUserName] = useState("");
  const waitingInfos = useSelector(selectWaitingInfos);
  const peers = useSelector(selectPeers);
  const rootDivRef = useRef<HTMLDivElement>(null);
  const [lobbyMembers, setLobbyMembers] = useState<AuthUserInfo[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {};
  });

  useEffect(() => {
    const handleClickOutside = (ev: Event) => {
      if (buttonRef && buttonRef.current?.contains(ev.target as Node)) {
        return;
      }

      if (rootDivRef.current && !rootDivRef.current.contains(ev.target as Node) && !isOpenSubWindow) {
        onCloseView();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [buttonRef, isOpenSubWindow, onCloseView]);

  useEffect(() => {
    setJoinMembers(peers);
  }, [peers]);

  useEffect(() => {
    // console.log('대기자 변경됨:', waitingInfos, waitingInfos.length);
    setLobbyMembers(waitingInfos);
  }, [waitingInfos]);

  const handleAddMember = () => {
    dispatch(setIsShowInvitePopup(true));
    onCloseView();
  };

  const handleSearchUser = (s: string) => {
    setSearchUserName(s);
  };

  return (
    <div
      ref={rootDivRef}
      className={`${
        isMobile ? "max-h-full" : "max-h-[70vh] w-[21.5rem] md:absolute md:bottom-1 md:left-0 md:right-0 md:-translate-x-2/4 md:mx-9"
      } overflow-y-scroll overflow-x-hidden flex flex-col items-center z-50 rounded shadow bg-[#333] select-none border-0 border-green-400`}
    >
      <div>
        <div className="flex w-full h-9 justify-start items-center">
          <div className="w-full text-sm font-bold text-white ml-4 pt-1">{t("users.참여자 목록")}</div>
        </div>
        <div className="w-[344px] h-px bg-[#555]" />
      </div>

      <div>
        <div className="flex w-full h-9 justify-start items-center">
          <div className="w-full text-sm font-bold text-white ml-4 pt-1">
            {t("users.대기실")} ({lobbyMembers.length})
          </div>
        </div>
        <div className="w-[344px] h-px bg-[#555]" />
      </div>

      <div style={{ width: "100%" }}>
        <LobbyMemberList members={lobbyMembers} />
        <div className="w-[344px] h-px bg-[#555]" />
      </div>

      <div>
        <div className="flex w-full h-9 justify-start items-center">
          <div className="w-full text-sm font-bold text-white ml-4 pt-1">
            {t("users.회의실")} ({joinMembers.length})
          </div>
        </div>
        <div className="w-[344px] h-px bg-[#555]" />
      </div>

      <div className="w-full">
        <SearchUser onSearchText={handleSearchUser} />
      </div>

      <div className="w-full">
        <JoinMemberList members={joinMembers} setIsOpenSubWindow={setIsOpenSubWindow} searchUserName={searchUserName} />
        <div className="w-[344px] h-px bg-[#555] mt-5" />
      </div>

      <div className="flex h-[2.75rem] bg-[#333] justify-center items-center cursor-pointer shrink-0" onClick={handleAddMember}>
        <IconPlus />
        <div className="ml-2 text-sm pt-1">{t("users.참여자 초대")}</div>
      </div>
    </div>
  );
};

export default withRoomContext(Users);
