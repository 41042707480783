import { useEffect, useRef, useState } from "react";
import { Modal } from "../../Modal";
import { useTranslation } from "react-i18next";
import { PeerInfo } from "types/clientTypes";

export interface RenameBoxProps {
  isOpen: boolean;
  callback: (res: boolean, newNme: string) => void;
  // setIsOpen: (res: boolean) => void;
  peer: PeerInfo;
}

const RenameBox = (props: RenameBoxProps) => {
  const { isOpen, callback, peer } = props;
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const btnOkRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (peer) setName(peer.userInfo.username);
  }, [peer]);

  useEffect(() => {
    if (btnOkRef.current) {
      btnOkRef.current.disabled = name.length <= 0;
    }
  }, [name]);

  const handleClick = (res: boolean) => {
    callback(res, name.trim());
  };

  return (
    <Modal isOpen={isOpen}>
      <div onClick={e => e.stopPropagation()}>
        <div className="fixed inset-0 bg-black/75 z-50"></div>
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 p-5 rounded shadow bg-zinc-700 text-white">
          <div>
            <p className="font-malgungothic font-bold">{t("users.이름 변경")}</p>
            <input
              className="rounded border border-[#86878b] w-80 h-8 mt-6 bg-[#555] px-3"
              value={name}
              onChange={e => setName(e.target.value.trimStart())}
            />

            <div className="flex justify-center items-center mt-8 cursor-pointer">
              <button
                className="rounded border border-[#555] flex justify-center items-center w-24 h-8 mr-3 hover:bg-[#444]"
                onClick={() => handleClick(false)}
              >
                {t("취소")}
              </button>
              <button
                ref={btnOkRef}
                className="rounded flex justify-center items-center w-24 h-8 ml-3 bg-blue-20 hover:bg-blue-30 disabled:bg-[#444] disabled:border disabled:border-[#555]"
                onClick={() => handleClick(true)}
              >
                {t("확인")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RenameBox;
