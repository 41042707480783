import { ChatData } from "./../types/commonTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { ChatMsgType } from "types/commonTypes";

type ChatState = {
  array: ChatData[];
  canWindowClose: boolean;
  keyDown: string;
  lastReadCnt: number;
  notiCnt: number;
};

const initialState: ChatState = {
  array: [],
  canWindowClose: true,
  keyDown: "",
  lastReadCnt: 0,
  notiCnt: 0,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addChatData: (state: ChatState, action: PayloadAction<ChatData>) => {
      if (action.payload.msgType === ChatMsgType.NOTI) state.notiCnt += 1;
      state.array.push(action.payload);
    },
    setCanClose: (state: ChatState, action: PayloadAction<boolean>) => {
      state.canWindowClose = action.payload;
    },
    setKeyDown: (state: ChatState, action: PayloadAction<string>) => {
      // console.log(`리덕스-키눌림 상태업데이트 진입 : [${action.payload}]`);
      state.keyDown = action.payload;
    },
    setLastReadCnt: (state: ChatState, action: PayloadAction<number>) => {
      state.lastReadCnt = action.payload - state.notiCnt;
    },
  },
});

export const { addChatData, setCanClose, setKeyDown, setLastReadCnt } = chatSlice.actions;
export const selectAllChatData = (state: RootState) => state.chat.array;
export const selectCanClose = (state: RootState) => state.chat.canWindowClose;
export const selectKeyDown = (state: RootState) => state.chat.keyDown;
export const selectUnreadCnt = (state: RootState) => state.chat.array.length - state.chat.lastReadCnt - state.chat.notiCnt;

export default chatSlice.reducer;
