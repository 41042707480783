import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

interface IAssignHostPopup {
  isShow: boolean;
  username: string;
  bid: string;
}

interface IWindowState {
  assignHostPopup: IAssignHostPopup;
  isShowHostModeEnable: boolean;
  isShowHostModeRequest: boolean;
  isShowHostSetting: boolean;
  isShowInvitePopup: boolean;
  isShowVirtualBgSetting: boolean;
  isShowRecordingControl: boolean;
  isShowRecordingStoreList: boolean;
  isShowDocList: boolean;
  isShowDocSharingHost: boolean;
  isShowDocSharingClient: boolean;
  isShowNote: boolean;
  isShowChatting: boolean;
  isShowStopDocSharingPopup: boolean;
  isShowStopScreenSharingPopup: boolean;
  isShowScreenShareHost: boolean;
  isShowScreenShareClient: boolean;
  isShowMenuBar: boolean;
  isShowUsers: boolean;
  isFullScreen: boolean;
  isShowMobileMenu: boolean;
  isShowChangeView: boolean;
  isShowCaption: boolean;
  isShowLockSetting: boolean;
  isShowBottomVideoList: boolean;
}

const initialState: IWindowState = {
  assignHostPopup: { isShow: false, username: "", bid: "" },
  isShowHostModeEnable: false,
  isShowHostModeRequest: false,
  isShowHostSetting: false,
  isShowInvitePopup: false,
  isShowVirtualBgSetting: false,
  isShowRecordingControl: false,
  isShowRecordingStoreList: false,
  isShowDocList: false,
  isShowDocSharingHost: false,
  isShowDocSharingClient: false,
  isShowNote: false,
  isShowChatting: false,
  isShowStopDocSharingPopup: false,
  isShowStopScreenSharingPopup: false,
  isShowScreenShareHost: false,
  isShowScreenShareClient: false,
  isShowMenuBar: true,
  isShowUsers: false,
  isFullScreen: false,
  isShowMobileMenu: false,
  isShowChangeView: false,
  isShowCaption: false,
  isShowLockSetting: false,
  isShowBottomVideoList: true,
};

export const windowControlSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setAssignHostPopup: (state: IWindowState, action: PayloadAction<IAssignHostPopup>) => {
      state.assignHostPopup = action.payload;
    },
    setIsShowHostModeEnable: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowHostModeEnable = action.payload;
    },
    setIsShowHostModeRequest: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowHostModeRequest = action.payload;
    },
    setIsShowHostSetting: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowHostSetting = action.payload;
    },
    setIsShowInvitePopup: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowInvitePopup = action.payload;
    },
    setIsShowVirtualBgSetting: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowVirtualBgSetting = action.payload;
    },
    setIsShowRecordingControl: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowRecordingControl = action.payload;
    },
    setIsShowRecordingStoreList: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowRecordingStoreList = action.payload;
    },
    setIsShowDocList: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowDocList = action.payload;
    },
    setIsShowDocSharingHost: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowDocSharingHost = action.payload;
    },
    setIsShowDocSharingClient: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowDocSharingClient = action.payload;
    },
    setIsShowNote: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowNote = action.payload;
    },
    setIsShowChatting: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowChatting = action.payload;
    },
    setIsShowStopDocSharingPopup: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowStopDocSharingPopup = action.payload;
    },
    setIsShowStopScreenSharingPopup: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowStopScreenSharingPopup = action.payload;
    },
    setIsShowScreenSharingHost: (state: IWindowState, action: PayloadAction<boolean>) => {
      console.log(action.payload ? "화면공유중" : "화면공유중 아님");
      state.isShowScreenShareHost = action.payload;
    },
    setIsShowScreenSharingClient: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowScreenShareClient = action.payload;
    },
    setIsShowMenuBar: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowMenuBar = action.payload;
    },
    setIsShowUsers: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowUsers = action.payload;
    },
    setIsFullScreen: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isFullScreen = action.payload;
    },
    setIsShowMobileMenu: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowMobileMenu = action.payload;
    },
    setIsShowChangeView: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowChangeView = action.payload;
    },
    setIsShowCaption: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowCaption = action.payload;
    },
    setIsShowLockSetting: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowLockSetting = action.payload;
    },
    setIsShowBottomVideoList: (state: IWindowState, action: PayloadAction<boolean>) => {
      state.isShowBottomVideoList = action.payload;
    },
  },
});

export const {
  setAssignHostPopup,
  setIsShowHostModeEnable,
  setIsShowHostModeRequest,
  setIsShowHostSetting,
  setIsShowVirtualBgSetting,
  setIsShowInvitePopup,
  setIsShowRecordingControl,
  setIsShowRecordingStoreList,
  setIsShowDocList,
  setIsShowDocSharingHost,
  setIsShowDocSharingClient,
  setIsShowNote,
  setIsShowChatting,
  setIsShowStopDocSharingPopup,
  setIsShowStopScreenSharingPopup,
  setIsShowScreenSharingHost,
  setIsShowScreenSharingClient,
  setIsShowMenuBar,
  setIsShowUsers,
  setIsFullScreen,
  setIsShowMobileMenu,
  setIsShowChangeView,
  setIsShowCaption,
  setIsShowLockSetting,
  setIsShowBottomVideoList,
} = windowControlSlice.actions;

export const selectAssignHostPopup = (state: RootState) => state.windowControl.assignHostPopup;
export const selectIsShowHostModeEnable = (state: RootState) => state.windowControl.isShowHostModeEnable;
export const selectIsShowHostModeRequest = (state: RootState) => state.windowControl.isShowHostModeRequest;
export const selectIsShowHostSetting = (state: RootState) => state.windowControl.isShowHostSetting;
export const selectIsShowInvitePopup = (state: RootState) => state.windowControl.isShowInvitePopup;
export const selectIsShowVirtualBgSetting = (state: RootState) => state.windowControl.isShowVirtualBgSetting;
export const selectIsShowRecordingControl = (state: RootState) => state.windowControl.isShowRecordingControl;
export const selectIsShowRecordingStoreList = (state: RootState) => state.windowControl.isShowRecordingStoreList;
export const selectIsShowDocList = (state: RootState) => state.windowControl.isShowDocList;
export const selectIsShowDocSharingHost = (state: RootState) => state.windowControl.isShowDocSharingHost;
export const selectIsShowDocSharingClient = (state: RootState) => state.windowControl.isShowDocSharingClient;
export const selectIsShowNote = (state: RootState) => state.windowControl.isShowNote;
export const selectIsShowChatting = (state: RootState) => state.windowControl.isShowChatting;
export const selectIsShowStopDocSharingPopup = (state: RootState) => state.windowControl.isShowStopDocSharingPopup;
export const selectIsShowStopScreenSharingPopup = (state: RootState) => state.windowControl.isShowStopScreenSharingPopup;
export const selectIsShowScreenSharingHost = (state: RootState) => state.windowControl.isShowScreenShareHost;
export const selectIsShowScreenSharingClient = (state: RootState) => state.windowControl.isShowScreenShareClient;
export const selectIsShowMenuBar = (state: RootState) => state.windowControl.isShowMenuBar;
export const selectIsShowSubWindow = (state: RootState) => {
  return (
    state.windowControl.isShowUsers ||
    state.windowControl.isShowDocList ||
    state.windowControl.isShowChangeView ||
    state.windowControl.isShowLockSetting ||
    state.windowControl.isShowVirtualBgSetting
  );
};
export const selectIsShowUsers = (state: RootState) => state.windowControl.isShowUsers;
export const selectIsFullScreen = (state: RootState): boolean => state.windowControl.isFullScreen;
export const selectIsShowMobileMenu = (state: RootState) => state.windowControl.isShowMobileMenu;
export const selectIsShowChangeView = (state: RootState) => state.windowControl.isShowChangeView;
export const selectIsShowCaption = (state: RootState) => state.windowControl.isShowCaption;
export const selectIsShowLockSetting = (state: RootState) => state.windowControl.isShowLockSetting;
export const selectIsShowBottomVideoList = (state: RootState) => state.windowControl.isShowBottomVideoList;

export default windowControlSlice.reducer;
