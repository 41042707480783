import YesNoDialog from "../YesNoDialog";
import { useDispatch, useSelector } from "react-redux";
import RoomClient from "../../RoomClient";
import { useTranslation } from "react-i18next";
import { setIsShowStopDocSharingPopup, selectIsShowDocSharingClient, selectIsShowDocSharingHost } from "store/windowControlSlice";

interface IProps {
  roomClient: RoomClient;
  isOpen: boolean;
}

const StopDocSharingPopup = (props: IProps) => {
  const { isOpen, roomClient } = props;
  const { t } = useTranslation();
  const isShowDocSharingHost = useSelector(selectIsShowDocSharingHost);
  const isShowDocSharingClient = useSelector(selectIsShowDocSharingClient);
  const dispatch = useDispatch();

  const handlerCallback = async (res: boolean) => {
    if (!res) return;

    if (isShowDocSharingHost) {
      await roomClient.closeDocSharingHost();
    } else if (isShowDocSharingClient) {
      await roomClient.sendForceStopDocSharing();
    }

    roomClient.closeScreenShare();
    roomClient.screenShare();
  };

  const setIsOpen = (open: boolean) => {
    dispatch(setIsShowStopDocSharingPopup(open));
  };

  return (
    <YesNoDialog
      msg={t("msg.문서공유를 중지하고 화면공유를 시작하겠습니까?")}
      isOpen={isOpen}
      callback={(res: boolean) => handlerCallback(res)}
      setIsOpen={(open: boolean) => setIsOpen(open)}
      leftButtonText={t("아니오")}
      rightButtonText={t("예")}
    />
  );
};

export default StopDocSharingPopup;
