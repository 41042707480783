import React from "react";
import { selectActiveSpeaker } from "store/roomInfoSlice";
import { useSelector } from "react-redux";

interface IProps {
  bid: string;
}

const ActiveSpeakerEffect = ({ bid }: IProps) => {
  const activeSpeaker = useSelector(selectActiveSpeaker);
  return <div className={`absolute inset-0 border-4 ${activeSpeaker === bid ? "border-[#18c0fd]" : "border-black/0"}`} />;
};

export default React.memo(ActiveSpeakerEffect);
