import React, { useRef } from "react";
import UserCommandMore from "components/menubar/users/UserCommandMore";
import { usePeer } from "hooks/usePeer";

export interface IContextMenuProps {
  x: number;
  y: number;
  closeMenu: () => void;
  socketId: string;
}

const ContextMenu = ({ x, y, closeMenu, socketId }: IContextMenuProps) => {
  const peer = usePeer(socketId);
  const buttonRef = useRef<HTMLDivElement>(null);

  return (
    <div className="text-white fixed top-0 left-0 z-50 border border-[#555]" style={{ top: y - 5, left: x - 5 }}>
      {peer && <UserCommandMore peer={peer} buttonRef={buttonRef} callback={() => {}} onClose={() => closeMenu()} />}
      <div ref={buttonRef}></div>
    </div>
  );
};

export default ContextMenu;
