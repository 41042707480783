import { store } from "store/store";
import { useGlobalPayload, useGlobalStorageId, Payload } from "../hooks/useGlobalState";
import { ResponseStorageEndpoint } from "../interfaces/File";
import { RequestOrgList, RequestUser, ResponseUser } from "../interfaces/OrgData";
import { useFetch, useLoadMore, QueryKeyT } from "./CRUD";
import { UseQueryOptions } from "@tanstack/react-query";

export const useGetOrgTree = <T>(requestData: RequestOrgList, config: UseQueryOptions<T, Error, T, QueryKeyT>) => {
  const commonHost = store.getState().env.value.AUTH_SERVER;

  return useFetch("post", commonHost + "/api/v1/crs-id-hrs/orgs/list", requestData, config);
};

export const useSearchUser = <T>(keyword: string | null) => {
  const [globalPayload] = useGlobalPayload();
  const commonHost = store.getState().env.value.AUTH_SERVER;

  const context = useLoadMore<T>(
    "post",
    commonHost + "/api/v1/crs-id-hrs/users/search",
    {
      cmd: "1102",
      sid: globalPayload.sid,
      kind: "ALL",
      fields: "FULL",
      keyword,
    },
    !!(keyword && commonHost),
  );

  return context;
};

export const useGetUser = (who: RequestUser, globalPayload: Partial<Payload>) => {
  const commonHost = store.getState().env.value.AUTH_SERVER;
  const context = useFetch<ResponseUser>(
    "post",
    commonHost + "/api/v1/crs-id-hrs/users/list",
    {
      cmd: "1101",
      sid: globalPayload.sid,
      who,
      language: "ko",
    },
    undefined,
    !!globalPayload.bid && !!globalPayload.sid && !!who.length && !!who[0].bid,
  );

  return context;
};

export const useGetStorageEndpoint = () => {
  const [globalPayload] = useGlobalPayload();
  const [globalStorageId] = useGlobalStorageId();
  const commonHost = store.getState().env.value.AUTH_SERVER;

  const { data } = useFetch<ResponseStorageEndpoint>("post", commonHost + "/api/v1/crs-file-storage/list", {
    cmd: "1302",
    sid: globalPayload.sid,
    what: [{ strid: globalStorageId }],
  });
  const storageEndpoint = `${data?.data.result[0].endpoint ?? ""}/${data?.data.result[0].ver ?? ""}/crs-file-storage/object`;

  return storageEndpoint;
};
