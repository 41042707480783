import { ChangeEvent, memo, useEffect, useState } from "react";
import { SPACE } from "components/common/const";
import { isEmailValid, isNonEmptyString } from "lib/utils";
import Button from "components/common/buttons/Button";
import CloseButton from "components/common/buttons/CloseButton";
import InputText from "components/common/inputs/InputText";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { IOuterAttendee } from "api/manager/invite";

interface IProps {
  closeModal: () => void;
  onResult: (data: IOuterAttendee[]) => void;
  prevOuterAttendees: IOuterAttendee[];
}

const ExternalAttendeeInput = memo(({ closeModal, onResult, prevOuterAttendees }: IProps) => {
  const { t } = useTranslation();
  const [outerAttendees, setOuterAttendees] = useState<IOuterAttendee[]>([]);
  const [form, setValues] = useState<{
    [index: string]: string | boolean;
    name: string;
    incorrectName: boolean;
    email: string;
    incorrectEmail: boolean;
  }>({
    name: "",
    incorrectName: false,
    email: "",
    incorrectEmail: false,
  });

  useEffect(() => {
    console.log("현재 prevOuterAttendees", prevOuterAttendees);
    setOuterAttendees(prevOuterAttendees);
  }, [prevOuterAttendees]);

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setValues(prevState => ({
      ...prevState,
      name: e.target.value,
      incorrectName: false,
    }));
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setValues(prevState => ({
      ...prevState,
      email: e.target.value.trim(),
      incorrectEmail: false,
    }));
  };

  const checkField = (fieldKey: "email" | "name", fieldErrorKey: "incorrectEmail" | "incorrectName", fieldValidator: (arg: string) => boolean) => {
    if (!fieldValidator(form[fieldKey])) {
      setValues(prevState => ({
        ...prevState,
        [fieldErrorKey]: true,
      }));
      return false;
    }
    return true;
  };

  const checkValidation = () => {
    let isValid = true;
    isValid = isValid && checkField("name", "incorrectName", isNonEmptyString);
    isValid = isValid && checkField("email", "incorrectEmail", isEmailValid);
    return isValid;
  };

  const resetState = () => {
    setValues(prev => ({
      ...prev,
      email: "",
      incorrectEmail: false,
      name: "",
      incorrectName: false,
    }));
  };

  const onAddUser = () => {
    if (!checkValidation()) {
      return;
    }
    const { name, email } = form;

    const findExist = outerAttendees.find(item => item.email === email);
    if (findExist) {
      toast.info(t("invite.이미 추가된 이메일입니다."), {
        autoClose: 3000,
        progress: undefined,
      });
      return;
    }

    setOuterAttendees([...outerAttendees, { name: name.trim(), email: email.trim() }]);
    resetState();
  };

  const onRemoveUser = (email: string) => {
    const newList = outerAttendees.filter(item => item.email !== email);
    setOuterAttendees(newList);
  };

  const handleClose = () => {
    onResult(outerAttendees);
    closeModal();
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-[41]" onClick={closeModal}>
      <div
        className={[
          "bg-white dark:bg-[#333] dark:text-white",
          "w-[99%] h-[99%] max-w-[45.25rem] max-h-[41.188rem]",
          "flex flex-col gap-8 rounded-md pt-5 pr-5 pl-5 pb-[1.875rem] m-2.5  overflow-auto",
          "shadow-[0_3px_12px_0_rgba(75,85,98,0.3)] ",
        ].join(SPACE)}
        onClick={e => e.stopPropagation()}
      >
        <div className="text-base font-bold text-[#555] dark:text-white">{t("invite.외부 참여자 추가")}</div>
        <div className="flex flex-row gap-2">
          <div className="flex-[1] relative">
            <div className="text-[0.813rem] font-bold text-[#9d9ea2] dark:text-white">
              {t("invite.이름")}
              <span className="text-[#d21011]">*</span>
            </div>
            <InputText
              value={form.name}
              handleChangeInput={onChangeName}
              handleClear={() =>
                setValues(prev => ({
                  ...prev,
                  name: "",
                  incorrectName: false,
                }))
              }
            />
            {form.incorrectName && <span className="text-sm text-[#d20707] mt-[0.25rem] absolute truncate">{t("invite.이름을 입력해주세요.")}</span>}
          </div>
          <div className="flex-[2]">
            <div className="text-[0.813rem] font-bold text-[#9d9ea2] dark:text-white">
              {t("invite.이메일")}
              <span className="text-[#d21011]">*</span>
            </div>
            <InputText
              value={form.email}
              handleChangeInput={onChangeEmail}
              handleClear={() => {
                setValues(prev => ({
                  ...prev,
                  email: "",
                  incorrectEmail: false,
                }));
              }}
            />
            {form.incorrectEmail && (
              <span className="text-sm text-[#d20707] mt-[0.25rem] absolute truncate">{t("invite.올바른 이메일 형식으로 입력해주세요.")}</span>
            )}
          </div>
          <div>
            <div className="text-[0.813rem] text-transparent">.</div>
            <button
              className="w-[3.75rem] h-[2rem] ml-2 p-[0.437rem_1rem_0.438rem] rounded-[4px] bg-[#1f62b9] text-sm text-white"
              onClick={onAddUser}
            >
              {t("invite.추가")}
            </button>
          </div>
        </div>
        <div className="border border-[#c8cace] h-[28.5rem] rounded">
          <div className="border-b-[1px] border-b-[#c8cace] w-full h-[2.375rem] p-2 text-sm text-[#676767] dark:text-white">
            {t("invite.총")} {outerAttendees.length} {t("adduser.명")}
          </div>
          <div className="overflow-auto p-2 flex gap-2 flex-wrap max-h-[22.6rem]">
            {outerAttendees.map((attendee, index) => (
              <div
                key={index}
                className="h-[2.25rem] bg-[#333] border border-[#1f62b9] dark:border-[#86878b] rounded text-sm text-[#1f62b9] dark:text-white p-[0.188rem_0.5rem_0.188rem_0.75rem] w-fit flex gap-2 items-center select-none"
              >
                <span className="whitespace-nowrap overflow-x-hidden text-ellipsis max-w-[7.25rem] md:max-w-[17rem]">{attendee.name}</span>
                <span className="whitespace-nowrap overflow-x-hidden text-ellipsis max-w-[7.25rem] md:max-w-[17rem]">({attendee.email})</span>

                {!attendee.readonly && (
                  <CloseButton width={13} height={13} classNameSVG="stroke-C-ffffff" onClick={() => onRemoveUser(attendee.email)} />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="mt-auto">
          <div className="flex w-full justify-center gap-8">
            <Button
              kind="cancel-dark"
              label={t("취소")}
              className="w-[6.25rem] h-[2rem] rounded-[4px] border border-solid border-[#555] bg-[#fff] dark:bg-[#333] dark:text-white dark:hover:bg-[#555]"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              kind="primary"
              label={t("확인")}
              className="w-[6.25rem] h-[2rem] rounded-[4px] border border-solid border-[#555] bg-[#1f62b9] text-sm text-[#fff]"
              onClick={handleClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

ExternalAttendeeInput.displayName = "ExternalAttendeeInput";

export default ExternalAttendeeInput;
