import { useEffect, useState, useCallback } from "react";
import RoomClient from "../../RoomClient";
import { withRoomContext } from "../../RoomContext";
import { useSelector } from "react-redux";
import { selectRoomSetting } from "store/roomInfoSlice";
import {
  ResultReason,
  SpeechRecognizer,
  Recognizer,
  SpeechRecognitionEventArgs,
  SpeechRecognitionCanceledEventArgs,
  SessionEventArgs,
} from "microsoft-cognitiveservices-speech-sdk";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import { SpeechToTextData } from "../../types/commonTypes";
import { selectSttFromLanguage, selectIsLocalMicOn } from "../../store/settingSlice1";
import { getCurrentLocalTimeString } from "lib/utils";

interface IProps {
  roomClient: RoomClient;
}

export interface IAzureSpeechToken {
  token: string;
  region: string;
}

const AzureStt = (props: IProps) => {
  const { roomClient } = props;
  const [recognizer, setRecognizer] = useState<SpeechRecognizer | null>();
  const [curLanguage, setCurLanguage] = useState<string>("");
  const roomSetting = useSelector(selectRoomSetting);
  const sttFromLanguage = useSelector(selectSttFromLanguage);
  const isLocalMicOn = useSelector(selectIsLocalMicOn);

  const getAzureSpeechToken = useCallback(async (): Promise<IAzureSpeechToken> => {
    return await new Promise(resolve => {
      roomClient.socket.emit("get-azure-speech-token", (answer: IAzureSpeechToken) => {
        resolve(answer);
      });
    });
  }, [roomClient.socket]);

  const onSpeechToText = useCallback(
    (result: string, isFinal: boolean) => {
      if (result.length === 0) return;

      // if (isFinal) console.log(`onSpeechToText(final). language:${sttFromLanguage}`, result);

      const today = new Date();
      const newStt: SpeechToTextData = {
        roomId: roomClient.roomId,
        userInfo: { isInsider: true, bidOrEmail: roomClient.userInfo.bid },
        text: result,
        time: today.toISOString(),
        isLocal: true,
        language: sttFromLanguage,
        isFinal,
      };

      roomClient.sendSpeechToText(newStt);
    },
    [sttFromLanguage, roomClient],
  );

  const start = useCallback(async () => {
    console.log(`AzureStt-start() language:${sttFromLanguage}`);

    const token = await getAzureSpeechToken();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(token.token, token.region);
    speechConfig.speechRecognitionLanguage = sttFromLanguage;

    const deviceId = await roomClient._getAudioDeviceId();

    const audioConfig = speechsdk.AudioConfig.fromMicrophoneInput(deviceId);
    const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
    setRecognizer(recognizer);

    recognizer.recognizing = function (s, e) {
      onSpeechToText(e.result.text, false);
    };

    recognizer.recognized = async (sender: Recognizer, event: SpeechRecognitionEventArgs) => {
      try {
        if (event.result.reason === ResultReason.RecognizedSpeech) {
          onSpeechToText(event.result.text, true);
        }
      } catch (error) {
        console.log(getCurrentLocalTimeString(), error);
      }
    };

    recognizer.canceled = (sender: Recognizer, event: SpeechRecognitionCanceledEventArgs) => {
      console.log("canceled", getCurrentLocalTimeString(), event);
      recognizer.stopContinuousRecognitionAsync();
      start();
    };

    recognizer.sessionStarted = (sender: Recognizer, event: SessionEventArgs) => {
      console.log("sessionStarted", getCurrentLocalTimeString(), event);
    };

    recognizer.sessionStopped = (sender: Recognizer, event: SessionEventArgs) => {
      console.log("sessionStopped", getCurrentLocalTimeString(), event);
      recognizer.stopContinuousRecognitionAsync();
    };

    recognizer.startContinuousRecognitionAsync(
      () => {
        console.log(`azure STT START. ${sttFromLanguage}`, getCurrentLocalTimeString());
      },
      (e: string) => {
        console.error("azure STT START error!", e);
      },
    );
  }, [getAzureSpeechToken, sttFromLanguage, onSpeechToText, roomClient]);

  const stop = useCallback(async () => {
    if (recognizer) {
      console.log("azure STT STOP");
      recognizer.stopContinuousRecognitionAsync();
      setRecognizer(null);
    }
  }, [recognizer]);

  useEffect(() => {
    if (isLocalMicOn && roomSetting.isSttEnable) {
      start();
    } else {
      stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomSetting.isSttEnable, isLocalMicOn]);

  useEffect(() => {
    if (recognizer) {
      if (curLanguage !== sttFromLanguage) {
        stop();
        start();
      }
    }
    setCurLanguage(sttFromLanguage);
  }, [curLanguage, recognizer, start, stop, sttFromLanguage]);

  return <div className="hidden"></div>;
};

export default withRoomContext(AzureStt);
