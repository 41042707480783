import React, { useCallback, useState, useEffect, useRef } from "react";
import RoomClient from "../RoomClient";
import SelectListbox2 from "../components/SelectListbox2";
import i18next from "../translate/i18n";
import LoadingSimple from "components/LoadingSimple";
import PermissionError from "components/error/PermissionError";
import DeviceNotFoundError from "components/error/DeviceNotFoundError";
import config from "config";
import Tooltip from "components/setting/Tooltip";
import ClickAwayListener from "components/ClickAwayListener";
import TestRoot from "components/setting/speakerMicTest/TestRoot";
import { withRoomContext } from "../RoomContext";
import { useLocation } from "react-router-dom";
import { Language } from "../types/commonTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAudioOutputDevice,
  selectMic,
  selectWebcam,
  selectWebcams,
  selectSelectedWebcam,
  selectMics,
  selectSelectedMic,
  getSpeakers,
  getSelectedSpeaker,
  selectIsWebcamPermissionError,
  selectIsMicPermissionError,
  setIsCamRotate,
  setLanguage,
  setSttFromLanguage,
  setSttToLanguage,
  setVirtualBgType,
  setVirtualBgUrl,
  VirtualBgType,
} from "store/settingSlice1";
import { setAuthUserInfo, selectAuthUserInfo } from "store/authSlice";
import { LayoutType, setIsKtccTest, setIsSttTranslation, setLayoutType } from "store/roomInfoSlice";
import { options_lang } from "components/setting/Setting";
import { useTranslation } from "react-i18next";
import { isIOS, isSafari } from "react-device-detect";
import { Value } from "components/common/interfaces/TypeSelectListbox";
import { backgroundImageUrls } from "lib/virtualbg/core/helpers/backgroundHelper";
import { ReactComponent as IconQuestion } from "assets/icon-question.svg";
import { ReactComponent as IconChecked } from "assets/check-box-checked.svg";
import { ReactComponent as IconUnChecked } from "assets/check-box-unchecked.svg";
import { ReactComponent as IconError } from "assets/icon-error.svg";
import { recordingDb } from "lib/recording/RecordingDb";
import { recordingListDb } from "lib/recording/RecordingListDb";
import YesNoDialog from "components/YesNoDialog";

interface IJoinProps {
  roomClient: RoomClient;
  callback: () => void;
}

const JoinPopupWithVideo = (props: IJoinProps) => {
  const { roomClient, callback } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedOptionCam, setSelectedOptionCam] = useState<Value>({ id: "", name: "" });
  const [selectedOptionMic, setSelectedOptionMic] = useState<Value>({ id: "", name: "" });
  const [selectedOptionSpk, setSelectedOptionSpk] = useState<Value>({ id: "", name: "" });
  const [selectedOptionLang, setSelectedOptionLang] = useState<Value>(options_lang[0]);
  const [options_webcam, setOptionWebcam] = useState<Value[]>([]);
  const [options_mic, setOptionMic] = useState<Value[]>([]);
  const [options_spk, setOptionSpk] = useState<Value[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [isOpenTestDialog, setIsOpenTestDialog] = useState(false);
  const [isPermissionRequestAfter, setIsPermissionRequestAfter] = useState(false);
  const [isShowJoinYesNo, setIsShowJoinYesNo] = useState(false);
  const webcams = useSelector(selectWebcams);
  const mics = useSelector(selectMics);
  const speakers = useSelector(getSpeakers);
  const selectedWebcam = useSelector(selectSelectedWebcam);
  const selectedMic = useSelector(selectSelectedMic);
  const selectedSpk = useSelector(getSelectedSpeaker);
  const userInfo = useSelector(selectAuthUserInfo);
  const isWebcamPermissionError = useSelector(selectIsWebcamPermissionError);
  const isMicPermissionError = useSelector(selectIsMicPermissionError);
  const [videoStream, setVideoStream] = useState<MediaStream>();
  const savedWebcamId = localStorage.getItem("selectedWebcomId");
  const savedMicId = localStorage.getItem("selectedMicId");
  const savedSpeakerId = localStorage.getItem("selectedAudioOutputDeviceId");
  const savedIsCamRotate = localStorage.getItem("isCamRotate");
  const [isRotate, setIsRotate] = useState(savedIsCamRotate === "true" || savedIsCamRotate == null);
  const savedVirtualBgType = localStorage.getItem("virtualBgType");
  const savedVirtualBgUrl = localStorage.getItem("virtualBgUrl");
  const isSttTranslation = localStorage.getItem("isSttTranslation");
  const sttFromLanguage = localStorage.getItem("sttFromLanguage");
  const sttToLanguage = localStorage.getItem("sttToLanguage");
  const videoRef = useRef<HTMLVideoElement>(null);
  const testBtnRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const executeJoin = useCallback(async () => {
    dispatch(setIsCamRotate(isRotate));
    dispatch(setLanguage(selectedOptionLang.id as Language));
    dispatch(setSttFromLanguage(sttFromLanguage || selectedOptionLang.id));
    dispatch(setSttToLanguage(sttToLanguage || selectedOptionLang.id));
    callback();
  }, [callback, dispatch, isRotate, selectedOptionLang.id, sttFromLanguage, sttToLanguage]);

  const handleJoin = useCallback(() => {
    if (isLoading) return;
    if (isWebcamPermissionError || isMicPermissionError) {
      // toast.error(t("msg.카메라/마이크 접근을 허용해주세요."));
      setIsShowJoinYesNo(true);
      return;
    }

    executeJoin();
  }, [executeJoin, isLoading, isMicPermissionError, isWebcamPermissionError]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (videoStream !== undefined) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isPerfTest = params.get("perftest");
    const layout = params.get("layout") === "1" ? LayoutType.Highlight : LayoutType.Split;

    if (isPerfTest === "true" && !isLoading && !(isWebcamPermissionError || isMicPermissionError)) {
      dispatch(setLayoutType(layout as LayoutType));
      setTimeout(() => {
        handleJoin();
      }, 1000);
    }
  }, [dispatch, handleJoin, isLoading, isMicPermissionError, isWebcamPermissionError, location]);

  const clearAllRecordingDb = useCallback(async () => {
    console.log("clearAllRecordingDb");
    await recordingDb.clearAll();
    await recordingListDb.clearAll();
  }, []);

  const loadingDeviceSetting = useCallback(async () => {
    if (savedWebcamId) {
      dispatch(selectWebcam(savedWebcamId));
    }

    if (savedMicId) {
      dispatch(selectMic(savedMicId));
    }

    if (savedSpeakerId) {
      dispatch(selectAudioOutputDevice(savedSpeakerId));
    }

    dispatch(setIsCamRotate(savedIsCamRotate === "true" || savedIsCamRotate == null));

    if (savedVirtualBgType) {
      dispatch(setVirtualBgType(savedVirtualBgType as VirtualBgType));

      if (savedVirtualBgUrl) {
        backgroundImageUrls.forEach(url => {
          if (url === savedVirtualBgUrl) {
            dispatch(setVirtualBgUrl(savedVirtualBgUrl));
          }
        });
      }
    }

    dispatch(setIsSttTranslation(isSttTranslation ? JSON.parse(isSttTranslation) : false));

    let uiLanguageId = localStorage.getItem("language");
    if (!uiLanguageId) uiLanguageId = navigator.language;

    const findLang = options_lang.find(item => uiLanguageId && item.id.startsWith(uiLanguageId));
    if (findLang) {
      dispatch(setLanguage(uiLanguageId as Language));
      setSelectedOptionLang(findLang);
      i18next.changeLanguage(findLang.id);
    } else {
      console.error("JoinPopup - 언어설정 오류");
    }

    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      console.log("JoinPopup - getUserMedia success");
    } catch (e) {
      console.error("join popup", e);
    }

    await roomClient._updateWebcams({ init: true });
    await roomClient._updateAudioInputDevices({ init: true });
    await roomClient._updateAudioOutputDevices({ init: true });

    setIsPermissionRequestAfter(true);
  }, [savedWebcamId, savedMicId, savedSpeakerId, dispatch, savedIsCamRotate, savedVirtualBgType, isSttTranslation, roomClient, savedVirtualBgUrl]);

  useEffect(() => {
    console.log("JoinPopupWithVideo 생성~~~~~~~~~~~~~~");
    clearAllRecordingDb();
    loadingDeviceSetting();
    if (localStorage.getItem("isKtcc")) {
      dispatch(setIsKtccTest(true));
    }
    return () => {
      console.log("JoinPopupWithVideo 소멸~~~~~~~~~~~~~~");
    };
  }, [clearAllRecordingDb, dispatch, loadingDeviceSetting]);

  const changeVideoDevice = useCallback(
    async (id: string) => {
      const stream = await roomClient.getMediaStreamWithRetry(
        {
          video: {
            ...config.userMedia.video,
            deviceId: { ideal: id },
            aspectRatio: { ideal: config.aspectRatio },
          },
        },
        10,
      );

      setVideoStream(stream);
    },
    [roomClient],
  );

  useEffect(() => {
    const newWebcamNames: Value[] = [];
    webcams.forEach((values, key) => {
      const val = { id: key, name: values.label };
      newWebcamNames.push(val);

      if (key === selectedWebcam) {
        setSelectedOptionCam(val);
        changeVideoDevice(key);
      }
    });
    setOptionWebcam(newWebcamNames);
  }, [webcams, selectedWebcam, changeVideoDevice]);

  useEffect(() => {
    const newMics: Value[] = [];
    mics.forEach((values, key) => {
      const val = { id: key, name: values.label };
      newMics.push(val);
      if (key === selectedMic) {
        setSelectedOptionMic(val);
      }
    });

    setOptionMic(newMics);
  }, [mics, selectedMic, t]);

  useEffect(() => {
    const newSpks: Value[] = [];

    speakers.forEach((values, key) => {
      const val = { id: key, name: values.label };
      newSpks.push(val);
      if (key === selectedSpk) {
        setSelectedOptionSpk(val);
      }
    });

    setOptionSpk(newSpks);
  }, [selectedSpk, speakers, t]);

  useEffect(() => {
    if (!timer) {
      setTimer(
        setTimeout(() => {
          setIsLoading(false);
        }, 10000),
      );
    }

    if (options_webcam.length > 0 && options_mic.length > 0) {
      if (timer) clearTimeout(timer);
      setIsLoading(false);
    }

    if (isWebcamPermissionError && isMicPermissionError) {
      setIsLoading(false);
    }
  }, [options_webcam, options_mic, options_spk, timer, isWebcamPermissionError, isMicPermissionError]);

  const handleChangeName = (value: string) => {
    if (userInfo) {
      dispatch(setAuthUserInfo({ ...userInfo, username: value }));
    }
  };

  const handleChangeLanguage = (v: Value) => {
    setSelectedOptionLang(v);
    i18next.changeLanguage(v.id);
  };

  const handleChangeWebcam = async (v: Value) => {
    setSelectedOptionCam(v);
    dispatch(selectWebcam(v.id));
  };

  const handleChangeMic = (v: Value) => {
    setSelectedOptionMic(v);
    dispatch(selectMic(v.id));
  };

  const handleChangeSpeaker = (v: Value) => {
    setSelectedOptionSpk(v);
    dispatch(selectAudioOutputDevice(v.id));
    localStorage.setItem("selectedAudioOutputDeviceId", v.id);
  };

  const onMicDeviceId = (id: string) => {
    const v = options_mic.find(opt => opt.id === id);
    if (v) {
      setSelectedOptionMic(v);
      dispatch(selectMic(v.id));
    }
  };

  const onSpeakerDeviceId = (id: string) => {
    const v = options_spk.find(opt => opt.id === id);
    if (v) {
      setSelectedOptionSpk(v);
      dispatch(selectAudioOutputDevice(v.id));
      localStorage.setItem("selectedAudioOutputDeviceId", v.id);
    }
  };

  return (
    <div className="bg-black/75 h-full w-full absolute inset-0 flex md:justify-center items-center text-sm">
      <div className="relative w-full m-2 md:w-auto max-h-full flex flex-col items-center rounded-lg shadow bg-[#333] text-white mb-0 p-6 md:py-8 select-none">
        <div className="flex flex-col justify-around sm:flex-row sm:justify-center mt-[1.188rem] w-full">
          <div className="flex flex-col md:flex-row mr-0 w-full items-center md:items-start">
            <div className="w-[16.75rem] flex justify-center items-center flex-col">
              <div className="w-full h-[10.188rem] border-2 border-[#222] bg-[#555] flex justify-center items-center overflow-hidden">
                <video
                  className="bg-black"
                  autoPlay
                  muted
                  ref={videoRef}
                  width="100%"
                  height="100%"
                  style={isRotate ? { transform: "rotateY(180deg)" } : {}}
                  playsInline
                  disablePictureInPicture
                />
              </div>

              <div className="flex items-center w-full mt-2 mb-4 cursor-pointer" onClick={() => setIsRotate(!isRotate)}>
                <div>{isRotate ? <IconChecked /> : <IconUnChecked />}</div>
                <div className="ml-2 select-none" onClick={() => setIsRotate(!isRotate)}>
                  {t("setting.좌우반전")}
                </div>
              </div>
            </div>

            <div className="w-full md:w-auto">
              <table className="table table-fixed md:ml-6">
                <tbody>
                  <tr className="h-10 align-middle">
                    <td className="h-9 min-w-[5.25rem]">
                      <div className="flex">
                        <div className="shrink-0">{t("카메라")}</div>
                        {!isPermissionRequestAfter ? (
                          <IconError className="ml-1" />
                        ) : (
                          <>
                            {isWebcamPermissionError ? (
                              <PermissionError type="webcam" isShowTooltip={isPermissionRequestAfter} />
                            ) : (
                              <>{webcams.size === 0 && <DeviceNotFoundError />}</>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                    <td className="h-9 w-full md:w-[16.25rem] relative">
                      {options_webcam && (
                        <div className="absolute top-[2px] left-0 w-full h-9">
                          <SelectListbox2
                            options={options_webcam}
                            value={selectedOptionCam}
                            onChange={v => handleChangeWebcam(v)}
                            optionsHeight={40}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr className="h-10 align-middle">
                    <td className="h-9 min-w-[5.25rem]">
                      <div className="flex">
                        <div className="shrink-0">{t("마이크")}</div>
                        {!isPermissionRequestAfter ? (
                          <IconError className="ml-1" />
                        ) : (
                          <>
                            {isMicPermissionError ? (
                              <PermissionError type="mic" isShowTooltip={isPermissionRequestAfter} />
                            ) : (
                              <> {mics.size === 0 && <DeviceNotFoundError />}</>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                    <td className="h-9 w-full md:w-[16.25rem] relative">
                      <div className="absolute top-[2px] left-0 w-full h-8">
                        <SelectListbox2 options={options_mic} value={selectedOptionMic} onChange={v => handleChangeMic(v)} optionsHeight={40} />
                      </div>
                    </td>
                  </tr>
                  <tr className="h-10 align-middle">
                    <td className="min-w-[5.25rem]">{t("스피커")}</td>
                    <td className="h-9 w-full md:w-[16.25rem] relative">
                      <div className="h-9 w-ful">
                        {!isIOS && !isSafari ? (
                          <SelectListbox2 options={options_spk} value={selectedOptionSpk} onChange={v => handleChangeSpeaker(v)} optionsHeight={40} />
                        ) : (
                          <div className="border border-solid border-[#86878b] bg-[#555] h-8 rounded text-white pl-3 pr-2 flex flex-row items-center cursor-default">
                            {t("시스템 기본")}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr className="h-10 align-middle text-right">
                    <td colSpan={2} className="h-9">
                      {!isWebcamPermissionError && !isMicPermissionError && (
                        <div className="flex mt-0 mb-2 justify-end" onClick={() => setIsOpenTestDialog(!isOpenTestDialog)} ref={testBtnRef}>
                          <div className="flex items-center h-8 border border-[#86878b] rounded text-white cursor-pointer px-6 text-sm hover:bg-[#555]">
                            {t("setting.스피커/마이크 테스트")}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="relative w-full bg-[#333] rounded shadow text-white">
          <table className="table table-fixed">
            <tbody className="">
              <tr className="h-10 align-middle">
                <td className="h-9 min-w-[5.25rem]">{t("join.내 이름")}</td>
                <td className="h-9 w-full">
                  <input
                    className="h-8 w-full rounded bg-[#555] border border-[#86878b] px-3"
                    type="text"
                    value={userInfo?.username ?? ""}
                    readOnly={userInfo ? false : true}
                    onChange={e => handleChangeName(e.target.value)}
                  />
                </td>
              </tr>
              <tr className="h-10 align-middle">
                <td className="h-9 min-w-[5.25rem]">
                  <div className="flex items-center">
                    <div className="">{t("join.언어")}</div>
                    <div className="">
                      <Tooltip content={t("setting.언어툴팁")} isCenter={false}>
                        <IconQuestion className="ml-1 cursor-pointer translate-y-[2px]" />
                      </Tooltip>
                    </div>
                  </div>
                </td>
                <td className="h-9 w-full relative overflow-visible">
                  <div className="w-full h-8">
                    <SelectListbox2 options={options_lang} value={selectedOptionLang} onChange={v => handleChangeLanguage(v)} optionsHeight={40} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-full flex justify-center mt-8">
          <button
            className={`w-[6.25rem] h-8 rounded flex justify-center items-center px-4 ${"bg-blue-20 hover:bg-blue-30"}  shadow`}
            onClick={handleJoin}
            id="joinButton"
          >
            {t("입장")}
          </button>
        </div>

        {isLoading && (
          <div className="absolute inset-0 w-full h-full flex justify-center items-center">
            <LoadingSimple />
          </div>
        )}
      </div>

      {isOpenTestDialog && (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-50 bg-black/75 flex justify-center items-center text-white">
          <ClickAwayListener onClickAway={() => setIsOpenTestDialog(false)} buttonRef={testBtnRef}>
            {ref => (
              <div ref={ref} className="z-50">
                <TestRoot
                  onSpeakerDeviceId={onSpeakerDeviceId}
                  onMicDeviceId={onMicDeviceId}
                  setIsOpen={(open: boolean) => setIsOpenTestDialog(open)}
                />
              </div>
            )}
          </ClickAwayListener>
        </div>
      )}

      <YesNoDialog
        msg={t("msg.카메라 혹은 마이크가 지정되지 않았습니다. 이대로 입장하시겠습니까?")}
        isOpen={isShowJoinYesNo}
        callback={(res: boolean) => {
          res ? executeJoin() : setIsShowJoinYesNo(false);
        }}
        setIsOpen={(open: boolean) => setIsShowJoinYesNo(open)}
        leftButtonText={t("아니오")}
        rightButtonText={t("예")}
      />
    </div>
  );
};

export default React.memo(withRoomContext(JoinPopupWithVideo));
