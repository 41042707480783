import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ImgNoDataSvg } from "assets/img-no-data-dark.svg";
import { IDuplicatePeerInfo } from "types/commonTypes";

interface IProps {
  peerInfo: IDuplicatePeerInfo;
}

const DuplicateJoin = ({ peerInfo }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="absolute inset-0 bg-[#222222] z-[999]">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-fit h-fit p-4 text-white border-0 flex items-center">
        <div className="flex flex-col items-center justify-start w-fit">
          <ImgNoDataSvg />
          <div className="mt-8 flex flex-col items-center text-base font-bold">
            <div className="flex shrink-0 text-base font-bold">{t("msg.동일한 아이디로 중복 로그인하여 자동으로 로그아웃 처리되었습니다.")}</div>

            <div className="flex w-fit flex-col mt-4">
              <div className="flex shrink-0">
                {t("로그인 아이디")} : {peerInfo.peerId}
              </div>
              <div className="flex shrink-0">
                {t("로그인 기기")} : {peerInfo.os}({peerInfo.browser})
              </div>

              {/* <div className="flex shrink-0">
                {t("ip")} : {peerInfo.ip}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuplicateJoin;
