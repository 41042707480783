import React, { useState } from "react";
import { ReactComponent as DeleteIcon } from "assets/btn-refresh.svg";
import { useTranslation } from "react-i18next";

interface IProps {
  onSearchText: (text: string) => void;
}

const SearchUser = (props: IProps) => {
  const { t } = useTranslation();
  const { onSearchText } = props;
  const [text, setText] = useState<string>("");

  const onInputTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    onSearchText(e.target.value);
  };

  const handleDeleteAll = () => {
    setText("");
    onSearchText("");
  };

  return (
    <div className="w-full h-[2.375rem] border border-[#86878b] bg-[#555] flex justify-between text-sm">
      <div className="flex flex-1">
        <input
          className="flex-1 h-full ml-2 placeholder-[#86878b] bg-[#555] outline-none text-sm bg-no-repeat bg-[url('./assets/icon-search.svg')] bg-left-1 pl-8"
          placeholder={t("users.참여자 검색")}
          onChange={onInputTextChange}
          value={text}
        ></input>
      </div>

      <DeleteIcon className="h-full w-[1.8rem] px-2 justify-center items-center mr-1 cursor-pointer" onClick={handleDeleteAll} />
    </div>
  );
};

export default SearchUser;
