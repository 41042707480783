import config from "config";
import { IRecListItem } from "lib/recording/RecordingListDb";
import { saveAs } from "file-saver";
import { Producer } from "mediasoup-client/lib/types";
import { MyConsumer } from "types/clientTypes";
import { store } from "store/store";
import { RecordingState, setLatestRecordingStartTime, setLocalRecordingState } from "store/recordingSlice";
import { setIsShowRecordingControl } from "store/windowControlSlice";
import { recordingDb } from "./RecordingDb";
import { isSafari } from "react-device-detect";
import { recordingListDb } from "./RecordingListDb";
import { format } from "date-fns";

export default class ScreenRecorder {
  private static instance: ScreenRecorder;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new this();
    }
    return this.instance;

    // return this.instance || (this.instance = new this());
  }

  private mediaRecorder: MediaRecorder | null = null;
  private mediaStream: MediaStream | null = null;
  private recorderStream: MediaStream | undefined | null = null;
  private startTime: number = 0;
  private endTime: number = 0;
  public prevRecTime: number = 0;

  // mixer
  private audioContext: AudioContext | null = null;
  private audioDest: MediaStreamAudioDestinationNode | null = null;
  private gainNode: GainNode | null = null;
  private audioConsumersMap = new Map<string, MediaStreamAudioSourceNode>();

  private micProducerStreamSource: MediaStreamAudioSourceNode | null = null;
  private mimeTypes = [
    { type: "video/mp4", transType: "video/mp4;codecs=h264", ext: "mp4" },
    { type: "video/webm;codecs=h264", transType: "video/mp4", ext: "webm" },
    { type: "video/webm;codecs=vp9", transType: "video/webm;codecs=vp9", ext: "webm" },
    { type: "video/webm;codecs=vp8", transType: "video/webm;codecs=vp8", ext: "webm" },
    { type: "video/webm", transType: "video/webm", ext: "webm" },
  ];
  private mimeType = "video/webm";
  public transType = "video/webm";
  public fileExt = "webm";
  private sliceSize = 10000;
  public startKey: IDBValidKey = 1;
  public endKey: IDBValidKey = 1;

  checkSupportMimeType() {
    const findType = this.mimeTypes.find(item => MediaRecorder.isTypeSupported(item.type));
    if (findType) {
      console.log("recording supported type", findType);
      this.mimeType = findType.type;
      this.transType = findType.transType;
      this.fileExt = findType.ext;
    }
  }

  async startRecording(additionalAudioTracks: MediaStreamTrack[]): Promise<void> {
    store.dispatch(setLocalRecordingState(RecordingState.Prepare));

    this.audioContext = new AudioContext();
    this.audioDest = this.audioContext.createMediaStreamDestination();
    this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioDest);

    try {
      const chromeOptions = { ...config.displayMedia.chrome, preferCurrentTab: false, audio: false };
      this.mediaStream = await navigator.mediaDevices.getDisplayMedia(!isSafari ? chromeOptions : config.displayMedia.safari);
    } catch (error) {
      store.dispatch(setIsShowRecordingControl(false));
    }

    if (!this.mediaStream) {
      store.dispatch(setLocalRecordingState(RecordingState.Stop));
      return;
    }

    const lastRecord = await recordingDb.getLastRecord();
    if (lastRecord) {
      this.startKey = (lastRecord.key as number) + 1;
      this.endKey = this.startKey;
    }

    this.mediaStream.getVideoTracks().forEach(track => {
      track.addEventListener("ended", e => {
        console.log(`displayMediaStream ${track.kind} track ended event: ${JSON.stringify(e)}`);
        this.stopRecording();
      });
    });

    this.recorderStream = this.mixer(this.mediaStream, additionalAudioTracks);
    if (!this.recorderStream) return;

    this.checkSupportMimeType();
    console.log("recording mimeType", this.mimeType, this.fileExt, this.transType);

    this.mediaRecorder = new MediaRecorder(this.recorderStream, { mimeType: this.mimeType, videoBitsPerSecond: 2000000 });
    if (!this.mediaRecorder) return;

    this.mediaRecorder.onstart = () => {
      console.log("mediaRecorder onstart");
      this.startTime = Date.now();
      this.prevRecTime = 0;
      store.dispatch(setLocalRecordingState(RecordingState.Recording));
      store.dispatch(setLatestRecordingStartTime(new Date()));
    };

    this.mediaRecorder.ondataavailable = async (event: BlobEvent) => {
      if (event.data.size > 0) {
        // console.log("recording put size", event.data.size, recordingDb?.storeName);
        const validKey = await recordingDb?.putData(event.data);
        if (validKey) {
          this.endKey = validKey;
        }
      }
    };

    this.mediaRecorder.onstop = () => {
      this.endTime = Date.now();
      console.log("mediaRecorder onstop");

      let recTime = this.prevRecTime;

      if (store.getState().recording.localRecordingState !== RecordingState.Pause) {
        recTime += this.endTime - this.startTime;
      }

      store.dispatch(setLocalRecordingState(RecordingState.Complete));

      setTimeout(() => {
        recordingListDb.putData({
          name: format(new Date(), "yyyyMMdd-Hms"),
          secGap: Math.floor(recTime / 1000),
          startTime: this.startTime,
          endTime: this.endTime,
          startKey: this.startKey,
          endKey: this.endKey,
        });
      }, 1000);
    };

    this.mediaRecorder.onpause = () => {
      this.prevRecTime += Date.now() - this.startTime;
      console.log("mediaRecorder onpause", this.prevRecTime);
      this.startTime = Date.now();
      store.dispatch(setLocalRecordingState(RecordingState.Pause));
    };

    this.mediaRecorder.onresume = () => {
      console.log("mediaRecorder onresume");
      this.startTime = Date.now();
      store.dispatch(setLatestRecordingStartTime(new Date()));
      store.dispatch(setLocalRecordingState(RecordingState.Recording));
    };

    this.mediaRecorder.start(this.sliceSize);
  }

  async stopRecording(): Promise<void> {
    if (this.mediaRecorder && this.mediaStream) {
      this.mediaRecorder.stop();
      this.mediaStream.getTracks().forEach(track => track.stop());
      this.mediaStream = null;
      this.mediaRecorder = null;
    }

    if (this.micProducerStreamSource) this.micProducerStreamSource = null;
    this.audioConsumersMap.clear();
  }

  clearAllData() {
    recordingDb?.clearAll();
  }

  mixer(videostream: MediaStream, audioTracks: MediaStreamTrack[] | []) {
    if (!this.audioContext || !this.audioDest) {
      console.error("mixer ctx or dest is null");
      return;
    }

    // AUDIO
    audioTracks.forEach(audiotrack => {
      if (this.audioContext && this.audioDest) {
        this.audioContext.createMediaStreamSource(new MediaStream([audiotrack])).connect(this.audioDest);
      }
    });

    // VIDEO+AUDIO
    if (videostream.getAudioTracks().length > 0) {
      console.log("화면스트림에 audio track이 포함되어 있음");
      this.audioContext.createMediaStreamSource(videostream).connect(this.audioDest);
    }
    // VIDEO MIX
    let tracks = this.audioDest.stream.getTracks();
    tracks = tracks.concat(videostream.getVideoTracks());

    return new MediaStream(tracks);
  }

  async pauseLocalRecording() {
    if (!this.mediaRecorder) return;
    this.mediaRecorder.pause();
    store.dispatch(setLocalRecordingState(RecordingState.Pause));
  }

  async resumeLocalRecording() {
    if (!this.mediaRecorder) return;
    this.mediaRecorder.resume();
  }

  checkMicProducer(producers: Producer[]) {
    console.log("checkMicProducer 호출됨");
    if (!this.audioContext || !this.audioDest || !this.mediaRecorder) {
      return;
    }

    if (this.mediaRecorder.state === "recording" || this.mediaRecorder.state === "paused") {
      const micProducer = producers.find(p => p.appData.source === "mic");
      try {
        if (micProducer) {
          if (this.micProducerStreamSource) {
            this.micProducerStreamSource.disconnect(this.audioDest);
          }

          if (!micProducer.track) {
            return;
          }
          this.micProducerStreamSource = this.audioContext.createMediaStreamSource(new MediaStream([micProducer.track]));
          console.log("mic stream connect");
          this.micProducerStreamSource.connect(this.audioDest);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  checkAudioConsumer(consumers: MyConsumer[]) {
    if (this.mediaRecorder != null && (this.mediaRecorder.state === "recording" || this.mediaRecorder.state === "paused")) {
      consumers.forEach(c => {
        if (c.consumer.kind === "audio") {
          if (!this.audioConsumersMap.has(c.consumer.id)) {
            if (this.audioContext && this.audioDest) {
              const audioConsumerStreamSource = this.audioContext.createMediaStreamSource(new MediaStream([c.consumer.track]));
              console.log("새로운 참여자의 오디오는 녹화대상에 포함한다.");
              audioConsumerStreamSource.connect(this.audioDest);
              this.audioConsumersMap.set(c.consumer.id, audioConsumerStreamSource);
            }
          }
        }
      });

      try {
        for (let item of Array.from(this.audioConsumersMap)) {
          if (!consumers.find(c => item[0] === c.consumer.id)) {
            if (this.audioDest) {
              console.log("존재하지 않는 컨슈머의 오디오는 녹화대상에서 제외한다.");
              item[1].disconnect(this.audioDest);
              this.audioConsumersMap.delete(item[0]);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async downloadLastFile(): Promise<void> {
    const lastRecord = await recordingListDb.getLastRecord();
    if (!lastRecord) return;

    this.downloadFile(lastRecord.value);
  }

  async downloadFile(item: IRecListItem) {
    const recData = await recordingDb?.getAllData(item.startKey, item.endKey);
    if (!recData) {
      console.log("RecordingStoreList recData is null");
      return;
    }

    const blob = new Blob(recData, { type: recorder.transType });
    const roomTitle = store.getState().room.info.title.replace(/[\\/:*?"<>|"']/g, "_");
    const fileName = `ucworks-vc-${roomTitle}-${format(item.startTime, "yyyyMMdd-HHmmss")}_${item.secGap}.${recorder.fileExt}`;
    saveAs(blob, fileName);
  }
}

export const recorder = ScreenRecorder.getInstance();
