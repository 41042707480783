import RoomClient from "RoomClient";
import ClickAwayListener from "components/ClickAwayListener";
import AttachmentItem from "./AttachmentItem";
import LoadingSimple from "components/LoadingSimple";
import NotSupportDocFileFormat from "./NotSupportDocFileFormat";
import { useCallback, useEffect, useState } from "react";
import { withRoomContext } from "RoomContext";
import { setConvertedFileList, setCurrentIndex, setCurrentFileId, setIsWhiteBoard } from "store/docSharingSlice";
import { selectAuthUserInfo } from "store/authSlice";
import { setFileStatus, pushAttachments, selectAttachments, setAttachments, setAttachment, removeAttachment } from "store/attachmentSlice";
import {
  setIsShowDocSharingHost,
  setIsShowDocList,
  setIsShowDocSharingClient,
  selectIsShowDocSharingClient,
  selectIsShowScreenSharingHost,
  selectIsShowScreenSharingClient,
} from "store/windowControlSlice";
import { selectIsLocalHost, selectRoomSetting } from "store/roomInfoSlice";
// import { selectRoomSetting } from "store/roomSettingSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { Attachment, FileStatus } from "./AttachmentTypes";
import { ReactComponent as IconPlus } from "assets/icon-plus.svg";
import { uploadFile as uploadFileV6 } from "api/common/file/uploadFile";
import { uploadFile as uploadFileV5 } from "api/manager/file/uploadFile";
import { deleteFromStorage as deleteFileV6 } from "api/common/file/deleteFile";
import { deleteFile as deleteFileV5 } from "api/manager/file/deleteFile";
import { toast } from "react-toastify";
import { SocketIoConvertChecker } from "api/cloudconvert/socketIoConvertChecker";
import { deleteAttachment } from "api/manager/attachments/delete";
import { registerAttachments } from "api/manager/attachments/create";
import { getAttachments } from "api/manager/attachments/list";
import { updateAttachment as updateAttachmentToMgrServer } from "api/manager/attachments/update";
import { useMediaQuery } from "react-responsive";
import { v4 as uuidv4 } from "uuid";
import { selectEnv } from "store/envSlice";

interface IProps {
  onCloseView: () => void;
  roomClient: RoomClient;
  buttonRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}

const DocSharingFileList = (props: IProps) => {
  const { onCloseView, roomClient, buttonRef, isMobile } = props;
  const { t } = useTranslation();
  const [isInitEnd, setIsInitEnd] = useState(false);
  const [isOpenNotSupportFile, setIsOpenNotSupportFile] = useState(false);
  const user = useSelector(selectAuthUserInfo);
  const attachments = useSelector(selectAttachments);
  const isShowClient = useSelector(selectIsShowDocSharingClient);
  const isLocalHost = useSelector(selectIsLocalHost);
  const isShowScreenSharingHost = useSelector(selectIsShowScreenSharingHost);
  const isShowScreenSharingClient = useSelector(selectIsShowScreenSharingClient);
  const roomSetting = useSelector(selectRoomSetting);
  const isLandscape = useMediaQuery({ orientation: "landscape" });
  const dispatch = useDispatch();
  const env = useSelector(selectEnv);

  const getFileStatusFromConvertServer = (status: string): FileStatus => {
    if (status === "finished") return "CONVERTED";
    else if (status === "error") return "CONVERT_ERROR";
    else if (status === "processing") return "CONVERTING";
    else return "UPLOADED";
  };

  const fetchServerData = async () => {
    console.log("fetchServerData start");
    const list = await getAttachments(roomClient.roomId);

    for (const li of list) {
      li.status = "UPLOADED";

      roomClient.docFileConvertCheck(li.convertJobId).then(checkRes => {
        if (checkRes) {
          console.log("docFileConvertCheck res", checkRes.status);
          dispatch(setAttachment({ ...li, status: getFileStatusFromConvertServer(checkRes.status) }));
        } else {
          console.log(`docFileConvertCheck res ${li.filename}`, checkRes);
          startConvert(li);
        }
      });
    }

    dispatch(setAttachments(list));
    setIsInitEnd(true);

    // todo: 서버의 Room객체에 문서공유 파일 목록을 저장했다가 회의실 삭제 시 폴더 삭제할것!
  };

  useEffect(() => {
    fetchServerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, roomClient.roomId]);

  const openConvertedFile = useCallback(() => {
    dispatch(setCurrentIndex(0));
    dispatch(setIsShowDocSharingHost(true));
    dispatch(setIsShowDocList(false));
  }, [dispatch]);

  const startConvert = useCallback(
    async (attachment: Attachment, isOpenAfterConvert: boolean = false) => {
      const startRes = await roomClient.startDocFileConvertJob(attachment.filename, attachment.presignedurl);
      dispatch(setAttachment({ ...attachment, convertJobId: startRes.id, status: "CONVERTING" }));
      updateAttachmentToMgrServer(attachment.fileid, startRes.id);

      SocketIoConvertChecker.getInstance().checkForCompletion(startRes.id, async files => {
        await roomClient.downloadFromConvertServer(attachment.fileid, files);
        dispatch(setFileStatus({ fileid: attachment.fileid, percent: 0, status: "CONVERTED" }));

        if (isOpenAfterConvert) {
          const resFiles = files.map(file => file.url);
          if (resFiles) dispatch(setConvertedFileList(resFiles));
          openConvertedFile();
        }
      });

      const timer = setInterval(async () => {
        const checkRes = await roomClient.docFileConvertCheck(startRes.id);
        console.log("timer checkRes", checkRes);
        if (checkRes.status === "finished" || checkRes.status === "error") {
          console.log("더이상 변환감지 필요없음~~~~~~~~~~~~~~~~~~~~~~");
          clearInterval(timer);
          SocketIoConvertChecker.getInstance().disconnectSocket();
          const fileStatus = checkRes.status === "finished" ? "CONVERTED" : "CONVERT_ERROR";
          dispatch(setFileStatus({ fileid: attachment.fileid, percent: 0, status: fileStatus }));
        }
      }, 5000);
    },
    [dispatch, openConvertedFile, roomClient],
  );

  useEffect(() => {
    if (attachments.find(att => att.status === "CONVERTING")) return;
    const attToConvert = attachments.find(att => att.status === "UPLOADED" && !att.convertJobId);
    if (attToConvert) {
      console.log("### 변환할 파일 존재", attToConvert);
      dispatch(setAttachment({ ...attToConvert, status: "CONVERTING" }));
      startConvert(attToConvert);
    }
  }, [attachments, dispatch, startConvert]);

  const syncUpload = async (files: Attachment[]) => {
    await files.reduce(async (prevTask, currTask) => {
      await prevTask;

      return new Promise(async resolve => {
        if (currTask.status === "ADDED") {
          dispatch(setFileStatus({ fileid: currTask.fileid, percent: 0, status: "UPLOADING" }));

          let res;
          if (env.UCWORKS_VER === "5") {
            res = await uploadFileV5(currTask.object, currTask.abortController, percent => {
              dispatch(setFileStatus({ fileid: currTask.fileid, percent, status: "UPLOADING" }));
            });
          } else {
            res = await uploadFileV6(currTask.object, currTask.fileid, currTask.abortController, percent => {
              dispatch(setFileStatus({ fileid: currTask.fileid, percent, status: "UPLOADING" }));
            });
          }

          if (res) {
            const updateAtt: Attachment = { ...currTask, status: "UPLOADED", presignedurl: res.data.result as string };
            const regRes = await registerAttachments(roomClient.roomId, [updateAtt]);
            if (regRes?.result) {
              dispatch(setAttachment(updateAtt));
            }
          } else {
            dispatch(setAttachment({ ...currTask, status: "UPLOAD_ERROR" }));
          }
        }

        resolve();
      });
    }, Promise.resolve());
  };

  const onDrop = (dropFiles: File[]) => {
    const tempFiles: Attachment[] = [];
    const supportExt = ["ppt", "pptx", "doc", "docx", "xls", "xlsx", "hwp", "pdf", "jpg", "jpeg", "png", "gif", "bmp"];
    let notSupportFileCount = 0;

    dropFiles.forEach(file => {
      const lastDot = file.name.lastIndexOf(".");
      const ext = file.name.substring(lastDot + 1).toLowerCase();
      if (supportExt.includes(ext)) {
        tempFiles.push({
          fileid: uuidv4().toString(),
          object: file,
          status: "ADDED",
          abortController: new AbortController(),
          progress: 0,
          type: "SHARED",
          filename: file.name.normalize(),
          filesize: file.size,
          bid: user?.bid ?? "",
          presignedurl: "",
          convertJobId: "",
        });
      } else {
        notSupportFileCount++;
      }
    });

    dispatch(pushAttachments(tempFiles));
    syncUpload(tempFiles);

    if (notSupportFileCount > 0) {
      setIsOpenNotSupportFile(true);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true });

  const onRemoveFile = async (fileid: string, filename: string) => {
    const findAtt = attachments.find(att => att.fileid === fileid);
    if (findAtt) {
      if (findAtt.bid !== user?.bid) {
        toast.error(t("doc.업로드한사람만삭제가능"));
        return;
      }

      dispatch(setFileStatus({ fileid: findAtt.fileid, percent: 0, status: "DELETING" }));

      if (findAtt.status === "UPLOADING") {
        findAtt.abortController.abort();
      } else if (findAtt.status === "UPLOAD_ERROR") {
      } else {
        let delRes;

        if (env.UCWORKS_VER === "5") {
          delRes = await deleteFileV5(findAtt.presignedurl);
        } else {
          delRes = await deleteFileV6(fileid, filename, findAtt.filesize);
        }

        if (delRes.code === 200) {
          // console.log(`스토리지에서 파일 ${delRes.data.count}개 삭제됨`, delRes);
          toast.info(t("doc.스토리지 파일삭제 성공"));
          const delAttachRes = await deleteAttachment(findAtt.fileid);
          if (!delAttachRes?.result) toast.error(t("doc.관리서버 첨부파일 데이터 삭제 실패"));
        }
      }

      dispatch(removeAttachment(fileid));
      roomClient.deleteConvertedFiles(fileid);
    }
  };

  const onClickFile = async (fileid: string) => {
    if (roomSetting.isDocSharingOnlyHost && !isLocalHost) {
      toast.error(t("msg.사회자만 가능하도록 설정되어 있습니다."));
      return;
    }

    if (isShowScreenSharingHost || isShowScreenSharingClient) {
      toast.warn(t("msg.화면공유 중에는 문서공유를 할 수 없습니다."));
      onCloseView();
      return;
    }

    const find = attachments.find(att => att.fileid === fileid);
    if (!find || find.status === "CHECKING") return;

    if (isShowClient) dispatch(setIsShowDocSharingClient(false));

    if (!find.convertJobId) {
      startConvert(find, true);
      return;
    }

    dispatch(setFileStatus({ fileid, percent: 0, status: "CHECKING" }));

    const checkRes = await roomClient.docFileConvertCheck(find.convertJobId);
    if (checkRes === null) {
      startConvert(find, true);
      return;
    }

    if (checkRes && checkRes.status === "finished") {
      const exportTask = checkRes.tasks.find(task => task.name === "export-my-file");
      if (exportTask) {
        const resFiles = exportTask.result?.files.map(file => file.url);
        if (resFiles) dispatch(setConvertedFileList(resFiles));

        const isExistThumbnail = await roomClient.checkThumbnailImages(fileid);
        if (!isExistThumbnail && exportTask.result) {
          dispatch(setFileStatus({ fileid, percent: 0, status: "CONVERTING" }));
          await roomClient.downloadFromConvertServer(fileid, exportTask.result.files);
        }

        toast.info(t("doc.변환된 파일 로드"));

        dispatch(setFileStatus({ fileid, percent: 0, status: "CONVERTED" }));
        dispatch(setCurrentFileId(fileid));
        dispatch(setCurrentIndex(0));
        dispatch(setIsShowDocSharingHost(true));
        dispatch(setIsShowDocList(false));
      }
    } else if (checkRes.status === "waiting") {
      dispatch(setFileStatus({ fileid, percent: 0, status: "CONVERT_WAITING" }));
    } else if (checkRes.status === "processing") {
      dispatch(setFileStatus({ fileid, percent: 0, status: "CONVERTING" }));
    } else if (checkRes.status === "error") {
      dispatch(setFileStatus({ fileid, percent: 0, status: "CONVERT_ERROR" }));
    }
  };

  const handleShowWhiteBoard = async () => {
    dispatch(setIsWhiteBoard(true));
    dispatch(setIsShowDocSharingHost(true));
    dispatch(setIsShowDocList(false));
  };

  const callbackTest = (res: boolean) => {
    console.log("callbackTest", res);
    setIsOpenNotSupportFile(res);
  };

  return (
    <ClickAwayListener onClickAway={onCloseView} buttonRef={buttonRef} isOpenSubWindow={isOpenNotSupportFile}>
      {ref => (
        <div
          ref={ref}
          className={`${
            isMobile ? "" : "md:absolute md:bottom-1 md:left-0 md:right-0 md:-translate-x-2/4 md:mx-9 max-h-[70vh]"
          } overflow-y-auto overflow-x-hidden flex flex-col items-center w-[18.75rem] z-50 rounded shadow bg-[#333] select-none text-white`}
        >
          <div className="w-full">
            <div className="text-sm">
              <div className="flex w-full h-9 justify-start items-center border-b border-[#555]">
                <div className="w-full text-sm font-bold text-white ml-4 pt-1">{t("doc.문서 공유하기")}</div>
              </div>

              <div
                className={`${isMobile && isLandscape && "max-h-[60vh]"} h-[16.219rem] text-[#555] text-sm overflow-auto p-2 bg-[#333]`}
                {...getRootProps()}
              >
                {isInitEnd ? (
                  <>
                    {!attachments.length && (
                      <div className="w-full h-full px-8 text-center flex items-center justify-center text-white border border-dashed border-[#555]">
                        <p>{t("doc.파일을 드래그앤드랍하여 추가하세요")}</p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full h-full flex items-center justify-center text-red-400">
                    <div className="w-4 h-4">
                      <LoadingSimple size={32} />
                    </div>
                  </div>
                )}

                <ul className="flex flex-col gap-2 overflow-hidden text-white">
                  {attachments.map(attachment => (
                    <AttachmentItem
                      key={attachment.fileid}
                      fileid={attachment.fileid}
                      name={attachment.filename}
                      size={attachment.filesize}
                      progress={attachment.progress}
                      status={attachment.status}
                      onRemoveFile={onRemoveFile}
                      onClick={() => onClickFile(attachment.fileid)}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <input {...getInputProps()} />
          </div>

          {(!roomSetting.isWhiteBoardOnlyHost || isLocalHost) && (
            <div className="w-full flex items-center justify-center border-t border-[#555]" onClick={handleShowWhiteBoard}>
              <IconPlus />
              <button className="h-[2.75rem] text-sm flex justify-center items-center hover:text-blue-30 ml-2">{t("doc.빈 화이트보드")}</button>
            </div>
          )}

          {(!roomSetting.isDocSharingOnlyHost || isLocalHost) && (
            <div className="w-full flex items-center justify-center border-t border-[#555]" onClick={e => e.stopPropagation()}>
              <IconPlus />
              <button className="h-[2.75rem] text-sm flex justify-center items-center hover:text-blue-30 ml-2" onClick={open}>
                {t("doc.문서 추가")}
              </button>
            </div>
          )}

          <NotSupportDocFileFormat isOpen={isOpenNotSupportFile} setIsOpen={() => callbackTest(false)} />
        </div>
      )}
    </ClickAwayListener>
  );
};

export default withRoomContext(DocSharingFileList);
