import React from "react";
import { SimpleUserInfo } from "types/commonTypes";
import { useTranslation } from "react-i18next";

interface IProps {
  text: string;
  isLocal?: boolean;
  localName: string;
  user?: SimpleUserInfo;
  time?: string;
  emoticon?: string;
  targetName?: string;
}

const ChatBox = ({ text, isLocal, localName, user, time, emoticon, targetName }: IProps) => {
  const { t } = useTranslation();

  let boxStyle = "flex flex-col max-w-[20rem] rounded border p-[0.5rem] text-[#555] mt-1 shadow-md select-text";
  if (isLocal) {
    boxStyle += " bg-[#e6edf8] ml-0 mr-6";
  } else {
    boxStyle += " bg-[#f1f3f6] ml-0 mr-0";
  }

  return (
    <div className={boxStyle}>
      {emoticon && (
        <div className="overflow-hidden w-[7.5rem] h-[7.5rem] shrink-0 justify-center items-center mb-1">
          <img className="flex justify-center items-center w-full h-full object-cover" src={emoticon} alt="" />
        </div>
      )}

      <div>
        {targetName && isLocal && <span className="text-sm text-[#1f62b9] font-bold grow">/w {targetName}</span>}
        {targetName && !isLocal && <span className="text-sm text-[#1f62b9] font-bold grow">({t("chat.귓속말")})</span>}
        <span className="text-sm whitespace-pre-wrap text-start break-all">{text}</span>
      </div>
    </div>
  );
};

export default ChatBox;
